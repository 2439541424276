import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'xx-large',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '5px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },  

    buttonStyle: {
      paddingRight: '5px',
    }

}));

export default function RycomPageHeader({title, links}) {
  const classes = useStyles();
  const [keycloak] = useKeycloak();

              
  const homeButton=<Link key="0" className={classes.buttonStyle} to="/topics"><Button
                  color="primary" 
                variant="contained" size="medium">
                  Home</Button></Link>;

  const buttonLinks= links.map((link,index)=>{

            console.log("inside the button clicks:"+link.role);
            console.log("inside the button clicks:"+keycloak.hasRealmRole(link.role)||keycloak.hasResourceRole(link.role));

          if(link.title == 'Back'){
            return <Link className={classes.buttonStyle} to={link.url}>
              <Button
                  color="warning"
                  variant="contained" size="medium">
                  {link.title}
              </Button>
            </Link>;
          } else{
            if(link.role!==undefined && (keycloak.hasRealmRole(link.role)||keycloak.hasResourceRole(link.role))){
                  return <Link   
                                              key={++index}
                                              className={classes.buttonStyle} to={link.url}><Button
                                color="primary" 
                                variant="contained" size="medium">
          {link.title}</Button></Link>}
          }
        });

  buttonLinks.push(homeButton);    

  return (
    <Card className={classes.aocard}>
    <div className={classes.header}>
     <span className={classes.title} >
      {title}</span>
     <span className={classes.toolbar}>
       {buttonLinks}
      </span>
     </div>
    </Card>
  );
}
