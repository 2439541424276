import React, { Component } from 'react'
import RycomTable from '../../../common/RycomTable'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomTableHeader from '../../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as DocumentClassService from '../../../../services/DocumentClassService';
import environment from '../../../../environment'



class DocumentVertexList extends Component {


  constructor(props) {
    super(props)
    this.state = {
      vertexes: [],
    }
  }

  componentDidMount() {
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //DocumentClassService.getAllVertex(parentcompanyid)
    DocumentClassService.getAllVertex(paramID)
      .then((res) => {
        if (res && res.data) {
          console.log(res.data)
          this.setState({ vertexes: res.data });
        }
      })
      .catch((error) => {
        console.error('getVertex =>', error)
        alert('Vertex Retrieval Error');
      })
  }

  render() {
    const columns = [
      { id: 'className', label: 'Vertex Name', minWidth: 170 },
      { id: 'classDescription', label: 'Description', minWidth: 170 }
     ];

     const rows=this.state.vertexes.map((vertex)=>{
       console.log(vertex)
                  console.log(`/topics/documentvertex/detail/${vertex.classId}`);
                   vertex.className=<Link to={`/topics/documentvertex/detail/${vertex.classId}`} >{vertex.className}</Link>
                                                     return vertex;
              });


     return (<div>
        <RycomPageHeader title="Document Vertex List" links={[{title:'Add Vertex',url:'/topics/documentvertex/form', role:"create:graph"}]}></RycomPageHeader>
        <RycomTableHeader title="Vertex" buttonTitle="Add Vertex"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default DocumentVertexList;
