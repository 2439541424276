import axios from "axios";
import environment from '../environment';

export const login = (payload) => {
    
   
    const payload1={
        client_id : 'abc',
        username : 'testuser',
        password : 'testuser123',
        grant_type : "password",
    };
    return axios.post(`http://localhost:8080/auth/realms/rapid/protocol/openid-connect/token`,
               payload1
                );
}

