import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'


export const saveEdge = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/edgeRelationship`,
          vertexPayload, addBearerToken());
}
export const updateEdge = (vertexPayload) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edgeRelationship`,
          vertexPayload, addBearerToken());
}

export const getAllEdge = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeRelationship/getByParentCompany/${companyId}`, addBearerToken());
}

export const getAllEdgeByVertexType = (vertexType) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeRelationship/vertex/${vertexType}`, addBearerToken());
}


export const getEdgeById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeRelationship/${id}`, addBearerToken());
}
export const deleteEdge = (id) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edgeRelationship/delete/${id}`, {}, addBearerToken());
}
export const deleteEdgeByVertexType = (vertexType) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edgeRelationship/deleteByVertex/${vertexType}`, {}, addBearerToken());
}

