import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveVertex = (task) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/task`,
            task, addBearerToken()
    );
}

export const updateVertex = (task) => {
    console.log(task);
    return axios.patch(`${environment.dataBaseUrl}/api/dms/task`,
            task, addBearerToken()
    );
}


export const executeTask = (task) => {
    console.log(JSON.stringify(task));
    return axios.post(`${environment.dataBaseUrl}/api/dms/exectask`,
            task, addBearerToken()
    );
}

export const deleteTask = (task) => {
    console.log(JSON.stringify(task));
    return axios.patch(`${environment.dataBaseUrl}/api/dms/task/delete`,
            task, addBearerToken()
    );
}

export const deleteTaskById = (taskId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/delete/${taskId}`,
             addBearerToken()
    );
}

export const deleteTaskByConnectorId = (connectorId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/deleteByConnector/${connectorId}`,
             addBearerToken()
    );
}

export const deleteTaskBySchedulerId = (schedulerId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/deleteByScheduler/${schedulerId}`,
             addBearerToken()
    );
}


export const deleteTaskByVertexTypeId = (vertexTypeId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/deleteByVertex/${vertexTypeId}`,
             addBearerToken()
    );
}

export const getTaskStatusDetail = (taskStatusId, config) => {
    const token=localStorage.getItem('react-token')
    config.headers= { Authorization: `Bearer ${token}` }
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/status/detail/${taskStatusId}`, config, addBearerToken());
  }

export const getAllTask = (companyId,config) => {
    const token=localStorage.getItem('react-token')
    config.headers= { Authorization: `Bearer ${token}` }
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/parentcompany/${companyId}`, config, addBearerToken());
}


export const getAllTaskByConnector= (connectorId) => {
    const token=localStorage.getItem('react-token')
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/connector/${connectorId}`, addBearerToken());
}

export const getAllTaskByDatamapper= (datamapperId) => {
    const token=localStorage.getItem('react-token')
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/datamapper/${datamapperId}`, addBearerToken());
}

export const getAllTaskByScheduler= (scheduledId) => {
    const token=localStorage.getItem('react-token')
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/scheduler/${scheduledId}`, addBearerToken());
}

export const getAllTaskByVertex= (vertexId) => {
    const token=localStorage.getItem('react-token')
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/vertex/${vertexId}`, addBearerToken());
}


export const deleteTaskByDatamapperId = (datamapperId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/deleteByDatamapper/${datamapperId}`,
             addBearerToken()
    );
}

export const deleteTaskByVertexId = (vertexId) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/task/deleteByVertex/${vertexId}`,
             addBearerToken()
    );
}



export const getAllTaskStatus = () => {
  return axios.get(`${environment.dataBaseUrl}/api/dms/task/status`, addBearerToken());
}

export const getAllTaskStatusDetail = (taskStatusId) => {
  return axios.get(`${environment.dataBaseUrl}/api/dms/task/status/${taskStatusId}`, addBearerToken());
}

export const getTaskById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/${id}`, addBearerToken());
}
