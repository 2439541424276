import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import * as ZoneService from '../../../services/ZoneService';


import { useParams } from 'react-router-dom'


export default function OrganisationZoneDetail() {

    let { id } = useParams();

    const [zoneValues, setZoneValues] =useState();

    useEffect(() => {
        ZoneService.getZoneById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setZoneValues(res.data);
                }
            })
            .catch((error) => {
                console.error('getCompanyById =>', error)
            })
    }, [])
   
    return (<div>
        <RycomPageHeader title={zoneValues ? `Zone Details about ${zoneValues.zoneName}` : 'Organisation Details'} links={[{ title: 'Back', url: '/company/zone' }, { title: 'Edit', url: '/form' }]}></RycomPageHeader>
        <RycomSectionHeader title="Zone Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Zone Name" value={zoneValues ? zoneValues.zoneName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Zone Description" value={zoneValues ? zoneValues.zoneDescription : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="organisation" value={zoneValues ? zoneValues.companyName : ''}></RycomInfoPair>
            </Grid>
           
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Venue" value={zoneValues ? zoneValues.venueName : ''}></RycomInfoPair>
                </Grid>
            
            </Grid>
        </div>);
}
