import React from 'react';


import RycomSectionHeader from '../common/RycomSectionHeader'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  invalidMessage: {
    display: 'inline-block',
    fontSize: '12px',
    color: 'red',
    paddingLeft: '12px',
  }
}));

export default function MultipleValueHandler(props) {

 const propertyKeyHandler = (metadatacontent, key) => {
    metadatacontent.key = key;
    const updateMetadataProperties = props.parameters.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    props.setParameters(updateMetadataProperties);
   props.setParametersContent(updateMetadataProperties);
  }

  const propertyValueHandler = (metadatacontent, value) => {
    metadatacontent.value = value;
    const updateMetadataProperties = props.parameters.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    props.setParameters(updateMetadataProperties);
    props.setParametersContent(updateMetadataProperties);
  }

  const canAddParameters = () => {
    const length = props.parameters.length - 1
    return props.parameters[length].v_id
  };

  const newPropertyProfile = () => {
    let id = 1;
    if (props.parameters.length > 0) {
      let lastKey = canAddParameters();
      id = ++lastKey;
    }

    console.log(id)
    const newPropertyProfile = [...props.parameters, {
      'v_id': id,
      'key': null,
      'value': null,
    }]
  
    props.setParameters(newPropertyProfile);
    props.setParametersContent(newPropertyProfile)
  }

  const deleteParameterProfile = (index) => {
    const deleteProfiles = [...props.parameters]
    if (deleteProfiles.length > 1) {
      deleteProfiles.splice(index, 1);
      props.setParameters(deleteProfiles);
       props.setParametersContent(deleteProfiles)
    } else {
        props.setParameters([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
      props.setParametersContent([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
     
    }
  };

  if (!props.parameters.length) {
    deleteParameterProfile();
  }


 const parametersList= props.parameters.map((metadata, index) => {
    metadata.id = index;
    return <RycomGrid>
   <RycomGridItem>
        <div>  <TextField
          id="outlined-multiline-flexible"
          label="key"
          variant="outlined"
          inputRef={props.register({
            required: {
              value: true,
              message: 'Metadata key is required'
            }
          })}
          value={metadata.key}
          onChange={(event) => {
            propertyKeyHandler(metadata, event.target.value)
            return event.target.value
          }} />
          <span>
            <span>
              {props.errors['edgepropertykey' + metadata.id] && <span>{props.errors['edgepropertykey' + metadata.id].message}</span>}
            </span>
          </span>
        </div>
      </RycomGridItem>
      <RycomGridItem>
        <span>
          <TextField
            id="outlined-multiline-flexible"
            label="Value"
            variant="outlined"
            inputRef={props.register({
              required: {
                value: true,
                message: 'Edge key is required'
              }
            })}
            value={metadata.value}
            onChange={(event) => {
              console.log(event.target.value)
              propertyValueHandler(metadata, event.target.value)
              return event.target.value
            }}
          />
          <span>
            {props.errors['PropertyValue' + metadata.id] && <span>{props.errors['PropertyValue' + metadata.id].message}</span>}
          </span>
        </span>
      </RycomGridItem>
      <RycomGridItem>
        <Button color="secondary" variant="contained" size="small" disabled={props.parameters.length == 1} value="delete Edge"
          onClick={(e) => {
            const deleteProfiles = [...props.parameters]

            if (props.parameters.length > 1) {
              const deleteProfiles1 = deleteProfiles.filter(edge => {
                return edge.v_id != metadata.v_id
              });
              props.setParameters(deleteProfiles1);
            } else {
                props.setParameters([{
                'v_id': 1,
                'key': null,
                'value': null
              }])
            }
          }}>Delete {props.propertyName}</Button>
      </RycomGridItem>
    
</RycomGrid>});


  return<div><RycomSectionHeader title="Header Details"></RycomSectionHeader>
    {parametersList}
    <Grid container spacing={12} className={props.classes.rycomButtonGroup} >
  <Grid item xs={12} sm={4} lg={4} xl={4} >
  </Grid>
  <Grid item xs={12} sm={4} lg={4} xl={4} >
  </Grid>
  <Grid item xs={12} sm={4} lg={4} xl={4} >
    <span>
      <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newPropertyProfile}>Add {props.propertyName}</Button>
    </span>
  </Grid>
</Grid>
   </div>
}