import React, { Component } from 'react'
import * as OrganisationService from '../../../services/OrganisationService'

import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import environment from '../../../environment';

class OrganisationList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      orgValues: [],
    }
  }

   getParentOrgId(){
    const userInfo=localStorage.getItem("userInfo"); 
    return userInfo['parentcompanyid'];
  }

  componentDidMount() {
    console.log('componentDidMount');
  const userInfoJson=localStorage.getItem("userInfo"); 
  const userInfo=JSON.parse(userInfoJson);
  const parentcompanyid=userInfo['parentcompanyid'];
  const companyid=userInfo['companyid'];
  const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
  //OrganisationService.getCompanyByParentId(parentcompanyid)
  //OrganisationService.getCompanyByParentId(paramID)
  OrganisationService.getCompanyParentById(companyid)
      .then((res) => {
        if (res && res.data && res.data) {
          const orgValues = res.data;
          console.log(res);
          this.setState({ orgValues: orgValues });
        }
      })
      .catch((error) => {
        console.error('dataMappers =>', error)
      })
  }

  render() {
    const columns = [
      { id: 'companyname', label: 'Organisation', minWidth: 170 },
      { id: 'isactive', label: 'Active', minWidth: 100 },

    ];
      const rows=this.state.orgValues.map((company)=>{
               //   const currentTimestamp=company.datecreated?new Date( company.datecreated *1000):'';
               //   console.log(currentTimestamp);
                  company.companyname=<Link to={`/topics/company/detail/${company.companyid}`} >{company.companyname}</Link>
                  company.isactive=company.isactive?'TRUE':'FALSE'
                 // company.datecreated=currentTimestamp;
                                                     return company;


              });
      return (<div>
        <RycomPageHeader title=""  links={[{title:'Add Company',url:'/topics/company/form',role:"create:org"}]}>></RycomPageHeader>
        <RycomTableHeader title="Company"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default OrganisationList;
