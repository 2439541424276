import React, { Component } from 'react'
import RycomTable from '../../../common/RycomTable'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomTableHeader from '../../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as EdgeService from '../../../../services/EdgeService';
import environment from '../../../../environment'

class EdgeList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      edges: [],
    }
  }

  componentDidMount() {//5ec513ac32b4af33e72f4edb
    console.log('componentDidMount');
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //EdgeService.getAllEdge(parentcompanyid)
    EdgeService.getAllEdge(paramID)
      .then((res) => {
        if (res && res.data && res.data) {
          let edges = res.data;
          console.log('connectorInfo');
          console.log('res.data')
          console.log(res)
          const edgeList=edges.filter(edge=>edge.type==="CONFIG_TYPE")

          this.setState({ edges: edgeList });
        }
      })
      .catch((error) => {
        console.error('getEdges =>', error)
        alert('Edge Retrieval Error');

      })
  }

  render() {
    const columns = [
      { id: 'edgename', label: 'Edge Name', minWidth: 170 },
      { id: 'edgedescription', label: 'Description', minWidth: 170 }
    ];

     const rows=this.state.edges.map((edge)=>{
                   edge.edgename=<Link to={`/topics/edge/detail/${edge.edgeid}`} >{edge.edgename}</Link>
                                                     return edge;
              });

     return (<div>
        <RycomPageHeader title="Edge List" links={[{title:'Add Edge',url:'/topics/edge/form', role:"create:graph"}]}></RycomPageHeader>
        <RycomTableHeader title="Edge" buttonTitle="Add Edge"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default EdgeList;
