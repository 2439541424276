const environment ={
    schedulerBaseUrl:"https://api.s2hweb.net/scheduler",
    orgBaseUrl:"https://api.s2hweb.net/org",
    dataBaseUrl:"https://api.s2hweb.net/data-api",
    graphDbUrl:"https://rapidquerydevdb.rycom.com",
    connectorBaseUrl:"https://api.s2hweb.net/connector",
    graphDbUrl:"https://rapidquerydevdb.rycom.com",
    url:'https://ssodev.rycom.com/auth/',
    realm:'dev',
    clientId:'testclient',
    url:'https://kck.s2hweb.net/auth/',
    realm:'master',
    clientId:'testclient',
  
    //Rapid-DevTest-LB-1643701117.ca-central-1.elb.amazonaws.com
   /*
    connectorBaseUrl: "http://localhost:8200",
    schedulerBaseUrl:"http://localhost:8900",
    orgBaseUrl:"http://localhost:8600",
    dataBaseUrl:"http://localhost:8400",
    graphDbUrl:"http://localhost:2480"*/
   // parentcompanyid : "5ea2dadb14f1a953491873be"
}

if(process.env.REACT_APP_ENV==="development"){
    environment.connectorBaseUrl="https://api.s2hweb.net/connector";
    environment.schedulerBaseUrl="https://api.s2hweb.net/scheduler";
    environment.orgBaseUrl="https://api.s2hweb.net/org";
    environment.dataBaseUrl="https://api.s2hweb.net/data-api";
   // environment.dataBaseUrl="http://localhost:8400";
   // environment.connectorBaseUrl="http://localhost:8200";
   // environment.schedulerBaseUrl="http://localhost:8600";
   // environment.schedulerBaseUrl="http://localhost:8900";

    environment.graphDbUrl="https://ordb.s2hweb.net";
    environment.url='https://kck.s2hweb.net/auth/';
    environment.realm='master';
    environment.clientId='testclient';
    environment.graphDbUrl="https://ordb.s2hweb.net";

}

if(process.env.REACT_APP_ENV==="test"){
    environment.connectorBaseUrl="https://rapidquerytest.rycom.com:8200";
    environment.schedulerBaseUrl="https://rapidquerytest.rycom.com:8900";
    environment.orgBaseUrl="https://rapidquerytest.rycom.com:8600";
    environment.dataBaseUrl="https://rapidquerytest.rycom.com:8400";
    environment.graphDbUrl="https://rapidquerydevdb.rycom.com";
    environment.url='https://ssodev.rycom.com/auth/';
    environment.realm='rapid-test';
    environment.clientId='testclient';
}



export default environment;