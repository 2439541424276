import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomInfoPair from '../../common/RycomInfoPair'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'
import RycomAddressGrid from '../../common/RycomAddressGrid'
import environment from '../../../environment'
import * as OrganisationService from '../../../services/OrganisationService';



import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    }
    
}));

export default function OrganisationForm() {

    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const defaultValues = {
        "metadata": metadataproperties
    };

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const [contactList, setContactList] = useState([]);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid = userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    
     //todo retrieve fromthe token

   
    const {
        register: register2,
        errors: errors2,
        control: control2,
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm({
    });

    const canAddNewMetadataProperties = () => {
        const length = metadataproperties.length - 1
        return metadataproperties[length].v_id
    };

    const newMetadataProfile = () => {
        let id = 1;
        if (metadataproperties.length > 0) {
            let lastKey = canAddNewMetadataProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newMetadataProfile = [...metadataproperties, {
            'v_id': id,
            'key': null,
            'value': null,
        }]
        setMetadataproperties(newMetadataProfile);
    }

    const deleteMetadataProfile = (index) => {
        const deleteProfiles = [...metadataproperties]
        if (deleteProfiles.length > 1) {
            deleteProfiles.splice(index, 1);
            setMetadataproperties(deleteProfiles);
        } else {
            setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
            }])
        }
    };

    if (!metadataproperties.length) {
        deleteMetadataProfile()
    }

    const changeKeyHandler = (edgeProperty) => (event) => edgeProperty.key = event.target.value;
    const changeValueHandler = (edgeProperty) => (event) => edgeProperty.value = event.target.value;

    const metadataList = metadataproperties.map((metadata, index) => {
        metadata.id = index;
        return <RycomGrid>
            <RycomGridItem>
                <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="key"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Metadata key is required'
                        }
                    })}
                    value={metadata.key}
                    onChange={changeKeyHandler(metadata)}
                />
                    <span>
                        <span>
                            {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                        </span>
                    </span>
                </div>
            </RycomGridItem>
            <RycomGridItem>
                <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Value"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Value is required'
                        }
                    })}
                    value={metadata.value}
                    onChange={changeValueHandler(metadata)}
                />
                    <span>
                        {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                    </span>
                </span>
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
                    onClick={(e) => {
                        const deleteProfiles = [...metadataproperties]

                        if (metadataproperties.length > 1) {
                            const deleteProfiles1 = deleteProfiles.filter(edge => {
                                return edge.v_id != metadata.v_id
                            });
                            setMetadataproperties(deleteProfiles1);
                        } else {
                            setMetadataproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null
                            }])
                        }
                    }}>Delete Metadata</Button>
            </RycomGridItem>
        </RycomGrid>;
    });

    const contactDisplayList = contactList.map(contact => <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="First Name" value={contact ? contact.firstname : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Last Name" value={contact ? contact.lastname : ''}></RycomInfoPair>
        </Grid>
        <Grid>
            <Button color="secondary" variant="contained" size="small" value="delete Contact"
                onClick={(e) => {
                    const deleteProfiles = [...contactList]
                    if (contactList.length > 1) {
                        const deleteProfiles1 = deleteProfiles.filter(contactListValue => {
                            return contactListValue.key !== contact.key
                        });
                        setContactList(deleteProfiles1);
                    } else {
                        setContactList([])
                    }
                }}>Delete Contact</Button>
        </Grid>
    </Grid>);


const handleMapperSubmit = data => {
    console.log('handleMapperSubmit');
    console.log(data);
    const contactPayload = {
    "key": data.key,
      "contacttype": data.contacttype,
      "emailaddress": data.emailaddress,
      "firstname": data.firstname,
      "lastname": data.lastname,
      "phone": data.phone
    };
    console.log(data.metadataKey)
    setContactList([...contactList, contactPayload]);
    handleClose(true);
}



    const onSubmit = data => {
        console.log(data);
        const payload = {
            "companyAddress": {
              "address1": data.address1,
              "address2": data.address2,
              "address3": data.address3,
              "city": data.city,
              "country": data.country,
              "state": data.state,
              "zip": data.zip
            },
            "companyname": data.companyname,
            "contacts": contactList,
            "datecreated": 0,
            "isactive": true,
            "isUpdatePassword":false,
            "metadata": metadataproperties,
            //"parentcompanyid": parentcompanyid
            "parentcompanyid": paramID
          }

        console.log(JSON.stringify(payload))
       OrganisationService.saveCompany(payload).then((res) => {
            console.log(res)
            if(res.status === 200)  
            alert('Company inserted successfully');
            history.push("/topics/company")
          })
        .catch((error) => {
          alert("Company error")                                      
           console.error('Company =>', error)
         }) 
    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Company Details"
                    links={[{ title: 'Back', url: '/topics/company' }]}></RycomPageHeader>
                <RycomSectionHeader title="Company Form"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="companyname"
                            label="Company Name"
                            name="companyname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Compnay Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                </RycomGrid>
               
               <RycomAddressGrid caption="Company Address Form" 
               register={register} control={control} errors={errors} ></RycomAddressGrid>
               
                <RycomSectionHeader title="Meta Data"></RycomSectionHeader>
                {metadataList}
                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMetadataProfile}>Add Metadata</Button>
                        </span>
                    </Grid>
                </Grid>;
                <RycomSectionHeader title="Contact Configuration"></RycomSectionHeader>
                <div>
                    {contactDisplayList}
                    <Grid container spacing={12} className={classes.rycomButtonGroup} >
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                            <span>
                                <Button color="primary" variant="contained" size="medium" value="Add Contact" onClick={handleOpen}>Add Contact</Button>
                            </span>
                        </Grid>
                    </Grid>;
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        <RycomSectionHeader title="Contact Information Form"></RycomSectionHeader>
                                        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Contact Type"
                                                    name="contacttype"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Contact Type is required'
                                                        }
                                                    })}
                                                />
                                                    <div>
                                                    <span className={classes.invalidMessage}>
                                                    
                                                        {errors2['contacttype'] && <span>{errors2['contacttype'].message}</span>}
                                                   
                                                </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                            <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Email"
                                                    name="emailaddress"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Email is required'
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <span className={classes.invalidMessage}>
                                                        {errors2['emailaddress'] && <span>{errors2['emailaddress'].message}</span>}
                                                </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                        <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                            <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="First Name"
                                                    name="firstname"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'First Name is required'
                                                        }
                                                    })}
                                                />
                                                     <div>
                                                    <span className={classes.invalidMessage}>
                                                        {errors2['firstname'] && <span>{errors2['firstname'].message}</span>}
                                                    
                                                </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                            <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Last Name"
                                                    name="lastname"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Last Name is required'
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <span className={classes.invalidMessage}>
                                                    
                                                        {errors2['lastname'] && <span>{errors2['lastname'].message}</span>}
                                                    
                                                </span>
                                                </div>
                                            </Grid>
                                           </Grid>
                                           <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                        <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                            <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Phone"
                                                    name="phone"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'phone  is required'
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <span className={classes.invalidMessage}>
                                                    
                                                        {errors2['phone'] && <span>{errors2['phone'].message}</span>}
                                                </span>
                                                </div>
                                            </Grid>
                                         </Grid>           
                                        <RycomSubmitModel>
                                            <Button type="reset" variant="" size="large"
                                                onClick={() => {
                                                    reset2();
                                                }}>Clear</Button>
                                            <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleMapperSubmit)}>Submit</Button>
                                        </RycomSubmitModel>
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                </div>
                <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                        onClick={() => {
                            reset();
                        }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
