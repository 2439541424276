import React from 'react';
import { KeycloakProvider } from '@react-keycloak/web'
import keycloak from './KeycloakConfig'
import RycomRouter from './RycomRouter';
import * as UserService from '../services/UserService';



const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  checkLoginIframe: false
}

export default function KeycloakRycomProvider () {

  const onKeycloakEvent = (event, error) => {
    //console.log('onKeycloakEvent', event, error)
  }

  const getProfileInformation=()=>{
    UserService.getUserByKeycloakId(keycloak.subject).then((res) => {

        console.log('keycloak response', res);

        if (res && res.data && res.data) {
            localStorage.setItem("userInfo",JSON.stringify({
              parentcompanyid:res.data.parentcompanyid,
              companyid: res.data.companyid
            }))
        }
    })
    .catch((error) => {
        console.error('getUserById =>', error)
    })
  }

  const onKeycloakTokens = (tokens) => {
    localStorage.setItem("react-token", keycloak.token);
    localStorage.setItem("react-refresh-token", keycloak.refreshToken);
    localStorage.setItem("id-token", keycloak.idToken);
    getProfileInformation();
  }

  return (
    <KeycloakProvider
    initConfig={keycloakProviderInitConfig}
    keycloak={keycloak}
    onEvent={onKeycloakEvent}
    onTokens={onKeycloakTokens}
  >
      <RycomRouter /> 
    </KeycloakProvider>
  )
}
