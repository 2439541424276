import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getDeviceByVenueId = (venueId) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/venueevents/${venueId}`, addBearerToken()
    );
}

export const getDeviceById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/device/${id}`, addBearerToken()
    );
}


export const getDeviceByParentCompanyId = (companyid) => {
        return axios.get(`${environment.orgBaseUrl}/api/org/device/parent/${companyid}`, addBearerToken()
        );
}

export const saveDevice = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/device`,
               payload, addBearerToken()
               );
}