import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as DocumentClassService from '../../../../services/DocumentClassService';
import DocumentVertexEditTemplate from './DocumentVertexEditTemplate';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));

export default function DocumentVertexEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
      DocumentClassService.getVertexById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.classId)?
        <DocumentVertexEditTemplate defaultValues={defaultValues}></DocumentVertexEditTemplate>:"";
  
}
