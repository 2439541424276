import React, { useEffect } from "react";
import RycomSectionHeader from "../common/RycomSectionHeader";
import RycomTextField from "../common/RycomTextField";
import RycomRadioField from "../common/RycomRadioField";
import RycomSelectField from "../common/RycomSelectField";
import RycomSelectSmallWidth from "../common/RycomSelectSmallWidth";
import MenuItem from "@material-ui/core/MenuItem";
import { RycomGrid, RycomGridItem } from "../common/RycomGrid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, useTheme, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { Button, Checkbox, Input } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";
import { sassFalse } from "sass";
var moment = require('moment-timezone');

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f1592a",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  rycomGridItem: {
    backgroundColor: "white",
    width: "100%",
  },
  invalidMessage: {
    display: "inline-block",
    fontSize: "12px",
    color: "red",
    paddingLeft: "12px",
  },
  paddingStyle: {
    paddingTop: "0px !important",
    paddingLeft: "10px !important",
    paddingBottom: "0px !important",
    paddingRight: "10px !important",
  },
  paddingEndDate: {
    paddingTop: "10px !important",
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "30px !important",
    float: "left",
    width: "300px",
  },
  paddingStartDate: {
    paddingTop: "10px !important",
    paddingLeft: "10px !important",
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
    display:"table-cell"
  },
  paddingHistoricalRecords: {
    paddingTop: "10px !important",
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
    display:"table-cell"
  },
  paddingStyleDateRadio: {
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "10px !important",
  },
}));

export default function HistoriaclOffsetDetails(props) {
  //let defaultValues = props.defaultValues;
  console.log("HistoriaclOffsetDetailsProps",props)
  const {
    register: register2,
    errors: errors2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();
  const theme = useTheme();
  const showdownsm = useMediaQuery(theme.breakpoints.down("sm"))
    ? "100%"
    : null;
  const showUpsm = useMediaQuery(theme.breakpoints.up("sm")) ? "100%" : "100%";
  const showDownmd = useMediaQuery(theme.breakpoints.down("md")) ? "80%" : null;
  const showUpmd = useMediaQuery(theme.breakpoints.up("md")) ? "100%" : null;
  const showDownlg = useMediaQuery(theme.breakpoints.down("lg"))
    ? "100%"
    : null;

  const showUplg = useMediaQuery(theme.breakpoints.up("lg")) ? "100%" : null;
  const showdownxl = useMediaQuery(theme.breakpoints.only("xl"))
    ? "100%"
    : null;
 
  const [checked, setChecked] = useState(true);

  console.log("props.fetchType123:"+props.fetchType)
  console.log("props.props.defaultValues.fetchOffsetType :"+props.fetchOffsetType )
  const [fetchType, setFetchType] = useState(props.fetchType);
  const [checked2, setChecked2] = useState(props.fetchType=='range'?true:false);
  const [checkedDate, setCheckedDate] = useState(false);
  const [checkedDate2, setCheckedDate2] = useState(false);
  const [unlockUnitType, setUnlockUnitType] = useState(true);
  const [unlockOffestduration, setUnlockOffestduration] = useState(true);
  const [unlockOffsetUnitType, setUnlockOffsetUnitType] = useState(true);
  const [handleDateChange, sethandleDateChange] = useState(new Date());
  const [dataStoreType, setDataStoreType] = useState(
    props.defaultValues!==undefined?props.defaultValues.dataStoreType:''
  );
  const [initDefaultDate, setInitDefaultDate] = useState(null);

  const [ selectDateFormat, setSelectDateFormat ] = useState(props.dateFormat|null);
  const [ selectTimeZone, setSelectTimeZone ] = useState(props.timeZone|null);
 
  const [ historyRecordStatus, setHistoryRecordStatus ] = useState(props.fetchOffsetType == "history");
    const [ lastOneDayStatus, setLastOneDayStatus ] = useState(props.fetchOffsetType == "lastOneDay");
    const [ customDateStatus, setCustomDateStatus ] = useState(props.fetchOffsetType == "customDate");


    const [show, setHide] = useState(customDateStatus);
    const [show2, setHide2] = useState(historyRecordStatus);
    const [show3, setHide3] = useState(true);

  
    useEffect(() => {
    
    setDataStoreType(props.defaultValues!==undefined?props.dataStoreType:false);
   // setDataDirectionType(props.defaultValues.historyOffsetDirectionType);
  
    if (customDateStatus) {
      //console.log('initDefaultDate=====',initDefaultDate);
      setHide(true);
    } else {
      setInitDefaultDate(null);
    }

    if (historyRecordStatus) {
      setHide2(true);
    }


  }, []);
  const customeDateTogle = () => {
    setHide((oldState) => !oldState);
    setCheckedDate(false);
    setChecked(false);
    setHide2(false);
    setCustomDateStatus(true)
    setHistoryRecordStatus(false)
    setLastOneDayStatus(false)
  };

  const historicalRecordToggle = () => {
    setHide2((oldState) => !oldState);
    setHide(false);
    setCheckedDate(false);
    setChecked(false);
    setHistoryRecordStatus(true)
    setCustomDateStatus(false)
    setLastOneDayStatus(false)
  };
  const toggleHide3 = () => {
    setFetchType("range")
    setHide3(true);
    // setCheckedDate2(true);
    setChecked2(true);
  };



  const setDateValid = (date) => {

    
    var dateformt= selectedDate ||
     (initStartDate !== null
       ? new Date(initStartDate * 1000)
       : null || initDefaultDate);
 
 
     var milliseconds1 = new Date(dateformt).getTime();
     var milliseconds = date.getTime();
     if (milliseconds1 > milliseconds) {
       setCheckedDate(true);
     } else {
       setCheckedDate(false);
     }
     setHandleEndDate(date);
     
   };


 
  const setDateValid2 = (date) => {

   var dateformt2 = selectedEndDate ||
    (initEndDate !== null
      ? new Date(initEndDate * 1000)
      : null || initDefaultDate)
    if(dateformt2!==null){
    var milliseconds1 = new Date(dateformt2).getTime();
      var milliseconds = date.getTime();
      if (milliseconds1 < milliseconds) {
        setCheckedDate(true);
      } else {
        setCheckedDate(false);
      }
  }
  };
  const [selectedDate, setHandleDateChange] = useState(props.fetchStartDateString);

  const [selectedEndDate, setHandleEndDate] = useState(props.fetchEndDateString);


  const classes = useStyles();

  console.log("SelectedDate", selectedDate);

  console.log("SelectedEndDate", selectedEndDate);

  const dataUnitTypes = [
    "minutes",
    "hours",
    "days",
    "weeks",
    "months",
    "years",
  ];

  const dataDateformatTypes = [
     {name: "yyyy-MM-dd hh:mm:ss a", value: "yyyy-MM-dd hh:mm:ss a"},
     {name: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", value: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"},
     {name: "yyyy-MM-dd'T'HH:mm:ss'Z'", value: "yyyy-MM-dd'T'HH:mm:ss'Z'"},
     {name: "yyyy-MM-dd'T'HH:mm:ssZ", value: "yyyy-MM-d'T'HH:mm:ssZ"},
     {name: "yyyy-MM-dd'T'HH:mm:ss", value: "yyyy-MM-d'T'HH:mm:ss"},
     {name: "yyyy-MM-dd'T'HH:mm:ss.SSSZ", value: "yyyy-MM-dd'T'HH:mm:ss.SSSZ"},
     {name: "yyyy-MM-dd HH:mm:ss", value: "yyyy-MM-dd HH:mm:ss"},
     {name: "MM/dd/yyyy HH:mm:ss", value: "MM/dd/yyyy HH:mm:ss"},
     {name: "MM/dd/yyyy'T'HH:mm:ss.SSS'Z'", value: "MM/dd/yyyy'T'HH:mm:ss.SSS'Z'"},
     {name: "MM/dd/yyyy'T'HH:mm:ss.SSSZ", value: "MM/dd/yyyy'T'HH:mm:ss.SSSZ"},
     {name: "MM/dd/yyyy'T'HH:mm:ss.SSS", value: "MM/dd/yyyy'T'HH:mm:ss.SSS"},
     {name: "MM/dd/yyyy'T'HH:mm:ssZ", value: "MM/dd/yyyy'T'HH:mm:ssZ"},
     {name: "MM/dd/yyyy'T'HH:mm:ss", value: "MM/dd/yyyy'T'HH:mm:ss"},
     {name: "yyyy:MM:dd HH:mm:ss", value: "yyyy:MM:dd HH:mm:ss"},
     {name: "yyyy-MM-dd", value: "yyyy-MM-dd"},
     {name: "yyyyMMdd", value: "yyyyMMdd"},
     {name: "epochMilliSeconds", value: "epochMilliSeconds"},
     {name: "epochSeconds", value: "epochSeconds"}

  ];

  const dataTimeZoneTypes = [
    "Etc/GMT",
    "America/New_York",
    "America/Denver",
    "America/Los_Angeles",
    "America/Halifax"
    
 ]

  /*"frequency": res.data.connectionInfo.configuration.frequency,
                        "historyDurationType":res.data.connectionInfo.configuration.historyDurationType,
                        "offsetDuration":res.data.connectionInfo.configuration.offsetDuration,
                        "offsetDurationUnitType":res.data.connectionInfo.configuration.offsetDurationUnitType,
                        maxmiumFetchOffset: res.data.connectionInfo.configuration.maxmiumFetchOffset,
                        maxmiumUnitType: res.data.connectionInfo.configuration.maxmiumUnitType,
                        "createdDateColumn":res.data.connectionInfo.configuration.createdDateColumn,
                        "modifiedDateColumn":res.data.connectionInfo.configuration.modifiedDateColumn,
                        "fetchOffsetType":res.data.connectionInfo.configuration.fetchOffsetType,
*/

  const [dataTimeZoneType , setDataTimeZoneType] = useState('');
  const [dataUnitType, setUnitType] = useState(props.offsetDurationUnitType);
 
  const [durationMilisecondCheck, setDurationMilisecondCheck] = useState();
  const [durationCheck, setDurationCheck] = useState();
  const [durationCheck2, setDurationCheck2] = useState(props.offsetDuration|null);
  const [offSetDurationCheck, setOffSetDurationCheck] = useState(false);
  const [unitCheck, setUnitCheck] = useState();
  const [unitCheck2, setUnitCheck2] = useState(props.offsetDurationUnitType);
  const [offSetUnitCheck, setOffsetUnitCheck] = useState(false);
  const [unitvalidationLast, setUnitvalidationLast] = useState();
  const [initStartDate, setInitStartDate] = useState(
    props.defaultValues!== undefined?props.defaultValues.fetchStartDateString:null
  );
  const [initEndDate, setInitEndDate] = useState(
    props.defaultValues!== undefined?props.defaultValues.fetchEndDateString:null
  );
  const durationValidationChk = (value) => {
    let timeToMilisecondsTwo = 0;
    if (unitCheck2 !== undefined || unitCheck2 !== null) {
      if (unitCheck2 === "days") {
        timeToMilisecondsTwo = parseInt(value) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (unitCheck2 === "minutes") {
        timeToMilisecondsTwo = parseInt(value) * 60000; // minuts to milliseconds
      }
      if (unitCheck2 === "hours") {
        timeToMilisecondsTwo = parseInt(value) * 60000 * 60; // horus to milliseconds
      }
      if (unitCheck2 === "weeks") {
        timeToMilisecondsTwo = parseInt(value) * 604800000; // days to milliseconds
      }
      if (unitCheck2 === "months") {
        timeToMilisecondsTwo = parseInt(value) * 2629800000; // days to milliseconds
      }
      if (unitCheck2 === "years") {
        timeToMilisecondsTwo = parseInt(value) * 31556952000; // days to milliseconds
      }
    }
    if (
      durationMilisecondCheck !== undefined ||
      durationMilisecondCheck !== null
    ) {
      if (durationMilisecondCheck < timeToMilisecondsTwo) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  };

  const durationValidationChkPart2 = (value) => {
    let timeToMiliseconds = 0;
    if (unitCheck !== undefined || unitCheck !== null) {
      if (unitCheck === "days") {
        timeToMiliseconds = parseInt(value) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (unitCheck === "minutes") {
        timeToMiliseconds = parseInt(value) * 60000; // minuts to milliseconds
      }
      if (unitCheck === "hours") {
        timeToMiliseconds = parseInt(value) * 60000 * 60; // horus to milliseconds
      }
      if (unitCheck === "weeks") {
        timeToMiliseconds = parseInt(value) * 604800000; // days to milliseconds
      }
      if (unitCheck === "months") {
        timeToMiliseconds = parseInt(value) * 2629800000; // days to milliseconds
      }
      if (unitCheck === "years") {
        timeToMiliseconds = parseInt(value) * 31556952000; // days to milliseconds
      }
      // setDurationMilisecondCheck(timeToMiliseconds);
      if (unitvalidationLast !== undefined || unitvalidationLast !== null) {
        if (timeToMiliseconds < unitvalidationLast) {
          setOffsetUnitCheck(true);
        } else {
          setOffsetUnitCheck(false);
        }
      }
    }
  };

  const unitValidationChk = (value) => {
    let millisecondsToAdd2 = 0;
    if (durationCheck2 !== undefined || durationCheck2 !== null) {
      if (value === "days") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (value === "minutes") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 60000; // minuts to milliseconds
      }
      if (value === "hours") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 60000 * 60; // horus to milliseconds
      }
      if (value === "weeks") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 604800000; // days to milliseconds
      }
      if (value === "months") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 2629800000; // days to milliseconds
      }
      if (value === "years") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 31556952000; // days to milliseconds
      }
      setUnitvalidationLast(millisecondsToAdd2);
    }
    if (
      durationMilisecondCheck !== undefined ||
      durationMilisecondCheck !== null
    ) {
      if (durationMilisecondCheck < millisecondsToAdd2) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  };

  const unitValidationChk2 = (value) => {
    let millisecondsToAdd = 0;
    if (durationCheck !== undefined || durationCheck !== null) {
      if (value === "days") {
        millisecondsToAdd = parseInt(durationCheck) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (value === "minutes") {
        millisecondsToAdd = parseInt(durationCheck) * 60000; // minuts to milliseconds
      }
      if (value === "hours") {
        millisecondsToAdd = parseInt(durationCheck) * 60000 * 60; // horus to milliseconds
      }
      if (value === "weeks") {
        millisecondsToAdd = parseInt(durationCheck) * 604800000; // days to milliseconds
      }
      if (value === "months") {
        millisecondsToAdd = parseInt(durationCheck) * 2629800000; // days to milliseconds
      }
      if (value === "years") {
        millisecondsToAdd = parseInt(durationCheck) * 31556952000; // days to milliseconds
      }
      setDurationMilisecondCheck(millisecondsToAdd);
    }
    if (unitvalidationLast !== undefined || unitvalidationLast !== null) {
      if (unitvalidationLast > millisecondsToAdd) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  };

  const {defaultValues, connectorType,register, errors, control, multiline, rows, value } = props;
  console.log("Historical Component",connectorType);
  console.log(props);
  let dataStoreTypeValue = null;

  return (
    <div>
      <RycomSectionHeader title="Fetching Records Offset Details" />
      <RycomGrid>
      <div className={classes.paddingStyle}>
            <FormControl component="fieldset">
              <Controller
                control={control}
                name="fetchType"
                id="fetchType"
                register={register}
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="range"
                      control={<Radio checked={checked2} />}
                      label="Range"
                      register={register}
                      onChange={toggleHide3}
                    />
                    <FormControlLabel
                      value="complete"
                      control={<Radio />}
                      label="Complete"
                      register={register}
                      onChange={() => {
                        setFetchType("complete")
                        setHide3(false);
                        setChecked2(false);
                        setCheckedDate(false);
                      }}
                    />
                  </RadioGroup>
                }
              />
            </FormControl>
          </div>
          </RycomGrid>
          <RycomGrid>
        {show3 === true && checked2 ? (
          <div className={classes.paddingStyle}>
            <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
            {((connectorType=='API' || connectorType=='api') && checked2) ? <span> 
               <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <TextField
                    style={{ width: "39ch" }}
                    id="startParamter"
                    label="Start - URI Parameter*"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Start - URI Parameter is required",
                      },
                    })}
                    name="startParamter"
                  />
                  <span className={classes.invalidMessage}>
                    {errors["startParamter"] && (
                      <span> {errors["startParamter"].message} </span>
                    )}
                  </span>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <TextField
                    style={{ width: "39ch" }}
                    id="endParamter"
                    label="End - URI Parameter*"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: {
                        value: true,
                        message: "End - URI Parameter is required",
                      },
                    })}
                   
                    name="endParamter"
                  />
                  <span className={classes.invalidMessage}>
                    {errors["endParamter"] && (
                      <span> {errors["endParamter"].message} </span>
                    )}
                  </span>
                  </Grid></span>:null}
                  <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <RycomSelectSmallWidth
                    id="dateFormat"
                    label="Date - Format*"
                    name="dateFormat"
                    value={selectDateFormat}
                    list={dataDateformatTypes}
                    // disabled={unlockUnitType}
                    onChangeHandler={(event) => {
                     setSelectDateFormat(event[0].target.value);

                      //dataDateformatTypes.map(item => {if(item.value===event[0].target.value){
                      //  props.dateNameFormat(item.name)
                     // }})
                      //props.dateFormat(event[0].target.value);
                      setSelectDateFormat(event[0].target.value);
                      console.log('dateFORMATNASMAAE',event[0].target.value);
                      return event[0].target.value;
                    }}
                    register={register}
                    control={control}
                    errors={errors}
                    // value={selectDateFormat}
                    errorDescription={{
                      required: {
                        value: true,
                        message: "Date - Format is required",
                      },
                    }}
                  >
                    <MenuItem value="">Select Date Format</MenuItem>
                    {dataDateformatTypes.map((dataDateformatTypes1, index) => (
                      <MenuItem key={++index} value={dataDateformatTypes1.value}>
                        {dataDateformatTypes1.name}
                      </MenuItem>
                    ))}
                  </RycomSelectSmallWidth>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <RycomSelectSmallWidth
                    id="timeZone"
                    label="Time - Zone*"
                    name="timeZone"
                    list={dataTimeZoneTypes}
                    // disabled={unlockUnitType}
                    onChangeHandler={(event) => {
                      setDataTimeZoneType(event[0].target.value);
                      setSelectTimeZone(event[0].target.value);
                     // props.dateTimeZone(event[0].target.value)
                      return event[0].target.value;
                    }}
                    value={selectTimeZone}
                    register={register}
                    control={control}
                    errors={errors}
                    errorDescription={{
                      required: {
                        value: true,
                        message: "Time - Zone is required",
                      },
                    }}
                  >
                    <MenuItem value="">Select Time Zone</MenuItem>
                    {dataTimeZoneTypes.map((dataTimeZoneTypes1, index) => (
                      <MenuItem key={++index} value={dataTimeZoneTypes1}>
                        {dataTimeZoneTypes1}
                      </MenuItem>
                    ))}
                  </RycomSelectSmallWidth>
                  </Grid>
                  </Grid>
              <div className={classes.paddingStyleDateRadio}>
                <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="fetchOffsetType"
                    defaultValue="lastOneDay"
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="lastOneDay"
                          control={<Radio />}
                          label="Last One Day"
                          checked={lastOneDayStatus}
                          onChange={() => {
                            setHide(false);
                            setChecked(true);
                            setHide2(false);
                            setLastOneDayStatus(true)
                            setHistoryRecordStatus(false)
                            setCustomDateStatus(false)
                          }}
                        />
                        <FormControlLabel
                          value="customDate"
                          control={
                            <Radio 
                              disabled={selectDateFormat=== null || selectDateFormat=== "" || selectTimeZone === null || selectTimeZone === ""?true:false}
                            />
                          }
                          label="Custom Date"
                          onChange={customeDateTogle}
                          checked={customDateStatus}
                        />
                        <FormControlLabel
                          value="history"
                          control={<Radio />}
                          label="Historical Records"
                          onChange={historicalRecordToggle}
                          checked={historyRecordStatus}
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
                </div>
              {show || (((initStartDate || selectedDate) &&
            (initEndDate || selectedEndDate)) !== null &&
            show) ||
          ((initStartDate || selectedDate) &&
            (initEndDate || selectedEndDate)) === true ? (
                
                <ThemeProvider theme={materialTheme}>
                  <span className={classes.paddingStartDate}>
                    <FormControl component="fieldset">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControlLabel
                          value={
                            selectedDate ||
                            (initStartDate !== null
                              ? new Date(initStartDate * 1000)
                              : null || initDefaultDate)
                          }
                          id="fetchStartDateString"
                          name="fetchStartDateString"
                          
                          control={
                            <KeyboardDateTimePicker
                              required={true}
                              invalidDateMessage={"Start Date is required"}
                              style={{ width: "50ch" }}
                              value={
                                selectedDate ||
                                (initStartDate !== null
                                  ? new Date(initStartDate * 1000)
                                  : null || initDefaultDate)
                              }
                              id="fetchStartDateString"
                              label={"Start Date "+dataTimeZoneType+" Zone"}
                              name="fetchStartDateString"
                              format="yyyy-MM-dd hh:mm:ss a"
                              onChange={(date) => {
                            setHandleDateChange(date);
                            props.dateStartValue(date);
                            setInitStartDate(null);
                            setInitDefaultDate(null);
                            setDateValid2(date);
                           
                            
                            //console.log("------------DataFormatSelected-------",moment(date).tz(selectTimeZone).format(selectDateFormat));
                              }}
                            />
                          }
                           onChange={(date) => {
                            setHandleDateChange(date);
                            props.dateStartValue(date);
                            //props.dateFormat(selectDateFormat);
                            //props.selectDateStartValue;
                            //props.changeStartDate(true);
                            setInitStartDate(null);
                            setInitDefaultDate(null);
                            setDateValid2(date);
                              }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </span>

                  <span className={classes.paddingStartDate}>
                    <FormControl component="fieldset">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControlLabel
                          value={selectedEndDate}
                          id="fetchEndDate"
                          name="fetchEndDate"
                          control={
                            <KeyboardDateTimePicker
                              style={{ width: "50ch" }}
                              value={selectedEndDate}
                              label={"End Date "+dataTimeZoneType+ " Zone"}
                              id="fetchEndDate"
                              disabled={selectedDate==null?true:false}
                              name="fetchEndDate"
                              format="yyyy-MM-dd hh:mm:ss a"
                              onChange={(date) => {
                                setDateValid(date);
                                props.dateEndValue(date);
                              }}
                            />
                          }
                          onChange={(date) => {
                            setDateValid(date);
                            props.dateEndValue(date);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </span>
                </ThemeProvider>
              ) : null}
              {show2 && (
                <span className={classes.paddingHistoricalRecords}>
                  <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
                  <Grid item xs={12} sm={12} lg={3} xl={3}>
                  <TextField
                    style={{ width: "39ch" }}
                    id="frequency"
                    label="Duration*"
                    variant="outlined"
                    type="number"
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Duration is required",
                      },
                    })}
                    onChange={(event) => {
                      durationValidationChkPart2(event.target.value);
                      setDurationCheck(event.target.value);
                      if (event.target.value.length !== 0) {
                        setUnlockUnitType(false);
                        if (
                          dataUnitType?.length !== 0 &&
                          dataUnitType !== undefined
                        ) {
                          setUnlockOffestduration(false);
                          setUnlockOffsetUnitType(false);
                        }
                      } else {
                        setUnlockUnitType(true);
                        setUnlockOffestduration(true);
                        setUnlockOffsetUnitType(true);
                      }
                    }}
                    name="frequency"
                  />
                  <span className={classes.invalidMessage}>
                    {errors["frequency"] && (
                      <span> {errors["frequency"].message} </span>
                    )}
                  </span>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={3} xl={3}>
                  <RycomSelectSmallWidth
                    id="historyDurationType"
                    label="Select Unit Type"
                    name="historyDurationType"
                    list={dataUnitTypes}
                    onChangeHandler={(event) => {
                      unitValidationChk2(event[0].target.value);
                      setUnitCheck(event[0].target.value);
                      setUnitType(event[0].target.value);
                      if (event[0].target.value !== 0) {
                        setUnlockOffestduration(false);
                      } else {
                        setUnlockOffestduration(true);
                      }
                      return event[0].target.value;
                    }}
                    register={register}
                    control={control}
                    errors={errors}
                    errorDescription={{
                      required: {
                        value: true,
                        message: "Unit Type is required",
                      },
                    }}
                  >
                    <MenuItem value="">Select Unit</MenuItem>
                    {dataUnitTypes.map((dataUnitType1, index) => (
                      <MenuItem key={++index} value={dataUnitType1}>
                        {dataUnitType1}
                      </MenuItem>
                    ))}
                  </RycomSelectSmallWidth>
                  </Grid>
                 
                 <Grid item xs={12} sm={12} lg={3} xl={3}>
                  <TextField
                    style={{ width: "39ch", marginLeft: "19px" }}
                    id="offsetDuration"
                    label="Offset Duration"
                    variant="outlined"
                    type="number"
                    inputRef={register}
                    onChange={(event) => {
                      setDurationCheck2(event.target.value);
                      durationValidationChk(event.target.value);
                      if (event.target.value !== 0) {
                        setUnlockOffsetUnitType(false);
                      } else {
                        setUnlockOffsetUnitType(true);
                      }
                    }}
                    value={durationCheck2}
                    name="offsetDuration"
                  />
                  {offSetDurationCheck === true ? (
                    <span className={classes.invalidMessage}>
                      Offset Duration value should be less then Duration
                    </span>
                  ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} lg={3} xl={3}>
                  <RycomSelectSmallWidth
                    id="offsetDurationUnitType"
                    label="offset Unit"
                    name="offsetDurationUnitType"
                    list={dataUnitTypes}
                    onChangeHandler={(event) => {
                      setUnitType(event[0].target.value);
                      setUnitCheck2(event[0].target.value);
                      unitValidationChk(event[0].target.value);
                      return event[0].target.value;
                    }}
                    value={unitCheck2}
                    register={register}
                    control={control}
                  >
                    <MenuItem value="">Select OffSet Unit</MenuItem>
                    {dataUnitTypes.map((dataUnitType1, index) => (
                      <MenuItem key={++index} value={dataUnitType1}>
                        {dataUnitType1}
                      </MenuItem>
                    ))}
                  </RycomSelectSmallWidth>
                  </Grid>
                  {offSetUnitCheck === true ? (
                    <span className={classes.invalidMessage}>
                      offset (duration/unit) should be less then Unit Duration
                    </span>
                    
                  ) : null}
                  </Grid>
                </span>
              )}
          </div>
        ) : null}
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          {checkedDate === true ? (
            <span className={classes.invalidMessage}>
              End date should be greater than start date
            </span>
          ) : null}
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem></RycomGridItem>
      </RycomGrid>
    {(connectorType=='JDBC' || connectorType=='ORIENT') && checked2  ? ( <span>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField
            id="created_date_coulmn"
            label="Created Date Coulmn Name"
            name="createdDateColumn"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Created Date Coulmn Name is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField
            id="modified_date_coulmn"
            label="Modified Date Coulmn Name"
            name="modifiedDateColumn"
            register={register}
            errors={errors} 
            errorDescription={{
              required: {
                value: true,
                message: "Modified Date Coulmn Name is required",
              },
            }}
          />
        </RycomGridItem>
      </RycomGrid>
      </span>):null}
      {show3 && checked2 ? ( 
      <span>
          
          <TextField
                    style={{ width: "39ch", marginLeft: "19px" }}
                    id="maxmiumFetchOffset"
                    label="Maxmium Fetch Offset"
                    variant="outlined"
                    type="number"
                    errors={errors}
                    errorDescription={{
                      required: {
                        value: true,
                        message: "Maxmium Fetch Offset is required",
                      },
                    }}
                    inputRef={register}
                    onChangeHandler={(event) => {
                        return event[0].target.value;
                      }}
                    name="maxmiumFetchOffset"
                  />
                  <RycomSelectSmallWidth
                    id="maxmiumUnitType"
                    label="Maxmium Unit Type"
                    name="maxmiumUnitType"
                    errors={errors}
                    errorDescription={{
                      required: {
                        value: true,
                        message: "Maxmium Unit Type is required",
                      },
                    }}
                    list={dataUnitTypes}
                    onChangeHandler={(event) => {
                      return event[0].target.value;
                    }}
                    register={register}
                    control={control}
                  >
                    <MenuItem value="">Select OffSet Unit</MenuItem>
                    {dataUnitTypes.map((dataUnitType1, index) => (
                      <MenuItem key={++index} value={dataUnitType1}>
                        {dataUnitType1}
                      </MenuItem>
                    ))}
                  </RycomSelectSmallWidth>
                  </span>):null
      }
    </div>
  );
}
