import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Close , Edit, Add } from "@material-ui/icons";



const useStyles = makeStyles(theme => ({
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    },
    requiredMessage: {
        color: 'red',
    },
	browseButtonStyle: {
		color: "white",
		width: "300px",
		height: "50px",
		fontSize: "1rem",
		backgroundColor: "#3f51b5",
		border: "none",
		marginTop: "10px",
		position:"absolute",
		left:0,
		pointerEvents:"none"
	},
	browseInput:{
		width:"300px",
		height:"50px",
		marginTop:"10px",
		opacity:0
	},
	uploadFileWrap: {
		position:"relative"
	},
	fileName:{
		color: "#3f51b5",
		fontSize:"18px"
	},
	addIconWrap: {
		marginRight: "10px",
		border: "1px solid #6f7dc8",
		borderRadius: "10px",
		cursor:"pointer"
	},
	editIconWrap: {
		marginRight: "10px",
		border: "1px solid green",
		borderRadius: "10px",
		padding:"2px",
		cursor:"pointer"
	},
	deleteIconWrap: {
		marginRight: "10px",
		border: "1px solid red",
		borderRadius: "10px",
		cursor:"pointer"
	}

}));


export default function RycomBrowseFile(props) {
    const classes = useStyles();

	const { id, label, name, onChangeHandler, register, control, errors, errorDescription, value, disabled, file, onDeleteHandler, onAddHandler, onEditHandler } = props
   const labelWithcaption=<span>{label}<span classes={classes.requiredMessage}>*</span></span>;

   return (
        errors?
			<span>
				<Controller
					as={
						<div className={classes.uploadFileWrap}>
							<TextField
								id={id}
								label={labelWithcaption}
								className={classes.browseInput}
								variant="outlined"
								name={name}
								value={value?value:""}
								type="file"
								>
								{props.children}
							</TextField>
							<button className={classes.browseButtonStyle}>
								{label}
							</button>
							{file && 
								<>
									<p className={classes.fileName}><span>{file} File attached </span> <Close className={classes.deleteIconWrap} onClick={onDeleteHandler} style={{color:"red"}} fontSize="small" /></p>
									{/* <p>
										<Add className={classes.addIconWrap} color="primary" fontSize="small" onChange={onAddHandler} />
										<Edit className={classes.editIconWrap} style={{color:"green"}} fontSize="small" onChange={onEditHandler} />
									</p> */}
								</>
							}
						</div>
					}
					rules={errorDescription}
					control={control}
					name={name}
					value={value?value:""}
				onChange={onChangeHandler}
					disabled={disabled}
				/>
				
				
				<div className={classes.invalidMessage}>
					{errors[name] && <span>{errors[name].message}</span>}
				</div>
				
			</span>
		:
			<span>
			<Controller
				as={
					<div className={classes.uploadFileWrap}>
						<TextField
							id={id}
							label={labelWithcaption}
							className={classes.browseInput}
							variant="outlined"
							name={name}
							value={value?value:""}
							type="file"
							
							>
							{props.children}
						</TextField>
						<button className={classes.browseButtonStyle}>
							{label}
						</button>
						{file && <p className={classes.fileName}>{file.name} File attached</p>}
					</div>
				}
				rules={errorDescription}
				control={control}
				name={name}
				value={value?value:""}
				onChange={onChangeHandler}
				disabled={disabled}
			/>
				
		</span>

    );
}
