import React from "react";
import RycomSectionHeader from "../common/RycomSectionHeader";
import RycomTextField from "../common/RycomTextField";
import RycomRadioField from "../common/RycomRadioField";
import RycomSelectField from "../common/RycomSelectField";
import RycomSelectSmallWidth from "../common/RycomSelectSmallWidth";
import MenuItem from "@material-ui/core/MenuItem";
import { RycomGrid, RycomGridItem } from "../common/RycomGrid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, useTheme, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { Button, Checkbox, Input } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import HistoriaclOffsetDetails from "../common/HistoricalOffsetDetails";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f1592a",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  rycomGridItem: {
    backgroundColor: "white",
    width: "100%",
  },
  invalidMessage: {
    display: "inline-block",
    fontSize: "12px",
    color: "red",
    paddingLeft: "12px",
  },
  paddingStyle: {
    paddingTop: "10px !important",
    paddingLeft: "30px !important",
    paddingBottom: "0px !important",
    paddingRight: "30px !important",
  },
  paddingEndDate: {
    paddingTop: "10px !important",
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "30px !important",
    float: "left",
    width: "300px",
  },
  paddingStartDate: {
    paddingTop: "10px !important",
    paddingLeft: "30px !important",
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
    float: "left",
    width: "300px",
  },
}));

export default function ConnectorJDBCConfiguration(props) {
  const {
    register: register2,
    errors: errors2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm({});
  const theme = useTheme();
  const showdownsm = useMediaQuery(theme.breakpoints.down("sm"))
    ? "100%"
    : null;
  const showUpsm = useMediaQuery(theme.breakpoints.up("sm")) ? "100%" : "100%";
  const showDownmd = useMediaQuery(theme.breakpoints.down("md")) ? "80%" : null;
  const showUpmd = useMediaQuery(theme.breakpoints.up("md")) ? "100%" : null;
  const showDownlg = useMediaQuery(theme.breakpoints.down("lg"))
    ? "100%"
    : null;
    
  const showUplg = useMediaQuery(theme.breakpoints.up("lg")) ? "100%" : null;
  const showdownxl = useMediaQuery(theme.breakpoints.only("xl"))
    ? "100%"
    : null;
  const [show, setHide] = useState(false);
  const [show2, setHide2] = useState(false);
  const [show3, setHide3] = useState(true);
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checkedDate, setCheckedDate] = useState(false);
  const [checkedDate2, setCheckedDate2] = useState(false);
  const [unlockUnitType,setUnlockUnitType] = useState(true);
  const [unlockOffestduration,setUnlockOffestduration] = useState(true);
  const [unlockOffsetUnitType,setUnlockOffsetUnitType] = useState(true);
  const [handleDateChange, sethandleDateChange] = useState(new Date());
  const toggleHide = () => {
    setHide((oldState) => !oldState);
    setCheckedDate(false);
    setChecked(false);
    setHide2(false);
  };

  const toggleHide2 = () => {
    setHide2((oldState) => !oldState);
    setHide(false);
    setCheckedDate(false);
    setChecked(false);
  };
  const toggleHide3 = () => {
    setHide3(true);
    // setCheckedDate2(true);
     setChecked2(true);
  };

  const setDateValid = (date) => {
    var milliseconds1 = selectedDate.getTime();
    var milliseconds = date.getTime();
    if (milliseconds1 > milliseconds) {
      setCheckedDate(true);
    } else {
      setCheckedDate(false);
    }
    setHandleEndDate(date);
  };

  const setDateValid2 = (date) => {
    if (selectedEndDate !== null) {
      var milliseconds1 = selectedEndDate.getTime();
      var milliseconds = date.getTime();
      if (milliseconds1 < milliseconds) {
        setCheckedDate(true);
      } else {
        setCheckedDate(false);
      }
    }
  };
  const [selectedDate, setHandleDateChange] = useState(null);
  const [selectedEndDate, setHandleEndDate] = useState(null);
  const classes = useStyles();
  const dataStoreTypes = ["query", "table"];
  const dataBaseTypes = ["query", "table"];

  const [dataStoreType, setDataStoreType] = useState();

  console.log('SelectedDate',selectedDate);
  console.log('SelectedEndDate',selectedEndDate);


  const { connectorType, connectorFlowType, register, errors, control, multiline, rows, value } =
    props;
  console.log("inside ConnectorJDBCConfiguration",connectorType);
  console.log(connectorFlowType);
  
  
 

  let dataStoreTypeValue = null;
  if (dataStoreType !== undefined && dataStoreType === "query") {
    dataStoreTypeValue = (
      <RycomTextField
        id="dataStoreName"
        label="Query"
        name="dataStoreName"
        multiline={true}
        rows={2}
        rowsMax={4}
        register={register}
        errors={errors}
        style={{ width: showdownsm || showDownmd || showDownlg || showdownxl }}
        dataStoreType={dataStoreType}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 12);
        }}
        errorDescription={{
          required: {
            value: true,
            message: "Query Name is required",
          },
        }}
      />
    );
  }
  if (dataStoreType !== undefined && dataStoreType === "table") {
    dataStoreTypeValue = (
      <RycomTextField
        id="dataStoreName"
        label="Data Store Name"
        name="dataStoreName"
        register={register}
        errors={errors}
        errorDescription={{
          required: {
            value: true,
            message: "Data Store Name is required",
          },
        }}
      />
    );
  }
  return (
    <div>
     
     
     {connectorType!=='ORIENT'?<RycomSectionHeader title="JDBC Connection Details" />:<RycomSectionHeader title="OrientDB Connection Details" />}
     
      {connectorType!=='ORIENT'?<span><RycomGrid>
        <RycomGridItem>
          <RycomTextField
            id="UserName"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 12);
            }}
            errorDescription={{
              required: {
                value: true,
                message: "User Name is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomGridItem>
          <TextField
            id="Password"
            label="Password*"
            variant="outlined"
            inputRef={register({
              required: {
                value: true,
                message: "Password is required",
              },
            })}
            name="password"
            type="password"
          />
          <span className={classes.invalidMessage}>
            {errors["password"] && <span> {errors["password"].message} </span>}
          </span>
        </RycomGridItem>
        <RycomGridItem>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <RycomTextField
            id="host"
            label="Host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Host is required",
              },
            }}
          />
          </Grid>
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField
            id="port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Port is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField
            id="databaseName"
            label="Database Name"
            name="databaseName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Database Name is required",
              },
            }}
          />
        </RycomGridItem>
          
      <RycomSelectField
          id="sslEnabled"
          label="SSL JDBC Connection"
          name="sslEnabled"
          onChangeHandler={(event) => {
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "SSL enabled is required",
            },
          }}
        >
            <MenuItem value="enabled" key="1">Enabled</MenuItem>
            <MenuItem value="disabled" key="2">Disabled</MenuItem>
        </RycomSelectField>
        </RycomGrid>
        <RycomGrid>
        <RycomSelectField
          id="DataBase Type"
          label="DataBase Types"
          name="databaseType"
          onChangeHandler={(event) => {
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Database Type is required",
            },
          }}
        >
            <MenuItem value="mariadb" key="1">MariaDB</MenuItem>
            <MenuItem value="mysql_v8" key="2">MYSQL 8</MenuItem>
            <MenuItem value="mysql_v5" key="3">MYSQL 5</MenuItem>
            <MenuItem value="mssql_2005-2012" key="4">MSSQL 2000-2012</MenuItem>
            <MenuItem value="mssql_latest" key="5">MSSQL 2012-2022</MenuItem>
            <MenuItem value="oracle" key="6">ORACLE</MenuItem>
            <MenuItem value="postgre" key="7">POSTGRE</MenuItem>
        </RycomSelectField>
      
        </RycomGrid>

        <RycomSectionHeader title="Table Details" />
        <RycomGrid>

        <RycomSelectField
          id="Data Store Type"
          label="Data Store Types"
          name="dataStoreType"
          list={dataStoreTypes}
          onChangeHandler={(event) => {
            setDataStoreType(event[0].target.value);
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Data Store Type is required",
            },
          }}
        >
          {dataStoreTypes.map((dataStoreType1, index) => (
            <MenuItem key={++index} value={dataStoreType1}>
              {dataStoreType1}
            </MenuItem>
          ))}
        </RycomSelectField>
        </RycomGrid>
      </span>: 
     <RycomGrid>
        <RycomGridItem>
        <RycomSelectField
          id="dataStoreType"
          label="Data Store Types"
          name="dataStoreType"
          list={dataStoreTypes}
          onChangeHandler={(event) => {
            setDataStoreType(event[0].target.value);
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Data Store Type is required",
            },
          }}
        >
          {dataStoreTypes.map((dataStoreType1, index) => (
            <MenuItem key={++index} value={dataStoreType1}>
              {dataStoreType1}
            </MenuItem>
          ))}
        </RycomSelectField>
        </RycomGridItem>
        </RycomGrid> }
      <Grid
        container
        spacing={12}
        style={{ padding: 4 }}
        className={classes.rycomGridItem}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={12}
          xl={12}
          style={{ marginRight: "3%" }}
          className={classes.rycomGridItem}
        >
          {dataStoreTypeValue}
        </Grid>
      </Grid>
        {connectorFlowType!=null && connectorFlowType=='inbound'?
        <HistoriaclOffsetDetails dateFormat={props.selectedDateFormat} dateTimeZone={props.slectedTimeZone} dateEndValue={props.dateEndValue}   dateStartValue={props.dateStartValue} register={register} control={control} errors={errors} connectorType={connectorType}/>:''}


    </div>
  );
}
