import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import Steps from '../../common/steps/Steps';

import Grid from '@material-ui/core/Grid';
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'

import * as OrganisationService from '../../../services/OrganisationService';
import * as VenueService from '../../../services/VenueService';
import * as ZoneService from '../../../services/ZoneService';
import environment from '../../../environment'



const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '1100px'

    }
}));

export default function OrganisationZoneForm() {

    const history = useHistory();

    const [companyList, setCompanyList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [companyId, setCompanyId] = useState(undefined);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];

    const defaultValues = {
        "venueid": "",
        "zoneName": "",
        "zoneDescription": "",
        "isActive": true,
    };

    const classes = useStyles();
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });

    const onSubmit = data => {
        const payload = {
            "venueid": data.venueid,
            "zoneName": data.zoneName,
            "zoneDescription": data.zoneDescription,
            "isActive": true,
        }
        console.log(payload)
        ZoneService.saveZone(payload).then((res) => {
            console.log(res)
            if (res.status === 200)
                alert('Zone inserted successfully');
            history.push("/company/zone")
        })
            .catch((error) => {
                alert("Zone error")
                console.error('Zone =>', error)
            })
    }

    useEffect(() => {
        if (companyList.length == 0) {
            OrganisationService.getCompanyByParentId(parentcompanyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }, []);

    const getVenueList = (event) => {
        setCompanyId(event.target.value);
        VenueService.getVenueByCompanyId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVenueList(res.data);
                }
            })
            .catch((error) => {
                console.error('getVenueList =>', error)
            })
        return event.target.value
    };

  
    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
    const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);


    return (
        <form className={classes.root}  >
            <RycomPageHeader title="Enter the Zone Details"
                links={[{ title: 'Back', url: '/company/zone' }]}></RycomPageHeader>
            <RycomSectionHeader title="Zone Overview Details"></RycomSectionHeader>
            <RycomGrid>
                <RycomGridItem>
                    <RycomSelectField id="companyid"
                        label="companyid"
                        name="companyid"
                        onChangeHandler={(event) => {
                            return getVenueList(event[0])
                        }}
                        register={register}
                        control={control}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Company is required'
                            }
                        }}
                    >
                        {companyMenuList}
                    </RycomSelectField>
                </RycomGridItem>
                <RycomGridItem>
                    <RycomSelectField id="Venue"
                        label="Venue"
                        name="venueid"
                        onChangeHandler={(event) => {
                           return event[0].target.value;
                        }}
                        register={register}
                        control={control}
                        errors={errors}
                        disabled={!companyId}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Venue is required'
                            }
                        }}
                    >
                        {venueMenuList}
                    </RycomSelectField>
                </RycomGridItem>
            </RycomGrid>
            <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
                <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <RycomTextField id="Zone Name"
                        label="Zone Name"
                        name="zoneName"
                        register={register}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Zone Name is required'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <RycomTextField id="Zone Description"
                        label="Zone Description"
                        name="zoneDescription"
                        register={register}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Zone Description is required'
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid>

            </Grid>
            <RycomSubmitModel>
                <Button type="reset" variant="" size="large"
                    onClick={() => {
                        reset();
                    }}>Clear</Button>
                <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
            </RycomSubmitModel>
        </form>
    );
}
