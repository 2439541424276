import * as React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    }
  
}));
export default function RowRadioButtonsGroup(props) {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const { label, name, register, errors, errorDescription, value, placeholder, disabled, onChangeHandler, style } = props
  const labelWithcaption=errorDescription && errorDescription["required"] && errorDescription["required"].value?label+"*":label;



  return (
    <span>
    { value?
      <FormControl component="fieldset">
        <RadioGroup row name="row-radio-buttons-group">
          <FormControlLabel value="Complete" id="" control={<Radio />} label="Complete"  />
          <FormControlLabel value="last_one_day" control={<Radio />} label="Last One Day" />
          <FormControlLabel value="custom_date" id="custom_date" control={<Radio />} label="Custom Date" />
        </RadioGroup>
      </FormControl>:
      <FormControl component="fieldset">
        <RadioGroup row name="row-radio-buttons-group">
          <FormControlLabel value="Complete" id="" control={<Radio />} label="Complete"  />
          <FormControlLabel value="last_one_day" control={<Radio />} label="Last One Day" />
          <FormControlLabel value="custom_date" id="custom_date" control={<Radio />} label="Custom Date" />
        </RadioGroup>
      </FormControl>
    }
    <span className={classes.invalidMessage}>
           </span>
        </span>
    );
}
