import React, { Component } from 'react'
import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as DeviceService from '../../../services/DeviceService'
import environment from '../../../environment'

class DeviceList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      devices: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];

    DeviceService.getDeviceByParentCompanyId(parentcompanyid)
      .then((res) => {
        if (res && res.data && res.data) {
          const devices = res.data;
          console.log(res);
          this.setState({ devices: devices });
        }
      })
      .catch((error) => {
        console.error('devices =>', error)
      })
  }


  render() {
   

    const columns = [
      { id: 'devicename', label: 'Device Name', minWidth: 170 },
      { id: 'devicedescription', label: 'Device Description', minWidth: 170 },
      { id: 'companyName', label: 'Company', minWidth: 100 },
      { id: 'venueName', label: 'Venue', minWidth: 100 },
    ];
    
      const rows=this.state.devices.map((device)=>{
             device.devicename=<Link to={`/company/device/detail/${device.deviceid}`} >{ device.devicename}</Link>
                                                     return device;
              });
      console.log(rows)
      return (<div>
        <RycomPageHeader title="" links={[{title:'Add Device',url:'/company/device/form',role:"create:org"}]}></RycomPageHeader>
        <RycomTableHeader title="Device" buttonTitle="Add Device"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default DeviceList;
