import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import RycomMultiTextField from '../../../common/RycomMultiTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'

import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';
import * as DocumentClassService from '../../../../services/DocumentClassService';
import * as DataMapperService from '../../../../services/DataMapperService';


import environment from '../../../../environment'


import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { SkipPrevious } from '@material-ui/icons';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function DocumentEdgeEditTemplateForm(props) {

    const history = useHistory();
    const classes = useStyles();
    let defaultValues=props.defaultValues;

    if(defaultValues && defaultValues.fromPropertyName){
    defaultValues.fromPropertyName.forEach((fromPropertyname, index)=>{
        defaultValues["fromPropertyName"+index]=fromPropertyname
    })
    }

    if(defaultValues && defaultValues.toPropertyName){
        defaultValues.toPropertyName.forEach((toPropertyName, index)=>{
            defaultValues["toPropertyName"+index]=toPropertyName
        })
        }
    //console.log("defaultValues")

    //console.log(defaultValues)

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
   
    //console.log(defaultValues.fromEdgeproperties)
    const [fromEdgeproperties, setFromEdgeproperties] = useState(defaultValues.fromEdgeproperties);
    const [toEdgeproperties, setToEdgeproperties] = useState(defaultValues.toEdgeproperties);

    const [dataMapperList, setDataMapperList] = useState([]);

    const [toSelectedDatamapper, setToSelectedDataMapper] = useState(defaultValues.toSelectedDatamapper);
    const [fromSelectedDatamapper, setFromSelectedDataMapper] = useState(defaultValues.fromSelectedDatamapper);

    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
    const [documentClassList, setDocumentClassList] = useState([]);

    let edgeCombinationType='1';

    if(defaultValues.subclassToSubclass){
        edgeCombinationType='1';
    }else if(defaultValues.classPropertyToClassProperty){
        edgeCombinationType='2';
    }else if(defaultValues.classPropertyToSubclass){
        edgeCombinationType='3';
    }else if(defaultValues.multiEdgeTagging){
        edgeCombinationType='4';
    }


    const [edgeCombinationValue, setEdgeCombinationValue] = useState(edgeCombinationType);
    const [fromVertexPropertyValue, setFromVertexPropertyValue] = useState(defaultValues.fromEdgepropertiesValue);
    const [toVertexPropertyValue, setToVertexPropertyValue] = useState(defaultValues.toEdgepropertiesValue);

    const handleEdgeCombinationChange = (event) => {
        setEdgeCombinationValue(event.target.value);

        // setFromEdgeproperties([{
        //     'v_id': 1,
        //     'key': null,
        // }]);
        // setFromVertexPropertyValue(
        //     [{
        //         'v_id': 1,
        //         'value': null,
        //     }]
        // );
        // setToEdgeproperties([{
        //     'v_id': 1,
        //     'key': null,
        // }]);
        // setToVertexPropertyValue(
        //     [{
        //         'v_id': 1,
        //         'value': null,
        //     }]
        // );
    };


    useEffect(() => {//
        //getDocumentClassList(parentcompanyid);
        //getDataMapperList(parentcompanyid);
        getDocumentClassList(paramID);
        getDataMapperList(paramID);
  }, []);

  const getDataMapperList=(parentcompanyid) => {//
    DataMapperService.getDataMapperByCompanyIdForTask(parentcompanyid)
    .then((res) => {
        if (res && res.data && res.data) {
            console.log(res);
            setDataMapperList(res.data);
        }
    })
    .catch((error) => {
        console.error('getConnector =>', error)
    })       }
    


    const canAddNewFromEdgeProperties = () => {
        const length = fromEdgeproperties.length - 1
        return fromEdgeproperties[length].v_id
    };

    const newFromEdgeProfile = () => {
        let id = 1;
        if (fromEdgeproperties.length > 0) {
            let lastKey = canAddNewFromEdgeProperties();
            id = ++lastKey;
        }

        //console.log(id)
        const newEdgeProfile = [...fromEdgeproperties, {
            'v_id': id,
            'key': null,
        }]
        const newEdgeProfileVaue = [...fromVertexPropertyValue, {
            'v_id': id,
            'value': null,
        }]
        setFromEdgeproperties(newEdgeProfile);
        setFromVertexPropertyValue(newEdgeProfileVaue);
    }

    const deleteFromEdgeProfile = (index) => {
        const deleteEdgeProfiles = [...fromEdgeproperties]
        if (deleteEdgeProfiles.length > 1) {
            deleteEdgeProfiles.splice(index, 1);
            setFromEdgeproperties(deleteEdgeProfiles);
        } else {
            setFromEdgeproperties([{
                'v_id': 1,
                'key': null,
            }])
        }
    };

    if (!fromEdgeproperties.length) {
        newFromEdgeProfile()
    }

 
    const canAddNewToEdgeProperties = () => {
        const length = toEdgeproperties.length - 1
        return toEdgeproperties[length].v_id
    };

    const newToEdgeProfile = () => {
        let id = 1;
        if (toEdgeproperties.length > 0) {
            let lastKey = canAddNewToEdgeProperties();
            id = ++lastKey;
        }

        //console.log(id)
        const newEdgeProfile = [...toEdgeproperties, {
            'v_id': id,
            'key': null,
        }]
        const newEdgeProfileVaue = [...toVertexPropertyValue, {
            'v_id': id,
            'value': null,
        }]
        
        setToEdgeproperties(newEdgeProfile);
        setToVertexPropertyValue(newEdgeProfileVaue);
    }

    const deleteToEdgeProfile = (index) => {
        const deleteEdgeProfiles = [...toEdgeproperties]
        if (deleteEdgeProfiles.length > 1) {
            deleteEdgeProfiles.splice(index, 1);
            setToEdgeproperties(deleteEdgeProfiles);
        } else {
            setToEdgeproperties([{
                'v_id': 1,
                'key': null,
            }])
        }
    };

    if (!toEdgeproperties.length) {
        newToEdgeProfile()
    }

    const changeFromKeyHandler = (edgeProperty, event) => {
        edgeProperty.key =event[0].target.value;
    }
    const changeToKeyHandler = (edgeProperty, event) => {
        edgeProperty.key =event[0].target.value;
    }

    const changeFromValueHandler = (e, index) => {
        const { name, value } = e.target;
        const list = [...fromVertexPropertyValue];
        list[index]['value'] = value;
        setFromVertexPropertyValue(list);
    };

    const changeFromValueMultiHandler = (value, index) => {
        const list = [...fromVertexPropertyValue];
        list[index]['value'] = value;
        setFromVertexPropertyValue(list);
    }

    const changeToValueHandler = (e, index) => {
        const { name, value } = e.target;
        let list = [...toVertexPropertyValue];
        if(list.length){
            list[index]['value'] = value;
        } else{
            list= [{
                'v_id': 1,
                'key': null,
                'value': value,
                'valueType': null
            }];
        }
        
        setToVertexPropertyValue(list);
    };

    const changeToValueMultiHandler = (value, index) => {
        let list = [...toVertexPropertyValue];

        if(list.length){
            list[index]['value'] = value;
        } else{
            list= [{
                'v_id': 1,
                'key': null,
                'value': value,
                'valueType': null
            }];
        }

        setToVertexPropertyValue(list);
    };

 
    const documentClassMenuList = documentClassList.map((relation, index) => <MenuItem key={++index} value={relation.className}> {relation.className}</MenuItem>);
    const dataMapperMenuList = dataMapperList.map((dataMapper, index) => <MenuItem key={++index} value={dataMapper.datamapperid}> {dataMapper.datamappername}</MenuItem>);
    const extractVertexPropertyNames=mapper=>{
        console.log("extractVertexPropertyNames");
        console.log(mapper);
        return mapper.map(mappervalue=>mappervalue.mappedJsonKey)};
    const extractVertexProperties=(mapper)=>{
        let vertexPropertiesNames=[];
        mapper.forEach(mapperValue=>{
            if(mapperValue.flattening && mapperValue.nestedJsonTypeConfig){
               const values=extractVertexPropertyNames(mapperValue.nestedJsonTypeConfig);
               values.forEach(value=>vertexPropertiesNames.push(value));
            }else{
                const value=mapperValue.mappedJsonKey;
                vertexPropertiesNames.push(value);
            }
        })
        return vertexPropertiesNames;
     }

    let fromVertexProperties=[];
    let toVertexProperties=[];

    let fromVertexMenuList = [];
    let toVertexMenuList = [];

    if(fromSelectedDatamapper && dataMapperList.length>0){
        const savedFromDataMapper=dataMapperList.filter(dataMapper=>dataMapper.datamapperid===fromSelectedDatamapper)
        console.log(savedFromDataMapper)
         fromVertexProperties = extractVertexProperties(savedFromDataMapper[0].mapper);     
        fromVertexMenuList = fromVertexProperties.map((propertyName, index) => <MenuItem key={propertyName} value={propertyName}> {propertyName}</MenuItem>);
     }

     if(toSelectedDatamapper && dataMapperList.length>0){
        const savedToDataMapper=dataMapperList.filter(dataMapper=>dataMapper.datamapperid===toSelectedDatamapper)
        toVertexProperties = extractVertexProperties(savedToDataMapper[0].mapper);     
         toVertexMenuList = toVertexProperties.map((propertyName, index) => <MenuItem key={++index} value={propertyName}> {propertyName}</MenuItem>);
    }
  

    const fromDataMapperChangeHandler=(dataMapper)=>{
        setFromSelectedDataMapper(dataMapper);
    }
    
  
    const toDataMapperChangeHandler=(dataMapper)=>{
        setToSelectedDataMapper(dataMapper);
    }
  
    
  
    const getDocumentClassList = (companyId) => {
        DocumentClassService.getAllVertex(companyId)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log("Relation documwnt")
                    console.log(res.data)
                    setDocumentClassList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };
    

  
    
    const geFromVertexPropertyField=(edgeProperty,index)=>{
                        const value=false;
     const propertyName="fromPropertyName"+index

                        if(value){
        return <RycomTextField id={propertyName}
                        label="Vertex Property Name"
                name={propertyName}
                register={register}
                errors={errors}
                onChange={changeFromKeyHandler(edgeProperty)}
                value={edgeProperty.key}
                />;
     }   else{
       return <RycomSelectField id={propertyName}
                label="Vertex Property Name"
                name={propertyName}
                onChangeHandler={(event) => {
                    changeFromKeyHandler(edgeProperty,event)
                    return event[0].target.value
                }}
                register={register}
                control={control}
                errors={errors}
                errorDescription={{
                    required: {
                        value: true,
                        message: 'From Vertex is required'
                    }
                }}>
            {fromVertexMenuList}
        </RycomSelectField>
     }
      
    }




    const toVertexPropertyField=(edgeProperty,index)=>{
        const value=false;
        const propertyName="toPropertyName"+index
     if(value){
        return <RycomTextField id="toVertexPropertyName"
                        label="To Property Name"
                name="toVertexPropertyName"
                register={register}
                errors={errors}
                onChange={changeToKeyHandler(edgeProperty)}
                value={edgeProperty.key}
                />;
     }   else{
       return <RycomSelectField id={propertyName}
                label="To Vertex Property Name"
                name={propertyName}
                onChangeHandler={(event) => {
                    changeToKeyHandler(edgeProperty,event)
                    return event[0].target.value
                }}
                register={register}
                control={control}
                errors={errors}
                errorDescription={{
                    required: {
                        value: true,
                        message: 'To Vertex is required'
                    }
                }}>
            {toVertexMenuList}
        </RycomSelectField>
     }
      
    }


    const fromEdgeList = fromEdgeproperties.map((edgeProperty, index) => {
        edgeProperty.id = index;
        return <RycomGrid>   
            <RycomGridItem>
                {
                    geFromVertexPropertyField(edgeProperty,index)
                }
            </RycomGridItem>
            <RycomGridItem>
                {edgeCombinationValue > 1 && edgeCombinationValue < 5 ? (<RycomTextField id="vertexFromPropertyValue"
                        label="Vertex From Property Value"
                        name="vertexFromPropertyValue"
                        register={register}
                        errors={errors}
                        value={fromVertexPropertyValue[index] && fromVertexPropertyValue[index].value? 
                            fromVertexPropertyValue[index].value:null}
                        onChangeHandler={e => changeFromValueHandler(e, index)}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Property Value is required'
                            }
                    }} />
                    ) : edgeCombinationValue == 5 ? (<RycomMultiTextField
                        label="Vertex From Property Value"
                        name="vertexFromPropertyValue"
                        placeholder="Separate values with ENTER."
                        register={register}
                        errors={errors}
                        onChangeHandler={(chips) => changeFromValueMultiHandler(chips, index)}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Property Value is required'
                            }
                        }} />) : ""}
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" variant="contained" size="small" disabled={fromEdgeproperties.length==1} value="delete Edge"
                    onClick={(e) => {
                        const deleteEdgeProfiles = [...fromEdgeproperties]
                        const deleteEdgeProfilesValue = [...fromVertexPropertyValue]
                        if (fromEdgeproperties.length > 1) {
                            const deleteEdgeProfiles1 = deleteEdgeProfiles.filter(edge => {
                                return edge.v_id != edgeProperty.v_id
                            });
                            setFromEdgeproperties(deleteEdgeProfiles1);

                            const deleteEdgeProfilesValue1 = deleteEdgeProfilesValue.filter(edge => {
                                return edge.v_id != edgeProperty.v_id
                            });
                            setFromVertexPropertyValue(deleteEdgeProfilesValue1);
                        } else {
                            setFromEdgeproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }])
                            setFromVertexPropertyValue([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }]);
                        }
                    }}>Delete Vertex Property</Button>
                    </RycomGridItem>
        </RycomGrid> ;
    });
 
    const toEdgeList = toEdgeproperties.map((edgeProperty, index) => {
        edgeProperty.id = index;
        return <RycomGrid>   
            <RycomGridItem>
                {
                    toVertexPropertyField(edgeProperty,index)
                }
            </RycomGridItem>
            <RycomGridItem>
                {edgeCombinationValue > 1 && edgeCombinationValue < 4 ? (<RycomTextField id="vertexToPropertyValue"
                        label="Vertex To Property Value"
                        name="vertexToPropertyValue"
                        register={register}
                        errors={errors}
                        value={toVertexPropertyValue[index] && toVertexPropertyValue[index].value? 
                            toVertexPropertyValue[index].value:null}
                        onChangeHandler={e => changeToValueHandler(e, index)}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Property Value is required'
                            }
                        }} />
                    ) : edgeCombinationValue == 4 ? (<RycomMultiTextField
                        label="Vertex To Property Value"
                        name="vertexToPropertyValue"
                        placeholder="Separate values with ENTER."
                        register={register}
                        errors={errors}
                        value={toVertexPropertyValue[index] && toVertexPropertyValue[index].value? 
                            toVertexPropertyValue[index].value:null}
                        onChangeHandler={(chips) => changeToValueMultiHandler(chips, index)}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Property Value is required'
                            }
                        }} />) : ""}
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" variant="contained" size="small" disabled={toEdgeproperties.length==1} value="delete Edge"
                    onClick={(e) => {
                        const deleteEdgeProfiles = [...toEdgeproperties]
                        const deleteEdgeProfilesValue = [...toVertexPropertyValue]
                        if (toEdgeproperties.length > 1) {
                            const deleteEdgeProfiles1 = deleteEdgeProfiles.filter(edge => {
                                return edge.v_id != edgeProperty.v_id
                            });
                            setToEdgeproperties(deleteEdgeProfiles1);

                            const deleteEdgeProfilesValue1 = deleteEdgeProfilesValue.filter(edge => {
                                return edge.v_id != edgeProperty.v_id
                            });
                            setToVertexPropertyValue(deleteEdgeProfilesValue1);
                        } else {
                            setToEdgeproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }])
                            setToVertexPropertyValue([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }]);
                        }
                    }}>Delete To Vertex Property</Button>
                    </RycomGridItem>
        </RycomGrid> ;
    });
 
    
 
    const onSubmit = payload => {
        // console.log("fromEdgeproperties")
        // console.log(fromEdgeproperties);
        // console.log(toEdgeproperties);
        // console.log(fromVertexPropertyValue);
        // console.log(toVertexPropertyValue);

        let fromPropertyName;
        let fromPropertyValue;
        let toPropertyName;
        let toPropertyValue;
        let returnFlag=false;
        payload['fromPropertyNameMap']=new Object();
        payload['toPropertyNameMap']=new Object();

        if(edgeCombinationValue > 1){
            if(fromVertexPropertyValue && fromVertexPropertyValue.length >0){
                fromVertexPropertyValue.map((v, i)=>{
                    if(v['v_id'] === fromEdgeproperties[i]['v_id']){
                        fromPropertyName=fromEdgeproperties[i].key;
                        //fromPropertyValue=v['value'].split(',');
                        fromPropertyValue=v['value'];
                        if(!fromPropertyValue){
                            alert("Please select From Property Value");
                            returnFlag= true;
                        }

                        payload['fromPropertyNameMap'][[fromPropertyName]] = fromPropertyValue;
                    }
                });
            } else{
                alert("Please select From Property Value");
                return false;
            }
        } else{
            returnFlag= false;
        }

        if(returnFlag)
            return false;

        if(edgeCombinationValue > 1 && edgeCombinationValue != 3){
            if(toVertexPropertyValue && toVertexPropertyValue.length >0){
                toVertexPropertyValue.map((v, i)=>{
                    if(v['v_id'] === toEdgeproperties[i]['v_id']){
                        toPropertyName=toEdgeproperties[i].key;
                        //toPropertyValue=v['value'].split(',');
                        toPropertyValue=v['value'];
                        if(!toPropertyValue){
                            alert("Please select To Property Value");
                            returnFlag= true;
                        }

                        payload['toPropertyNameMap'][[toPropertyName]] = toPropertyValue;
                    }
                });
            } else{
                alert("Please select To Property Value");
                return false;
            }
        } else{
            returnFlag= false;
        }

        if(returnFlag)
            return false;

        if(edgeCombinationValue == 4){
            payload['toPropertyNameMapList']= payload['toPropertyNameMap'];
            delete payload['toPropertyNameMap'];
        }

        if(fromEdgeproperties && fromEdgeproperties.length >0)
        payload['fromPropertyName']=fromEdgeproperties.map(edgeProperty=>edgeProperty.key);
        else{
            alert("Please enter From Property info");
            return false;
        }

        if(toEdgeproperties && toEdgeproperties.length >0)
        payload['toPropertyName']=toEdgeproperties.map(edgeProperty=>edgeProperty.key);
          else{
            alert("Please enter From Property info");
            return false;
        }
        payload.isActive=true;
        //payload.parentcompanyid=parentcompanyid;
        payload.parentcompanyid=paramID;

        payload['isSubclassToSubclass'] = false;
        payload['isClassPropertyToClassProperty'] = false;
        payload['isClassPropertyToSubclass'] = false;
        payload['isMultiEdgeTagging'] = false;

        if(edgeCombinationValue === "1"){
            payload['isSubclassToSubclass'] = true;
        }else if(edgeCombinationValue === "2"){
            payload['isClassPropertyToClassProperty'] = true;
        }else if(edgeCombinationValue === "3"){
            payload['isClassPropertyToSubclass'] = true;
        }else if(edgeCombinationValue === "4"){
            payload['isMultiEdgeTagging'] = true;
        }else{
            alert("Please select proper Vertex Combinations")
            return false;
        }

        /*
        private String edgeName;
        private String fromClassName;
        private List<String> fromPropertyName;
        private String toClassName;
        private List<String> toPropertyName;
        payload.parentcompanyid=parentcompanyid;

        */

        payload['edgeRelationshipId']=defaultValues['edgeRelationshipId']
        
        DocumentEdgeService.updateEdge(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Edge inserted successfully');
            history.push("/topics/documentedge")
          })
        .catch((error) => {
          alert("Edge error")                                      
           console.error('getEdge =>', error)
         }) 
    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Document Edge Details"
                    links={[{ title: 'Back', url: '/topics/edge' }]}></RycomPageHeader>
                <RycomSectionHeader title="Edge Overview Details"></RycomSectionHeader>
           
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="linkName"
                            label="Edge Name"
                            name="edgeName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="linkDescription"
                            label="Edge Description"
                            name="edgeDescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Description is required'
                                }
                            }}
                        />
              
                    </RycomGridItem>
                    </RycomGrid>

                    <RycomSectionHeader title="Vertex Combinations"></RycomSectionHeader>
                    <RycomGrid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <RadioGroup row aria-label="position" name="edgeCombinationType" value={edgeCombinationValue} onChange={handleEdgeCombinationChange} defaultValue="top">
                                <FormControlLabel
                                    value="1"
                                    control={<Radio color="primary" />}
                                    label="Dynamic Edge Creation"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio color="primary" />}
                                    label="Vertex with value"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio color="primary" />}
                                    label="Main Vertex to Sub Vertex"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="4"
                                    control={<Radio color="primary" />}
                                    label="Multi Edge Tagging"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </Grid>
                    </RycomGrid>

                    <RycomSectionHeader title="From Vertex Details"></RycomSectionHeader>
           
                        <RycomGrid>
                                     <RycomGridItem>
                            <RycomSelectField id="fromClassName"
                            label="From Document Vertex"
                            name="fromClassName"
                            onChangeHandler={(event) => {
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'From Vertex is required'
                                }
                            }}>
                                {documentClassMenuList}
                            </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="fromSelectedDatamapper"
                            label="DataMapper"
                            name="fromSelectedDatamapper"
                            onChangeHandler={(event) => {
                                fromDataMapperChangeHandler(event[0].target.value);
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            value={fromSelectedDatamapper}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'DataMapper is required'
                                }
                            }}
                        >
                            {dataMapperMenuList}
                        </RycomSelectField> 
                    </RycomGridItem>
             
{ fromEdgeList}
<Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newFromEdgeProfile}>Add Vertex Property</Button>
                        </span>
                    </Grid>
                </Grid>           
        
                    </RycomGrid>
                    <RycomSectionHeader title="To Vertex Details"></RycomSectionHeader>

                <RycomGrid>
                    <RycomGridItem>
                            <RycomSelectField id="toClassName"
                            label="To Document Vertex"
                            name="toClassName"
                            onChangeHandler={(event) => {
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'To Vertex is required'
                                }
                            }}>
                                {documentClassMenuList}
                            </RycomSelectField>
                    </RycomGridItem>  
                    {edgeCombinationValue != 3 ? (
                        <RycomGridItem>
                            <RycomSelectField id="toSelectedDatamapper"
                                label="DataMapper"
                                name="toSelectedDatamapper"
                                onChangeHandler={(event) => {
                                    toDataMapperChangeHandler(event[0].target.value);
                                    return event[0].target.value;
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                value={toSelectedDatamapper}
                                errorDescription={{
                                    required: {
                                        value: true,
                                        message: 'DataMapper is required'
                                    }
                                }}
                            >
                                {dataMapperMenuList}
                            </RycomSelectField> 
                            </RycomGridItem>
                    ) : ""}

                    { edgeCombinationValue != 3 ? toEdgeList : "" }

<Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add To Edge" onClick={newToEdgeProfile}>Add Vertex Property</Button>
                        </span>
                    </Grid>
                </Grid>           

               
                </RycomGrid>
                
            
            
             <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                setFromEdgeproperties([])
                                setToEdgeproperties([])
                                setFromVertexPropertyValue([])
                                setToVertexPropertyValue([])
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
