import React from 'react'
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader';

export default function ConnectorSFTPDetail({connectorInfo}) {
       
    const suffix = 'recursive'
    const pathLabel = (connectorInfo.connectionInfo.type !== undefined &&
        connectorInfo.connectionInfo.type.substr(-suffix.length) === suffix) ? 'Directory Path' : 'File Path';
    
    return (
        <div>
            <RycomSectionHeader title="Connector Device & Server Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Host" value={connectorInfo ? connectorInfo.connectionInfo.configuration.host : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Port" value={connectorInfo ? connectorInfo.connectionInfo.configuration.port : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label={pathLabel} value={connectorInfo ? connectorInfo.connectionInfo.configuration.filePath : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="File Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.fileName : ''}></RycomInfoPair>
                </Grid>
                {connectorInfo.connectionInfo.dataflow == "outbound" &&
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="File Name already exist In the FTP Server" value={connectorInfo ? connectorInfo.connectionInfo.configuration.existingFileStrategy : ''}></RycomInfoPair>
                    </Grid>
                }
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Authentication Type" value={connectorInfo ? connectorInfo.connectionInfo.configuration.authenticationType : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="User Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.userName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Key File" value={connectorInfo ? connectorInfo.connectionInfo.configuration.sshPrivateKeyFileName : ''}></RycomInfoPair>
                </Grid>
                {
                    connectorInfo.connectionInfo.configuration.decryptionEnabled && 
                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <RycomInfoPair label="Decrypt the file" value={(connectorInfo && connectorInfo.connectionInfo.configuration.decryptionEnabled)
                         ? 'TRUE' : 'FALSE'}></RycomInfoPair>
                        </Grid>
                }
                {
                    connectorInfo.connectionInfo.configuration.encryptionEnabled && 
                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <RycomInfoPair label="Encrypt the file" value={(connectorInfo && connectorInfo.connectionInfo.configuration.encryptionEnabled)
                         ? 'TRUE' : 'FALSE'}></RycomInfoPair>
                        </Grid>
                }
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Encryption File Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.pgpKeyFileName : ''}></RycomInfoPair>
                </Grid>
                {/* <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Password" value={connectorInfo ? connectorInfo.connectionInfo.configuration.password : ''}></RycomInfoPair>
                </Grid> */}
                {/* <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Public Key Authentication" value={(connectorInfo && connectorInfo.connectionInfo.configuration.publicKeyAuthenticaiton)
                         ? 'TRUE' : 'FALSE'}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="PassPhrase" value={connectorInfo ? connectorInfo.connectionInfo.configuration.passPhrase : ''}></RycomInfoPair>
                </Grid> */}
            </Grid>
        </div>);
 }
