import React from 'react'
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader';

export default function ConnectorApiDetail({ connectorInfo }) {

    console.log(connectorInfo);
    const headerContent=connectorInfo.connectionInfo.configuration.header;
    console.log(headerContent)
    let headerContentArr=[];
    let headerContentValues=[];
 
    if(headerContent) {   
    for (let key in headerContent){
        headerContentArr.push({headerKey: key, value:headerContent[key]});
      }
     
      console.log(headerContentArr);

      headerContentValues=headerContentArr.map((headerValues)=>{
      return <Grid container spacing={12} style={{ padding: 8 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label={headerValues ? headerValues.headerKey : ""} value={headerValues ? headerValues.value : ''}></RycomInfoPair>
        </Grid>
     </Grid>; 
      });
    }

    return (
        <div>
            <RycomSectionHeader title="Connector Device & Server Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Base URL" value={connectorInfo ? connectorInfo.connectionInfo.configuration.baseURI : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Path URL" value={connectorInfo ? connectorInfo.connectionInfo.configuration.pathURI : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Request Method" value={connectorInfo ? connectorInfo.connectionInfo.configuration.requestMethod : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Content Type" value={connectorInfo ? connectorInfo.connectionInfo.configuration.contentType : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="History" value={connectorInfo ? connectorInfo.connectionInfo.configuration.history : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Frequency" value={connectorInfo ? connectorInfo.connectionInfo.configuration.frequency : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Include Path" value={connectorInfo ? connectorInfo.connectionInfo.configuration.includePathId : ''}></RycomInfoPair>
                </Grid>
            </Grid>
            <RycomSectionHeader title="Header"></RycomSectionHeader>
           {headerContentValues}
           
           
             
        </div>);
}
