import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveDocumentRelation = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/documentrelation`,
          vertexPayload, addBearerToken()
    );
}

export const getAllDocumentRelation = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/documentrelation/parentcompanyvertex/${companyId}`, addBearerToken());
}

export const getDocumentRelationById = (relationshipid) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/documentrelation/${relationshipid}`, addBearerToken());
}
