import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import Steps from '../../common/steps/Steps';

import Grid from '@material-ui/core/Grid';
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'

import * as OrganisationService from '../../../services/OrganisationService';
import * as VenueService from '../../../services/VenueService';
import * as ZoneService from '../../../services/ZoneService';
import * as DeviceService from '../../../services/DeviceService';
import environment from '../../../environment'


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '1100px'

    }
}));

export default function DeviceForm() {
    const history = useHistory();
    const [companyList, setCompanyList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [companyId, setCompanyId] = useState(undefined);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const [zoneList, setZoneList] = useState([]);

    const defaultValues = {
        "devicename": "",
        "devicedescription": "",
        "venueid": "",
        "companyid": "",
        "isActive": true
    };

    const classes = useStyles();
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });

    const onSubmit = data => {
        const payload = {
            "devicename": data.devicename,
            "devicedescription": data.devicedescription,
            "venueid": data.venueid,
            "companyid": data.companyid,
            "activezoneid":[data.zonelist],
            "isActive": true
        }
        console.log(payload)
        DeviceService.saveDevice(payload).then((res) => {
            console.log(res)
            if(res.status === 200)  
            alert('Device inserted successfully');
            history.push("/company/device/")
          })
        .catch((error) => {
          alert("Device error")                                      
           console.error('Device =>', error)
         }) 


    }

    useEffect(() => {
        if (companyList.length == 0) {
            OrganisationService.getCompanyByParentId(parentcompanyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }, []);

    const getVenueList = (event) => {
        setCompanyId(event.target.value);
        VenueService.getVenueByCompanyId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVenueList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        return event.target.value
    };

    const getZoneList = (event) => {
        ZoneService.getZoneByVenueId(event.target.value)
            .then((res) => {
                console.log(res)
                if (res && res.data && res.data) {
                    setZoneList(res.data);
                }
            })
            .catch((error) => {
                console.error('getZoneList =>', error)
            })
        return event.target.value
    };

    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
    const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);
    const zoneMenuList = zoneList.map((zone, index) => <MenuItem key={++index} value={zone.zoneid}> {zone.zoneName}</MenuItem>);
    console.log(zoneMenuList)
    return (
        <form className={classes.root}  >
            <RycomPageHeader title="Enter the Device Details"
                links={[{ title: 'Back', url: '/' }]}></RycomPageHeader>
            <RycomSectionHeader title="Device Overview Details"></RycomSectionHeader>
            <RycomGrid>
                <RycomGridItem>
                    <RycomSelectField id="companyid"
                        label="companyid"
                        name="companyid"
                        onChangeHandler={(event) => {
                            return getVenueList(event[0])
                        }}
                        register={register}
                        control={control}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Company is required'
                            }
                        }}
                    >
                        {companyMenuList}
                    </RycomSelectField>
                </RycomGridItem>
                <RycomGridItem>
                    <RycomSelectField id="Venue"
                        label="Venue"
                        name="venueid"
                        onChangeHandler={(event) => {
                           return getZoneList(event[0])
                        }}
                        register={register}
                        control={control}
                        errors={errors}
                        disabled={!companyId}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Venue is required'
                            }
                        }}
                    >
                        {venueMenuList}
                    </RycomSelectField>
                </RycomGridItem>
                <RycomGridItem>
                    <span>
                        <Controller
                            as={
                                <TextField
                                    id="zonelist"
                                    select
                                    label="Zones"
                                    variant="outlined"
                                    name="zonelist"
                                    multiple
                                >
                                    {zoneMenuList}
                                </TextField>
                            }
                            control={control}
                            name="zonelist"
                            multiple
                        />
                        <span className={classes.invalidMessage}>
                            {errors["zonelist"] && <span>{errors["zonelist"].message}</span>}
                        </span>
                    </span>
                </RycomGridItem>
            </RycomGrid>
            <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
                <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <RycomTextField id="Device Name"
                        label="Device Name"
                        name="devicename"
                        register={register}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Device Name is required'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <RycomTextField id="devicedescription"
                        label="Device Description"
                        name="devicedescription"
                        register={register}
                        errors={errors}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Device Description is required'
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <RycomSubmitModel>
                <Button type="reset" variant="" size="large"
                    onClick={() => {
                        reset();
                    }}>Clear</Button>
                <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
            </RycomSubmitModel>
        </form>
    );
}
