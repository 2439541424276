import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as EdgeTaskService from '../../services/EdgeTaskService';
import TaskEdgeEditTemplate from './TaskEdgeEditTemplate';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));

export default function TaskEdit() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
      EdgeTaskService.getTaskEdgeById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.taskid)?
        <TaskEdgeEditTemplate defaultValues={defaultValues}></TaskEdgeEditTemplate>:"";
  
}
