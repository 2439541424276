import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'
import FormHelperText from '@material-ui/core/FormHelperText';

import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as VertexService from '../../../../services/VertexService';
import * as EdgeService from '../../../../services/EdgeService';
import * as RelationService from '../../../../services/RelationService';


import environment from '../../../../environment'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function RelationForm() {
   
    const defaultValues = {
    };
    const history = useHistory();
    const classes = useStyles();
    const [companyId, setCompanyId] = useState(undefined);
    const [baseVertexId, setBaseVertexId] = useState(undefined);

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const [companyList, setCompanyList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [vertexList, setVertexList] = useState([]);
    const [edgeList, setEdgeList] = useState([]);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    useEffect(() => {
        getEdgeList(parentcompanyid);
        getVertexList(parentcompanyid);
    }, []);

    const getVertexList = (companyId) => {
        
        //VertexService.getAllVertex(parentcompanyid)
        VertexService.getAllVertex(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    const vertexList=res.data.filter(vertex=>vertex.type==="CONFIG_TYPE")
                    console.log(vertexList)
                    setVertexList(vertexList);
                }
            })
            .catch((error) => {
                console.error('getVertexList =>', error)
            })
            
    };

    const getEdgeList = (companyId) => {
        //EdgeService.getAllEdge(parentcompanyid)
        EdgeService.getAllEdge(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    const edgeList=res.data.filter(edge=>edge.type==="CONFIG_TYPE")
                    setEdgeList(edgeList);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
          
    };


    const vertexMenuList = vertexList.map((vertex, index) => <MenuItem key={++index} value={vertex.vertexid}> {vertex.vertexname}</MenuItem>);
    const edgeMenuList = edgeList.map((edge, index) => <MenuItem key={++index} value={edge.edgeid}> {edge.edgename}</MenuItem>);
    const edgeDirectionMenuList=['IN','OUT'].map((value, index) => <MenuItem key={++index} value={value}> {value}</MenuItem>);
    const edgeRelationTypeMenuList=['O2M','O2O'].map((value, index) => <MenuItem key={++index} value={value}> {value}</MenuItem>);

    const onSubmit = formData => {

        const baseVertexObj={
            vertexid:formData.basevertex
        };

        const edgeObj={
            "edgeid":formData.edgeid
        };

        const destinationVertexObj={
            vertexid:formData.otherVertex
        }

         let payload = {
            "rname": formData.rname,
            "rdescription":formData.rdescription,
            "isActive":true,
            //"parentcompanyid": parentcompanyid,
            "parentcompanyid": paramID,
            "createdbyuserid":"0",
            "basevertex":baseVertexObj,
            "type":"CONFIG_TYPE",
            "relation":
            [
                {
                    "edges":[edgeObj],
                    "edgerelationtype":formData.edgerelationtype,
                    "edgedirection": formData.edgedirection,
                    "vertexes": [destinationVertexObj]
                 }
            ]
        };

         console.log(JSON.stringify(payload));

      RelationService.saveRelation(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Relation inserted successfully');
            history.push("/topics/relation")
          })
        .catch((error) => {
          alert("Relation error")                                      
           console.error('Relation =>', error)
         }) 
   }

   return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Relation Details"
                    links={[{ title: 'Back', url: '/topics/relation' }]}></RycomPageHeader>
                <RycomSectionHeader title="Relation Overview Details"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="rname"
                            label="Relation Name"
                            name="rname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Relationship Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="rdescription"
                            label="Relationship Description"
                            name="rdescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Relationship Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="Relationship Configuration"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="basevertexid"
                            label="Base Vertex"
                            name="basevertex"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Base vertex is required'
                                }
                            }}
                        >
                            {vertexMenuList}
                        </RycomSelectField> 
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="edgeid"
                            label="Edge"
                            name="edgeid"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge is required'
                                }
                            }}
                        >
                            {edgeMenuList}
                        </RycomSelectField>
                    </RycomGridItem>  
                    <RycomGridItem>
                        <RycomSelectField id="edgedirection"
                            label="Edge Direction"
                            name="edgedirection"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Direction is required'
                                }
                            }}
                        >
                           {edgeDirectionMenuList} 
                        </RycomSelectField>
                    </RycomGridItem>  
                    </RycomGrid>       
                     <RycomGrid>
                         <RycomGridItem>
                        <RycomSelectField id="edgerelationtype"
                            label="Edge Relationship Type"
                            name="edgerelationtype"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Relation Type is required'
                                }
                            }}
                        >
                            {edgeRelationTypeMenuList}
                        </RycomSelectField> 
                    </RycomGridItem>
                    </RycomGrid>       
                 <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
