import React, { Component } from 'react'
import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as ZoneService from '../../../services/ZoneService';
import environment from '../../../environment'


class OrganisationZoneList extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      zones: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];

    ZoneService.getAllZones(parentcompanyid)
      .then((res) => {
        if (res && res.data && res.data) {
          const zones = res.data;
          console.log(zones);
          this.setState({ zones: zones });
        }
      })
      .catch((error) => {
        console.error('zones List =>', error)
      })
  }

  render() {

    const columns = [
      { id: 'zoneName', label: 'Zone Name', minWidth: 170 },
      { id: 'zoneDescription', label: 'Zone Description', minWidth: 100 },
      { id: 'venueName', label: 'Venue', minWidth: 100 },
      { id: 'companyName', label: 'Company', minWidth: 100 }

    ];
    
      const rows=this.state.zones.map((zone)=>{
            zone.zoneName=<Link to={`/company/zone/detail/${  zone.zoneid}`}>{zone.zoneName}</Link>
                                                     return zone;
              });

    return (<div>
        <RycomPageHeader title="" links={[{title:'Add Zone',url:'/company/zone/form',role:"create:org"}]}></RycomPageHeader>
        <RycomTableHeader title="Zone List" buttonTitle="Add Zone"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default OrganisationZoneList;
