import React, { Component } from 'react'
import RycomTable from '../../../common/RycomTable'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomTableHeader from '../../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as RelationService from '../../../../services/RelationService';
import environment from '../../../../environment'


class RelationList extends Component {


  constructor(props) {
    super(props)
    this.state = {
      relations: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //RelationService.getAllRelation(parentcompanyid)
    RelationService.getAllRelation(paramID)
      .then((res) => {
        if (res && res.data && res.data) {
          const relations = res.data;
          console.log(res);
          this.setState({ relations: relations });
        }
      })
      .catch((error) => {
        alert('Relation Retrieval Error');
        console.error('relations =>', error)
      })
  }

  render() {
    const columns = [
      { id: 'rname', label: 'Relation Name', minWidth: 170 },
      { id: 'rdescription', label: 'Description', minWidth: 170 },
    ];

     const rows=this.state.relations.map((relation)=>{
                   relation.rname=<Link to={`/topics/relation/detail/${relation.relationshipid	}`} >{relation.rname}</Link>
                                                     return relation;
              });

     return (<div>
        <RycomPageHeader title="Relation List" links={[{title:'Add Relation',url:'/topics/relation/form', role:"create:graph"}]}></RycomPageHeader>
        <RycomTableHeader title="Relation" buttonTitle="Add Relation"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default RelationList;
