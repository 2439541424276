import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'


export const saveEdge = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/edges`,
          vertexPayload, addBearerToken());
}
export const updateEdge = (vertexPayload) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edges`,
          vertexPayload, addBearerToken());
}
export const getAllEdge = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/parentcompanyedges/${companyId}`, addBearerToken());
}

export const getEdgeById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edges/${id}`, addBearerToken());
}

export const deleteEdge = (id) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edges/delete/${id}`, {}, addBearerToken());
}