import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';

const useStyles = makeStyles(theme => ({
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    },
    WAMuiChipInput: {
        width: '50ch',
        margin: '8px'
    }
}));

export default function RycomMultiTextField(props) {
    const classes = useStyles();
    const { label, name, register, errors, errorDescription, value, placeholder, disabled, onChangeHandler, onHandleAdd, onHandleDelete } = props
    const labelWithcaption=errorDescription && errorDescription["required"] && errorDescription["required"].value?label+"*":label;
    return (
        <span>
           { value?
                <ChipInput
                    id="outlined-multiline-flexible"
                    //id="outlined-basic" 
                    variant="outlined"
                    name={name}
                    register={register}
                    placeholder={placeholder}
                    inputRef={register(errorDescription)}
                    label={labelWithcaption}
                    defaultValue={value?value:""}
                    onChange={onChangeHandler}
                    onAdd={onHandleAdd}
                    onDelete={onHandleDelete}
                    disabled={disabled}
                    className={classes.WAMuiChipInput}
                    />:
                <ChipInput
                    id="outlined-multiline-flexible"
                    //id="outlined-basic" 
                    variant="outlined"
                    name={name}
                    register={register}
                    placeholder={placeholder}
                    inputRef={register(errorDescription)}
                    label={labelWithcaption}
                    onChange={onChangeHandler}
                    onAdd={onHandleAdd}
                    onDelete={onHandleDelete}
                    disabled={disabled}
                    className={classes.WAMuiChipInput}
                    />
           } 
            <span className={classes.invalidMessage}>
                {errors[name] && <span>{errors[name].message}</span>}
            </span>
        </span>
    );
}
