import React from 'react'
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader';

export default function ConnectorFTPDetail({ connectorInfo }) {

    const suffix = 'recrusive'
    const pathLabel = (connectorInfo.connectionInfo.type !== undefined &&
        connectorInfo.connectionInfo.type.substr(-suffix.length) === suffix) ? 'Directory Path' : 'File Path';
    
    return (
        <div>
            <RycomSectionHeader title="Connector Device & Server Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Host" value={connectorInfo ? connectorInfo.connectionInfo.configuration.host : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Port" value={connectorInfo ? connectorInfo.connectionInfo.configuration.port : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label={pathLabel} value={connectorInfo ? connectorInfo.connectionInfo.configuration.filePath : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="File Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.fileName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Authentication Type" value={connectorInfo ? connectorInfo.connectionInfo.configuration.authenticationType : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="User Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.userName : ''}></RycomInfoPair>
                </Grid>
                {/* <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Password" value={connectorInfo ? connectorInfo.connectionInfo.configuration.password : ''}></RycomInfoPair>
                </Grid> */}
            </Grid>
        </div>);
}
