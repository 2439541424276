import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveVertex = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/vertex`,
          vertexPayload, addBearerToken()
    );
}

export const updateVertex = (vertexPayload) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/vertex`,
          vertexPayload, addBearerToken()
    );
}

export const deleteVertex = (id) => {
    console.log('delete vertx'+id);
    return axios.patch(`${environment.dataBaseUrl}/api/dms/vertex/delete/${id}`,{}, addBearerToken());
}   


export const getAllVertex = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/parentcompanyvertex/${companyId}`, addBearerToken());
}

export const getVertexById = (id) => {
    console.log('Get vertex ID API PAGE',`${environment.dataBaseUrl}/api/dms/vertex/${id}`);
    return axios.get(`${environment.dataBaseUrl}/api/dms/vertex/${id}`, addBearerToken());
}
