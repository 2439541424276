import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as EdgeService from '../../../../services/EdgeService';
import EdgeEditTemplate from './EdgeEditTemplate';
import { useParams } from 'react-router-dom'



export default function UserEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
        EdgeService
            .getEdgeById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.edgeid)?
        <EdgeEditTemplate defaultValues={defaultValues}></EdgeEditTemplate>:"";
  
}
