import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'
import RycomSelectSmallWidth from  '../../common/RycomSectionHeader'


import * as OrganisationService from '../../../services/OrganisationService';
import * as VenueService from '../../../services/VenueService';
import * as DeviceService from '../../../services/DeviceService';
import * as RelationService from '../../../services/RelationService';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Controller } from 'react-hook-form'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InnerJsonMapper from '../../common/InnerJsonMapper';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    }
}));

export default function JsonMapperForm(props) {
    const classes = useStyles();
    const { register2, errors2, control2, handleSubmit2, mapperList, setMapperList, handleClose, setMapperError, fileType, selectedMapper } = props;
    const [flattening, setFlattening] = React.useState(selectedMapper && selectedMapper.flattening ? selectedMapper.flattening : false);
    const [primaryKey, setPrimaryKey] = React.useState(selectedMapper && selectedMapper.markedAsPrimaryKey ? selectedMapper.markedAsPrimaryKey : false);
    // const [maintainHistoryValue, setMaintainHistoryValue] = React.useState(selectedMapper && selectedMapper.maintainHistoricalRecord?selectedMapper.maintainHistoricalRecord:false);
    const [maintainHistoryValue, setMaintainHistoryValue] = React.useState(selectedMapper && selectedMapper.maintainHistoricalRecord ? selectedMapper.maintainHistoricalRecord : props.historicalEnabled);
    const primaryKeyEnabled = props.primaryKeyEnabled
    const historicalEnabled = props.historicalEnabled
    const [transformationType, setTransformationType]=React.useState(selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : '');
    const [transformationConfiguration, setTransformationConfiguration]=React.useState(selectedMapper && selectedMapper.transformationConfiguration ? selectedMapper.transformationConfiguration : {});
    
    const completeDateSet = props.completeDateSet
    const dataTimeZoneTypes = [
        "Etc/GMT",
        "America/New_York",
        "America/Denver",
        "America/Los_Angeles",
        "America/Halifax"
     ]
    const [jsonMapperPayload, setJsonMapperPayload] = useState([]);

    console.log("selectedMapper++++", selectedMapper)
    console.log('maintainHistoryValue', props.historicalEnabled);
    const handleHistoryChange = () => {
        setFlattening(!flattening)
    }

    const {
        reset: reset2
    } = useForm({});

    const handlePrimaryKeyChange = () => {
        setPrimaryKey(!primaryKey)
        setMaintainHistoryValue(true)
    }

    const handleMaintainHistoryValue = () => {
        setMaintainHistoryValue(!maintainHistoryValue)
    }
    const resettJsonForm = () => {
        setJsonMapperPayload([]);
        setFlattening(false);
        setPrimaryKey(false);
        setMaintainHistoryValue(false);
        setMetadatapropertiesContent([]);
    }

    const handleMapperSubmit = data => {
        console.log('handleMapperSubmit', data);
        
        const mapperPayload = {
            "key": data.key,
            "mappedJsonKey": data.mappedJsonKey,
            "jsonKey": data.key,
            "valuetype": data.valueType,
            "transformationType": data.transformationType,
            "isActive": true,
            "flattening": flattening,
            "markedAsPrimaryKey": primaryKey,
            "maintainHistoricalRecord": maintainHistoryValue
        };

        console.log(transformationType)
        console.log(data.timezone)
        if(data.transformationType && data.transformationType=='DATE'){
            mapperPayload['transformationConfiguration']=transformationConfiguration
        }
        console.log(mapperPayload)



        if (flattening) {
            mapperPayload.nestedJsonTypeConfig = jsonMapperPayload;
        }
        console.log(jsonMapperPayload);
        const findExistingMapperWithSamePosition = selectedMapper ?
            mapperList.find(mapper => mapper.key === mapperPayload.key && mapperPayload.key !== selectedMapper.key) :
            mapperList.find(mapper => mapper.key === mapperPayload.key)
    //   if (findExistingMapperWithSamePosition) {
       //     alert('Position already exist: Please enter different Position or Delete the existing mapper with same position number')
     //   } else {
            let mapperListValues = [];
            if (selectedMapper && mapperPayload.key === selectedMapper.key) {
                // update map
                mapperListValues = mapperList.map(data => {
                    let mapperListValue;
                    if (data.key === selectedMapper.key) {
                        mapperListValue = { ...mapperPayload }
                    } else {
                        mapperListValue = { ...data }
                    }

                    return mapperListValue;
                })
            } else {
                mapperListValues = [...mapperList, mapperPayload]
            }

            mapperListValues.sort((a, b) => {
                if (parseInt(a.postion) > parseInt(b.postion)) return 1;
                else if (parseInt(a.postion) < parseInt(b.postion)) return -1;
                else return 0;
            });
            setMapperList(mapperListValues);
            setMapperError(false);
            resettJsonForm();
            handleClose(true);
    //    }

    }


    const setMetadatapropertiesContent = (metadataproperties) => {
        console.log(metadataproperties);
        setJsonMapperPayload(metadataproperties)
    }
    const [mappedJsonKey, setMappedJsonKey] = React.useState(selectedMapper && selectedMapper.mappedJsonKey ? selectedMapper.mappedJsonKey : '');

    const mappedJsonKeyChange = e => {
        const value = e.target.value;

        setMappedJsonKey(value.split(" ").join(""));
    };

    console.log('inside the json mapper form')
    const innerJsonContent = flattening ? <InnerJsonMapper selectedInnerMapper={selectedMapper} setMetadatapropertiesContent={setMetadatapropertiesContent}></InnerJsonMapper> : "";

    return (
        <div>
            <RycomSectionHeader title={fileType != 'JSON-CSV DATA GRID' ? "Header - Json Attribute" : "Header - JSON-CSV Attribute"}></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 2 }} className={classes.rycomGridItem}>
                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                    <TextField
                        id="key"
                        label={fileType != 'JSON-CSV DATA GRID' ? "Json Key" : "Json Key to Index"}
                        name="key"
                        type={fileType != 'JSON-CSV DATA GRID' ? "text" : "number"}
                        defaultValue={selectedMapper && selectedMapper.key ? selectedMapper.key : ''}
                        variant="outlined"
                        style={{ width: 300 }}
                        disabled={Object.keys(selectedMapper).length !== 0}
                        inputRef={register2({
                            required: {
                                value: true,
                                message: 'Json Key is required'
                            }
                        })}
                    />
                    <span>
                        <span className={classes.invalidMessage}>
                            {errors2['key'] && <span>{errors2['key'].message}</span>}
                        </span>
                    </span>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                    <TextField
                        id="mappedJsonKey"
                        label="Mapped Json Key"
                        name="mappedJsonKey"
                        variant="outlined"
                        style={{ width: 300 }}
                        inputRef={register2({
                            required: {
                                value: true,
                                message: 'Column Header is required'
                            }
                        })}
                        //  value={mappedJsonKey}
                        defaultValue={mappedJsonKey && mappedJsonKey ? mappedJsonKey : ''}

                        onChange={mappedJsonKeyChange}
                    />
                    <span>
                        <span className={classes.invalidMessage}>
                            {errors2['mappedJsonKey'] && <span>{errors2['mappedJsonKey'].message}</span>}
                        </span>
                    </span>
                </Grid>

                {
                    primaryKey ? <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                        <span>
                            <Controller
                                as={
                                    <TextField
                                        id="valueType"
                                        select
                                        label="Value Type"
                                        variant="outlined"
                                        name="valueType"
                                    >
                                        <MenuItem key="1" value="STRING">STRING</MenuItem>
                                        <MenuItem key="2" value="NUMBER">NUMBER</MenuItem>
                                        <MenuItem key="3" value="BOOLEAN">BOOLEAN</MenuItem>
                                        <MenuItem key="4" value="JSON_OBJECT">JSON_OBJECT</MenuItem>
                                        <MenuItem key="5" value="ARRAY_OBJECT">ARRAY_OBJECT</MenuItem>
                                    </TextField>
                                }
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Value Type is required'
                                    }
                                }}
                                control={control2}
                                name="valueType"
                                defaultValue={selectedMapper && selectedMapper.valuetype ? selectedMapper.valuetype : ''}
                                style={{ width: 300 }}
                            />
                            <span className={classes.invalidMessage}>
                                {errors2["valueType"] && <span>{errors2["valueType"].message}</span>}
                            </span>
                        </span>
                    </Grid> : ""
                }

                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                    <span>
                        <Controller
                            as={
                                <TextField
                                    id="transformationType"
                                    select
                                    label="Transformation Type"
                                    variant="outlined"
                                    name="transformationType"
                                >
                                    <MenuItem key="0" value="">Select Transformation Type</MenuItem>
                                    <MenuItem key="1" value="CURRENCY">CURRENCY</MenuItem>
                                    <MenuItem key="2" value="DATE">DATE</MenuItem>
                                    <MenuItem key="3" value="BOOPROBABILITY_PERCENTAGELEAN">BOOLPROBABILITY PERCENTAGEEAN</MenuItem>
                                </TextField>
                            }
                            control={control2}
                            name="transformationType"
                            defaultValue={selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : ''}
                            style={{ width: 300 }}
                            onChange={(event) => {
                                console.log(event[0].target.value);
                                setTransformationType(event[0].target.value)
                                if(event[0].target.value!='DATE'){
                                    setTransformationConfiguration({})
                                }
                                return event[0].target.value
                              }}
                        />
                        <span className={classes.invalidMessage}>
                            {errors2["transformationType"] && <span>{errors2["transformationType"].message}</span>}
                        </span>
                    </span>
                </Grid>

                { transformationType =='DATE'?<Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                <span>
                        <Controller
                            as={
                                <TextField
                                    id="timeZone"
                                    select
                                    label="Time - Zone*"
                                    variant="outlined"
                                    name="timeZone"
                                >
                                 <MenuItem value="">Select Time Zone</MenuItem>
                        {dataTimeZoneTypes.map((dataTimeZoneTypes1, index) => (
                            <MenuItem key={++index} value={dataTimeZoneTypes1}>
                                {dataTimeZoneTypes1}
                            </MenuItem>   ))}
                                 
                                   </TextField>
                            }
                            control={control2}
                            name="timeZone"
                            defaultValue={transformationConfiguration && transformationConfiguration.timeZone ? transformationConfiguration.timeZone : ''}
                            onChange={(event) => {
                                console.log(event[0].target.value);
                                setTransformationConfiguration({timeZone:event[0].target.value})
                                return event[0].target.value
                              }}
                            style={{ width: 300 }}
                        />
                        <span className={classes.invalidMessage}>
                            {errors2["timeZone"] && <span>{errors2["timeZone"].message}</span>}
                        </span>
                    </span>
                </Grid>:""}
                <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
                    <FormControlLabel
                        style={{ width: 300 }}
                        control={<Checkbox
                            checked={flattening} onChange={handleHistoryChange} name="flattening" />}
                        label="Flattening"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
                    {!primaryKeyEnabled || flattening ? "" :
                        <FormControlLabel
                            style={{ width: 300 }}
                            control={<Checkbox
                                checked={primaryKey} onChange={handlePrimaryKeyChange} name="flattening" />}
                            label="Primary Key"
                        />
                    }
                </Grid>
                {primaryKey || !historicalEnabled ? "" :
                    <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
                        <FormControlLabel
                            style={{ width: 300 }}
                            control={<Checkbox
                                checked={completeDateSet} onChange={handleMaintainHistoryValue} name="flattening" />}
                            label="Maintain Historical Record"
                            disabled={completeDateSet == true ? completeDateSet : false}
                        />
                    </Grid>}

            </Grid>
            {innerJsonContent}
            <RycomSubmitModel>
                <Button type="reset" variant="" size="large"
                    onClick={() => {
                        reset2();
                    }}>Clear</Button>
                <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleMapperSubmit)}>Submit</Button>
            </RycomSubmitModel>
        </div>
    );
}
