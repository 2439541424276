import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getTokensByUserId = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/getUserTokens/${id}`, addBearerToken());
}

export const saveToken = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/admin/apiuser/token`,payload, addBearerToken());
}

export const revokeToken = (tokenId) => {
    return axios.delete(`${environment.orgBaseUrl}/api/org/revokeTokenById/${tokenId}`, addBearerToken());
}

