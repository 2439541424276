import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'
import FormHelperText from '@material-ui/core/FormHelperText';

import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as DocumentClassService from '../../../../services/DocumentClassService';
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';
import * as DocumentRelationService from '../../../../services/DocumentRelationService';


import environment from '../../../../environment'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function DocumentRelationForm() {
   
    const defaultValues = {
    };
    const history = useHistory();
    const classes = useStyles();
    const [companyId, setCompanyId] = useState(undefined);
    const [baseVertexId, setBaseVertexId] = useState(undefined);

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const [vertexList, setVertexList] = useState([]);
    const [edgeList, setEdgeList] = useState([]);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    useEffect(() => {
        getEdgeList(parentcompanyid);
        getVertexList(parentcompanyid);
    }, []);

    const getVertexList = (companyId) => {
        
        //DocumentClassService.getAllVertex(parentcompanyid)
        DocumentClassService.getAllVertex(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVertexList(res.data);
                }
            })
            .catch((error) => {
                console.error('getVertexList =>', error)
            })
            
    };

    const getEdgeList = (companyId) => {
        DocumentEdgeService.getAllEdge(parentcompanyid)
            .then((res) => {
                if (res && res.data && res.data) {
                    setEdgeList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
          
    };


    const vertexMenuList = vertexList.map((vertex, index) => <MenuItem key={++index} value={vertex.classId}> {vertex.className}</MenuItem>);
    const edgeMenuList = edgeList.map((edge, index) => <MenuItem key={++index} value={edge.linkId}> {edge.linkName}</MenuItem>);

    const onSubmit = formData => {

        let payload = {
            "documentRelationName": formData.documentRelationName,
            "documentRelationDescription":formData.documentRelationDescription,
            "isActive":true,
            //"parentcompanyid": parentcompanyid,
            "parentcompanyid": paramID,
            "documentClassId": formData.documentClassId,
            "documentLinkId":[formData.documentLinkIdValue]
        };

      DocumentRelationService.saveDocumentRelation(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Relation inserted successfully');
            history.push("/topics/documentrelation")
          })
        .catch((error) => {
          alert("Relation error")                                      
           console.error('Relation =>', error)
         }) 
   }

   return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Relation Details"
                    links={[{ title: 'Back', url: '/topics/relation' }]}></RycomPageHeader>
                <RycomSectionHeader title="Relation Overview Details"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="documentRelationName"
                            label="Document Relation Name"
                            name="documentRelationName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Relationship Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="documentRelationDescription"
                            label="Document Relationship Description"
                            name="documentRelationDescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Relationship Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="Relationship Configuration"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="documentClassId"
                            label="Document Vertex"
                            name="documentClassId"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Base vertex is required'
                                }
                            }}
                        >
                            {vertexMenuList}
                        </RycomSelectField> 
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="documentLinkIdValue"
                            label="Document Link"
                            name="documentLinkIdValue"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge is required'
                                }
                            }}
                        >
                            {edgeMenuList}
                        </RycomSelectField>
                    </RycomGridItem>  
                    </RycomGrid>       
                          
                 <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
