import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import RycomTextField from '../common/RycomTextField'
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import RycomSelectField from '../common/RycomSelectField'
import { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as DocumentClassService from '../../services/DocumentClassService';
import { Controller } from "react-hook-form";
import ConnectorLoginCredentialModal from './ConnectorLoginCredentialModal';

import HistoriaclOffsetDetails from '../common/HistoricalOffsetDetails';
import RycomInfoPair from '../common/RycomInfoPair';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MultipleKeyValueHandler from '../common/MultipleKeyValueHandler'

const useStyles = makeStyles(theme => ({
  invalidMessage: {
    display: 'inline-block',
    fontSize: '12px',
    color: 'red',
    paddingLeft: '12px',
  }

}));

export default function ConnectorEditAPIConfiguration(props) {
  

  const classes = useStyles();
  const { connectorType, connectorFlowType,register, errors, control, setMetadatapropertiesContent, setMethodParametersContent, setHistoryValue,  methodParameterValue, 
    metadatapropertiesContentValue, historyValueContent, 
    setIncludePathIdValue, includePathIdValueContent, setIncludePathId, includePathId,
    connectorLoginCredential, setConnectorLoginCredential, setHeaderContent, setBodyContent, setIncludePathIdFlagValue, setIncludePathIdFlag, headerContent,
    bodyContent
  } = props


  console.log("ConnectorEditAPIConfiguration props")

   console.log(props)
   console.log("includePathIdValueContent")
   console.log(includePathIdValueContent)
  // console.log(metadatapropertiesContentValue)

  const [metadataproperties, setMetadataproperties] = useState(metadatapropertiesContentValue);
  const [methodParameters, setMethodParameters] = useState(methodParameterValue);
  const [history, setHistory] = useState(historyValueContent);
  // const [includePathIdFlag, setIncludePathId] = useState(includePathId);
  const [documentClassList, setDocumentClassList] = useState([]);
  const userInfoJson=localStorage.getItem("userInfo"); 
  const userInfo=JSON.parse(userInfoJson);
  const parentcompanyid=userInfo['parentcompanyid'];
  const companyid=userInfo['companyid'];
  const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;


let headerIndexContent=[];
if(headerContent){
  const keys=Object.keys(headerContent)
  headerIndexContent=keys.map((key,index)=>{
    return {
         'v_id': ++index,
        'key': key,
        'value': headerContent[key]
    }
  })
 
}

console.log("headerIndexContent new:"+JSON.stringify(headerIndexContent))


  
  const [historyParameters, setHistoryParameters] = useState(headerIndexContent);
  const [methodType, setMethodType] = useState(props.requestMethod);

  const [bodyPayload, setBodyPayload] = React.useState(bodyContent);
  console.log("methodType:"+methodType)
  console.log("bodyPayload:"+bodyPayload)

  
  useEffect(() => {//
    //getDocumentClassList(parentcompanyid);
    getDocumentClassList(paramID);
 }, []);

  const [openLoginCredentialModal, setOpenLoginCredentialModal] = React.useState(false);

  const handleOpenLoginCredentialModal = () => {
    setOpenLoginCredentialModal(true);
  };

  const handleCloseLoginCredentialModal = () => {
    setOpenLoginCredentialModal(false);
  };


  
  const handleLoginCredentialModalSubmit = (data) =>{
    console.log('handleLoginCredentialModalSubmit', data);
    const usernameAttribute=data.usernameKey;
    const passwordAttribute=data.passwordKey;
    const credentialInfoObj ={};
    credentialInfoObj[usernameAttribute]=data.username
    credentialInfoObj[passwordAttribute]=data.password

    const payload={
      url:data.url,
      methodType:"post",
      credentialInfoMap:credentialInfoObj,
      extractTokenAttributeKey:data.tokenAttribute,
      keyName:data.keyName,
      responseChannel:data.responseChannel,
      tokenType:data.tokenType,
      reqType:data.reqType
    }
    console.log('handleLoginCredentialModalSubmit payload', payload);
    setConnectorLoginCredential(payload);
    handleCloseLoginCredentialModal();
  }

  const changeKeyHandler = (metadatacontent, key) => {
    metadatacontent.key = key;
    console.log(metadatacontent)
    const updateMetadataProperties = metadataproperties.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    setMetadataproperties(updateMetadataProperties);
    setMetadatapropertiesContent(updateMetadataProperties);
  }
  const changeValueHandler = (metadatacontent, value) => {
    metadatacontent.value = value;
    const updateMetadataProperties = metadataproperties.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    setMetadataproperties(updateMetadataProperties);
    setMetadatapropertiesContent(updateMetadataProperties);
  }

  const handleHistoryChange = (event) => {
    setHistoryValue(!history)
    setHistory(!history)
  };

  const handleIncludePathIdFlagChange = (event) => {
    setIncludePathIdFlagValue(!includePathId)
    setIncludePathId(!includePathId)
  };

  const getDocumentClassList = (companyId) => {
    DocumentClassService.getAllVertex(companyId)
      .then((res) => {
        if (res && res.data && res.data) {
          console.log("Relation documwnt")
          console.log(res.data)
          setDocumentClassList(res.data);
        }
      })
      .catch((error) => {
        console.error('getConnector =>', error)
      })
  };

  const methodPropertyKeyHandler = (metadatacontent, key) => {
    metadatacontent.key = key;
    console.log(metadatacontent)
    const updateMetadataProperties = methodParameters.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    setMethodParameters(updateMetadataProperties);
    setMethodParametersContent(updateMetadataProperties);
  }

  const methodPropertyValueHandler = (metadatacontent, value) => {
    metadatacontent.value = value;
    console.log(metadatacontent)
    const updateMetadataProperties = methodParameters.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    console.log(updateMetadataProperties)
    setMethodParameters(updateMetadataProperties);
    setMethodParametersContent(updateMetadataProperties);
  }


  const canAddNewMethodParameters = () => {
    const length = methodParameters.length - 1
    return methodParameters[length].v_id
  };

  const newMethodPropertyProfile = () => {
    let id = 1;
    if (methodParameters.length > 0) {
      let lastKey = canAddNewMethodParameters();
      id = ++lastKey;
    }

    console.log(id)
    const newMethodParametersProfile = [...methodParameters, {
      'v_id': id,
      'key': null,
      'value': null,
    }]
    console.log('newMethodParametersProfile in newMethodPropertyProfile')
    console.log(newMethodParametersProfile)

    setMethodParameters(newMethodParametersProfile);
    setMethodParametersContent(newMethodParametersProfile)
  }

  const deleteMethodParameterProfile = (index) => {
    const deleteProfiles = [...methodParameters]
    if (deleteProfiles.length > 1) {
      deleteProfiles.splice(index, 1);
      setMethodParameters(deleteProfiles);
      setMethodParametersContent(deleteProfiles)
    } else {
      setMethodParameters([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
      setMethodParametersContent([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
      console.log('newMethodParametersProfile in deleteMethodParameterProfile')
      console.log(methodParameters)
    }
  };

  if (!methodParameters.length) {
    console.log('newMethodParametersProfile in if methodParameters')
    console.log(methodParameters)
    deleteMethodParameterProfile();
  }

  console.log('iteration in methodParameters')

  console.log(methodParameters)
  const documentClassMenuList = documentClassList.map((relation, index) => <MenuItem key={++index} value={relation.className}> {relation.className}</MenuItem>);


  const methodParametersList = methodParameters.map((metadata, index) => {
    metadata.id = index;
    return <RycomGrid>
      <RycomGridItem>
        <div>  <TextField
          id="outlined-multiline-flexible"
          label="key"
          variant="outlined"
          inputRef={register({
            required: {
              value: true,
              message: 'Metadata key is required'
            }
          })}
          value={metadata.key}
          onChange={(event) => {
            methodPropertyKeyHandler(metadata, event.target.value)
            return event.target.value
          }} />
          <span>
            <span>
              {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
            </span>
          </span>
        </div>
      </RycomGridItem>
      <RycomGridItem>
        <span>
          <TextField
            id="outlined-multiline-flexible"
            label="Value"
            variant="outlined"
            inputRef={register({
              required: {
                value: true,
                message: 'Edge key is required'
              }
            })}
            value={metadata.value}
            onChange={(event) => {
              console.log(event.target.value)
              methodPropertyValueHandler(metadata, event.target.value)
              return event.target.value
            }}
          />
          <span>
            {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
          </span>
        </span>
      </RycomGridItem>
      <RycomGridItem>
        <Button color="secondary" variant="contained" size="small" disabled={methodParameters.length == 1} value="delete Edge"
          onClick={(e) => {
            const deleteProfiles = [...methodParameters]

            if (methodParameters.length > 1) {
              const deleteProfiles1 = deleteProfiles.filter(edge => {
                return edge.v_id != metadata.v_id
              });
              setMethodParameters(deleteProfiles1);
            } else {
              setMethodParameters([{
                'v_id': 1,
                'key': null,
                'value': null
              }])
            }
          }}>Delete Method Parameter</Button>
      </RycomGridItem>
    </RycomGrid>;
  });


  const canAddNewMetadataProperties = () => {
    const length = metadataproperties.length - 1
    return metadataproperties[length].v_id
  };

  const newMetadataProfile = () => {
    let id = 1;
    if (metadataproperties.length > 0) {
      let lastKey = canAddNewMetadataProperties();
      id = ++lastKey;
    }

    console.log(id)
    const newMetadataProfile = [...metadataproperties, {
      'v_id': id,
      'key': null,
      'value': null,
    }]
    setMetadataproperties(newMetadataProfile);
    setMetadatapropertiesContent(newMetadataProfile)

  }

  const deleteMetadataProfile = (index) => {
    const deleteProfiles = [...metadataproperties]
    if (deleteProfiles.length > 1) {
      deleteProfiles.splice(index, 1);
      setMetadataproperties(deleteProfiles);
      setMetadatapropertiesContent(deleteProfiles)
    } else {
      setMetadataproperties([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
      setMetadatapropertiesContent([{
        'v_id': 1,
        'key': null,
        'value': null,
      }])
    }
  };
  if (!metadataproperties.length) {
    deleteMetadataProfile()
  }

  const metadataList = metadataproperties.map((metadata, index) => {
    metadata.id = index;
    const nameType = "value" + index;
    return <RycomGrid>
      <RycomGridItem>
        <div>  <TextField
          id="outlined-multiline-flexible"
          label="key"
          variant="outlined"
          inputRef={register({
            required: {
              value: true,
              message: 'Path variable is required'
            }
          })}
          value={metadata.key}
          onChange={(event) => {
            changeKeyHandler(metadata, event.target.value)
            return event.target.value
          }}
        />
          <span>
            <span>
              {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
            </span>
          </span>
        </div>
      </RycomGridItem>
      <RycomGridItem>
        <span>
          <Controller
              as={
                  <TextField
                      // id="pathClassType-value"
                      select
                      label="Value"
                      variant="outlined"
                      name="value"
                  >
                    {documentClassMenuList}
                  </TextField>
              }
              control={control}
              name={nameType}
              defaultValue={metadata.value}
              onChange={(event) => {
                changeValueHandler(metadata, event[0].target.value)
                return event[0].target.value
              }}
          />
          <span className={classes.invalidMessage}>
              {errors["value"] && <span>{errors["value"].message}</span>}
          </span>
      </span>
      </RycomGridItem>
      <RycomGridItem>
        <Button color="secondary" variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
          onClick={(e) => {
            const deleteProfiles = [...metadataproperties]

            if (metadataproperties.length > 1) {
              const deleteProfiles1 = deleteProfiles.filter(edge => {
                return edge.v_id != metadata.v_id
              });
              setMetadataproperties(deleteProfiles1);
              setMetadatapropertiesContent(deleteProfiles1);

            } else {
              setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'value': null
              }])
              setMetadatapropertiesContent([{
                'v_id': 1,
                'key': null,
                'value': null
              }]);
            }
          }}>Delete Path Variable</Button>
      </RycomGridItem>
    </RycomGrid>;
  });












  return (
    <div>
      <RycomSectionHeader title="URL Connection Information"></RycomSectionHeader>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id="baseURI"
            label="Base URI"
            name="baseURI"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Base URI is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="pathURI"
            label="Path URI"
            name="pathURI"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Path URI is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
          <RycomSelectField id="Request Method"
            label="Request Method"
            name="requestMethod"
            onChangeHandler={(event) => {
              setMethodType(event[0].target.value)
              return event[0].target.value
            }}
            register={register}
            control={control}
            errors={errors}
            errorDescription={{
            }} >
            <MenuItem key="1" value="get">GET</MenuItem>
            <MenuItem key="2" value="post">POST</MenuItem>
            <MenuItem key="3" value="patch">PATCH</MenuItem>
            <MenuItem key="4" value="patch">DELETE</MenuItem>
          </RycomSelectField>
        </RycomGridItem>
        <RycomGridItem>
          <RycomSelectField id="contentType"
            label="Content Type"
            name="contentType"
            onChangeHandler={(event) => {
              return event[0].target.value
            }}
            register={register}
            control={control}
            errors={errors}
            errorDescription={{
            }} >
            <MenuItem key="1" value="application/json">application/json</MenuItem>
            <MenuItem key="2" value="application/xml">application/xml</MenuItem>
          </RycomSelectField>
        </RycomGridItem>
      </RycomGrid>
     

     {methodType=='post' && connectorFlowType=='inbound'?<div>
      <RycomSectionHeader title="Body Content: Input Payload"></RycomSectionHeader>
      <RycomGrid>
        <RycomGridItem>
      <TextareaAutosize
                                                aria-label="maximum height"
                                                placeholder="Paste the Body Payload here"
                                                style={{ width: 450, height:300 }}
                                                minRows={3}
                                                maxRows={40}
                                                value={bodyPayload}
                                                onChange={(event)=>{
                                                  setBodyContent(event.target.value);
                                                    setBodyPayload(event.target.value);
                                                }}
                                                />
            </RycomGridItem>
</RycomGrid></div>:""}

     
     
     
      <RycomSectionHeader title="Authentication Details"></RycomSectionHeader>
    { connectorLoginCredential.url?<div>
      <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="URL" value={ connectorLoginCredential.url }></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Response Channel" value={connectorLoginCredential.responseChannel}></RycomInfoPair>
                </Grid>
            </Grid><RycomGrid>
      <Grid item xs={12} sm={4} lg={4} xl={4} >
        <span>
          <Button color="primary" variant="contained" size="medium" value="Edit Login Details" onClick={handleOpenLoginCredentialModal}>Edit Login Details</Button>
        </span>
      </Grid>
    </RycomGrid></div>:<Grid item xs={12} sm={4} lg={4} xl={4} >
            <span>
              <Button color="primary" variant="contained" size="medium" value="Add Login Details" onClick={handleOpenLoginCredentialModal}>Add Login Details</Button>
            </span>
          </Grid>}

      {connectorFlowType!=null && connectorFlowType=='inbound'?<span>
      <RycomSectionHeader title="Path Variable Details"></RycomSectionHeader>
      {metadataList}
      <Grid container spacing={12} className={classes.rycomButtonGroup} >
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
          <span>
            <Button color="primary" variant="contained" size="medium" value="Add PathVariable" onClick={newMetadataProfile}>Add PathVariable</Button>
          </span>
        </Grid>
      </Grid>
      {metadataproperties.length &&
        typeof metadataproperties[0].key !== 'undefined' &&
        metadataproperties[0].key
        ? (<RycomGrid>
          <RycomGridItem>
            <FormControlLabel
              control={<Checkbox
                checked={includePathId} onChange={handleIncludePathIdFlagChange} name="includePathIdFlag" />}
              label="Include Path ID to the Payload"
            />
          </RycomGridItem>
        </RycomGrid>) : ""}
        </span>: ""}
        
      <RycomSectionHeader title="URI Parameter Details"></RycomSectionHeader>
      {methodParametersList}
      <Grid container spacing={12} className={classes.rycomButtonGroup} >
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
          <span>
            <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMethodPropertyProfile}>Add Method Parameter</Button>
          </span>
        </Grid> 
      </Grid>

     <MultipleKeyValueHandler classes={classes} 
     register={register} errors={errors} 
     setParameters={setHistoryParameters} 
     setParametersContent={setHeaderContent}
     parameters={historyParameters} 
     propertyName="Header"></MultipleKeyValueHandler>

      <HistoriaclOffsetDetails register={register} control={control} 
      errors={errors} connectorType={connectorType} 
      dateEndValue={props.dateEndValue}   
      dateStartValue={props.dateStartValue}
      dateFormat={props.dateFormat} 
      timeZone={props.timeZone} 
      maximumOffset={props.maximumOffset}
      maximumOffsetUnitType={props.maximumOffsetUnitType}
      offsetDuration={props.offsetDuration}
      offsetDurationUnitType={props.offsetDurationUnitType}
      fetchOffsetType={props.fetchOffsetType}
      historyDurationType={props.historyDurationType}
      frequency={props.frequency}
      fetchType={props.fetchType}
      fetchStartDateString={props.fetchStartDateString}
      fetchEndDateString={props.fetchEndDateString}
      endParamter={props.endParamter}
      startParamter={props.startParamter}
      />

      <ConnectorLoginCredentialModal 
        connectorLoginCredential={connectorLoginCredential}
        handleOpen={openLoginCredentialModal}
        handleClose={handleCloseLoginCredentialModal}
        handleLoginCredentialModalSubmit={handleLoginCredentialModalSubmit}
      ></ConnectorLoginCredentialModal>

    </div>
  );

  
}