import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getCompanyByParentId = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/parentcompany/${id}`, addBearerToken());
}

export const getCompanyById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/company/${id}`, addBearerToken());
}

export const getCompanyParentById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/companylist/${id}`, addBearerToken());
}


export const saveCompany = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/company`,
               payload, addBearerToken()
                );
}