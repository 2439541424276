import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomInfoPair from '../../../common/RycomInfoPair'
import RycomTable from '../../../common/RycomTable'

import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';
import * as SchedulerService from '../../../../services/SchedulerService';
import * as EdgeTaskService from '../../../../services/EdgeTaskService';


import { useParams } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));


export default function DocumentEdgeDetail() {
    const classes = useStyles();
    const history = useHistory();
    let { id } = useParams();
    const [edgeValue,setEdgeValue]=useState({edgeproperties:[]})
    const [keycloak] = useKeycloak();
    const [taskDetails, setTaskDetails] = React.useState([])


    useEffect(() => {
        DocumentEdgeService.getEdgeById(id)
        .then((res) => {
            if (res && res.data && res.data) {
                setEdgeValue(res.data);
                console.log(res.data)

                EdgeTaskService.getTaskEdgeByEdge(id).then((res) => {
                                            if (res && res.data && res.data) {
                                                setTaskDetails(res.data);
                                              console.log(res.data);
                                            }
                                          })
            }
        })
        .catch((error) => {
            console.error('getEdgeByid =>', error)
        })
    },[]);

    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                DocumentEdgeService.deleteEdge(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/documentedge");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Edge')
                        console.error('delete vertex =>', error)
                    })
            }}>Delete</Button>
        return DeleteButton;
    }  
   

    const createTaskDeleteButton = (edgeTaskId, edgeTaskName) => {
                const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
                    onClick={(e) => {
                        if (window.confirm('Are you sure you wish to delete this ' +edgeTaskName+ ' Task Item?')) 
                        {
                            EdgeTaskService.deleteTaskEdge(edgeTaskId).then((res) => {
                                if (res && res.data && res.data) {
                                    console.log(res.data);
                                    alert(res.data.message)
                                    history.push("/topics/documentedge");
                                }
                            })
                            .catch((error) => {
                                alert('some error in deleting the Tasl')
                                console.error('Task Delete =>', error)
                            })
                        } else {
                            console.log('Not Deleted');
                        } 
                    }}>Delete</Button>
                return DeleteButton;
            }
        
            const createStopJobButton = (taskValues) => {
                const startButton = <Button color="Red" variant="contained" size="small" value="Trigger Task"
                    onClick={(e) => {
                        const payload={
                            scheduledTaskId:taskValues.taskid,
                            companyId:taskValues.companyid,
                            groupId:taskValues.connectionid
                        }
                        SchedulerService.stopScheduler(payload).then((res) => {
                            if (res && res.data && res.data) {
                                console.log(res.data);
                                alert("Job Succesfully Stopped")
                                history.push("/topics/documentedge");
                            }
                        })
                            .catch((error) => {
                                alert('some error in starting the Job')
                                console.error('startTask =>', error)
                            })
                    }}>Stop the Running Jobs</Button>
                return startButton;
            }
        
            const createAllTaskDeleteButton = (edgeTaskId, edgeTaskName) => {
                const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
                    onClick={(e) => {
                        if (window.confirm('Are you sure to delete all the tasks related to '+ edgeTaskName+ ' Connector Item?')) 
                        {
                            EdgeTaskService.deleteTaskEdge(edgeTaskId).then((res) => {
                                if (res && res.data && res.data) {
                                    console.log(res.data);
                                    alert(res.data.message)
                                    history.push("/topics/documentedge");
                                }
                            })
                            .catch((error) => {
                                alert('some error in deleting the Tasl')
                                console.error('Task Delete =>', error)
                            })
                        } else {
                            console.log('Not Deleted');
                        } 
                    }}>Delete All Running Tasks Related to this Connector</Button>
                return DeleteButton;
            }



const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/documentedge"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {taskDetails.length==0 &&  keycloak.hasRealmRole("update:graph")?<Link className={classes.buttonStyle} to={'/topics/documentedge/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 
    { taskDetails.length==0 &&  keycloak.hasRealmRole("delete:graph")?<span className={classes.buttonStyle} to="/topics/edge">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;

const columns = [
    { id: 'taskname', label: 'Edge Task Name', minWidth: 120 },
    { id: 'taskType', label: 'Task Type', minWidth: 120 },
    { id: 'deleteTask', label: '', minWidth: 120 },

  ];

   const rows=[...taskDetails].map((edgeTask)=>{
        edgeTask.taskname=<Link  to={`/topics/edgetask/detail/${edgeTask.taskid}`}>{edgeTask.taskname}</Link>
        edgeTask.taskType="Complete";
        if(edgeTask.isDaily){
            edgeTask.taskType="Daily";
        }else if(edgeTask.isComple){
            edgeTask.taskType="ByDate";
        }    
        edgeTask.deleteTask= createTaskDeleteButton(edgeTask.taskid, edgeTask.taskname);
        return edgeTask;
    });

return (<div>
    
    <Card className={classes.aocard}>
<div className={classes.header}>
<span className={classes.title} >
{edgeValue ? `Edge Details about ${edgeValue.edgeName}` : 'Edge Details'} </span>
<span className={classes.toolbar}>
{buttonLinks}
</span>
</div>
</Card>


        {taskDetails.length>0 ? 
       <span>
        <Card className={classes.aocard}>
    <div className={classes.header}>
    <span className={classes.title} >
    Task Details
    </span>
    <span className={classes.toolbar}>
    {createAllTaskDeleteButton(id, edgeValue.edgeName)}
    </span>
    </div>
    </Card>
        <RycomTable rows={rows} columns={columns}></RycomTable></span>:
        <div></div>}

        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Edge Name" value={edgeValue ? edgeValue.edgeName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="From Vertex" value={edgeValue ? edgeValue.fromClassName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="From Property Name" value={edgeValue ? edgeValue.fromPropertyName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="To Vertex" value={edgeValue ? edgeValue.toClassName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="To Property Name" value={edgeValue ? edgeValue.toPropertyName : ''}></RycomInfoPair>
            </Grid>
        </Grid>
         </div>
    );
}
