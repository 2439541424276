import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getUserByCompanyId = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/users/${id}`, addBearerToken());
}

export const getUserById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/user/${id}`, addBearerToken());
}

export const getUserByKeycloakId = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/user/keycloak/${id}`, addBearerToken());
}


export const saveUser = (payload) => {
    console.log(payload)
    return axios.post(`${environment.orgBaseUrl}/api/org/user`,payload, addBearerToken());
}

export const updateUser = (payload) => {
    return axios.patch(`${environment.orgBaseUrl}/api/org/user`,payload, addBearerToken());
}

export const updatePassword = (payload) => {
    return axios.patch(`${environment.orgBaseUrl}/api/org/useradminpassword`,payload, addBearerToken());
}

export const updateByAdminUser = (payload) => {
    return axios.patch(`${environment.orgBaseUrl}/api/org/useradmin`,payload, addBearerToken());
}


export const saveRoleTemplate = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/roles/template`,
               payload, addBearerToken()
                );
}

export const getRoleByCompanyId = (companyId) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/roles/template/${companyId}`, addBearerToken());
}

export const checkUserName = (userName) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/checkkuser/${userName}`, addBearerToken());
}

export const checkEmail = (email) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/checkemail/${email}`, addBearerToken());
}

export const deleteUser = (id) => {
    return axios.patch(`${environment.orgBaseUrl}/api/org/user/delete`,
    {
        "userid": id
    }, addBearerToken()
    );
}

export const createActivateButton = (id) => {
    return axios.patch(`${environment.orgBaseUrl}/api/org/user/activate`,
    {
        "userid": id
    }, addBearerToken()
    );
}

export const getPermissions = () => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/company/sites`, addBearerToken());
}

export const getPermissionsByCompany = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/company/${id}/sites`, addBearerToken());
}