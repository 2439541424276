import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomInfoPair from '../../common/RycomInfoPair'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';


import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import { useForm } from 'react-hook-form'


import RycomAddressGrid from '../../common/RycomAddressGrid'
import environment from '../../../environment'
import * as UserService from '../../../services/UserService';
import * as OrganisationService from '../../../services/OrganisationService';

import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


/*
{
    "parentcompanyid": "0",
    "companyid": "3ba514e0-43c8-11ea-b77f-2e728ce88125",
    "login":{
     "username":"jlushington",
     "password": "fireflies"
    },
    "contact":
    [
     {
      "contactkey": "primary email",
      "contactvalue": "jay.lushington@gmail.com",
      "contacttype": "EMAIL"
     },
      {
      "contactkey": "primary Cell",
      "contactvalue": "1-416-321-3357",
      "contacttype": "PHONE"
     }
    ],
     "metadata": [
        {
            "key": "",
            "value": ""
        }
    ] 
}*/

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        margin: theme.spacing(5),
    },
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    }
}));

export default function UserForm() {

    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [userExist, setUserExist] = React.useState(true);
    const [emailExist, setEmailExist] = React.useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [profileMap, setProfileMap] = React.useState([])


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (companyList.length == 0) {
            //OrganisationService.getCompanyByParentId(parentcompanyid)
            //OrganisationService.getCompanyById(companyid)
            OrganisationService.getCompanyParentById(companyid)
                .then((res) => {
                    setCompanyList(res.data);
                    //setCompanyList([res.data]);
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }, []);



    const defaultValues = {
        "metadata": metadataproperties
    };

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const { register: register3, errors: errors3, handleSubmit: handleSubmit3, control: control3 } = useForm({});
    const [contactList, setContactList] = useState([]);

    const userInfoJson = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoJson);
    const parentcompanyid = userInfo['parentcompanyid'];
    const companyid = userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
    };
    const handleRedirectModal = ()=> {
        let path = '/topics/documentedge/form';
        history.push(path);
    }


    const {
        register: register2,
        errors: errors2,
        control: control2,
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm({
    });

    const resetUserGroupValue = {
        'api': false,
        'rapid': false,
        'promys': false,
        'trm':false

    };

    const [userGroupValue, setUserGroupValue] = useState(resetUserGroupValue);

    const [companyPermissions, setCompanyPermissions] = useState({});

    const [companyPermissionsDetails, setCompanyPermissionsDetails] = useState({});

    const selectedCompanyHandle = (event) => {
        console.log("123 accessUserGroup")

        const accessUserGroup = formatSelectedUserGroup(userGroupValue);
        console.log("accessUserGroup")

        console.log(accessUserGroup)
        if (accessUserGroup.length === 0) {
            alert("Please Select User Group");
            return false;
        } else{
            getPermissions(event[0].target.value, true);
        }
    }

    const handleUserGroupChange = (event) => {
        console.log('handleUserGroupChange');
        setUserGroupValue({ ...userGroupValue, [event.target.name]: event.target.checked })
        
        if(event.target.name == 'api' && event.target.checked)
            getPermissions(!event.target.checked, false);
    };

    const getPermissions = (val, type) => {
        
        const selCompanyId = type ? val : selectedCompanyId;
        const selectedCompanyParentId = selCompanyId ? companyList.filter(v => v.companyid === selCompanyId).map(v => v.parentcompanyid) : [];
        if (userGroupValue['api'] == type && selectedCompanyParentId.length) {
            if(selectedCompanyParentId[0] != 0){
                UserService.getPermissionsByCompany(selCompanyId)
                    .then((res) => {
                        //console.log('api permissions by company', res)
                        if (res && res.data && res.data) {

                            if (Object.keys(res.data).length === 0 && res.data.constructor === Object) {
                                //alert('No Permission found');
                                handleOpenAddModal();
                            } else{
                                let d = [];
                                Object.values(res.data).forEach(v => d[v] = false)

                                setCompanyPermissions(d);
                                setCompanyPermissionsDetails(res.data);
                            }
                            
                        }
                    })
                    .catch((error) => {
                        console.error('getConnector =>', error)
                    })
            } else if(selectedCompanyParentId == 0){
                UserService.getPermissions()
                    .then((res) => {
                        //console.log('api permissions', res)
                        if (res && res.data && res.data) {
                            
                            if (Object.keys(res.data).length === 0 && res.data.constructor === Object) {
                                //alert('No Permission found');
                                handleOpenAddModal();
                            } else{
                                let d = [];
                                Object.values(res.data).forEach(v => d[v] = false)

                                setCompanyPermissions(d);
                                setCompanyPermissionsDetails(res.data);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('getConnector =>', error)
                    })
            }
        }
    }

    const handlePermissionChange = (event) => {
        setCompanyPermissions({ ...companyPermissions, [event.target.name]: event.target.checked })
    };

    const permissions = Object.keys(companyPermissions).map((permission, index) => <FormControlLabel
        control={<Checkbox checked={companyPermissions[permission]} onChange={handlePermissionChange} name={permission} />} 
        label={permission}
    />);


    const canAddNewMetadataProperties = () => {
        const length = metadataproperties.length - 1
        return metadataproperties[length].v_id
    };

    const newMetadataProfile = () => {
        let id = 1;
        if (metadataproperties.length > 0) {
            let lastKey = canAddNewMetadataProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newMetadataProfile = [...metadataproperties, {
            'v_id': id,
            'key': null,
            'value': null,
        }]
        setMetadataproperties(newMetadataProfile);
    }

    const deleteMetadataProfile = (index) => {
        const deleteProfiles = [...metadataproperties]
        if (deleteProfiles.length > 1) {
            deleteProfiles.splice(index, 1);
            setMetadataproperties(deleteProfiles);
        } else {
            setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
            }])
        }
    };

    if (!metadataproperties.length) {
        deleteMetadataProfile()
    }

    const changeKeyHandler = (edgeProperty) => (event) => edgeProperty.key = event.target.value;
    const changeValueHandler = (edgeProperty) => (event) => edgeProperty.value = event.target.value;
    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);


    const getTemplateByCompanyId = (companyId) => {
        /* setProfileMap([{
             "companyId":companyId,
             "roleTemplateName":"admin",
             "accessRoles":["create:org","update:org"],
             "isActive":true 
         }]);*/


        UserService.getRoleByCompanyId(companyId).then((res) => {
            console.log(res)
            setProfileMap(res.data)
            if (res.status === 200)
                alert('Template Retrieved successfully');
        })
            .catch((error) => {
                alert("Teplate Retrieve error")
                console.error('Role =>', error)
            })
    }

    let profiles = profileMap || [];
    const profileMenuList = profiles.map((profile, index) => <MenuItem key={++index} value={profile.roleTemplateName}> {profile.roleTemplateName}</MenuItem>);
    // console.log(profileMap);
    // console.log(profileMenuList);

    const [selectedProfile, setSelectedProfile] = React.useState(null);



    const resetRoles = {
        'view:connector': false,
        'create:connector': false,
        'update:connector': false,
        'delete:connector': false,
        'access-site': false,
        'access-device': false,
        'access-point': false,
        'access-point_history': false,
        'access-weather': false,
        'access-financial_data': false,
        'view:org': false,
        'create:org': false,
        'update:org': false,
        'delete:org': false,
        'view:graph': false,
        'create:graph': false,
        'update:graph': false,
        'delete:graph': false,
        'view:task': false,
        'create:task': false,
        'update:task': false,
        'delete:task': false,
        'run:task': false,
        'schedule:task': false,
        'view:scheduler': false,
        'create:scheduler': false,
        'update:scheduler': false,
        'delete:scheduler': false,
    };


    const [filteredState, setFilteredState] = React.useState(resetRoles);

    const onProfileChange = (selectedProfile) => {
        // console.log("onProfileChange");
        // console.log(selectedProfile);
        // console.log(profileMap);
        if (profileMap && profileMap.length > 0 && selectedProfile) {
            const selectedProfiles = profileMap.filter(profile => profile.roleTemplateName === selectedProfile)
            const selectedProfileObject = selectedProfiles[0];
            const profileList = selectedProfileObject.accessRoles;
            console.log(profileList);
            let filteredStateValue = resetRoles
            if (profileList) {
                profileList.forEach(profile => {
                    filteredStateValue[profile] = true
                })
                setFilteredState(filteredStateValue)
                console.log(filteredStateValue);
            }
        }
    }

    const handleChange = (event) => {
        setFilteredState({ ...filteredState, [event.target.name]: event.target.checked })
    };

    const error = [].filter((v) => v).length !== 2;

    const metadataList = metadataproperties.map((metadata, index) => {
        metadata.id = index;
        return <RycomGrid>
            <RycomGridItem>
                <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="key"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Metadata key is required'
                        }
                    })}
                    value={metadata.key}
                    onChange={changeKeyHandler(metadata)}
                />
                    <span>
                        <span>
                            {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                        </span>
                    </span>
                </div>
            </RycomGridItem>
            <RycomGridItem>
                <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Value"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Value is required'
                        }
                    })}
                    value={metadata.value}
                    onChange={changeValueHandler(metadata)}
                />
                    <span>
                        {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                    </span>
                </span>
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
                    onClick={(e) => {
                        const deleteProfiles = [...metadataproperties]

                        if (metadataproperties.length > 1) {
                            const deleteProfiles1 = deleteProfiles.filter(edge => {
                                return edge.v_id != metadata.v_id
                            });
                            setMetadataproperties(deleteProfiles1);
                        } else {
                            setMetadataproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null
                            }])
                        }
                    }}>Delete Metadata</Button>
            </RycomGridItem>
        </RycomGrid>;
    });

    const contactDisplayList = contactList.map(contact => <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Contact Type" value={contact ? contact.contactType : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Contact Value" value={contact ? contact.contactValue : ''}></RycomInfoPair>
        </Grid>
        <Grid>
            <Button color="secondary" variant="contained" size="small" value="delete Contact"
                onClick={(e) => {
                    const deleteProfiles = [...contactList]
                    if (contactList.length > 1) {
                        const deleteProfiles1 = deleteProfiles.filter(contactListValue => {
                            return contactListValue.key !== contact.key
                        });
                        setContactList(deleteProfiles1);
                    } else {
                        setContactList([])
                    }
                }}>Delete Contact</Button>
        </Grid>
    </Grid>);


    const handleMapperSubmit = data => {
        // console.log('handleMapperSubmit');
        // console.log(data);
        const contactPayload = {
            "key": data.key,
            "contactType": data.contactType,
            "contactValue": data.contactValue,

        };
        // console.log(data.metadataKey)
        setContactList([...contactList, contactPayload]);
        handleClose(true);
    }

    const formatSelectedRoles = (selectedRoles) => {
        const accessRole = Object.keys(selectedRoles);
        const accessRoles = accessRole.filter(role => filteredState[role])
        return accessRoles;
    }

    const formatSelectedUserGroup = (selectedUserGroup) => {
        const accessUserGroup = Object.keys(selectedUserGroup);
        const accessUserGroups = accessUserGroup.filter(userGroup => userGroupValue[userGroup])
        return accessUserGroups;
    }

    const formatSelectedPermissions = (selectedPermissions) => {
        const accessPermission = Object.keys(selectedPermissions);
        const accessPermissions = {};
        accessPermission.filter(permission => selectedPermissions[permission]).forEach(value => {
            //console.log(accessPermission, companyPermissionsDetails, value);
            let kk = Object.keys(companyPermissionsDetails).find(key => companyPermissionsDetails[key] === value);
            
            accessPermissions[kk] = value;
        })

        //console.log('accessPermissions', accessPermissions)
        return accessPermissions;
    }

    const onProfileSubmit = (data) => {
        // console.log(filteredState);
        // console.log(data);

        const accessRoles = formatSelectedRoles(filteredState);
        console.log("submit data")

        console.log(data)
        if (accessRoles.length === 0) {
            alert("Please Select Role");
            return false;
        }

        if (!selectedCompanyId) {
            alert("Please Select Company");
            return false;
        }

        const payload = {
            "companyId": selectedCompanyId,
            "roleTemplateName": data.newProfile,
            "accessRoles": accessRoles,
            "isActive": true
        };




        console.log(JSON.stringify(payload))
        UserService.saveRoleTemplate(payload).then((res) => {
            console.log(res)
            if (res.status === 200)
                alert('Template inserted successfully');
        })
            .catch((error) => {
                alert("Error: Please check the Role Template Name and Permissions")
                console.error('Role =>', error)
            })
    }


    const onSubmit = data => {


        const accessRoles = formatSelectedRoles(filteredState);
        console.log("userGroupValue")
       console.log(userGroupValue)
               
        const accessUserGroup = formatSelectedUserGroup(userGroupValue);
       
       
       
        if (accessUserGroup.length === 0) {
            alert("Please Select User Group");
            return false;
        }
        const accessPermissions = formatSelectedPermissions(companyPermissions);
        // Roles are predefined and permission are not mandatory for Promys user
        if(userGroupValue['promys']){
            if (accessRoles.length === 0) {
                accessRoles[0]="access-promys"
            }else{    
               accessRoles[accessRoles.length]="access-promys"
            }
         }
        else{
        if (Object.keys(accessPermissions).length === 0 && accessPermissions.constructor === Object && userGroupValue['api']) {
            alert("Please Select Permission");
            return false;
        }
        }

        if(userGroupValue['trm']){
            if (accessRoles.length === 0) {
                accessRoles[0]="access-trm"
            }else{    
               accessRoles[accessRoles.length]="access-trm"
            }
         }
        else{
            if (Object.keys(accessPermissions).length === 0 && accessPermissions.constructor === Object && userGroupValue['api']) {
                alert("Please Select Permission");
                return false;
            }
        }
        
        if (accessRoles.length === 0) {
            alert("Please Select Role");
            return false;
        }
  
        // console.log("++++", companyPermissions, accessPermissions)
        // return;

        // check username and checkemail
        UserService.checkUserName(data.username).then((res) => {
            if (res.status === 200 & res.data.message !== "User Exist") {
                UserService.checkEmail(data.email).then((res) => {
                    console.log(res)
                    if (res.status === 200 & res.data.message !== 'Email Exist') {
                        UserService.saveUser(payload).then((res) => {
                            console.log(res)
                            if (res.status === 200)
                                alert('User inserted successfully');
                            history.push("/topics/user")
                        }).catch((error) => {
                            alert("User Insert error")
                            console.error('User =>', error)
                        })
                    } else
                        alert("Duplicate Email Error: Please enter unique Email")
                }).catch((error) => {
                    alert("Check Email error")
                    console.error('User =>', error)
                })
            } else
                alert("User Name error: Please enter unique username")
        }).catch((error) => {
            alert(" check User Name error")
            console.error('User =>', error)
        })

        /*
                 const checkEmailRequest=UserService.checkEmail(data.email).then((res) => {
                    console.log(res)
                    if(res.status === 200 &  res.data.message !== 'Email Exist')
                        setEmailExist(false);    
                    else
                        alert("Duplicate Email Error: Please enter unique Email")                                      
                }).catch((error) => {
                    alert("Duplicate Email Error: Please enter unique Email")                                      
                     console.error('User =>', error)
                   })
        */

        const payload = {
            //"parentcompanyid": parentcompanyid,
            "parentcompanyid": paramID,
            "companyid": data.companyid,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "profile": selectedProfile,
            "email": data.email,
            "isActive": true,
            "login": {
                "username": data.username,
                "password": data.password,
            },
            "contact": contactList,
            "accessRoles": accessRoles,
            "permissionMap": accessPermissions,
            "isApiUser": userGroupValue['api'],
            "isRycomUser": userGroupValue['rapid'],
            "isPromysUser": userGroupValue['promys'],
            "isTrmUser": userGroupValue['trm']

        };
    console.log('Profile Name',selectedProfile,payload);
        const filterCompanyList = companyList.filter(company => company.companyid == data.companyid)
        if (filterCompanyList && filterCompanyList.length === 1)
            payload["companyName"] = filterCompanyList[0].companyname;

        /*      setTimeout(function(){
                  console.log(
                      JSON.stringify(payload))
                      Promise.all([
                          checkUserRequest,
                          checkEmailRequest
                        ]).then((response1, response2)=>{
                            if(!userExist && !emailExist)
                          UserService.saveUser(payload).then((res) => {
                              console.log(res)
                              if(res.status === 200)  
                              alert('User inserted successfully');
                              history.push("/topics/user")
                          })
                          .catch((error) => {
                          alert("User Insert error")                                      
                          console.error('User =>', error)
                          })
                  })
              },500)
          */
    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the User Details"
                    links={[{ title: 'Back', url: '/topics/company' }]}></RycomPageHeader>
                <RycomSectionHeader title="User Group Details"></RycomSectionHeader>
                <RycomSectionHeader title="User Form"></RycomSectionHeader>
                <RycomGrid>
                <Grid item xs={12} sm={12} lg={6} xl={4} className={classes.rycomGridItem}>
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                        checked={userGroupValue['api']} 
                                        onChange={handleUserGroupChange} 
                                        name="api" />
                                    }
                            label="Bueno User"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                        checked={userGroupValue['rapid']} 
                                        onChange={handleUserGroupChange} 
                                        name="rapid" />
                                    }
                            label="RAPID User"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                        checked={userGroupValue['promys']} 
                                        onChange={handleUserGroupChange} 
                                        name="promys" />
                                    }
                            label="PROMYS User"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                        checked={userGroupValue['trm']} 
                                        onChange={handleUserGroupChange} 
                                        name="trm" />
                                    }
                            label="TRM User"
                            labelPlacement="start"
                        />
                                        </Grid>
                        

                </RycomGrid>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                setSelectedCompanyId(event[0].target.value);
                                getTemplateByCompanyId(event[0].target.value)
                                selectedCompanyHandle(event)
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="username"
                            label="User Name"
                            name="username"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'User Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        {/* <RycomTextField id="password"
                            label="Password"
                            name="password"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Password is required'
                                }
                            }}
                        /> */}
                        <TextField
                            id="Password"
                            label="Password*"
                            variant="outlined"
                            // inputRef={register({
                            // required: {
                            //     value: true,
                            //     message: "Password is required",
                            // },
                            // })}
                            inputRef={register({ required: {
                                value: true,
                                message: 'Password is required'
                            },pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,8}$/,
                                message: 'Required Min 6 and max 8 char and one special character and one capital letter'
                            }
                        
                        })}
                            name="password"
                            type="password"
                        />
                        <span className={classes.invalidMessage}>
                            
                            {errors["password"] && (
                            <span> {errors["password"].message} </span>
                            )}
                        </span>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="firstName"
                            label="First Name"
                            name="firstName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'First Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="lastName"
                            label="Last Name"
                            name="lastName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Last Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        
                        <TextField
                            id="email"
                            label="Email*"
                            variant="outlined"
                            inputRef={register({ required: {
                                value: true,
                                message: 'Email is required'
                            },pattern: {
                                value: /^\S+@\S+\.\S+$/
                                ,
                                message: 'Email is not valid'
                            }
                        
                        })}
                            name="email"
                            type="email"
                        />
                        <span className={classes.invalidMessage}>
                            
                            {errors["email"] && (
                            <span> {errors["email"].message} </span>
                            )}
                        </span>
                    </RycomGridItem>

                </RycomGrid>
                {
                    (userGroupValue['api'] == true || userGroupValue['rapid'] == true)? 
                        <RycomSectionHeader title="Roles"></RycomSectionHeader> : ""
                }

                {
                    userGroupValue['api'] == true? 
                    (<span>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">API Access</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-site']} onChange={handleChange} name="access-site" />}
                                    label="Site"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-device']} onChange={handleChange} name="access-device" />}
                                    label="Device"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-point']} onChange={handleChange} name="access-point" />}
                                    label="Point"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-point_history']} onChange={handleChange} name="access-point_history" />}
                                    label="Point History"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-weather']} onChange={handleChange} name="access-weather" />}
                                    label="Weather"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['access-financial_data']} onChange={handleChange} name="access-financial_data" />}
                                    label="Financial Data"
                                />
                            </FormGroup>
                        </FormControl>
                    </span>) : ""
                }

                {
                    userGroupValue['rapid'] == true? 
                    (<span>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Org/User</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['view:org']} onChange={handleChange} name="view:org" />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['create:org']} onChange={handleChange} name="create:org" />}
                                    label="Create"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['update:org']} onChange={handleChange} name="update:org" />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['delete:org']} onChange={handleChange} name="delete:org" />}
                                    label="Delete"
                                />
                            </FormGroup>
                        </FormControl>

                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Data Configuration</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['view:graph']} onChange={handleChange} name="view:graph" />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['create:graph']} onChange={handleChange} name="create:graph" />}
                                    label="Create"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['update:graph']} onChange={handleChange} name="update:graph" />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['delete:graph']} onChange={handleChange} name="delete:graph" />}
                                    label="Delete"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Connector</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['view:connector']} onChange={handleChange} name="view:connector" />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['create:connector']} onChange={handleChange} name="create:connector" />}
                                    label="Create"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['update:connector']} onChange={handleChange} name="update:connector" />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['delete:connector']} onChange={handleChange} name="delete:connector" />}
                                    label="Delete"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Scheduler</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['view:scheduler']} onChange={handleChange} name="view:scheduler" />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['create:scheduler']} onChange={handleChange} name="create:scheduler" />}
                                    label="Create"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Task</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['view:task']} onChange={handleChange} name="view:task" />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['create:task']} onChange={handleChange} name="create:task" />}
                                    label="Create"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['run:task']} onChange={handleChange} name="run:task" />}
                                    label="Execute Task"
                                />

                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['update:task']} onChange={handleChange} name="update:task" />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filteredState['delete:task']} onChange={handleChange} name="delete:task" />}
                                    label="Delete"
                                />
                            </FormGroup>
                        </FormControl>
                    </span>) : ""
                }

                {
                    (userGroupValue['api'] == true && selectedCompanyId) ?
                    (
                        <div>
                            <RycomSectionHeader title="Permissions"></RycomSectionHeader>
                            <FormControl required error={error} component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {permissions}
                                </FormGroup>
                            </FormControl>
                        </div>
                    ) : ""
                }

                <div>
                    <RycomGrid>
                        <RycomGridItem>
                            <RycomSelectField id="profile"
                                label="Existing Profile/Template"
                                name="profile"
                                onChangeHandler={(event) => {
                                    setSelectedProfile(event[0].target.value)
                                    onProfileChange(event[0].target.value)
                                    return event[0].target.value
                                }}
                                register={register3}
                                control={control3}
                                errors={errors3}
                                errorDescription={{

                                }}>
                                {profileMenuList}
                            </RycomSelectField>
                        </RycomGridItem>
                        <RycomGridItem>

                            <TextField
                                id="outlined-multiline-flexible"
                                label="New Profile/Template"
                                variant="outlined"
                                inputRef={register3({
                                    required: {
                                        value: false,
                                        message: 'Template is required'
                                    }
                                })}
                                name="newProfile" />

                        </RycomGridItem>
                        <RycomGridItem>
                            <Button type color="primary" variant="contained" size="large" onClick={handleSubmit3(onProfileSubmit)}>Save as a Profile</Button>
                        </RycomGridItem>

                    </RycomGrid>
                </div>



                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                </Grid>;


                <RycomSectionHeader title="Contact Configuration"></RycomSectionHeader>
                <div>
                    {contactDisplayList}
                    <Grid container spacing={12} className={classes.rycomButtonGroup} >
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                            <span>
                                <Button color="primary" variant="contained" size="medium" value="Add Contact" onClick={handleOpen}>Add Contact</Button>
                            </span>
                        </Grid>
                    </Grid>;
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        <RycomSectionHeader title="Contact Information Form"></RycomSectionHeader>
                                        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Contact Type"
                                                    name="contactType"
                                                    variant="outlined"
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Contact Type is required'
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <span className={classes.invalidMessage}>
                                                        {errors2['contactType'] && <span>{errors2['contactType'].message}</span>}
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Contact Value"
                                                    name="contactValue"
                                                    variant="outlined"
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Contact Value is required'
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <span className={classes.invalidMessage}>
                                                        {errors2['contactValue'] && <span>{errors2['contactValue'].message}</span>}
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <RycomSubmitModel>
                                            <Button type="reset" variant="" size="large"
                                                onClick={() => {
                                                    reset2();
                                                }}>Clear</Button>
                                            <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleMapperSubmit)}>Submit</Button>
                                        </RycomSubmitModel>
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                </div>
                <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                        onClick={() => {
                            reset();
                        }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >

            <Dialog open={openAddModal} aria-labelledby="form-dialog-title" maxWidth={maxWidth} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle id="form-dialog-title">No Permission found!</DialogTitle>
                
                <DialogContent>
                    <DialogContentText>
                        Please add some Document Edge and come back.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddModal} color="primary" variant="contained" size="medium">Disagree</Button>
                    <Button onClick={handleRedirectModal} color="primary" variant="contained" size="medium">
                        Go to Document Edge
                    </Button>
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}
