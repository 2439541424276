import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';
import DocumentEdgeEditTemplate from './DocumentEdgeEditTemplate';
import { useParams } from 'react-router-dom'



export default function DocumentEditForm() {

    let { id } = useParams();
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        DocumentEdgeService.getEdgeById(id)
            .then((res) => {
                if (res && res.data) {
                    //console.log(res.data)
                    const response = res.data;
                    const fromPropertyName = response.fromPropertyName;
                    const toPropertyName = response.toPropertyName;
                    const fromEdgeproperties = fromPropertyName.map((propertyValue, index) => {
                        return {
                            'v_id': ++index,
                            'key': propertyValue
                        }
                    });
                    const toEdgeproperties = toPropertyName.map((propertyValue, index) => {
                        return {
                            'v_id': ++index,
                            'key': propertyValue
                        }
                    });
                    const fromPropertyValue = Object.values(response.fromPropertyNameMap);
                    let toPropertyValue=[];
                    if(response.multiEdgeTagging){
                        toPropertyValue = Object.values(response.toPropertyNameMapList);
                    } else{
                        toPropertyValue = Object.values(response.toPropertyNameMap);
                    }
                    
                    
                    const fromEdgepropertiesValue = fromPropertyValue.map((propertyValue, index) => {
                        return {
                            'v_id': ++index,
                            'value': propertyValue
                        }
                    });
                    const toEdgepropertiesValue = toPropertyValue.map((propertyValue, index) => {
                        return {
                            'v_id': ++index,
                            'value': propertyValue
                        }
                    });
                    //console.log('++++++++++', fromPropertyName, fromEdgeproperties, fromPropertyValue, fromEdgepropertiesValue)
                    response['fromEdgeproperties'] = fromEdgeproperties;
                    response['toEdgeproperties'] = toEdgeproperties;
                    response['fromEdgepropertiesValue'] = fromEdgepropertiesValue;
                    response['toEdgepropertiesValue'] = toEdgepropertiesValue;
                    setDefaultValues(response);
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])

    console.log(defaultValues);
    return (defaultValues && defaultValues.edgeRelationshipId) ?
        <DocumentEdgeEditTemplate defaultValues={defaultValues}></DocumentEdgeEditTemplate> : "";

}
