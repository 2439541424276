import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rycomInfoPair: {
    marginBottom: '10px'
  },
  rycomInfoPairLabelText: {
    marginBottom: 0,
    marginTop: 0,
    fontWeight: 700,
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#757b80',
    display: 'inline-block',
  },
  rycomInfoPairLabelTextLabel: {
    display: 'inline-block',
    marginLeft: '10sm',
    fontSize: '100%',
  }

}));

export default function RycomInfoPair(props) {
  const classes = useStyles();
  return (
    <div className={classes.rycomInfoPair}>
      <div class={classes.rycomInfoPairLabelText}>
        <h4 class={classes.rycomInfoPairLabelTextLabel}>
          <span v-if="label">
            {props.label}
          </span>
        </h4>
      </div>
      <div>
        {props.value}
      </div>
    </div>
  );
}
