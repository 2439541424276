import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomInfoPair from '../../../common/RycomInfoPair'
import * as DocumentRelationService from '../../../../services/DocumentRelationService';
import * as DocumentClassService from '../../../../services/DocumentClassService';
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';



import { useParams } from 'react-router-dom'


export default function DocumentRelationDetail() {

    let { id } = useParams();
    const [relationValue,setRelationValue]=useState({relation:[]})
    const [linkName,setLinkName]=useState()
    const [className,setClassName]=useState()

    
    useEffect(() => {

        DocumentRelationService.getDocumentRelationById(id)
        .then((res) => {
            if (res && res.data && res.data) {
                console.log(res.data)
                setRelationValue(res.data);
              const linkIds = res.data.documentLinkId;
              const classId = res.data.documentClassId;
              if(classId){
                DocumentClassService.getVertexById(classId).then(
                    (res)=>{
                        if (res && res.data && res.data) {
                            setClassName(res.data.className)
                        }
                    }
                    );
                }
            if(linkIds && linkIds.length>0){
                const linkValue=linkIds[0];
            DocumentEdgeService.getEdgeById(linkValue).then(
                (res)=>{
                    if (res && res.data && res.data) {
                        setLinkName(res.data.linkName)
                    }
                }
            );
            }    
            }  
        })
        .catch((error) => {
            console.error('getRelationById =>', error)
        })
    },[]);

    return (<div>
        <RycomPageHeader title={relationValue ? `Relation Details about ${relationValue.documentRelationName}` : 'Relation Details'} links={[{ title: 'Back', url: '/topics/relation' }]}></RycomPageHeader>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Name" value={relationValue ? relationValue.documentRelationName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Description" value={relationValue ? relationValue.documentRelationDescription : ''}></RycomInfoPair>
            </Grid>
      
        </Grid>
        <div>
            <RycomSectionHeader title="Document Vertx & Link Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Document Vertex" value={className}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Link Name" value={linkName}></RycomInfoPair>
            </Grid>
         
                </Grid>
        </div>
    </div>
    );
}
