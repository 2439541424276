import React, { Component } from 'react'
import * as OrganisationService from '../../../services/OrganisationService'

import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import environment from '../../../environment';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import { useForm } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem';
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import * as TokenService from '../../../services/TokenService';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useKeycloak } from '@react-keycloak/web';

import Moment from 'react-moment';

import { format } from 'date-fns';



const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        margin: theme.spacing(5),
    },
    aocard: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'color-white',
        border: '1px solid ui-border-color-base',
        borderRadius: 'border-radius-base',
        marginBottom: 'spacer',
        padding: 0
    },
    title: {
        margin: 0,
        fontSize: 'xx-large',
        display: 'inline-block',
        paddingRight: '10px',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
    },
    buttonStyle: {
        paddingRight: '5px',
    }
}));

export default function UserToken() {
const [count,setCount] = useState();
    let arEG = new Intl.Locale("en-US");
console.log(arEG.timeZones); // logs ["Africa/Cairo"]
const date = new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738));
// Results below assume UTC timezone - your results may vary

// Specify default date formatting for language (locale)
console.log('DateFormatEST',new Intl.DateTimeFormat('en-US').format(date));
    let { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);
    const [tokenList, setTokenList] = useState([]);
    const [rows, setRows] = useState([]);
    const [keycloak] = useKeycloak();

    const [openTokenAddModal, setOpenTokenAddModal] = React.useState(false);
    const [tokenLongOrShort, setTokenLongOrShort] = React.useState('short');
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleOpenTokenAddModal = () => {
        setOpenTokenAddModal(true);
    };

    const handleCloseTokenAddModal = () => {
        setOpenTokenAddModal(false);
    };

    const handleTokenLongOrShortChange = (event) => {
        setTokenLongOrShort(event.target.value)
    }

    useEffect(() => {
        getTokens()
    }, []);

    const getTokens = () => {
        if (tokenList.length == 0) {
            TokenService.getTokensByUserId(id)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setTokenList(res.data);
                        getRows(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }


    const defaultValues = {
        "metadata": metadataproperties
    };

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });

    const handleRevoke = (tokenList, tokenId) => {

        if (tokenId) {
            TokenService.revokeToken(tokenId)
                .then((res) => {
                    if (res && res.data && res.data) {
                        let tL = tokenList.filter(function( obj ) {
                            return obj.tokenId !== tokenId;
                        });

                        setTokenList(tL)
                        getRows(tL)
                        alert("Revoked specific Access Token");
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }

    const columns = [
        { id: 'token', label: 'Token', minWidth: 500, styles: {'word-break':'break-all'} },
        { id: 'dateCreated', label: 'Date Created', minWidth: 100 },
        { id: 'dateExpired', label: 'Date Expired', minWidth: 100 },
        { id: 'tokenRevoke', label: 'Revoke', minWidth: 100 }
    ];
    
    const getRows=(tokenList)=>{

        let rs = tokenList.map((token) => {
            //should  display only Date Expired <= current Date(EST)
            var currentDate = new Date().getTime();
            
            console.log('New y-m-d format date',format(new Date(), 'yyyy/MM/dd h:mm a'))


           // var myDatetimeString = moment(d).tz(myTimezone).format(myDatetimeFormat);

            console.log('Current Date',currentDate); // gives me "2016-03-22 12:00:00 am EDT"
            console.log('Current Date Epoch',Math.floor(currentDate/1000));
            var currentDate = Math.floor(currentDate/1000);
            var expireDate = token.dateExpired;
            console.log('Expired Date Epoch',token.dateExpired); // gives me "2016-03-22 12:00:00 am EDT"

            //console.log('Intl.DateTimeFormat',new Intl.DateTimeFormat('en-GB').format(date,'YYYY/MM/DD h:mm a'));
            //var date_string = date.toLocaleString('en-GB');  // 24 hour format
            //console.log('Expired Date After Epoch',date_string);
            if(expireDate >= currentDate)
            {
                if(token.dateCreated && typeof token.dateCreated !== 'object'){
                    
                    token.dateCreated = <Moment format="YYYY/MM/DD h:mm a" unix="true">{token.dateCreated}</Moment>;
                }

                if(token.dateExpired && typeof token.dateExpired !== 'object'){
                    token.dateExpired = <Moment format="YYYY/MM/DD h:mm a" unix="true">{token.dateExpired}</Moment>;
                }

                if(token.tokenId){
                    token.tokenRevoke = <Button onClick={() => handleRevoke(tokenList, token.tokenId)} color="secondary">Revoke</Button>
                }
                return token;
            }else{
                return false;

            }
        }); 
        setRows(rs.filter(obj=> obj!==false));
    }
    

    const buttonLinks = (<span><Link className={classes.buttonStyle} to={"/topics/user/detail/"+id}><Button
        color="primary"
        variant="contained" size="medium">
        Back</Button></Link>
        {keycloak.hasRealmRole("update:org") ? <Link className={classes.buttonStyle} onClick={handleOpenTokenAddModal}><Button
            color="warning"
            variant="contained" size="medium">
            Add Token</Button>
        </Link> : ""}
    </span>);


    const onSubmit = data => {

        const payload = {
            "tokenLongOrShort": tokenLongOrShort,
            "userid": id
        };

        TokenService.saveToken(payload).then((res) => {

            if (res.status === 200){
                alert('Token generated successfully');
                handleCloseTokenAddModal();
                tokenList.push(res.data);

                setTokenList(tokenList)
                getRows(tokenList)
            }
                
            history.push(`/topics/user/token/${id}`)
        }).catch((error) => {
            alert("Token generation error")
            console.error('Token =>', error)
        })

    }

    return (<div>
        <Card className={classes.aocard}>
            <div className={classes.header}>
                <span className={classes.title} >
                    Manage Tokens
                </span>
                <span className={classes.toolbar}>
                    {buttonLinks}
                </span>
            </div>
        </Card>
        <form className={classes.root}  >
            <RycomGrid>
                <RycomGridItem>

                </RycomGridItem>
            </RycomGrid>
        </form>

        <RycomTableHeader title="Tokens"></RycomTableHeader>
        <RycomTable rows={rows}  columns={columns}></RycomTable>

        <Dialog open={openTokenAddModal} onClose={handleCloseTokenAddModal} aria-labelledby="form-dialog-title" maxWidth={maxWidth}>
            <DialogTitle id="form-dialog-title">Add Token</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select the token life span.
                </DialogContentText>
                <RadioGroup row aria-label="position" name="tokenLongOrShort" value={tokenLongOrShort} onChange={handleTokenLongOrShortChange} defaultValue="top">
                    <FormControlLabel
                        value="short"
                        control={<Radio color="primary" />}
                        label="Short Term Token"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="long"
                        control={<Radio color="primary" />}
                        label="Long Term Token"
                        labelPlacement="start"
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseTokenAddModal} color="dark" variant="contained" size="medium">
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="medium" value="submit" onClick={handleSubmit(onSubmit)}>
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
} 
