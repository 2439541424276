import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getZoneByVenueId = (venueid) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/venuezone/venue/${venueid}`, addBearerToken());
}

export const getZoneById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/venuezone/${id}`, addBearerToken());
}

export const getAllZones = (parentcompanyid) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/venuezone/parentcompany/${parentcompanyid}`, addBearerToken());
}

export const saveZone = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/venuezone`,
               payload, addBearerToken()
                );
}