import axios from "axios";
import environment from '../environment';
import addBearerToken from  '../auth/TokenConfigurator'

export const saveScheduler = (schedulerPayload) => {
    return axios.post(`${environment.schedulerBaseUrl}/api/schedulejob`,
        schedulerPayload, addBearerToken()
    );
}

export const updateScheduler = (schedulerPayload) => {
    return axios.put(`${environment.schedulerBaseUrl}/api/schedulejob`,
        schedulerPayload, addBearerToken()
    );
}

export const startScheduler = (task) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/startJob`,
        task, addBearerToken()
    );
}

export const startEdgeScheduler = (task) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/startEdgeJob`,
        task, addBearerToken()
    );
}

export const pauseScheduler = (task) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/pause`,
         task, addBearerToken()
    );
}

export const resumeScheduler = (task) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/resume`,
            task , addBearerToken()
    );
}

export const stopScheduler = (task) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/deleteJob`,
          task, addBearerToken()
    );
}


export const getTriggerDetails = (payload) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/getTriggerDetails`,
          payload, addBearerToken()
    );
}


export const getAllScheduler = () => {
    return axios.get(`${environment.schedulerBaseUrl}/api/schedulejob/startedJob` , addBearerToken());
}

export const getSchedulerById = (id) => {
    return axios.get(`${environment.schedulerBaseUrl}/api/schedulejob/startedJob/${id}`, addBearerToken());
}

export const deleteSchedulerById = (id) => {
    return axios.delete(`${environment.schedulerBaseUrl}/api/schedulejob/startedJob/${id}`, addBearerToken());
}


export const getSchedulerJobDetails = (payload) => {
    return axios.patch(`${environment.schedulerBaseUrl}/api/schedulejob/getjobs`, payload, addBearerToken());
}