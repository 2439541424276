import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import RycomSelectField from './RycomSelectField'
import RycomSectionHeader from './RycomSectionHeader'
import RycomTextField from './RycomTextField'
import { RycomGridItem, RycomGrid } from './RycomGrid'
import { useEffect, useState } from 'react'
import countryJsonValues from './countries.json'
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    }
}));

export default function RycomAddressGrid(props) {
    const classes = useStyles();
    const { caption, register, control, errors} = props
    const [countries, setCountries]=useState([]);
    const [provinces, setProvinces]=useState([]);
    const [countryName, setCountryName]=useState(undefined);
    const [zipLabel, setZipLabel] = React.useState('Zip');

    
    useEffect(() => {
        if (countries.length === 0) {
            const countryNameList=countryJsonValues.map(country=>country.name)
            setCountries(countryNameList);
        }
    }, []);

    const getProviceList = (event) => {
        const selectedCountry=event.target.value;
        setCountryName(selectedCountry)
        setZipLabel(selectedCountry==='United States'?'US ZIP':'Postal Zip Format: X1X 1X1');
        const filterCountry=countryJsonValues.filter(country=>country.name===selectedCountry)
        setProvinces(filterCountry[0].states)
        return event.target.value
    };
    const countryMenuList = countries.map((country, index) => <MenuItem key={++index} value={country}> {country}</MenuItem>);
    const ProvinceMenuList = provinces.map((state, index) => <MenuItem key={++index} value={state.name}> {state.name}</MenuItem>);

  return (<div>
    <RycomSectionHeader title={caption}></RycomSectionHeader>
    <RycomGrid>
        <RycomGridItem>
        <RycomTextField id="address1"
                label="Address 1"
                name="address1"
                register={register}
                errors={errors}
                errorDescription={{
                    required: {
                        value: true,
                        message: 'Address 1 is required'
                    }
                }}
            />
        </RycomGridItem>
        <RycomGridItem>
        <RycomTextField id="address2"
                label="Address 2"
                name="address2"
                register={register}
                errors={errors}
            />
        </RycomGridItem>
        <RycomGridItem>
         <RycomTextField id="address3"
                label="Address 3"
                name="address3"
                register={register}
                errors={errors}
            />
        </RycomGridItem>
    </RycomGrid>
    <RycomGrid>
    <RycomGridItem>
        <RycomTextField id="city"
                label="City"
                name="city"
                register={register}
                errors={errors}
                errorDescription={{
                    required: {
                        value: true,
                        message: 'City is required'
                    }
                }}
            />
        </RycomGridItem>
        <RycomGridItem>
        <RycomSelectField id="country"
                            label="Country"
                            name="country"
                            onChangeHandler={(event) => {
                                return getProviceList(event[0])
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Country is required'
                                }
                            }}
                        >
                            {countryMenuList}
                        </RycomSelectField>
        </RycomGridItem>
        <RycomGridItem>
        <RycomSelectField id="state"
                            label="State/Province"
                            name="state"
                            onChangeHandler={(event) => {
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            disabled={!countryName}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'State is required'
                                }
                            }}
                        >
                            {ProvinceMenuList}
                        </RycomSelectField>
        </RycomGridItem>
        </RycomGrid>
        <RycomGrid>
         <RycomGridItem>
              {countryName==='United States'?
              <TextField
                id="outlined-multiline-flexible"
                label="Zip*"
                variant="outlined"
                placeholder={zipLabel}
                inputRef={register({ required: {
                    value: true,
                    message: 'zip is required'
                },pattern: {
                    value: /(^\d{5}$)|(^\d{5}-\d{4}$)/ ,
                    message: 'format is required'
                }
            
            })}
                name="zip" />:
                <TextField
                id="outlined-multiline-flexible"
                label="Zip*"
                variant="outlined"
                placeholder={zipLabel}
                inputRef={register({ required: {
                    value: true,
                    message: 'zip is required'
                },pattern: {
                    value: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/ ,
                    message: 'format is required'
                }
            
            })}
                name="zip" />}
                 <span className={classes.invalidMessage}>
                {errors['zip'] && <span>{errors['zip'].message}</span>}
            </span>
                    </RycomGridItem>
    </RycomGrid></div>);
}