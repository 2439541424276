import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomInfoPair from '../../common/RycomInfoPair'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'

import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';


import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import { useForm } from 'react-hook-form'


import RycomAddressGrid from '../../common/RycomAddressGrid'
import environment from '../../../environment'
import * as UserService from '../../../services/UserService';
import * as OrganisationService from '../../../services/OrganisationService';

import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


/*
{
    "parentcompanyid": "0",
    "companyid": "3ba514e0-43c8-11ea-b77f-2e728ce88125",
    "login":{
     "username":"jlushington",
     "password": "fireflies"
    },
    "contact":
    [
     {
      "contactkey": "primary email",
      "contactvalue": "jay.lushington@gmail.com",
      "contacttype": "EMAIL"
     },
      {
      "contactkey": "primary Cell",
      "contactvalue": "1-416-321-3357",
      "contacttype": "PHONE"
     }
    ],
     "metadata": [
        {
            "key": "",
            "value": ""
        }
    ] 
}*/

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
      formControl: {
        margin: theme.spacing(5),
      },
}));

export default function UserSelfEditTemplateForm(props) {

    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
    const defaultValues = props.defaultValues;
    console.log(defaultValues)

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [contactList, setContactList] = useState(defaultValues.contact);




    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (companyList.length == 0) {
            OrganisationService.getCompanyByParentId(parentcompanyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
    }, []);
 

    const { register:register3, errors:errors3, handleSubmit:handleSubmit3, control:control3 } = useForm({ });
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
 
    const {
        register: register2,
        errors: errors2,
        control: control2,
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm({
    });

    const canAddNewMetadataProperties = () => {
        const length = metadataproperties.length - 1
        return metadataproperties[length].v_id
    };

    const newMetadataProfile = () => {
        let id = 1;
        if (metadataproperties.length > 0) {
            let lastKey = canAddNewMetadataProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newMetadataProfile = [...metadataproperties, {
            'v_id': id,
            'key': null,
            'value': null,
        }]
        setMetadataproperties(newMetadataProfile);
    }

    const deleteMetadataProfile = (index) => {
        const deleteProfiles = [...metadataproperties]
        if (deleteProfiles.length > 1) {
            deleteProfiles.splice(index, 1);
            setMetadataproperties(deleteProfiles);
        } else {
            setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
            }])
        }
    };

    if (!metadataproperties.length) {
        deleteMetadataProfile()
    }

    const changeKeyHandler = (edgeProperty) => (event) => edgeProperty.key = event.target.value;
    const changeValueHandler = (edgeProperty) => (event) => edgeProperty.value = event.target.value;
    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);



const handleMapperSubmit = data => {
    console.log('handleMapperSubmit');
    console.log(data);
    const contactPayload = {
    "key": data.key,
      "contactType": data.contactType,
      "contactValue": data.contactValue,

    };
    console.log(data.metadataKey)
    setContactList([...contactList, contactPayload]);
    handleClose(true);
}


const onProfileSubmit = (data) => {
    const payload = defaultValues;
    if(data.password !== data.confirmPassword){
        alert("Please enter the same password");
        return false;
    }

    payload.login.password = data.password;
    payload["isUpdatePassword"]= true;

    console.log(JSON.stringify(payload))
      UserService.updatePassword(payload).then((res) => {
        console.log(res)
        if(res.status === 200)  
        alert('Password updated successfully');
      })

    .catch((error) => {
      alert("Error: Please check the Password")
       console.error('Role =>', error)
     }) 
}
  
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const error = [].filter((v) => v).length !== 2;
    const metadataList = metadataproperties.map((metadata, index) => {
        metadata.id = index;
        return <RycomGrid>
            <RycomGridItem>
                <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="key"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Metadata key is required'
                        }
                    })}
                    value={metadata.key}
                    onChange={changeKeyHandler(metadata)}
                />
                    <span>
                        <span>
                            {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                        </span>
                    </span>
                </div>
            </RycomGridItem>
            <RycomGridItem>
                <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Value"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Value is required'
                        }
                    })}
                    value={metadata.value}
                    onChange={changeValueHandler(metadata)}
                />
                    <span>
                        {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                    </span>
                </span>
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
                    onClick={(e) => {
                        const deleteProfiles = [...metadataproperties]

                        if (metadataproperties.length > 1) {
                            const deleteProfiles1 = deleteProfiles.filter(edge => {
                                return edge.v_id != metadata.v_id
                            });
                            setMetadataproperties(deleteProfiles1);
                        } else {
                            setMetadataproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null
                            }])
                        }
                    }}>Delete Metadata</Button>
            </RycomGridItem>
        </RycomGrid>;
    });

    const contactDisplayList = contactList.map(contact => <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Contact Type" value={contact ? contact.contactType : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Contact Value" value={contact ? contact.contactValue : ''}></RycomInfoPair>
        </Grid>
        <Grid>
            <Button color="secondary" variant="contained" size="small" value="delete Contact"
                onClick={(e) => {
                    const deleteProfiles = [...contactList]
                    if (contactList.length > 1) {
                        const deleteProfiles1 = deleteProfiles.filter(contactListValue => {
                            return contactListValue.key !== contact.key
                        });
                        setContactList(deleteProfiles1);
                    } else {
                        setContactList([])
                    }
                }}>Delete Contact</Button>
        </Grid>
    </Grid>);



    const onSubmit = data => {
           console.log('inside the submit')
        const payload = defaultValues;
        payload["companyid"]= data.companyid;
        payload["firstName"]= data.firstName;
        payload["lastName"]= data.lastName;
        payload["email"]= data.email;
        payload["contact"]= contactList;
        payload["active"]= true;
        payload["isUpdatePassword"]= false;
        
        const filterCompanyList=companyList.filter(company=>company.companyid==data.companyid)
        if(filterCompanyList && filterCompanyList.length === 1)
        payload["companyName"]= filterCompanyList[0].companyname;

        console.log(
            JSON.stringify(payload))
          UserService.updateUser(payload).then((res) => {
            console.log(res)
            if(res.status === 200)  
            alert('User updated successfully');
            history.push("/topics/user/selfdetail/"+defaultValues.userid)
          })
        .catch((error) => {
          alert("User Insert error")                                      
           console.error('User =>', error)
         })
        }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the User Details"
                    links={[{ title: 'Back', url: '/topics/company' }]}></RycomPageHeader>
                <RycomSectionHeader title="User Form"></RycomSectionHeader>
                <RycomGrid>
                <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                setSelectedCompanyId(event[0].target.value);
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                    <RycomTextField id="firstName"
                            label="First Name"
                            name="firstName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'First Name is required'
                                }
                            }}
                             />
                                                </RycomGridItem>
                             <RycomGridItem>
                            <RycomTextField id="lastName"
                            label="Last Name"
                            name="lastName"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Last Name is required'
                                }
                            }}
                             />
                            </RycomGridItem>
                            <RycomGridItem>
                            <RycomTextField id="email"
                            label="Email"
                            name="email"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Email is required'
                                }
                            }}
                             />
                            </RycomGridItem>
                            </RycomGrid>
    



         <RycomSectionHeader title="Reset Password"></RycomSectionHeader>
        <RycomGrid>
    <RycomGridItem>
        <TextField
                id="outlined-multiline-flexible"
                label="Password"
                variant="outlined"
                inputRef={register3({
                    required: {
                      value: false,
                      message: 'Password is required'
                    }
                  })}
                name="password" />
  
                        </RycomGridItem>
                        <RycomGridItem>
              <TextField
                id="outlined-multiline-flexible"
                label="Confirm Password"
                variant="outlined"
                inputRef={register3({
                    required: {
                      value: false,
                      message: 'Confirm Password is required'
                    }
                  })}
                name="confirmPassword" />
                          </RycomGridItem>
                        <RycomGridItem>
                        <Button type color="primary" variant="contained" size="large"  onClick={handleSubmit3(onProfileSubmit)}>Reset Password</Button>
                        </RycomGridItem>
                  </RycomGrid>          
    

                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                </Grid>;


                <RycomSectionHeader title="Contact Configuration"></RycomSectionHeader>
                <div>
                    {contactDisplayList}
                    <Grid container spacing={12} className={classes.rycomButtonGroup} >
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                            <span>
                                <Button color="primary" variant="contained" size="medium" value="Add Contact" onClick={handleOpen}>Add Contact</Button>
                            </span>
                        </Grid>
                    </Grid>;
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        <RycomSectionHeader title="Contact Information Form"></RycomSectionHeader>
                                        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Contact Type"
                                                    name="contactType"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Contact Type is required'
                                                        }
                                                    })}
                                                />
                                                <span>
                                                    <span>
                                                        {errors2['contactType'] && <span>{errors2['contactType'].message}</span>}
                                                    </span>
                                                </span>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
                                            <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Contact Value"
                                                    name="contactValue"
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                    inputRef={register2({
                                                        required: {
                                                            value: true,
                                                            message: 'Contact Value is required'
                                                        }
                                                    })}
                                                />
                                                <span>
                                                    <span>
                                                        {errors2['contactValue'] && <span>{errors2['contactValue'].message}</span>}
                                                    </span>
                                                </span>
                                            </Grid>
                                         </Grid>           
                                        <RycomSubmitModel>
                                            <Button type="reset" variant="" size="large"
                                                onClick={() => {
                                                    reset2();
                                                }}>Clear</Button>
                                            <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleMapperSubmit)}>Submit</Button>
                                        </RycomSubmitModel>
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                </div>
                <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                        onClick={() => {
                            reset();
                        }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
