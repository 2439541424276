import React, { Component } from 'react'
import * as OrganisationService from '../../../services/OrganisationService'

import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import environment from '../../../environment';
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import { useForm } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem';
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import * as UserService from '../../../services/UserService';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
      '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '50ch',
      },
  },
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
    formControl: {
      margin: theme.spacing(5),
    },
}));

export default function UserForm() {
  const classes = useStyles();
  const history = useHistory();
  const [metadataproperties, setMetadataproperties] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const [companyList, setCompanyList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
      if (companyList.length == 0) {
            //OrganisationService.getCompanyByParentId(companyid)
            OrganisationService.getCompanyParentById(companyid)
              .then((res) => {
                  if (res && res.data && res.data) {
                      setCompanyList(res.data);
                  }
              })
              .catch((error) => {
                  console.error('getConnector =>', error)
              })
      }
  }, []);

  const getUserDetails=(companyId)=>{
    if (companyId) {
      UserService.getUserByCompanyId(companyId)
          .then((res) => {
              if (res && res.data && res.data) {
                console.log(res.data)
                  setUserDetails(res.data);
              }
          })
          .catch((error) => {
              console.error('getUserDetails =>', error)
          })
    }

  }

  const defaultValues = {
      "metadata": metadataproperties
  };

    const [contactList, setContactList] = useState([]);

    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];

    const companyMenuList = companyList?companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>):[];
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ });


    const columns = [
      { id: 'firstName', label: 'First Name', minWidth: 100 },
      { id: 'lastName', label: 'Last Name', minWidth: 100 },
      { id: 'companyName', label: 'Organisation', minWidth: 170 },
      { id: 'email', label: 'Email', minWidth: 100 },
      { id: 'active', label: 'IsActive', minWidth: 100 }

    ];
    let rows=[];
    console.log(userDetails)
    console.log(selectedCompanyId)

    if(selectedCompanyId && userDetails && userDetails.length>0){

        const filteredrows=userDetails.map((user)=>{
            if(user.active) 
            return user;
            else
            return false;
          });

       rows=userDetails.map((user)=>{
          user.active=user.active?'TRUE':'FALSE'
            user.firstName=<Link to={`/topics/user/detail/${user.userid}`} >{user.firstName}</Link>
        return user;
                });

          }

      return (<div>
        <RycomPageHeader title=""  links={[{title:'Add User',url:'/topics/user/form',role:"create:org"}]}>></RycomPageHeader>
        <form className={classes.root}  >
          <RycomGrid>
                <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                setSelectedCompanyId(event[0].target.value);
                                getUserDetails(event[0].target.value)
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    </RycomGrid>
                            </form>
        <RycomTableHeader title="User Details"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
