import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText';



const useStyles = makeStyles(theme => ({
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    },
    requiredMessage: {
        color: 'red',
    }
}));


export default function RycomSelectField(props) {
    const classes = useStyles();
    const { id, label, name, onChangeHandler, register, control, errors, errorDescription, value, disabled, defaultValue } = props
   const labelWithcaption=<span>{label}<span classes={classes.requiredMessage}>*</span></span>;

   return (
        errors?
        <span>
            <Controller
                as={
                    <TextField
                        id={id}
                        select
                        label={labelWithcaption}
                        variant="outlined"
                        name={name}
                        value={value?value:""}
                        >
                        {props.children}
                    </TextField>
                }
                rules={errorDescription}
                control={control}
                name={name}
                value={value?value:""}
                onChange={onChangeHandler}
                disabled={disabled}
                defaultValue={defaultValue}
            />
            <span className={classes.invalidMessage}>
                {errors[name] && <span>{errors[name].message}</span>}
            </span>
            
        </span>:
        <span>
        <Controller
            as={
                <TextField
                    id={id}
                    select
                    label={label}
                    variant="outlined"
                    name={name}
                    value={value?value:""}
                >
                    {props.children}
                </TextField>
            }
            control={control}
            name={name}
            value={value?value:""}
            onChange={onChangeHandler}
            defaultValue={defaultValue}
        />
       </span>

    );
}
