import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'
import environment from '../../../../environment'

import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as DocumentClassService from '../../../../services/DocumentClassService';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as DataMapperService from '../../../../services/DataMapperService';


import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function DocumentVertexEditTemplate(props) {

    const history = useHistory();
    const classes = useStyles();
    const defaultValues = props.defaultValues;
    console.log('defaultValues',defaultValues)
   
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });

    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
    const [dataMapperList, setDataMapperList] = React.useState([]);
    const [dataMapperId, setDataMapperId] = React.useState([]);
    const [dataMapperName ,setDataMapperName] = React.useState();
    // const [dataMapperId, setDataMapperId] = React.useState("");
    // const [primaryKeyEnabled, setPrimaryKeyEnabled] = React.useState(false);
    // const [historicalEnabled, setHistoricalEnabled] = React.useState(false);
    const handlePrimaryKeyEnabledChange=()=>{
        setPrimaryKeyEnabled(!primaryKeyEnabled)
    }

    const handleHistoricalEnabledChange=()=>{
        setHistoricalEnabled(!historicalEnabled)
    }

    const [primaryKeyEnabled, setPrimaryKeyEnabled] = React.useState(defaultValues.checkDuplicate);
    const [historicalEnabled, setHistoricalEnabled] = React.useState(defaultValues.checkForHistoricalChanges);


    // const dataMapperMenuList = dataMapperList.map((dataMapper, index) => <MenuItem key={++index} value={dataMapper}> {dataMapper.datamappername}</MenuItem>);

    const dataMapperMenuList=()=>{
        return dataMapperList.map((dataMapper, index) => {
        return dataMapper.datamapperid == props.defaultValues.dataMapperId?
        <MenuItem key={++index} value={dataMapper.datamapperid} >{dataMapper.datamappername}
        </MenuItem>:
        <MenuItem key={++index} value={dataMapper.datamapperid} >{dataMapper.datamappername}
        </MenuItem>
        })
      }
    
    useEffect(() => {//
        getDatamapperList(paramID);
     }, []);

    const getDatamapperList = (parentcompanyid) => {
        //DataMapperService..getDataMapperByCompanyIdForTask(parentcompanyid)
        DataMapperService.getDataMapperByCompanyIdForTask(parentcompanyid)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res);
                    setDataMapperList(res.data);
                    res.data.map(item => {
                        if(item.datamapperid===props.defaultValues.dataMapperId)
                        {
                            populateGraphInfo(item);
                        }

                    })
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const populateGraphInfo=(dataMapper)=>{
       // setDataMapperId(dataMapper.datamapperid);
        setPrimaryKeyEnabled(dataMapper.checkDuplicate);
        setHistoricalEnabled(dataMapper.checkForHistoricalChanges);
    };

    const onSubmit = payload => {
        //console.log(payload);
        //payload.parentcompanyid=parentcompanyid;
        payload.parentcompanyid=paramID;

        payload.classId=defaultValues.classId;
        payload["checkDuplicate"]=primaryKeyEnabled;
        payload["checkForHistoricalChanges"]=historicalEnabled;
        payload['dataMapperId']=dataMapperId;
        if(historicalEnabled){
            if(!payload.duplicateVertex){
                alert("please enter the Duplicate/Historical Vertex Name");
                return false;
            }
        }else{
            payload.insertionDate=undefined;
        }
     
        DocumentClassService.updateDocumentVertex(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('vertex inserted successfully');
            history.push("/topics/documentvertex")
          })
        .catch((error) => {
          alert("Document Vertex error")                                      
           console.error('document vertex =>', error)
         }) 

    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Document Vertex Details"
                    links={[{ title: 'Back', url: '/topics/vertex' }]}></RycomPageHeader>
                <RycomSectionHeader title="Vertex Overview Details"></RycomSectionHeader>
           
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="className"
                            label="Document Vertex Name"
                            name="className"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Document Vertex Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="classDescription"
                            label="Document Vertex Description"
                            name="classDescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Vertex Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="type"
                            label="Base Vertex Type"
                            name="type"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                        >
<MenuItem key="1" value="Site"> Site</MenuItem>
<MenuItem key="2" value="Device"> Device</MenuItem>
<MenuItem key="3" value="Point"> Point</MenuItem>
<MenuItem key="4" value="Point_History"> Point_History</MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>  
                    <RycomGridItem>
                    {dataMapperList.length !== 0 ?<RycomSelectField id="dataMapperId"
                            label="DataMapper"
                            name="dataMapperId"
                            list={dataMapperList}
                            onChangeHandler={(event) => {
                                dataMapperList.map(item => {
                                    if(item.datamapperid===event[0].target.value)
                                    {
                                        populateGraphInfo(item);
                                    }

                                })
                                
                                setDataMapperId(event[0].target.value);
                                console.log('dtatamperiddfsdfsdafds',event[0].target.value)
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            value={dataMapperId}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'DataMapper is required'
                                }
                            }}
                        >
                            {dataMapperMenuList()}
                        </RycomSelectField>:null}
                    </RycomGridItem>
                </RycomGrid>
             
                <RycomSectionHeader title="Graph Info"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                    <FormControlLabel
                                                     style={{ width: 300 }}
                                                    control={<Checkbox
                                                    checked={primaryKeyEnabled} onChange={handlePrimaryKeyEnabledChange} name="flattening" />}
                                                    label="Check for Duplicate Record"
                                                    disabled
                                                    />
                     
                    </RycomGridItem>
                    <RycomGridItem>
                    <FormControlLabel
                                                     style={{ width: 300 }}
                                                    control={<Checkbox
                                                    checked={historicalEnabled} onChange={handleHistoricalEnabledChange} name="flattening" />}
                                                    label="Maintain Historical Record - Changes"
                                                    disabled
                                                    /> 
                    </RycomGridItem>
                    {historicalEnabled?
                    <RycomGridItem>
                    <RycomTextField id="historicalVertexName"
                            label="Historical Vertex Name"
                            name="duplicateVertex"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                
                            }}
                        />
                    </RycomGridItem>:""}
                </RycomGrid>

             
             <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
