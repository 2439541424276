import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import RycomSectionHeader from "../common/RycomSectionHeader";
import RycomPageHeader from "../common/RycomPageHeader";
import RycomSelectField from "../common/RycomSelectField";
import RycomTextField from "../common/RycomTextField";

import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import * as ConnectorService from "../../services/ConnectorService";

import ConnectorSFTPConfiguration from "./ConnectorSFTPConfiguration";
import ConnectorFTPConfiguration from "./ConnectorFTPConfiguration";
import ConnectorAPIConfiguration from "./ConnectorAPIConfiguration";
import ConnectorJDBCConfiguration from "./ConnectorJDBCConfiguration";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import "date-fns";
import { format } from "date-fns";
//import moment from "moment";
import { useHistory } from "react-router-dom";
import he from "date-fns/esm/locale/he/index.js";
var moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },

  rycomButtonGroup: {
    paddingLeft: "900px",
  },
}));


export default function ConnectorForm() {
  const history = useHistory();
  const defaultValues = {
    companyID: "",
    filePath: "",
    host: "",
    passPhrase: "",
    encryptionPassphrase:"",
    password: "",
    port: "",
    privateKeyFileLocation: "",
    publicKeyAuthenticaiton: "",
    userName: "",
    databaseName: "",
    fetchStartDateString: "",
    fetchOffsetType: "",
    fetchEndDateString: "",
    dataClassificationID: "",
    dataTypeMapperID: "",
  };

  const connectorTypes = [
    "sftp",
    "sftprecursive",
    "ftp",
    "ftprecrusive",
    "ftps",
    "ftpsrecrusive",
    "API",
    "JDBC",
    "ORIENT"
  ];


   const [connectorFlowType, setConnectorFlowType]=useState(); 



  const classes = useStyles();
  const { register, errors, handleSubmit, reset, watch, control } = useForm({
    defaultValues,
  });
  const [connectorType, setConnectorType] = useState();
  const [methodParametersContent, setMethodParametersContent] = useState();
  const [metadatapropertiesContent, setmetadatapropertiesContent] = useState();
  const [historyValue, setHistoryValue] = useState(false);
  const [bodyContent, setBodyContent] = useState('');
  const [headerContent, setHeaderContent] = useState({});

  
  const [includePathIdFlagValue, setIncludePathIdFlagValue] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ dateFormat, setDateFormat ] = useState(null);
  const [ dateTimeZone, setDateTimeZone ] = useState(null);
  const [ jsonResponsePayload, setJsonResponsePayload ] = useState(null);


  //const [dateFormatName,setFormatName]=useState(null);
  //console.log('Original Start Date',startDate);

  //console.log('Original Start Date',moment(startDate).format('YYYY-MM-DD, hh:mm:ss A'));

  //console.log("startDate",startDate)  
  //console.log("endDate",endDate)  

  const startDateGmt = moment(startDate).format("yyyy-MM-DD hh:mm:ss A")
  const endDateGmt = moment(endDate).format("yyyy-MM-DD hh:mm:ss A")
  

  const [ sftpFiles, setSftpFiles ] = useState([]);
  const [ sftpFileContent, setSftpFileContent ] = useState(null);

  
  const getSftpFilesMenus = ()=>{
    return sftpFiles.map((sftpFile, index) => <MenuItem key={++index} value={sftpFile} >{sftpFile} </MenuItem>);
  }; 

  const filterSftpFileContent=(selectedSftpFile)=>{
    const filteredContent=jsonResponsePayload.filter(responsePayload=>responsePayload.fileName==selectedSftpFile);
    if(filteredContent && filteredContent.length==1){
      setSftpFileContent(filteredContent[0].data);
    }
  };
  

  const validate =(data)=>{
    console.log("validate")
    if(connectorLoginCredential && connectorLoginCredential.usernameKey){
      const usernameAttribute=connectorLoginCredential.usernameKey;
      const passwordAttribute=connectorLoginCredential.passwordKey;
      const credentialInfoObj ={};
      credentialInfoObj[usernameAttribute]=connectorLoginCredential.username
      credentialInfoObj[passwordAttribute]=connectorLoginCredential.password
      connectorLoginCredential['credentialInfoMap']=credentialInfoObj
      connectorLoginCredential['extractTokenAttributeKey']=connectorLoginCredential.tokenAttribute
    }

    const uploadedOrChoosedKeyFileName = data.sshKeyFileOption == "existing" ? data.selectKey : data.sshPrivateKeyFileName;
    const uploadedOrChoosedEncFileName = data.pgpKeyFileOption == "existing" ? data.selectEncFile : data.pgpKeyFileName;

    const configuration= {
      baseURI: data.baseURI,
      pathURI: data.pathURI,
      history: data.fetchOffsetType=='history'?true:false,
      includePathId: includePathIdFlagValue,
      frequency: data.frequency,
      requestMethod: data.requestMethod,
      maxmiumFetchOffset: data.maxmiumFetchOffset,
      maxmiumUnitType: data.maxmiumUnitType,
      fetchType: data.fetchType,
      startParamter: data.startParamter,
      endParamter: data.endParamter,
      timeZone: data.timeZone,
      historyDurationType: data.historyDurationType,
      offsetDuration: data.offsetDuration,
      offsetDurationUnitType: data.offsetDurationUnitType,
      dateFormat: data.dateFormat,
      fetchOffsetType:
        data.fetchOffsetType === ""
          ? "lastOneDay"
          : data.fetchOffsetType,
      fetchStartDateString: startDateGmt,
      fetchEndDateString: endDateGmt,
      body: '',
      connectorLoginCredential: connectorLoginCredential,
      password: data.password,
      userName: data.userName,
      port: data.port,
      host: data.host,
      history: true,
      databaseName: data.databaseName,
      dataStoreType: data.dataStoreType,
      dataStoreName: data.dataStoreName,
      databaseType:data.databaseType,
      sslEnabled: data.sslEnabled=='enabled'?true:false,
      query: data.dataStoreName,
      createdDateColumn: data.createdDateColumn,
      modifiedDateColumn: data.modifiedDateColumn,

      filePath:data.filePath,
      privateKeyFileLocation: data.privateKeyFileLocation,
      publicKeyAuthenticaiton: data.publicKeyAuthenticaiton,

      authenticationType: data.authenticationType,
      sshKeyFileOption:data.sshKeyFileOption,
      sshPrivateKeyFileName:uploadedOrChoosedKeyFileName,

      decryptionEnabled:data.decryptionEnabled,
      pgpKeyFileOption:data.pgpKeyFileOption,
      pgpKeyFileName:uploadedOrChoosedEncFileName,
      fileName:data.fileName,
      encryptionPassphrase:data.encryptionPassphrase,

      existingFileStrategy:data.existingFileStrategy,
      encryptionEnabled:data.encryptionEnabled,
      enableBatchProcessing:data.enableBatchProcessing

    }
    console.log(configuration)
    if(connectorType=='api' || connectorType=='API'){
      configuration.pathVariable = formatObject(
        metadatapropertiesContent
      );

      configuration.uriParameters = formatObject(
        methodParametersContent
      );
      configuration.header = formatObject(headerContent);
      console.log(bodyContent);
  
      if(bodyContent && bodyContent!=''){
        console.log(bodyContent);
        const parsedObject=JSON.parse(bodyContent);
        const jsonContent=JSON.stringify(parsedObject);  
        configuration.body = jsonContent
    }
    }
    
  

    ConnectorService.getConnectorFile(connectorType,configuration)
      .then((res) => {
        if(res.status==200){
          alert("Valid Connector Configuration")
          if(connectorType=='sftp' || connectorType =='sftprecursive'){
           const sftpFiles=res.data.map(fileOutput=>fileOutput.fileName);
           setSftpFiles(sftpFiles);
          }
           setJsonResponsePayload(res.data);
         } else {
          alert("InValid Connector Configuration")
        }
      })
      .catch((error) => {
        alert("Connector error");
        setJsonResponsePayload(null);

        console.error("getConnector =>", error);
      });
      return false;
  }


  var startDateNew = new Date(startDate);
  console.log(
    startDateNew.getFullYear() +
      "-" +
      startDateNew.getMonth() +
      1 +
      "-" +
      startDateNew.getDate() +
      "," +
      startDateNew.getHours() +
      ":" +
      startDateNew.getMinutes() +
      ":" +
      startDateNew.getSeconds() +
      ""
  );

  // const startDateGmt = new Date(startDate,'yyyy-MM-dd hh:mm:ss a').toLocaleString();

  // const endDateGmt = new Date(endDate).toISOString().replace('T','').substring(0,19);;

 // console.log("startDateGmt", startDateGmt);
 // console.log("endDateGmt", endDateGmt);

  

  const [connectorLoginCredential, setConnectorLoginCredential] = useState(
    {}
  );

  useEffect(() => {//

    const connectorLoginCredentialDefaultValues = {
      username: "",
      password: "",
      databaseName: "",
      tablename: "",
      responseChannel: "",
      tokenType: "",
      keyName: "",
      reqType: "body",
    };

    setConnectorLoginCredential(connectorLoginCredentialDefaultValues);
    console.log("before initialization connectorLoginCredentialDefaultValues");
    console.log(connectorLoginCredentialDefaultValues);
  }, []);

 


  const formatObject = (content) => {
    let pathContent = {};
    let pathInfoContent = content.filter((pathInfoContentValue) => {
      return !(
        pathInfoContentValue.key == null ||
        pathInfoContentValue.value == null ||
        pathInfoContentValue.value == "" ||
        pathInfoContentValue.key == "" ||
        pathInfoContentValue.key == undefined ||
        pathInfoContentValue.value == undefined
      );
    });
    pathInfoContent = pathInfoContent.map((pathObj) => {
      delete pathObj.v_id;
      delete pathObj.id;
      return pathObj;
    });
    console.log(pathInfoContent);
    if (pathInfoContent.length > 0) {
      for (var i = 0; i < pathInfoContent.length; i++) {
        pathContent[pathInfoContent[i].key] = pathInfoContent[i].value;
      }
    }
    return pathContent;
  };

  const onSubmit = (data) => {
    console.log("---------Test", data, connectorLoginCredential);
    // let StartDate = null;
    // let EndDate = null;
    console.log("data")
    console.log(data);
    // return false;

    let payload = {};
    if (connectorType == "API") {

      if(connectorLoginCredential && connectorLoginCredential.usernameKey){
        const usernameAttribute=connectorLoginCredential.usernameKey;
        const passwordAttribute=connectorLoginCredential.passwordKey;
        const credentialInfoObj ={};
        credentialInfoObj[usernameAttribute]=connectorLoginCredential.username
        credentialInfoObj[passwordAttribute]=connectorLoginCredential.password
        connectorLoginCredential['credentialInfoMap']=credentialInfoObj
        connectorLoginCredential['extractTokenAttributeKey']=connectorLoginCredential.tokenAttribute
      }

      payload = {
        connectionInfo: {
          configuration: {
            baseURI: data.baseURI,
            pathURI: data.pathURI,
            contentType:data.contentType,
            history: data.fetchOffsetType=='history'?true:false,
            includePathId: includePathIdFlagValue,
            frequency: data.frequency,
            requestMethod: data.requestMethod,
            maxmiumFetchOffset: data.maxmiumFetchOffset,
            maxmiumUnitType: data.maxmiumUnitType,
            fetchType: data.fetchType,
            startParamter: data.startParamter,
            endParamter: data.endParamter,
            timeZone: data.timeZone,
            historyDurationType: data.historyDurationType,
            offsetDuration: data.offsetDuration,
            offsetDurationUnitType: data.offsetDurationUnitType,
            dateFormat: data.dateFormat,
            fetchOffsetType:
              data.fetchOffsetType === ""
                ? "lastOneDay"
                : data.fetchOffsetType,
            fetchStartDateString: startDateGmt,
            fetchEndDateString: endDateGmt,
            body: '',
            connectorLoginCredential: connectorLoginCredential,
          },
          type: "api",
          dataflow:connectorFlowType,
        },
        userName: data.userName,
        connectorName: data.connectorName,
        dataClassificationID: data.dataClassificationID,
      };

      if(data.tokenValue){
        headerContent["api-key"]=data.tokenValue;
      }

      payload.connectionInfo.configuration.pathVariable = formatObject(
        metadatapropertiesContent
      );
      payload.connectionInfo.configuration.uriParameters = formatObject(
        methodParametersContent
      );
        console.log("before the headercontent"+JSON.stringify(headerContent));
      payload.connectionInfo.configuration.header = formatObject(headerContent);
      console.log(bodyContent);

      if(bodyContent && bodyContent!=''){
        console.log(bodyContent);
        const parsedObject=JSON.parse(bodyContent);
        const jsonContent=JSON.stringify(parsedObject);  
        payload.connectionInfo.configuration.body = jsonContent

      }
  

      console.log(JSON.stringify(payload));
    } else if (connectorType == "JDBC" || connectorType == "ORIENT" ) {
      if (data.dataStoreType == "table") {
        if (data.fetchType == "range") {
          if (data.fetchOffsetType == "customDate") {
            console.log("STARTDATE:----", startDate);
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              connectionInfo: {
                Type: connectorType,
                dataflow:connectorFlowType,
                configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history:false,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreType: data.dataStoreType,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  dateFormat: data.dateFormat,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  fetchStartDateString: startDateGmt,
                  fetchEndDateString: endDateGmt,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          } else if (data.fetchOffsetType == "history") {
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              connectionInfo: {
                Type: connectorType,
                dataflow:connectorFlowType,
                configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history:true,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreName: data.dataStoreName,
                  dataStoreType: data.dataStoreType,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  dateFormat: data.dateFormat,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  frequency: data.frequency,
                  historyDurationType: data.historyDurationType,
                  offsetDuration: data.offsetDuration,
                  offsetDurationUnitType: data.offsetDurationUnitType,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          } else {
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              ConnectionInfo: {
                Type: connectorType,
                dataflow:connectorFlowType,
                Configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history:false,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  startParamter: data.startParamter,
                  endParamter: data.endParamter,
                  dateFormat: data.dateFormat,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  dataStoreType: data.dataStoreType,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          }
        } // fetchType == complete then execute this case
        else {
          payload = {
            connectorName: data.connectorName,
            companyId: "0",
            ConnectionInfo: {
              Type: connectorType,
              dataflow:connectorFlowType,
              Configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                databaseName: data.databaseName,
                dataStoreName: data.dataStoreName,
                databaseType:data.databaseType,
                sslEnabled: data.sslEnabled=='enabled'?true:false,
                dataStoreType: data.dataStoreType,
                fetchType: data.fetchType,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
            },
          };
        }
      } else {
        // dataStoreType query
        if (data.fetchOffsetType == "customDate") {
          payload = {
            connectionInfo: {
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                databaseType:data.databaseType,
                query: data.dataStoreName,
                fetchType: data.fetchType,
                startParamter: data.startParamter,
                endParamter: data.endParamter,
                dateFormat: data.dateFormat,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                history:false,
                timeZone: data.timeZone,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                fetchStartDateString: startDateGmt,
                fetchEndDateString: endDateGmt,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
              type: data.type,
              dataflow:connectorFlowType,
            },
            connectorName: data.connectorName,
            companyId: "0",
          };
        } else if (data.fetchOffsetType == "history") {
          console.log("dataStoreName_____&&&", data.dataStoreName);
          console.log("dataStoreType&&&******---", data.dataStoreType);
          payload = {
            connectorName: data.connectorName,
            companyId: "0",
            connectionInfo: {
              Type: connectorType,
              dataflow:connectorFlowType,
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                databaseType:data.databaseType,
                query: data.dataStoreName,
                fetchType: data.fetchType,
                startParamter: data.startParamter,
                endParamter: data.endParamter,
                dateFormat: data.dateFormat,
                timeZone: data.timeZone,
                history:true,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                frequency: data.frequency,
                historyDurationType: data.historyDurationType,
                offsetDuration: data.offsetDuration,
                offsetDurationUnitType: data.offsetDurationUnitType,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
            },
          };
        } else {
          /// insert record when record offset type is lastOneDay
          payload = {
            connectionInfo: {
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                history:false,
                databaseName: data.databaseName,
                fetchType: data.fetchType,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                    startParamter:data.startParamter,
              endParamter:data.endParamter,
              dateFormat:data.dateFormat,
              timeZone:data.timeZone,
                dataStoreType: data.dataStoreType,
                query: data.dataStoreName,
                databaseType:data.databaseType,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
              dataflow:connectorFlowType,
              type: data.type,
            },
            connectorName: data.connectorName,
            companyId: "0",
          };
        }
      }

      console.log("Payload Data for the JDBC", JSON.stringify(payload));
    } else {

      let fileNameUpdated, pathName, fileName, fileCheck1, pathCheck ;
      pathName = data.filePath;
      pathName = pathName.replace(/([^:]\/)\/+/g, "$1");
      
      if(data.fileName != null) {
        fileName = data.fileName;
        fileName = fileName.replace(/([^:]\/)\/+/g, "$1");
        fileCheck1 = fileName.slice(0,1);
        
        if(fileCheck1 == "/") {
          fileNameUpdated =  fileName.substring(1);
        } else {
          fileNameUpdated =  fileName;
        }

      }

      pathCheck = pathName.slice(-1);
        
      if(pathCheck != "/") {
        pathName = `${pathName}/`;
      }

      if(data.sshKeyFileOption == "existing") {
        if(data.selectKey == "" || data.selectKey == undefined) {
          alert("Please select a file");
          return false;
        }
      }/* else {
        if(data.sshPrivateKeyFileName == "" || data.sshPrivateKeyFileName == undefined) {
          alert("Please select a file");
          return false;
        }
      }*/

      if(data.encryptionEnabled == "true" || data.decryptionEnabled == "true") {
    
          if(data.pgpKeyFileOption == "existing" ) {
            if(data.selectEncFile == "" || data.selectEncFile == undefined) {
              alert("Please select a file");
              return false;
            }
          }/* else {
            if(data.pgpKeyFileName == "" || data.pgpKeyFileName == undefined) {
              alert("Please select a file");
              return false;
            }
          }*/

      }

      const uploadedOrChoosedKeyFileName = data.sshKeyFileOption == "existing" ? data.selectKey : data.sshPrivateKeyFileName;
      const uploadedOrChoosedEncFileName = data.pgpKeyFileOption == "existing" ? data.selectEncFile : data.pgpKeyFileName;

      payload = {
        connectionInfo: {
          configuration: {
            filePath: data.filePath,
            host: data.host,
            passPhrase: data.passPhrase,
            userName: data.userName,
            password: data.password,
            port: data.port,
            privateKeyFileLocation: data.privateKeyFileLocation,
            publicKeyAuthenticaiton: data.publicKeyAuthenticaiton,

            authenticationType: data.authenticationType,
            sshKeyFileOption:data.sshKeyFileOption,
            sshPrivateKeyFileName:uploadedOrChoosedKeyFileName,

            decryptionEnabled:data.decryptionEnabled,
            pgpKeyFileOption:data.pgpKeyFileOption,
            pgpKeyFileName:uploadedOrChoosedEncFileName,
            fileName:fileNameUpdated,
            encryptionPassphrase:data.encryptionPassphrase,

            existingFileStrategy:data.existingFileStrategy,
            encryptionEnabled:data.encryptionEnabled,
            enableBatchProcessing:data.enableBatchProcessing
          },
          dataflow:connectorFlowType,
          type: data.type,
        },
        userName: data.userName,
        connectorName: data.connectorName,
        dataClassificationID: data.dataClassificationID,
      };
    }
    
    console.log("payload", payload);

    ConnectorService.saveConnector(payload)
      .then((res) => {
        console.log("your jdbc result is here", res);
        if (res.status === 200) alert("inserted successfully");
        history.push("/topics/connector");
        reset({
          "connectionInfo.type": "",
          companyID: "",
          dataTypeMapperID: "",
        });
      })
      .catch((error) => {
        alert("Connector error");
        console.error("getConnector =>", error);
      });
  };

  console.log("connectorType:" + connectorType);

  let configurationTypeValue = null;
  let submitDetails = null;

  if (
    connectorType !== undefined &&
    (connectorType === "sftp" ||
      connectorType === "sftprecursive" ||
      connectorType === "sftp" ||
      connectorType === "sftprecursive")
  ) {
    console.log("You are in sftpreuculs", connectorType);
    configurationTypeValue = (
      <ConnectorSFTPConfiguration
        register={register}
        connectorType={connectorType}
        connectorFlowType={connectorFlowType}
        errors={errors}
        control={control}
      />
    );
    console.log("You are in configurationTypeValue", configurationTypeValue);
  } else if (
    connectorType !== undefined &&
    (connectorType === "ftp" ||
      connectorType === "ftprecrusive" ||
      connectorType === "ftps" ||
      connectorType === "ftpsrecrusive")
  ) {
    configurationTypeValue = (
      <ConnectorFTPConfiguration
        register={register}
        connectorType={connectorType}
        errors={errors}
        control={control}
        connectorFlowType={connectorFlowType}
      />
    );
  } else if (connectorType !== undefined && connectorType === "API") {
    configurationTypeValue = (
      <ConnectorAPIConfiguration
        register={register}
        connectorType={connectorType}
        errors={errors}
        control={control}
        setMetadatapropertiesContent={setmetadatapropertiesContent}
        setMethodParametersContent={setMethodParametersContent}
        setHistoryValue={setHistoryValue}
        setHeaderContent={setHeaderContent}
        setBodyContent={setBodyContent}
        dateStartValue={setStartDate}
        dateEndValue={setEndDate}
        selectedDateFormat={setDateFormat}
        slectedTimeZone={setDateTimeZone}
        setIncludePathIdFlagValue={setIncludePathIdFlagValue}
        setConnectorLoginCredential={setConnectorLoginCredential}
        connectorLoginCredential={connectorLoginCredential}
        connectorFlowType={connectorFlowType}
      />
    );
  } else if (connectorType !== undefined && (connectorType === "JDBC" || connectorType === "ORIENT")) {
    configurationTypeValue = (
      <ConnectorJDBCConfiguration
        register={register}
        connectorType={connectorType}
        errors={errors}
        control={control}
        dateStartValue={setStartDate}
        unitValue={setUnitValue}
        dateEndValue={setEndDate}
        selectedDateFormat={setDateFormat}
        slectedTimeZone={setDateTimeZone}
        connectorFlowType={connectorFlowType}
        //dateFormatName={setFormatName}
      />
    );
  }
  if (configurationTypeValue != null){
    if (connectorType !== undefined &&  connectorFlowType !== undefined && connectorFlowType=='inbound' && (connectorType === "API" || connectorType === "JDBC" || connectorType === "jdbc" || connectorType === "ORIENT" || connectorType === "orient" ) ) {
      submitDetails = (
        <div>

{jsonResponsePayload?<span>
<br></br>
                <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1-header"
        >
          <Typography>Extracted Input Payload</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          <pre>{JSON.stringify(jsonResponsePayload, null, 2) }</pre>
                   </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel></span>:""}


      <br></br>

        <div className={classes.rycomButtonGroup}>
         <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            value="validate"
            onClick={handleSubmit(validate)}
          >
            validate
          </Button>
          <Button
            type="reset"
            variant=""
            size="large"
            onClick={() =>
              reset({
                type: "",
                companyID: "",
                dataTypeMapperID: "",
              })
            }
          >
            Clear
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            value="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
          
        </div>
        </div>
      );
    }else if (connectorType !== undefined &&   (connectorType === "sftp" || connectorType === "sftprecursive" ) ) {
      submitDetails = (
        <div>
      {jsonResponsePayload ? <span>
        <RycomSectionHeader title="Connector Configuration Output"></RycomSectionHeader>
        <RycomSelectField id="sftpFile"
            label="Sftp Files"
            name="contentType"
            onChangeHandler={(event) => {
              filterSftpFileContent(event[0].target.value);
              return event[0].target.value
            }}
            register={register}
            control={control}
            errors={errors}
            errorDescription={{
            }} >
              {getSftpFilesMenus()}
          </RycomSelectField>
          
            <br></br>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1-header"
              >
                <Typography>Extracted SFTP CSV File Content</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <pre>{sftpFileContent}</pre>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel></span> : ""}
          <br></br>
          <div className={classes.rycomButtonGroup}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              value="validate"
              onClick={handleSubmit(validate)}
            >
              validate
            </Button>
            <Button
              type="reset"
              variant=""
              size="large"
              onClick={() =>
                reset({
                  type: "",
                  companyID: "",
                  dataTypeMapperID: "",
                })
              }
            >
              Clear
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              value="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>

          </div>
        </div>
      );
    }else{
      submitDetails = (
        <div className={classes.rycomButtonGroup}>
          <Button
            type="reset"
            variant=""
            size="large"
            onClick={() =>
              reset({
                type: "",
                companyID: "",
                dataTypeMapperID: "",
              })
            }
          >
            Clear
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            value="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      );
    }
    
  }
  console.log(configurationTypeValue);
 return (
    <form className={classes.root}>
      <RycomPageHeader
        title="Enter the Connector Details"
        links={[{ title: "Back", url: "/topics/connector" }]}
      ></RycomPageHeader>
      <RycomSectionHeader title="Connector Overview Details"></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <RycomSelectField
            id="Connector Type"
            label="Connector Types"
            name="type"
            list={connectorTypes}
            onChangeHandler={(event) => {
              setConnectorType(event[0].target.value);
              return event[0].target.value;
            }}
            register={register}
            control={control}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Connector Type is required",
              },
            }}
          >
            {connectorTypes.map((connectorType1, index) => (
              <MenuItem key={++index} value={connectorType1}>
                {connectorType1}
              </MenuItem>
            ))}
          </RycomSelectField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
      <RycomSelectField
          id="dataflow"
          label="Connector Flow Types"
          name="dataflow"
          onChangeHandler={(event) => {
            setConnectorFlowType(event[0].target.value)
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Connector Flow Type is required",
            },
          }}
        >
            <MenuItem value="inbound" key="1">InBound Connector</MenuItem>
            <MenuItem value="outbound" key="2">OutBound Connector</MenuItem>
        </RycomSelectField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <RycomTextField
            id="Connector Name"
            label="Connector Name"
            name="connectorName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Connector Name is required",
              },
            }}
          />
        </Grid>
      </Grid>
      {configurationTypeValue}
      {submitDetails}
    </form>
  );
}
