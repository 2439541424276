import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'

import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as EdgeService from '../../../../services/EdgeService';
import environment from '../../../../environment'


import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function EdgeForm() {

    const history = useHistory();
    const classes = useStyles();
    const [edgeproperties, setEdgeproperties] = useState([]);
    const defaultValues = {
        "edgeproperties": edgeproperties
    };
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    //const [companyId, setCompanyId] = useState(undefined);
    //const [companyList, setCompanyList] = useState([]);
    //const [venueList, setVenueList] = useState([]);
    //const [deviceList, setDeviceList] = useState([]);


    const canAddNewEdgeProperties = () => {
        const length = edgeproperties.length - 1
        return edgeproperties[length].v_id
    };

    const newEdgeProfile = () => {
        let id = 1;
        if (edgeproperties.length > 0) {
            let lastKey = canAddNewEdgeProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newEdgeProfile = [...edgeproperties, {
            'v_id': id,
            'key': null,
            'value': null,
            'valueType': null
        }]
        setEdgeproperties(newEdgeProfile);
    }

    const deleteEdgeProfile = (index) => {
        const deleteEdgeProfiles = [...edgeproperties]
        if (deleteEdgeProfiles.length > 1) {
            deleteEdgeProfiles.splice(index, 1);
            setEdgeproperties(deleteEdgeProfiles);
        } else {
            setEdgeproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
                'valueType': null
            }])
        }
    };

    if (!edgeproperties.length) {
        newEdgeProfile()
    }

    const changeKeyHandler = (edgeProperty) => (event) => edgeProperty.key = event.target.value;
    const changeValueHandler = (edgeProperty) => (event) => edgeProperty.value = event.target.value;
    const selectChangeValueHandler = (edgeProperty) => (event) => edgeProperty.valueType = event[0].target.value;

    /*
    useEffect(() => {
        if(companyList.length==0){
        OrganisationService.getCompanyByParentId(parentcompanyid)
            .then((res) => {
                if (res && res.data && res.data) {
                    setCompanyList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        }
    }, []);

    const getVenueList = (event) => {
        setCompanyId(event.target.value);
        VenueService.getVenueByCompanyId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVenueList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
            return event.target.value
    };

    const getDeviceList = (event) => {
        DeviceService.getDeviceByVenueId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setDeviceList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
            return event.target.value
    };

    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
    const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);
    const deviceMenuList = deviceList.map((device, index) => <MenuItem key={++index} value={device.deviceid}> {device.devicename}</MenuItem>);
    */
    const edgeDirectionMenuList=['IN','OUT'].map((value, index) => <MenuItem key={++index} value={value}> {value}</MenuItem>);
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;


    const edgeList = edgeproperties.map((edgeProperty, index) => {
        edgeProperty.id = index;
        return <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
            <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="Edge Name"
                    style={{ width: 300 }}
                    variant="outlined"
                    inputRef={register({
                    })}
                    value={edgeProperty.key}
                    onChange={changeKeyHandler(edgeProperty)}
                />
                 <span>
                    </span>    
              </div>            
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
              <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Edge Value"
                    style={{ width: 300 }}
                    variant="outlined"
                    inputRef={register({
                      })}
                    value={edgeProperty.value}
                    onChange={changeValueHandler(edgeProperty)}
                />
                </span>           
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomButtonGroup}>
                <Button color="secondary" variant="contained" size="small" disabled={edgeproperties.length==1} value="delete Edge"
                    onClick={(e) => {
                        const deleteEdgeProfiles = [...edgeproperties]

                        if (edgeproperties.length > 1) {
                            const deleteEdgeProfiles1 = deleteEdgeProfiles.filter(edge => {
                                return edge.v_id != edgeProperty.v_id
                            });
                            setEdgeproperties(deleteEdgeProfiles1);
                        } else {
                            setEdgeproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }])
                        }
                    }}>Delete Edge</Button>
            </Grid>
        </Grid>;
    });
    const onSubmit = payload => {
        console.log(edgeproperties)
        payload.edgeproperties=edgeproperties;
        payload.isActive=true;
        //payload.parentcompanyid=parentcompanyid;
        payload.parentcompanyid=paramID;
        payload.type='CONFIG_TYPE';
        EdgeService.saveEdge(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Edge inserted successfully');
            history.push("/topics/edge")
          })
        .catch((error) => {
          alert("Edge error")                                      
           console.error('getEdge =>', error)
         }) 
    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Edge Details"
                    links={[{ title: 'Back', url: '/topics/edge' }]}></RycomPageHeader>
                <RycomSectionHeader title="Edge Overview Details"></RycomSectionHeader>
           
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="edgeName"
                            label="Edge Name"
                            name="edgename"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="edgedescription"
                            label="Edge Description"
                            name="edgedescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="edgedirection"
                            label="Edge Direction"
                            name="edgedirection"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge Direction is required'
                                }
                            }}
                        >
                           {edgeDirectionMenuList} 
                        </RycomSelectField>
                    </RycomGridItem>  
                </RycomGrid>
                <RycomSectionHeader title="Edge Properties"></RycomSectionHeader>
                {edgeList}

                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newEdgeProfile}>Add Edge</Button>
                        </span>
                    </Grid>
                </Grid>;
            
             <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                setEdgeproperties([])
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
