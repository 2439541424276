import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rycomGridItem: {
    backgroundColor: 'white'
  }
 
}));

export const RycomGrid = (props) =>{
    const classes = useStyles();
    return <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                  {props.children}
            </Grid>;

}
    
export const RycomGridItem = (props) =>{
    const classes = useStyles();

return <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
        {props.children}
  </Grid>;
}
