import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getVenueByCompanyId = (companyid) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/companyvenues/${companyid}` , addBearerToken());
}

export const getVenueById = (id) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/venue/${id}` , addBearerToken());
}

export const getVenueByParentCompanyId = (companyid) => {
    return axios.get(`${environment.orgBaseUrl}/api/org/allcompanyvenues/${companyid}`, addBearerToken());
}

export const saveVenue = (payload) => {
    return axios.post(`${environment.orgBaseUrl}/api/org/venue`,
               payload, addBearerToken()
                );
}