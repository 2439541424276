import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import * as OrganisationService from '../../../services/OrganisationService';
import { useParams } from 'react-router-dom'
import * as UserService from '../../../services/UserService';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));
  
export default function UserSelfDetail() {

    let { id } = useParams();
    const [userValues, setUserValues] =useState();
    const [permissions, setPermissions] =useState(null);
    let accessRolesIcon=null;
    const history = useHistory();
    const classes = useStyles();
    const [keycloak] = useKeycloak();


      if(permissions && permissions.length>0)
        accessRolesIcon = permissions.map(permission=> <Grid item xs={12} sm={6} lg={4} xl={2}>
                                                        <RycomInfoPair label="Permission Name" value={permission}></RycomInfoPair>
                                                        </Grid>);
    useEffect(() => {
        console.log(id)
        UserService.getUserById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setUserValues(res.data);
                    if(res.data && res.data.accessRoles && res.data.accessRoles.length>0)
                    setPermissions(res.data.accessRoles);
                }
            })
            .catch((error) => {
                console.error('getUserById =>', error)
            })
    }, [])

  
    const createResetButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                

                UserService.createActivateButton(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/user");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the User')
                        console.error('delete User =>', error)
                    })
            }}>Reset Password</Button>
        return DeleteButton;
    }
   
    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/user"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    <Link className={classes.buttonStyle} to={'/topics/user/selfedit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">Edit</Button>
    </Link>
  <span className={classes.buttonStyle} to="/topics/user">
        {createResetButton()}
    </span>
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;




    const orgContacts=(userValues && userValues.contact) ?
    (userValues.contact.map(contact=><Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Contact Type" value={contact ? contact.contactType : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Contact Value" value={contact ? contact.contactValue : ''}></RycomInfoPair>
                    </Grid>
                </Grid>
    )):<div>No Contact for this User</div>;
/*
    const metaDataProperties=userValues?
    userValues.metadata.map(metadata=><Grid container spacing={12} style={{ padding: 24 }}>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Key" value={metadata ? metadata.key : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Last Name" value={metadata ? metadata.value : ''}></RycomInfoPair>
    </Grid>
    </Grid>
    ):<div>No Metadata Properties for this Org</div>
*/
    return (<div>
            <Card className={classes.aocard}>
            <div className={classes.header}>
            <span className={classes.title} >
                {userValues ? 
                `User Details about ${userValues.firstName}` : 'Connector Details'} </span>
            <span className={classes.toolbar}>
                    {buttonLinks}
            </span>
            </div>
            </Card>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="organisation" value={userValues ? userValues.companyName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="First Name" value={userValues ? userValues.firstName : ''}></RycomInfoPair>
            </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
                 <RycomInfoPair label="Last Name" value={userValues ? userValues.lastName : ''}></RycomInfoPair>
                </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Email Address" value={userValues ? userValues.email : ''}></RycomInfoPair>
            </Grid>
        </Grid>
            <RycomSectionHeader title="User Contact Details"></RycomSectionHeader>
                {orgContacts}
    </div>
    );
}
