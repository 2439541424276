import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSelectField from '../common/RycomSelectField'
import RycomTextField from '../common/RycomTextField'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import RycomSubmitModel from '../common/RycomSubmitModel'
import * as SchedulerService from '../../services/SchedulerService';
import {cronValidator} from 'cron-expression-validator'
import RycomInfoPair from '../common/RycomInfoPair'



const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

  
}));

export default function SchedulerForm() {

    const history = useHistory();

    const defaultValues =  { 
       };


    const classes = useStyles();
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
     const [cronErrorMessage, setCronErrorMessage] =  useState(false);   


    function isCronValid(freq) {
      var cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
      return cronregex.test(freq);
    }

    const onSubmit = data => {
      //  if(!isCronValid(data.cronExpression))
      //  alert("Please enter Valid Cron Expression")
      // else{
        SchedulerService.saveScheduler(data).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Scheduler inserted successfully');
            history.push("/topics/scheduler")
          })
        .catch((error) => {
          alert("Scheduler error")                                      
           console.error('Scheduler =>', error)
         }) 
        //}
    }


    return (
        <div>  
        <form className={classes.root}  >
            <RycomPageHeader title="Enter the Scheduler Details"
                links={[{ title: 'Back', url: '/topics/scheduler' }]}></RycomPageHeader>

            <RycomSectionHeader title="Scheduler Form"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
               
            <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <RycomTextField id="schedulerName"
                        label="Scheduler Name"
                        name="schedulerName"
                        register={register}
                        errors={errors}
                        errorDescription={{
                            required: {
                              value: true,
                              message: 'Scheduler Name is required'
                            }
                          }}
                    />
                </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
                <RycomTextField id="schedulerDescription"
                    label="Scheduler Description"
                    name="schedulerDescription"
                    register={register}
                    errors={errors}
                    errorDescription={{
                        required: {
                          value: true,
                          message: 'Scheduler Description is required'
                        }
                      }}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
            <RycomTextField id="cronExpression"
                    label="Cron Expression"
                    name="cronExpression"
                    register={register}
                    errors={errors}
                    errorDescription={{
                        required: {
                          value: true,
                          message: 'Cron Expression is required'
                        }
                      }}
                />
               <span className={classes.invalidMessage}>
                {cronErrorMessage}
            </span>
            </Grid>
            </Grid>

        <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>

     </form >
     </div>
  );
}
/*
            <RycomSectionHeader title="Cron Examples"></RycomSectionHeader>

            <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
              <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 0 12 * * ?" value="Fire at 12pm (noon) every day"></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 15 10 ? * *" value="Fire at 10:15am every day"></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 0/5 14 * * ?" value="Fire every 5 minutes starting at 2pm and ending at 2:55pm, every day"></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 15 10 15 * ?" value="Fire at 10:15am on the 15th day of every month"></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 15 10 ? * *" value="Fire at 10:15am on the last day of every month"></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="0 11 11 11 11 ?" value="Fire every November 11th at 11:11am."></RycomInfoPair>
                </Grid>

                  </Grid>
            </Grid>          
*/