import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSelectField from '../common/RycomSelectField'
import RycomTextField from '../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import RycomSubmitModel from '../common/RycomSubmitModel'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';



import * as TaskService from '../../services/TaskService';
import * as DocumentEdgeService from '../../services/DocumentEdgeService';
import * as EdgeTaskService from '../../services/EdgeTaskService';
import * as SchedulerService from '../../services/SchedulerService';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import environment from '../../environment'


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    }


}));

export default function TaskEdgeForm() {

    const history = useHistory();

    /*
    const defaultValues =  { 
            "baserelationshipid": "string",
            "companyid": "string",
            "connectionid": "string",
            "createdbyuserid": "string",
            "datamapperid": "string",
            "datecreated": 0,
            "deviceid": "string",
            "isActive": true,
            "parentcompanyid": "string",
            "schedulerid": "string",
            "taskdescription": "string",
            "taskid": "string",
            "taskname": "string",
            "venueid": "string"
        };*/

    const defaultValues = {
    };
    const classes = useStyles();
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const [schedulerList, setSchedulerList] = useState([]);
    const [edgeList, setEdgeList] = useState([]);

    const userInfoJson = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoJson);
    const parentcompanyid = userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    /*
        //Fields explicitly for dynamic edge creation
private String edgeRelationshipId;	
private Boolean isComplete;
private Boolean isDaily;
private Boolean isByDate;
private String insertionDate;*/


    useEffect(() => {//
        getSchedulerList(parentcompanyid)
        //getEdgeList(parentcompanyid);
        getEdgeList(paramID);
    }, []);

    const [edgeTaskValue, setEdgeTaskValue] = React.useState('daily');
    const [dateValue, setDateValue] = React.useState();

    const handleChange = (event) => {
        setEdgeTaskValue(event.target.value);
    };

    const handleDateChange = (event) => {
        setDateValue(event.target.value);
        console.log(event.target.value)
    };
    const getEdgeList = (parentcompanyid) => {
        DocumentEdgeService.getAllEdge(parentcompanyid)
            .then((res) => {
                if (res && res.data && res.data) {
                    let edges = res.data;
                    setEdgeList(edges);
                }
            })
            .catch((error) => {
                console.error('getEdges =>', error)
                alert('Edge Retrieval Error');

            })
    }

    const getSchedulerList = () => {
        SchedulerService.getAllScheduler()
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res);
                    setSchedulerList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };


    const edgeMenuList = edgeList.map((relation, index) => <MenuItem key={++index} value={relation.edgeRelationshipId}> {relation.edgeName}</MenuItem>);
    const schedulerMenuList = schedulerList.map((scheduler, index) => <MenuItem key={++index} value={scheduler.id}> {scheduler.schedulerName}</MenuItem>);


    const onSubmit = data => {
        console.log(data);
        console.log(edgeTaskValue)
        console.log("edgeTaskValue")
        console.log(dateValue)

        //data['parentcompanyid'] = parentcompanyid;
        data['parentcompanyid'] = paramID;
        data['isComplete'] = false;
        data['isDaily'] = false;
        data['isByDate'] = false;
        data['isActive'] = true;

        if(edgeTaskValue === "complete"){
            data['isComplete'] = true;
        }else if(edgeTaskValue === "daily"){
            data['isDaily'] = true;
        }else if(edgeTaskValue === "byDate"){
            data['isByDate'] = true;
            data['insertionDate']=dateValue;
        }else if(edgeTaskValue === "edgeTagging"){
            data['isEdgeTagging'] = true;
        }else{
            alert("Please enter proper edge task type")
            return false;
        }

        EdgeTaskService.saveTaskEdge(data).then((res) => {
            console.log(res)
            if (res.status === 200)
                alert('Task inserted successfully');
            history.push("/topics/edgetask")
        })
            .catch((error) => {
                alert("Task error")
                console.error('Task =>', error)
            })
    }


    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Edge Task Details"
                    links={[{ title: 'Back', url: '/topics/task' }]}></RycomPageHeader>

                <RycomSectionHeader title="Edge Task Form"></RycomSectionHeader>
                <RycomGrid>

                    <RycomGridItem>
                        <RycomTextField id="taskname"
                            label="Task Name"
                            name="taskname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="edgeRelationshipId"
                            label="Edge"
                            name="edgeRelationshipId"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Edge is required'
                                }
                            }}
                        >
                            {edgeMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="schedulerid"
                            label="Scheduler"
                            name="schedulerid"
                            onChangeHandler={(event) => {
                                return event[0].target.value;

                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Scheduler is required'
                                }
                            }}
                        >
                            {schedulerMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>
                <RycomGrid>

                </RycomGrid>
                <RycomGrid>
                    <RycomGridItem>
                        <FormLabel component="legend">Edge Task Type</FormLabel>
                        <RadioGroup row aria-label="position" name="edgeTaskType" value={edgeTaskValue} onChange={handleChange} defaultValue="top">
                            <FormControlLabel
                                value="complete"
                                control={<Radio color="primary" />}
                                label="Complete Scan"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="daily"
                                control={<Radio color="primary" />}
                                label="Daily"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="byDate"
                                control={<Radio color="primary" />}
                                label="By Date"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="edgeTagging"
                                control={<Radio color="primary" />}
                                label="Edge Tagging"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </RycomGridItem>
                    {
                        edgeTaskValue=='byDate'?<RycomGridItem>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Insertion Date"
                            type="date"
                            name="insertionDate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDateChange}
                        />
                    </RycomGridItem>:""
                    }
                    
                </RycomGrid>
                <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                        onClick={() => {
                            reset();
                            setDateValue(undefined);
                            setEdgeTaskValue("daily")
                        }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
