import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomTextField from '../common/RycomTextField'
import RycomSelectField from '../common/RycomSelectField'
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {  useState } from 'react'
import { Grid, RadioGroup, FormControlLabel, Radio, FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RycomBrowseFile from '../common/RycomBrowseFile';
import * as ConnectorService from "../../services/ConnectorService";


const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    },
    radioLabel: {
      margin: "0 10px",
      display: "inline-flex",
      alignItems: "center",
      fontSize: "17px",
      fontWeight: "500"
    },
    rycomGridItem: {
      display:"flex"
    },
}));

const AuthenticationTypes = [
	"Password"
];

const EncryptionTypes = [
  {value:"pgpEncryption",label:"PGP Encryption"}
];

export default function ConnectorEditFTPConfiguration(props) {

  const classes = useStyles();
  const { connectorType, register, errors, control, connectorFlowType } = props
  console.log("Edit props")
  console.log(props)
  console.log(props.control.defaultValuesRef.current.connectionInfo.configuration.publicKeyAuthenticaiton)

  const configuration = props.control.defaultValuesRef.current.connectionInfo.configuration;

  const [authenticationType, setAuthenticationType] = useState(configuration.authenticationType != "" ? configuration.authenticationType : "");


  const suffix = 'recrusive'
  const pathLabel = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? 'Absoloute Directory Path' : 'Absolute File Path';
  
  const isRecursive = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? true : false;

  console.log("isRecursive")
  console.log(isRecursive)
  return (

    <div>
	{(connectorFlowType == "outbound" || connectorFlowType == "inbound") &&
      <>
      <RycomSectionHeader title="Server Information" />
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id="Host"
            label="host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Host is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="Port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Port is required'
              }
            }} />

        </RycomGridItem>
      </RycomGrid>

      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id={pathLabel}
            label={pathLabel}
            name="filePath"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: pathLabel + ' is required'
              }
            }} />
        </RycomGridItem>
        {!isRecursive && 
          <RycomGridItem>
            <RycomTextField id="fileName"
              label="File name"
              name="fileName"
              register={register}
			        // value={configuration.fileName}
              defaultValue={configuration.fileName == null ? "" : configuration.fileName}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: pathLabel + ' is required'
                }
              }} />
          </RycomGridItem>
        }
      </RycomGrid>

      <RycomSectionHeader title="Authentication Details " />
      <RycomGrid>
       <RycomGridItem>
          <RycomSelectField 
              id="authenticationType"
              label="Authentication Type"
              name="authenticationType"
              register={register}
              disabled="disabled"
              list={AuthenticationTypes}
              control={control}
              errors={errors}
              defaultValue={authenticationType}
          >
            {AuthenticationTypes.map((AuthType1, index) => (
              <MenuItem key={++index} value={AuthType1}>
                {AuthType1}
              </MenuItem>
            ))}
          </RycomSelectField>
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="User Name"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'User Name is required'
              }
            }} />
        </RycomGridItem>
        
        <RycomGridItem>
          <TextField
                id="outlined-multiline-flexible"
                label="Password*"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'password is required'
                  }})}
                name="password"
                type="password"/>
               <span className={classes.invalidMessage}>
                {errors['password'] && <span>{errors['password'].message}</span>}
            </span>
         </RycomGridItem>
      </RycomGrid>
	  </>
	}
	</div>
  );
}
