import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'


import { useParams } from 'react-router-dom'
import * as VenueService from '../../../services/VenueService';


export default function OrganisationVenueDetail() {

    let { id } = useParams();
    const [orgValues, setOrgValues] =useState();

    useEffect(() => {
        console.log(id)
        VenueService.getVenueById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setOrgValues(res.data);
                }
            })
            .catch((error) => {
                console.error('getVenueById =>', error)
            })
    }, [])



    /*const orgValues = {
        "venueaddress": {
            "address1": "data.address1",
            "address2":"data.address1",
            "address3": "data.address1",
            "city": "city",
            "country": "country",
            "state": "state",
            "zip": "zip"
          },
          "venuename": "venuename",
          "venuedescription":"venuedescription",
          "venuecontacts": [{
            "contacttype": "contacttype",
            "contactkey": "contactkey",
            "contactvalue": "contactvalue"
          }],
          "datecreated": 0,
          "isactive": true,
          "metadata": [],
          "parentcompanyid": "parentcompanyid",
          "companyid":"companyid"
    };*/

    const orgContacts=orgValues && orgValues.venuecontacts ?
    orgValues.venuecontacts.map(contact=><Grid container spacing={12} style={{ padding: 24 }}>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="First Name" value={contact ? contact.firstname : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Last Name" value={contact ? contact.lastname : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Email Address" value={contact ? contact.emailaddress : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Phone" value={contact ? contact.phone : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Contact Type" value={contact ? contact.contacttype : ''}></RycomInfoPair>
        </Grid>
    </Grid>
    ):<RycomInfoPair label="NO CONTACT AVAILABLE FOR THIS VENUE"/>

    const metaDataProperties=orgValues && orgValues.metadata?
    orgValues.metadata.map(metadata=><Grid container spacing={12} style={{ padding: 24 }}>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Key" value={metadata ? metadata.key : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Last Name" value={metadata ? metadata.value : ''}></RycomInfoPair>
    </Grid>
    </Grid>
    ):<RycomInfoPair label="NO METADATA AVAILABLE FOR THIS VENUE"/>

    
    return (<div>
        <RycomPageHeader title={orgValues ? `Venue Details about ${orgValues.venuename}` : 'Venue Details'} links={[{ title: 'Back', url: '/topics/company/venue' }, { title: 'Edit', url: '/topics/company/venue' }]}></RycomPageHeader>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Venu" value={orgValues ? orgValues.venuename : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Venue Description" value={orgValues ? orgValues.venuedescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Organisation" value={orgValues ? orgValues.companyName : ''}></RycomInfoPair>
            </Grid>
        </Grid>
        <div>
            <RycomSectionHeader title="Venue Address Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address1" value={orgValues && orgValues.venueaddress && orgValues.venueaddress.address1 ? orgValues.venueaddress.address1 : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address2" value={orgValues && orgValues.venueaddress && orgValues.venueaddress.address2 ? orgValues.venueaddress.address2 : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address3" value={orgValues && orgValues.venueaddress && orgValues.venueaddress.address3  ? orgValues.venueaddress.address3 : ''}></RycomInfoPair>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="City" value={orgValues  && orgValues.venueaddress && orgValues.venueaddress.city  ? orgValues.venueaddress.city : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="State" value={orgValues  && orgValues.venueaddress && orgValues.venueaddress.state ? orgValues.venueaddress.state : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Country" value={orgValues && orgValues.venueaddress && orgValues.venueaddress.country ? orgValues.venueaddress.country : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="zip" value={orgValues && orgValues.venueaddress && orgValues.venueaddress.zip ? orgValues.venueaddress.zip : ''}></RycomInfoPair>
                </Grid>
                </Grid>
                <RycomSectionHeader title="Venue Contact Details"></RycomSectionHeader>
                {orgContacts}
            <RycomSectionHeader title="MetaData Properties"></RycomSectionHeader>
                 {metaDataProperties}
        </div>
    </div>
    );
}
