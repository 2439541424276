import React, { useEffect, useState } from 'react';
import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import ProgressBar from '../../common/ProgressBar'
import { Link } from 'react-router-dom'
import * as DataMapperService from '../../../services/DataMapperService';
import environment from '../../../environment'


export default function DataMapperList() {

    const [dataMappers, setDataMappers] =useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(20);
  

    useEffect(() => {
      setLoading(true);
      let increamentedValue=progress
      const interval =  setInterval(function(){
        increamentedValue++;
        setProgress(increamentedValue);
      },500)
      console.log("indide the useefeect")
      const userInfoJson=localStorage.getItem("userInfo"); 
      const userInfo=JSON.parse(userInfoJson);
      const parentcompanyid=userInfo['parentcompanyid'];
      const companyid=userInfo['companyid'];
      const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

      // DataMapperService.getDataMapperByCompanyId(parentcompanyid,
      //   {
      //     onDownloadProgress: progressEvent => {
      //       const percentage = Math.round(
      //         (progressEvent.loaded * 100) / progressEvent.total
      //       );
      //       if (percentage === 100) {
      //         setProgress(100);
      //         clearInterval(interval);
      //         setTimeout(() => {
      //           setLoading(false);
      //         }, 500);
      //       }
      //     }
      //   })
      DataMapperService.getDataMapperByCompanyId(paramID,
        {
          onDownloadProgress: progressEvent => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentage === 100) {
              setProgress(100);
              clearInterval(interval);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            }
          }
        })  
        .then((res) => {
          if (res && res.data && res.data) {
            const dataMappersValue = res.data;
            console.log(res);
            setDataMappers(dataMappersValue);
          }
        })
        .catch((error) => {
          setLoading(false);
          setProgress(0);
          clearInterval(interval);
          console.error('dataMappers =>', error)
          alert('Data Mapper Retrieval Error');
        })
   }, [])

    const columns = [
      { id: 'datamappername', label: 'DataMapper Name', minWidth: 170 },
      { id: 'datamapperdescription', label: 'DataMapper Description', minWidth: 170 }
     
    ];
    
      const rows=dataMappers.map((dataMapper)=>{
        dataMapper.datamappername=<Link to={`/topics/datamapper/detail/${dataMapper.datamapperid}`} >{dataMapper.datamappername}</Link>
                                                     return dataMapper;
              });


      return (<div>
        <RycomPageHeader title="Data Mapper List" links={[{title:'Add DataMapper',url:'/topics/datamapper/form',role:"create:graph"}]}></RycomPageHeader>
        <RycomTableHeader title="Data Mapper" buttonTitle="Add DataMapper"></RycomTableHeader>
        {loading && <ProgressBar percentage={progress} />}

        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
}