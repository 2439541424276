import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSubmitModel from '../common/RycomSubmitModel'

import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import Button from '@material-ui/core/Button'

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import * as EdgeTaskService from '../../services/EdgeTaskService';
import * as DocumentEdgeService from '../../services/DocumentEdgeService';

import * as SchedulerService from '../../services/SchedulerService';
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'xx-large',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '5px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },  

    buttonStyle: {
      paddingRight: '5px',
    }

}));


export default function TaskDetail() {

    const history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    const [taskValues, setTaskValues] = useState();
    const [taskType, setTaskType] = useState();

    const [edgeDetails, setEdgeDetails] = useState({});

    const [jobStatus, setJobStatus] = useState('');
    const [timmingInfo, setTimmingInfo] = useState('');
    const [keycloak] = useKeycloak();

    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    useEffect(() => {
        console.log(id)
        EdgeTaskService.getTaskEdgeById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    setTaskValues(res.data);
                    const edgeTask = res.data;
                    console.log(JSON.stringify(edgeTask));
                    if(edgeTask){
                        getEdgeDetails(edgeTask.edgeRelationshipId)
                    }
                    let edgeTaskType='';
                    const taskDetails=res.data;
                    if(taskDetails.isComplete){
                        edgeTaskType='COMPLETE'
                    }else if(taskDetails.isDaily){
                        edgeTaskType='DAILY'
                    }else if(taskDetails.isByDate){
                        edgeTaskType='BY INSERTION DATE'
                    }
                    setTaskType(edgeTaskType);

                    const payload = {
                        "taskid": taskDetails.taskid,
                        "companyId": taskDetails.parentcompanyid,
                        "schedulerid": taskDetails.schedulerid
                    };

                    console.log("payload")
                    console.log(payload)

                    SchedulerService.getSchedulerJobDetails(payload)
                        .then((schedulerRes) => {
                            const edgeSchedule = schedulerRes.data;
                            console.log(edgeSchedule);
                            setJobStatus(edgeSchedule.jobStatus);
                            res.data.companyid= res.data.parentcompanyid;
                            res.data.schedulerStatus= edgeSchedule.jobStatus;
                            res.data.groupId= res.data.edgeRelationshipId;
                            res.data.cronExpression= edgeSchedule.cronExpression;
                            getTriggerDetails(res.data);
                        })
                        .catch((error) => {
                            console.error('getConnector =>', error)
                        })
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    }, []);

    const getEdgeDetails=(edgeRelationId)=>{
        DocumentEdgeService.getEdgeById(edgeRelationId)
        .then((res) => {
            if (res && res.data && res.data) {
                setEdgeDetails(res.data);
                console.log("edgeDetails");
                console.log(res.data);
             
            
              }
        })
        .catch((error) => {
            console.error('getConnector =>', error)
        })
    }

    

    const getTriggerDetails=(taskValues)=>{
        console.log('inside the trigger details', taskValues)
        const payload={
            scheduledTaskId: taskValues.taskid,
            schedulerId: taskValues.schedulerid,
            companyId:taskValues.companyid,
            cronExpression:taskValues.cronExpression,
            groupId: taskValues.groupId
            //cronExpression:"0 */2 * * * ?",
            // dataMapperId:taskValues.datamapperid,
        }
        //console.log(payload)
        if(taskValues.schedulerStatus!=='Not Triggered' && taskValues.schedulerStatus!=='paused'){
        SchedulerService.getTriggerDetails(payload).then((res) => {
            if (res && res.data && res.data) {
                console.log(res.data);
                const timmingDetails=res.data
                setTimmingInfo(timmingDetails?
                    <div>
                        <RycomSectionHeader title="Job Status"></RycomSectionHeader>
                        <Grid container spacing={12} style={{ padding: 24 }}>
                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="Start Time" value={timmingDetails ? timmingDetails.startTime : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="Previous Fire Time" value={timmingDetails ? timmingDetails.previousFireTime : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <RycomInfoPair label="Next Fire Time " value={timmingDetails ? timmingDetails.nextFireTime : ''}></RycomInfoPair>
                       </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="End Time" value={timmingDetails ? timmingDetails.endTime : ''}></RycomInfoPair>
                    </Grid>
               
                    </Grid>
                    </div>:'');

            }
        })
            .catch((error) => {
                alert('some error in starting the Job')
                console.error('startTask =>', error)
            })
        }else{
            setTimmingInfo('')
        }
       
    }

    const createStartJobButton = () => {
        const startButton = <Button className={classes.buttonStyle} color="primary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                SchedulerService.startEdgeScheduler(taskValues).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        taskValues.schedulerStatus='running';
                        alert('Job Successfully Started')
                        setJobStatus('running')
                        getTriggerDetails(taskValues)
                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Start Scheduler</Button>
        return startButton;
    };

    const createStopJobButton = () => {
        const startButton = <Button color="Red" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                const payload={
                    scheduledTaskId:taskValues.taskid,
                    companyId:taskValues.companyid,
                    groupId: taskValues.groupId
                }
                SchedulerService.stopScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Job Succesfully Stopped")
                        setJobStatus('Not Triggered')
                        taskValues.schedulerStatus='Not Triggered'
                        getTriggerDetails(taskValues)

                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Stop Scheduler</Button>
        return startButton;
    }

    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                EdgeTaskService.deleteTaskEdge(taskValues.taskid).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/edgetask");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Delete</Button>
        return DeleteButton;
    }

    

    const createPauseJobButton = () => {
        const startButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
              const payload={
                    scheduledTaskId:taskValues.taskid,
                    companyId:taskValues.companyid,
                    groupId: taskValues.groupId
                }
                SchedulerService.pauseScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('Job Successfully Paused')
                        setJobStatus('paused')
                        taskValues.schedulerStatus='paused'
                        getTriggerDetails(taskValues)
                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Pause Scheduler</Button>
        return startButton;
    }

    const createResumeJobButton = () => {
        const payload={
            scheduledTaskId:taskValues.taskid,
            companyId:taskValues.companyid,
            groupId: taskValues.groupId
        }
        const startButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                SchedulerService.resumeScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('successfully Resumed')
                        setJobStatus('running')
                        taskValues.schedulerStatus='running'
                        getTriggerDetails(taskValues)

                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Resume Scheduler</Button>
        return startButton;
    }
    const createManualButton = () => {
        const manualButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task Manually"
            onClick={(e) => {
                EdgeTaskService.executeTask(taskValues).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('Task Status Message:'+res.data.message);
                    }
                })
                    .catch((error) => {
                        alert('Task Error Message:'+error.message)
                        console.error('executeTask =>', error)
                    })
            }}>Trigger Task Manually</Button>
        return manualButton;
    }
    let links ="";
        if (jobStatus == 'Not Triggered') {

        console.log('inside the '+jobStatus)
         links =   <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
         <Grid item xs={12} sm={12} lg={8} xl={4} className={classes.rycomGridItem}></Grid>
           <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
              <span className={classes.buttonStyle}>  {createStartJobButton()}</span>
              <span className={classes.buttonStyle}>     {createManualButton()}</span>
              </Grid>
      </Grid> 

    } else if (jobStatus == 'running') {
        links = 
        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
           <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}></Grid>
             <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
             <span className={classes.buttonStyle}>  {createPauseJobButton()}</span>
                <span className={classes.buttonStyle}>  {createStopJobButton()}</span>
                <span className={classes.buttonStyle}>     {createManualButton()}</span>
                </Grid>
        </Grid>    
   }else if (jobStatus == 'paused') {
         links =  <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}></Grid>
             <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
             <span className={classes.buttonStyle}>  {createResumeJobButton()}</span>
              <span className={classes.buttonStyle}>  {createStopJobButton()}</span>
              <span className={classes.buttonStyle}>     {createManualButton()}</span>
              </Grid>
      </Grid>    
    } else { 
    // else if (jobStatus == 'stopped') {
         links = <RycomGrid>
            <RycomGridItem>
                {createManualButton()}
            </RycomGridItem>
        </RycomGrid>
     //   alert('Job for this Task has been stoped.')
    }
    console.log(jobStatus)
    console.log(links)

    const taskStatus=jobStatus?'Task Status:'+jobStatus:'Task Status';



    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/edgetask"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:task")?<Link className={classes.buttonStyle} to={'/topics/edgetask/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 
    {keycloak.hasRealmRole("delete:task")?<span className={classes.buttonStyle} to="/topics/edgetask">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;
       
  
    return (<div>

        <Card className={classes.aocard}>
            <div className={classes.header}>
            <span className={classes.title} >
            {taskValues ? `Edge Task Details: ${taskValues.taskname}` : 'Task Edge Details'} </span>
            <span className={classes.toolbar}>
            {buttonLinks}
            </span>
            </div>
        </Card>
        <RycomSectionHeader title={taskStatus}></RycomSectionHeader>

        {links}
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task" value={taskValues ? taskValues.taskname : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="From Vertex" value={edgeDetails ? edgeDetails.fromClassName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" To Vertex" value={edgeDetails ? edgeDetails.toClassName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Task Type" value={taskType ? taskType: ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="From Vertex" value={edgeDetails ? edgeDetails.fromPropertyName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" To Vertex" value={edgeDetails ? edgeDetails.toPropertyName : ''}></RycomInfoPair>
            </Grid>
        </Grid>
        {timmingInfo}

    </div>
    );
}
