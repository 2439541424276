import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import * as DocumentClassService from '../../services/DocumentClassService';
import RycomSelectField from '../common/RycomSelectField';
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import Button from '@material-ui/core/Button'
import ProgressBar from '../common/ProgressBar'

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import * as TaskService from '../../services/TaskService';
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'

import RycomTable from '../common/RycomTable'
import RycomTableHeader from '../common/RycomTableHeader'
import { useForm } from 'react-hook-form';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    }
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'xx-large',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '5px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },  

    buttonStyle: {
      paddingRight: '5px',
    },
    
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
    formControl: {
      margin: theme.spacing(5),
    }

}));


export default function TaskStatusDetail() {
    const history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    const [taskValues, setTaskValues] = useState();
    const [taskIdValues, setTaskIdValues] = useState("");
    const [vertexDetails, SetVertexDetails] = useState();
    const [selectedJob, setselectedJob] = useState(null);
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ });

    const [taskStatus, setTaskStatus] = useState();
    const [taskDetailStatus, setTaskDetailStatus] = useState([]);
    //const [filteredTaskStatus, setFilteredTaskStatus] = useState([]);

    let taskvalueTypes ="";

    useEffect(() => {
        console.log(id)
        setLoading(true);
      let increamentedValue=progress
      const interval =  setInterval(function(){
        increamentedValue++;
        setProgress(increamentedValue);
      },12000)
      loadTaskDetail(id, interval)
      //  return () => clearInterval(taskStatusInterval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);




    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(20);


    const loadTaskDetail = (id, interval)=> {
        TaskService.getTaskStatusDetail(id,
            {
                onDownloadProgress: progressEvent => {
                  const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  if (percentage === 100) {
                    setProgress(100);
                    clearInterval(interval);
                    setTimeout(() => {
                      setLoading(false);
                    }, 120000);
                  }
                }
              }
            ).then((res) => {
            if (res && res.data && res.data) {
             console.log("taskDetailStatus");
              console.log(res.data);
              if(res.data.taskDisplayModel?.vertexTypeId!=='')
              {
                  DocumentClassService.getVertexById(res.data.taskDisplayModel?.vertexTypeId)
                          .then((res) => {
                              if (res && res.data && res.data) {
                                  console.log("res.data")
                                  console.log(res.data)
                                  SetVertexDetails(res.data);
                              }
                          })
                          .catch((error) => {
                              console.error('getVertexById =>', error)
                          });
              }

             let taskStatusModel=res.data.taskStatusModel;
             let batchStatusModels=res.data.batchStatusRecords;

          /*
          const  batchStatusModelRecords=batchStatusModels.map(batchStatusModel=>{
              if(batchStatusModel.message=='Data Processing Completed Successfully' && batchStatusModel.numberOfRecordsRetrieved==0){
                batchStatusModel.batchCurrentStatus='Completed';
                batchStatusModel.message="Batch Process Completed with no records"
              }
              return batchStatusModel;
            });*/

            setTaskIdValues(res.data.taskStatusModel.taskId)
            setTaskStatus(taskStatusModel);
            setTaskValues(res.data.taskDisplayModel);   
            setTaskDetailStatus(batchStatusModels);

           

            if(batchStatusModels && taskStatusModel && batchStatusModels.length>0){
              const incompletedBatches=batchStatusModels.filter(
                batchStatusModel=>{
                  if(batchStatusModel.batchCurrentStatus=="Completed Successfully" || batchStatusModel.batchCurrentStatus=='Completed Successfully without Records'){
                    return false;
                  }else{
                    return true;
                  }
                });
              console.log('incompletedBatches',incompletedBatches);
              if(incompletedBatches!=null && incompletedBatches.length==0){
                taskStatusModel.setCompleted=true;
                const completedBatchStatusModels=batchStatusModels.filter(batchStatusModel=>batchStatusModel.batchCurrentStatus=="Completed Successfully");
                const sortedbatchList= completedBatchStatusModels.sort((a,b) => Date.parse(b.batchExecutionCompletedTime) - Date.parse(a.batchExecutionCompletedTime));
                //console.log('sortedbatchList',sortedbatchList);
                if(sortedbatchList && sortedbatchList.length>0){
                 taskStatusModel.taskExecutionCompletedTime=sortedbatchList[0].batchExecutionCompletedTime;
                 console.log('taskExecutionCompletedTime',taskStatusModel.taskExecutionCompletedTime);
                 setTaskStatus(taskStatusModel);
                 }
                }
              }
           }
        });
    }



    let filteredTaskStatus=taskDetailStatus;



    if(selectedJob!=null && selectedJob!="none"){
      console.log("selectedJob:"+selectedJob)
      if(selectedJob=='Completed Successfully'){
         filteredTaskStatus=taskDetailStatus.filter(batchStatusModel=>batchStatusModel.batchCurrentStatus=="Completed Successfully");
      }else if(selectedJob=='Completed without records'){
         filteredTaskStatus=taskDetailStatus.filter(batchStatusModel=>batchStatusModel.batchCurrentStatus=="Completed Successfully without Records");
      }else {
         filteredTaskStatus=taskDetailStatus.filter(
          batchStatusModel=>{
            if(batchStatusModel.batchCurrentStatus=="Completed Successfully" || batchStatusModel.batchCurrentStatus=='Completed Successfully without Records'){
              return false;
            }else{
              return true;
            }
          });
      }
   }else{
    filteredTaskStatus=taskDetailStatus;
   }


    const taskStatusName='Batch Status';
    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/task"><Button
    color="primary" variant="contained" size="medium">
    Back</Button></Link>
    <Button onClick={()=>loadTaskDetail(id)}
    color="primary" variant="contained" size="medium">
    Refresh</Button>
    </span>;

if(taskValues?.taskStorageType==="GraphConnector")
    {
        taskvalueTypes = <RycomInfoPair label="Vertex Type" value={vertexDetails ? vertexDetails.className : ''}></RycomInfoPair>
    }

  const taskStatusDetailsColumns = [
    { id: 'fileName', label: 'Batch Name', minWidth: 50 },
    { id: 'numberOfRecordsRetrieved', label: 'Retrieved Records', minWidth: 100 },
    { id: 'numberOfRecordsProcessed', label: 'Processed Records', minWidth: 100 },
    { id: 'numberOfRecordsStored', label: 'Stored Records', minWidth: 100 },
    { id: 'numberOfDuplicateRecords', label: 'Duplicate Records', minWidth: 100 },
    { id: 'numberOfHistoricalRecords', label: 'Historical Records', minWidth: 100 },
    { id: 'numberOfFailedRecords', label: 'Failed  Records', minWidth: 100 },
    { id: 'message', label: 'Message', minWidth: 50 },
    { id: 'batchCurrentStatus', label: 'Status', minWidth: 50 },
  ];


    return (<div>
        <Card className={classes.aocard}>
            <div className={classes.header}>
            <span className={classes.title} >
            {taskValues ? `Consolidated Task Status Details: ${taskValues.taskname}` : 'Consolidated Task Status Details'} </span>
            <span className={classes.toolbar}>
            {buttonLinks}
            </span>
            </div>
        </Card>
        {loading && <ProgressBar percentage={progress} />}
        <RycomSectionHeader title="Task Status Report"></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task" value={taskValues ? taskStatus.taskName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task Type" value={taskValues ? taskValues.taskType : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Organisation" value={taskValues ? taskValues.companyName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Retrieved Records" value={taskStatus ? taskStatus.totalNumberOfRecordsRetrieved : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Processed Records" value={taskStatus ? taskStatus.totalNumberOfRecordsProcessed : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Stored Records" value={taskStatus ? taskStatus.totalNumberOfRecordsStored : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Failed Records" value={taskStatus ? taskStatus.totalNumberOfFailedRecords : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Started Time" value={taskStatus ? taskStatus.taskExecutionStartedTime : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Completed Time" value={taskStatus ? taskStatus.taskExecutionCompletedTime : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Status" value={taskStatus ? taskStatus.currentJobStatus : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                {taskvalueTypes}
            </Grid>
        </Grid>

        <RycomGridItem>
                        <RycomSelectField id="jobstatus"
                            label="Job Status"
                            name="jobstatus"
                            onChangeHandler={(event) => {
                              if(event[0].target.value!=='none')
                              {
                              setselectedJob(event[0].target.value);
                                return event[0].target.value;
                              }else
                              {
                                setselectedJob(null);
                              }
                            }}
                            register={register}
                            control={control}
                            >
<MenuItem key="0" value="none">Select Task Status</MenuItem>
<MenuItem key="1" value="Completed Successfully">Batches Completed Successfully Records</MenuItem>
<MenuItem key="2" value="Completed without records">Batches Completed without Records</MenuItem>
<MenuItem key="3" value="Data Mapping Process Initiated">Incompleted Batches</MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>
                    
        <RycomTableHeader title={taskStatusName}></RycomTableHeader>
       

        <RycomTable rows={filteredTaskStatus} columns={taskStatusDetailsColumns}></RycomTable>
    </div>
    );


}
