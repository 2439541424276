import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import RycomTextField from '../common/RycomTextField'
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import RycomSelectField from '../common/RycomSelectField'
import { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'


const useStyles = makeStyles(theme => ({
  invalidMessage: {
    display: 'inline-block',
    fontSize: '12px',
    color: 'red',
    paddingLeft: '12px',
  }

}));

export default function InnerJsonMapper(props) {

  const classes = useStyles();

  const [primaryKey, setPrimaryKey] = React.useState(false);
  const [maintainHistoryValue, setMaintainHistoryValue] = React.useState(false);


  const setMetadatapropertiesContent = props.setMetadatapropertiesContent;
  const selectedInnerMapper = !!props.selectedInnerMapper? props.selectedInnerMapper.nestedJsonTypeConfig:[];

  const dataTimeZoneTypes = [
    "Etc/GMT",
    "America/New_York",
    "America/Denver",
    "America/Los_Angeles",
    "America/Halifax"
 ]
  console.log('setMetadatapropertiesContent', setMetadatapropertiesContent)
  console.log('inner json', selectedInnerMapper)

  let metadataProfile=[];
  if(selectedInnerMapper && selectedInnerMapper.length){
    metadataProfile = Object.values(selectedInnerMapper).map((curr, index) => { 
      console.log("curr");
      console.log(curr);
      return {
          'v_id': index+1,
          'key': curr.key,
          'mappedJsonKey': curr.mappedJsonKey,
          'valuetype': curr.valuetype,
          "markedAsPrimaryKey": curr.markedAsPrimaryKey,
          "maintainHistoricalRecord": curr.maintainHistoricalRecord,
          "transformationType":curr.transformationType,
          "transformationConfiguration":curr.transformationConfiguration
      }
    }, {});
  } else{
    metadataProfile = [{
      'v_id': 1,
      'key': null,
      'mappedJsonKey': null,
      'valuetype': null,
      "markedAsPrimaryKey": false,
      "maintainHistoricalRecord": false,
      "transformationType":null,
      transformationConfiguration:null
    }]
  }

  const [metadataproperties, setMetadataproperties] = useState(metadataProfile);

  const {
    register: register2,
    errors: errors2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2
  } = useForm({});

  const canAddNewMetadataProperties = () => {
    const length = metadataproperties.length - 1
    return metadataproperties[length].v_id
  };

  const newMetadataProfile = () => {
    let id = 1;
    if (metadataproperties.length > 0) {
      let lastKey = canAddNewMetadataProperties();
      id = ++lastKey;
    }

    const newMetadataProfile = [...metadataproperties, {
      'v_id': id,
      'key': null,
      'mappedJsonKey': null,

      'valuetype': null,
      "markedAsPrimaryKey": false,
      "maintainHistoricalRecord": false,
      "transformationType":null,
      transformationConfiguration:null

    }]
    setMetadataproperties(newMetadataProfile);
    //  setMetadatapropertiesContent(newMetadataProfile)

  }

  const deleteMetadataProfile = (index) => {
    const deleteProfiles = [...metadataproperties]
    if (deleteProfiles.length > 1) {
      deleteProfiles.splice(index, 1);
      setMetadataproperties(deleteProfiles);
      // setMetadatapropertiesContent(deleteProfiles)
    } else {
      setMetadataproperties([{
        'v_id': 1,
        'key': null,
        'valuetype': null,
        'mappedJsonKey': null,
        "markedAsPrimaryKey": false,
        "maintainHistoricalRecord": false,
        "transformationType":null,
        transformationConfiguration:null

      }])
    }
  };
  if (!metadataproperties.length) {
    deleteMetadataProfile()
  }

  const updateProperties=(metadatacontent)=>{
    const updateMetadataProperties = metadataproperties.map((metadata) => {
      if (metadatacontent.v_id == metadata.v_id) {
        metadata = metadatacontent;
      }
      return metadata;
    });
    setMetadataproperties(updateMetadataProperties);
    setMetadatapropertiesContent(updateMetadataProperties);
  }

  const methodPropertyKeyHandler = (metadatacontent, key) => {
    metadatacontent.key = key;
    updateProperties(metadatacontent);
  }

  const methodPropertyMappedKeyHandler = (metadatacontent, value) => {
    metadatacontent.mappedJsonKey = value;
    updateProperties(metadatacontent);
  }

  const methodPropertyValueHandler = (metadatacontent, value) => {
    metadatacontent.valuetype = value;
    updateProperties(metadatacontent);
  }

  const methodPropertyTransformationHandler = (metadatacontent, value) => {
    metadatacontent.transformationType = value;
    console.log(metadatacontent.transformationType )
    updateProperties(metadatacontent);
  }


  const methodPropertyTimeZoneHandler = (metadatacontent, value) => {
    metadatacontent.transformationConfiguration = {timeZone:value};
    console.log(metadatacontent.transformationType )
    updateProperties(metadatacontent);
  }

  const handlePrimaryKeyChange = (metadatacontent, value) => {
    metadatacontent.markedAsPrimaryKey = !primaryKey;
   updateProperties(metadatacontent);
    setPrimaryKey(!primaryKey)
  }

  const handleMaintainHistoryValue = (metadatacontent) => {
    metadatacontent.maintainHistoricalRecord = !maintainHistoryValue;
    updateProperties(metadatacontent);
    setMaintainHistoryValue(!maintainHistoryValue)
  }


  const metadataList = metadataproperties.map((metadata, index) => {
    metadata.id = index;
    return <div><Grid container spacing={12} style={{ padding: 2 }} className={classes.rycomGridItem}>
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
        <TextField
          id="outlined-multiline-flexible"
          label="Json Key"
          name="key"
          defaultValue={metadata.key}
          variant="outlined"
          style={{ width: 300 }}
          inputRef={register2({
            required: {
              value: true,
              message: 'Json Key is required'
            }
          })}
          onChange={(event) => {
            methodPropertyKeyHandler(metadata, event.target.value)
            return event.target.value
          }}
        />
        <span>
          <span className={classes.invalidMessage}>
            {errors2['key'] && <span>{errors2['key'].message}</span>}
          </span>
        </span>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
        <TextField
          id="outlined-multiline-flexible"
          label="Mapped Json Key"
          name="mappedJsonKey"
          defaultValue={metadata.mappedJsonKey}
          variant="outlined"
          style={{ width: 300 }}
          inputRef={register2({
            required: {
              value: true,
              message: 'Column Header is required'
            }
          })}
          onChange={(event) => {
            methodPropertyMappedKeyHandler(metadata, event.target.value)
            return event.target.value
          }}
        />
        <span>
          <span className={classes.invalidMessage}>
            {errors2['mappedJsonKey'] && <span>{errors2['mappedJsonKey'].message}</span>}
          </span>
        </span>
      </Grid>
      {primaryKey?<Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
        <span>
          <Controller
            as={
              <TextField
                id="valuetype"
                select
                label="Value Type"
                variant="outlined"
                name="valuetype"
              >
                <MenuItem key="1" value="STRING">STRING</MenuItem>
                <MenuItem key="2" value="NUMBER">NUMBER</MenuItem>
                <MenuItem key="3" value="BOOLEAN">BOOLEAN</MenuItem>
                <MenuItem key="4" value="jsonObject">JSON_OBJECT</MenuItem>
                <MenuItem key="5" value="arrayObject">ARRAY_OBJECT</MenuItem>
              </TextField>
            }
            rules={{
              required: {
                value: true,
                message: 'Value Type is required'
              }
            }}
            control={control2}
            name="valuetype"
            defaultValue={metadata.valuetype}
            style={{ width: 300 }}
            onChange={(event) => {
              methodPropertyValueHandler(metadata, event[0].target.value)
              return event[0].target.value
            }}
          />
          <span className={classes.invalidMessage}>
            {errors2["valuetype"] && <span>{errors2["valuetype"].message}</span>}
          </span>
        </span>
      </Grid>:""}
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                <span>
                                                    <Controller
                                                        as={
                                                            <TextField
                                                                id="transformationType"
                                                                select
                                                                label="Transformation Type"
                                                                variant="outlined"
                                                                name="transformationType"
                                                            >   
                                                                <MenuItem key="0" value="">Select Transformation Type</MenuItem>
                                                                <MenuItem key="1" value="CURRENCY">CURRENCY</MenuItem>
                                                                <MenuItem key="2" value="DATE">DATE</MenuItem>
                                                                <MenuItem key="3" value="BOOPROBABILITY_PERCENTAGELEAN">BOOLPROBABILITY PERCENTAGEEAN</MenuItem>
                                                            </TextField>
                                                        }
                                                        rules={{
                                                        }}
                                                        control={control2}
                                                        name="transformationType"
                                                        style={{ width: 300 }}
                                                        defaultValue={metadata && metadata.transformationType?metadata.transformationType:''}
                                                        onChange={(event) => {
                                                          methodPropertyTransformationHandler(metadata, event[0].target.value)
                                                          return event[0].target.value
                                                        }}
                                                    />
                                                    <span className={classes.invalidMessage}>
                                                        {errors2["transformationType"] && <span>{errors2["transformationType"].message}</span>}
                                                    </span>
                                                </span>
                                            </Grid>
                                            { metadata.transformationType =='DATE'?<Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                <span>
                        <Controller
                            as={
                                <TextField
                                    id="timeZone"
                                    select
                                    label="Time - Zone*"
                                    variant="outlined"
                                    name="timeZone"
                                >
                                 <MenuItem value="">Select Time Zone</MenuItem>
                        {dataTimeZoneTypes.map((dataTimeZoneTypes1, index) => (
                            <MenuItem key={++index} value={dataTimeZoneTypes1}>
                                {dataTimeZoneTypes1}
                            </MenuItem>   ))}
                                 
                                   </TextField>
                            }
                            control={control2}
                            name="timeZone"
                            defaultValue={metadata && metadata.transformationConfiguration && metadata.transformationConfiguration.timeZone ? metadata.transformationConfiguration.timeZone : ''}
                            style={{ width: 300 }}
                            onChange={(event) => {
                              methodPropertyTimeZoneHandler(metadata, event[0].target.value)
                              return event[0].target.value
                            }}
                        />
                        <span className={classes.invalidMessage}>
                            {errors2["timeZone"] && <span>{errors2["timeZone"].message}</span>}
                        </span>
                    </span>
                </Grid>:""}
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem} style={{ padding: 15 }}>
        <Button color="secondary" variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
          onClick={(e) => {
            const deleteProfiles = [...metadataproperties]

            if (metadataproperties.length > 1) {
              if (window.confirm('Are you sure you wish to delete this item?')) 
                {
                const deleteProfiles1 = deleteProfiles.filter(edge => {
                  return edge.v_id != metadata.v_id
                });
                setMetadataproperties(deleteProfiles1);
                setMetadatapropertiesContent(deleteProfiles1);
              }

            } else {
              setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'valuetype': null,
                'mappedJsonKey': null,
                "markedAsPrimaryKey": false,
                "maintainHistoricalRecord": false,
                "transformationType":null,
                transformationConfiguration:null
              }])
              setMetadatapropertiesContent([{
                'v_id': 1,
                'key': null,
                'valuetype': null,
                'mappedJsonKey': null,
                "markedAsPrimaryKey": false,
                "maintainHistoricalRecord": false,
                "transformationType":null,
                transformationConfiguration:null
              }]);
            }
          }}>Delete Record</Button>
      </Grid>
      
      
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
        <FormControlLabel
          style={{ width: 300 }}
          control={<Checkbox
            checked={metadata.markedAsPrimaryKey}
            onChange={
              (event) => {
                handlePrimaryKeyChange(metadata)
              }} name="flattening" />}
          label="Primary Key"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
        <FormControlLabel
          style={{ width: 300 }}
          checked={metadata.maintainHistoricalRecord}
          control={<Checkbox
            onChange={
              (event) => {
                handleMaintainHistoryValue(metadata)
              }} name="flattening" />}
          label="Maintain Historical Record"
        />
      </Grid>
    </Grid>
    </div>
  });

  return <div><RycomSectionHeader title="Inner Json Form"></RycomSectionHeader>
    {metadataList}
    <Grid container spacing={12} className={classes.rycomButtonGroup} >

      <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
      </Grid>
      <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
      </Grid>
      <Grid item xs={12} sm={4} lg={4} xl={4} >
        <span>
          <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMetadataProfile}>Add Inner Json Mapper</Button>
        </span>
      </Grid>
    </Grid></div>;

}
