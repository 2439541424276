import React, { Component } from 'react'
import RycomTable from '../common/RycomTable'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomTableHeader from '../common/RycomTableHeader'
import { Link } from 'react-router-dom'

class DataAnalystDashboard extends Component {
  render() {
    const taskValues = [ { 
      "taskId": "1234",
      "taskname": "Realestate-Data",
      "companyName": "Peter 134",
      "schedulerName":"Every Week Scheduler",
      "connectionName":"Peter 134 FTP Connector",
      "lastJobExecutedTime":"2020-05-23 14:45",
      "executionStartedStatus":"success"
      },{
        "taskId": "1234",
        "taskname": "Realestate-Data",
        "companyName": "Peter 134",
        "schedulerName":"Every Week Scheduler",
        "connectionName":"Peter 134 FTP Connector",
        "lastJobExecutedTime":"2020-05-23 14:45",
        "executionStartedStatus":"success"
      },{
        "taskId": "1234",
        "taskname": "Realestate-Data",
        "companyName": "Peter 134",
        "schedulerName":"Every Week Scheduler",
        "connectionName":"Peter 134 FTP Connector",
        "lastJobExecutedTime":"2020-05-23 14:45",
        "executionStartedStatus":"success"
    }]


    const queryValues = [ { 
      "taskId": "1234",
      "taskname": "Realestate-Data",
      "companyName": "Peter 134",
      "schedulerName":"Every Week Scheduler",
      "connectionName":"Peter 134 FTP Connector",
      "lastJobExecutedTime":"2020-05-23 14:45",
      "executionStartedStatus":"success"
      },{
        "taskId": "1234",
        "taskname": "Realestate-Data",
        "companyName": "Peter 134",
        "schedulerName":"Every Week Scheduler",
        "connectionName":"Peter 134 FTP Connector",
        "lastJobExecutedTime":"2020-05-23 14:45",
        "executionStartedStatus":"success"
      },{
        "taskId": "1234",
        "taskname": "Realestate-Data",
        "companyName": "Peter 134",
        "schedulerName":"Every Week Scheduler",
        "connectionName":"Peter 134 FTP Connector",
        "lastJobExecutedTime":"2020-05-23 14:45",
        "executionStartedStatus":"success"
    }]


    const columns = [
      { id: 'taskname', label: 'Task Name', minWidth: 170 },
      { id: 'companyName', label: 'Company', minWidth: 100 },
      { id: 'schedulerName', label: 'Scheduler', minWidth: 100 },
      { id: 'connectionName', label: 'Connection', minWidth: 100 },
      { id: 'lastJobExecutedTime', label: 'Last Job Executed Time', minWidth: 100 },
      { id: 'executionStartedStatus', label: 'Execution Started Status', minWidth: 100 }

    ];
    
      const rows=taskValues.map((task)=>{
             task.taskname=<Link to={`/task/detail/${task.taskId}`} >{task.taskname}</Link>
                                                     return task;
              });
      return (<div>
      </div>);
    } 
}
export default DataAnalystDashboard;
