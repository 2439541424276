import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';



const rootReducer = combineReducers({
    form: formReducer,
});

const store = createStore(rootReducer);

// function todos(state = [], action) {
//     switch (action.type) {
//       case 'ADD_TODO':
//         return state.concat([action.text])
//       default:
//         return state
//     }
// }  
//const store = createStore(todos, ['Use Redux']);

// store.dispatch({
//     type: 'ADD_TODO',
//     text: 'Read the docs'
// })
  
// console.log('store--------', store.getState())
// [ 'Use Redux', 'Read the docs' ]

/*
import Keycloak from 'keycloak-js';

//Get the keycloak configuration
let keycloak = Keycloak('./resources/keycloak.json');

//Initialization of the keycloak instance
keycloak.init({ onLoad: 'login-required' }).success((authenticated) => {
    console.log(keycloak);
    console.log(authenticated);
    // console.log(getState().keycloakLogin);
    if (!authenticated) {
        window.location.reload();
    } else {
        console.info("Authenticated");
    }

    //React Render on authentication
    ReactDOM.render(<App />, document.getElementById('root'));

    //store authentication tokens in sessionStorage
    sessionStorage.setItem('authentication', keycloak.token);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

    //to regenerate token on expiry
    setTimeout(() => {
        keycloak.updateToken(70).success((refreshed) => {
            if (refreshed) {
                console.debug('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).error(() => {
            console.error('Failed to refresh token');
        });


    }, 60000)

}).error((r) => {
    console.log(r)
    console.error("Authenticated Failed");
});
*/

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

serviceWorker.unregister();
