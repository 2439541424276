import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const getDataMapperById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/datamapper/${id}`, addBearerToken()
    );
}

export const getDataMapperByCompanyId = (companyId, config) => {
    //console.log("inside the getDataMapperByCompanyId")
    const token = localStorage.getItem('react-token')
    config.headers = { Authorization: `Bearer ${token}` }
    return axios.get(`${environment.dataBaseUrl}/api/dms/parentcompanydatamapper/${companyId}`, config);
}

export const getDataMapperByCompanyIdForTask = (companyId, config) => {
    //console.log("inside the getDataMapperByCompanyId Task")
    return axios.get(`${environment.dataBaseUrl}/api/dms/parentcompanydatamapper/${companyId}`, addBearerToken());

}


export const saveDataMapper = (datamapper) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/datamapper`,
        datamapper, addBearerToken()
    );
}

export const updateDataMapper = (datamapper) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/datamapper`,
        datamapper, addBearerToken()
    );
}

export const deleteDatamapper = (id) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/datamapper/delete/${id}`, {}, addBearerToken());
}


export const generateDatamapper = (mapperRequest,config) => {
    const token=localStorage.getItem('react-token')
    config['headers']=  { Authorization: `Bearer ${token}` } ;
    return axios.post(`${environment.dataBaseUrl}/api/dms/autogenerate/connector`,
        mapperRequest, config);
}
export const generateDatamapperFromSamplePayload = (mapperRequest) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/autogenerate/sample`,
        mapperRequest, addBearerToken()
    );
}

export const reviewDatamapper = (mapperRequest) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/autogenerate/review`,
        mapperRequest, addBearerToken()
    );
}


