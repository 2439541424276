import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
      }
    
}));

export default function RycomTextField(props) {
    const classes = useStyles();
    const { label, name, register, errors, errorDescription, value, placeholder, disabled, onChangeHandler, style,multiline,rows,rowsMax, defaultValue, type, InputProps} = props
    const labelWithcaption=errorDescription && errorDescription["required"] && errorDescription["required"].value?label+"*":label;
    return (
        <span>
           { value?
            <TextField
                id="outlined-multiline-flexible"
                label={labelWithcaption}
                variant="outlined"
                inputRef={register(errorDescription)}
                InputProps={InputProps}
                value={value?value:""}
                placeholder={placeholder}
                name={name}
                defaultValue={defaultValue}
                onChange={onChangeHandler}
                disabled={disabled}
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                type={type ? type : "text"}
                style = {style} />:
                <TextField
                id="outlined-multiline-flexible"
                label={labelWithcaption}
                variant="outlined"
                defaultValue={defaultValue}
                InputProps={InputProps}
                inputRef={register(errorDescription)}
                placeholder={placeholder}
                name={name}
                onChange={onChangeHandler}
                disabled={disabled}
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                type={type ? type : "text"}
                style = {style} />
           } 
            <span className={classes.invalidMessage}>
                {errors[name] && <span>{errors[name].message}</span>}
            </span>
        </span>
    );
}
