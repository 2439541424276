import Keycloak from 'keycloak-js'
import environment from '../environment'


const keycloakConfig = {
   url: environment.url, 
   realm: environment.realm, 
   clientId: environment.clientId
}
/*

const keycloakConfig = {

   url: 'http://localhost:8080/auth', 
   realm: 'testrealm', 
   clientId: 'testclient'
}*/
console.log(keycloakConfig)
const keycloak = new Keycloak(keycloakConfig);
export default keycloak