import React from 'react';
import { useEffect, useState } from 'react'
import * as SchedulerService from '../../services/SchedulerService';
import SchedulerEditTemplate from './SchedulerEditTemplate';
import { useParams } from 'react-router-dom'


export default function SchedulerEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
        SchedulerService.getSchedulerById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.id)?
        <SchedulerEditTemplate defaultValues={defaultValues}></SchedulerEditTemplate>:"";
  
}
