import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomTextField from '../common/RycomTextField'
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Grid from '@material-ui/core/Grid';

import { Controller } from 'react-hook-form'


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    position: 'absolute',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'auto',
    overflow: 'scroll',
  },
  invalidMessage: {
    display: 'inline-block',
    fontSize: '12px',
    color: 'red',
    paddingLeft: '12px',
  },
  pt4: {
    padding: "20px 0 0 0"
  }
}));

export default function ConnectorLoginCredentialModal(props) {

  console.log("inside the ConnectorLoginCredentialModalfunction")

  const classes = useStyles();
  const { connectorLoginCredential, handleOpen, handleClose, handleLoginCredentialModalSubmit } = props;
  const [responseChannel, setResponseChannel] = useState(connectorLoginCredential.responseChannel);
  const [tokenType, setTokenType] = useState(connectorLoginCredential.tokenType);

  const [defaultValues,setDefaultValues]=useState(connectorLoginCredential)

  console.log("ConnectorLoginCredentialModal default value")
  console.log(defaultValues)
  const { register, errors, reset, watch, control, handleSubmit } = useForm({ defaultValues });


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={handleOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={handleOpen}>
        <div className={classes.paper}>
          <div>
         {/* some value {JSON.stringify(defaultValues, null, 2)} */}
            <form key={2} className={classes.root} >
              {<span> <RycomSectionHeader title="Log in API Details"></RycomSectionHeader>
                

              <Grid container spacing={12} className={classes.pt4} >
                  <Grid item xs={12} sm={6} lg={6} xl={6} >
                  <RycomTextField id="url"
                      label="url"
                      name="url"
                      value={defaultValues.url}
                      defaultValue={defaultValues.url}
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.url=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'URL is required'
                        }
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} xl={6} >
                  <RycomTextField id="tokenAttribute"
                      label="Token Attribute Key"
                      name="tokenAttribute"
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      value={defaultValues.tokenAttribute}
                      errorDescription={{
                        
                      }}
                      defaultValue={defaultValues.tokenAttribute}
                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.tokenAttribute=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }} 
                      />
                  </Grid>
                </Grid>
               
              <Grid container spacing={12}  className={classes.pt4} >
                <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <RycomTextField id="usernameKey"
                      label="Username Attribute"
                      name="usernameKey"
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      value={defaultValues.usernameKey}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'Username Key is required'
                        }
                      }}
                      defaultValue={defaultValues.usernameKey}
                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.usernameKey=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }}  />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <RycomTextField id="username"
                      label="Username"
                      name="username"
                      value={defaultValues.username}
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'Username is required'
                        }
                      }}
                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.username=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }}  />
                  </Grid>
                </Grid>
                <Grid container spacing={12}  className={classes.pt4} >  
                  <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <RycomTextField id="passwordKey"
                      label="Password Attribute"
                      name="passwordKey"
                      value={defaultValues.passwordKey}
                     style={{width: '94%'}}
                      register={register}
                      errors={errors}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'Password Key is required'
                        }
                      }}
                      defaultValue={defaultValues.passwordKey}

                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.passwordKey=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <RycomTextField id="password"
                      label="Password"
                      name="password"
                      value={defaultValues.password}
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'Password is required'
                        }
                      }} 
                      defaultValue={defaultValues.password}

                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.password=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }} 
                      />
                  </Grid>
                </Grid>
                <Grid container spacing={12} className={classes.pt4} >
                    <Grid item xs={12} sm={12} lg={12} xl={12} >
                      <FormLabel component="legend">Request type</FormLabel>
                      <RadioGroup row
                        defaultValue={defaultValues.reqType?defaultValues.reqType:'body'}
                        aria-label="reqType" 
                        name="reqType" 
                        register={register}
                        errors={errors}
                        errorDescription={{
                          required: {
                            value: true,
                            message: 'Request type is required'
                          }
                        }}
                      >
                        <FormControlLabel value="form-multipart" control={<Radio />} label="Form-multipart" />
                        <FormControlLabel value="body" control={<Radio />} label="body" />
                      </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={12} className={classes.pt4} >
                  <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <span>
                      <Controller
                        as={
                          <TextField
                            id="responseChannel"
                            select
                            label="Response Channel"
                            variant="outlined"
                            name="responseChannel"
                            defaultValue={""}

                          >
                            <MenuItem key="1" value="HEADER">HEADER</MenuItem>
                            <MenuItem key="2" value="COOKIE">COOKIE</MenuItem>
                          </TextField>
                        }
                        rules={{
                          required: {
                            value: true,
                            message: 'Response Channel is required'
                          }
                        }}
                        value={defaultValues.responseChannel}
                        onChange={(event) => {
                          setResponseChannel(event[0].target.value)
                          const newDefaultValue={...defaultValues};
                          newDefaultValue.responseChannel=event[0].target.value;
                          setDefaultValues(newDefaultValue)
                          return event[0].target.value
    
                        }}
                        control={control}
                        name="responseChannel"
                        defaultValue={""}
                        style={{ width: '97%' }}
                      />
                      <span className={classes.invalidMessage}>
                        {errors["responseChannel"] && <span>{errors["responseChannel"].message}</span>}
                      </span>
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={12} className={classes.pt4} >
                  {responseChannel == 'HEADER'? <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <span>
                      <Controller
                        as={
                          <TextField
                            id="tokenType"
                            select
                            label="Token Type"
                            variant="outlined"
                            name="tokenType"
                            value={tokenType}
                          >
                            <MenuItem key="1" value="api-key">API Key</MenuItem>
                            <MenuItem key="2" value="bearer">Bearer Token</MenuItem>
                            <MenuItem key="3" value="basic">Basic Authentication</MenuItem>
                            <MenuItem key="4" value="other">Other</MenuItem>

                          </TextField>
                        }
                        rules={{
                          required: {
                            value: true,
                            message: 'Token Type is required'
                          }
                        }}
                        value={tokenType}
                        onChange={(event) => {
                          setTokenType(event[0].target.value)
                          const newDefaultValue={...defaultValues};
                          newDefaultValue.tokenType=event[0].target.value;
                          setDefaultValues(newDefaultValue)
                          return event[0].target.value
                        }}
                        control={control}
                        name="tokenType"
                        defaultValue={""}
                        style={{ width: '95%' }}
                      />
                      <span className={classes.invalidMessage}>
                        {errors["tokenType"] && <span>{errors["tokenType"].message}</span>}
                      </span>
                    </span>
                  </Grid>:""}

                  {(responseChannel == 'COOKIE' || tokenType == 'other')? <Grid item xs={12} sm={6} lg={6} xl={6} >
                    <RycomTextField id="keyName"
                      label="Key Name"
                      name="keyName"
                      style={{ width: '94%' }}
                      register={register}
                      errors={errors}
                      errorDescription={{
                        required: {
                          value: true,
                          message: 'Key Name is required'
                        }
                      }} 
                      value={defaultValues.keyName}
                      onChangeHandler={(event) => {
                        const newDefaultValue={...defaultValues};
                        newDefaultValue.keyName=event.target.value;
                        setDefaultValues(newDefaultValue)
                      }} 
                      />
                  </Grid>:""}
                </Grid>
                



                <Grid container spacing={12} className={classes.pt4} >
                  <Grid item xs={12} sm={7} lg={7} xl={7} ></Grid>
                  <Grid item xs={12} sm={5} lg={5} xl={5} >
                    <div className={classes.rycomButtonGroup}>
                      <Button type="reset" variant="" size="large"
                        onClick={() => reset({
                          "username": "",
                          "password": "",
                          "responseChannel": "",
                          "tokenType": "",
                          "keyName": ""
                        })}>Clear</Button>
                      <Button type="button" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(handleLoginCredentialModalSubmit)}>Submit</Button>
                    </div>
                  </Grid>
                </Grid>
              </span>}
            </form >
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
