import React, { useEffect, useState } from 'react';

import RycomTable from '../common/RycomTable'
import ProgressBar from '../common/ProgressBar'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomTableHeader from '../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as EdgeTaskService from '../../services/EdgeTaskService';
import environment from '../../environment'


export default function TaskList (props) {
  
    const [tasks, setTasks] = useState([]);
    const [edgeTaskStatus, setEdgeTaskStatus] = useState([]);

    useEffect(() => {
      const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //EdgeTaskService.getAllTaskEdge(parentcompanyid)
    EdgeTaskService.getAllTaskEdge(paramID)
      .then((res) => {
          if (res && res.data && res.data) {
            const tasks = res.data;
            setTasks( tasks );
          }
        })
        .catch((error) => {
          console.error('Task =>', error)
          alert('Task Retrieval Error');
        })


        const taskStatusInterval = setInterval(() => {
          EdgeTaskService.getTaskEdgeStatus().then((res) => {
            if (res && res.data && res.data) {
              const taskStatus = res.data;
              setEdgeTaskStatus( taskStatus );
              console.log("inside the taskStatusInterval")
            }
          });
        }, 5000);

        return () => clearInterval(taskStatusInterval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, []);

  
    const columns = [
      { id: 'taskname', label: 'Edge Task Name', minWidth: 170 },
      { id: 'taskType', label: 'Task Type', minWidth: 100 },
    ];

    const edgeTaskStatusColumns = [
      { id: 'edgeTaskName', label: 'Edge Task Name', minWidth: 170 },
      { id: 'executionStartedTime', label: 'Task Started Time', minWidth: 100 },
      { id: 'executionCompletedTime', label: 'Task Completed Time', minWidth: 170 },
      { id: 'status', label: 'Completed', minWidth: 100 },
      { id: 'totalNumberOfSourceEdgeCountRetrieved', label: 'Retrieved Source Edge Count', minWidth: 100 },
      { id: 'totalNumberOfSourceEdgeCount', label: 'Source Edge Count', minWidth: 100 },
      { id: 'message', label: 'Message', minWidth: 100 }
    ];
  





      const rows=tasks.map((task)=>{
             task.taskname=<Link to={`/topics/edgetask/detail/${task.taskid}`} >{task.taskname}</Link>
                let taskType="";
              if(task.isComplete){
                taskType='COMPLETE'
              }else if(task.isDaily){
                taskType='DAILY'
              }else if(task.isByDate){
                taskType='BY INSERTION DATE'
              }
              task.taskType=taskType;

              if(task.status){
                task.status="TRUE"
              }else{
                task.status="FALSE"
              }

              return task;
              });
      console.log(edgeTaskStatus)

      


      return (<div>
        <RycomPageHeader title="Edge Task List" links={[{title:'Add Edge Creation Task',url:'/topics/edgetask/form',role:"create:task"}]}></RycomPageHeader>
        <RycomTableHeader title="Task" buttonTitle="Add Edge Task"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>

        <RycomTableHeader title="Executed Edge Task Status Details" buttonTitle="Add Edge Task"></RycomTableHeader>
        <RycomTable rows={edgeTaskStatus} columns={edgeTaskStatusColumns}></RycomTable>

      </div>);
    
}
