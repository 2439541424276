import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import UserSelfEditTemplateForm from './UserSelfEditTemplateForm';
import { useParams } from 'react-router-dom'
import * as UserService from '../../../services/UserService';



export default function UserSelfEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
        UserService
         .getUserById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.userid)?
        <UserSelfEditTemplateForm defaultValues={defaultValues}></UserSelfEditTemplateForm>:"";
  
}
