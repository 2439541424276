import React, { useEffect } from "react";
import RycomSectionHeader from "../common/RycomSectionHeader";
import RycomTextField from "../common/RycomTextField";
import RycomSelectField from "../common/RycomSelectField";
import RycomSelectSmallWidth from "../common/RycomSelectSmallWidth";
import MenuItem from "@material-ui/core/MenuItem";
import { RycomGrid, RycomGridItem } from "../common/RycomGrid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, useTheme, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Controller, useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import HistoriaclOffsetDetails from "../common/HistoricalOffsetDetails";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  rycomGridItem: {
    backgroundColor: "white",
    width: "100%",
  },
  invalidMessage: {
    display: "inline-block",
    fontSize: "12px",
    color: "red",
    paddingLeft: "12px",
  },
  paddingStyle: {
    paddingTop: "10px !important",
    paddingLeft: "30px !important",
    paddingBottom: "0px !important",
    paddingRight: "30px !important",
  },
  paddingEndDate: {
    paddingTop: "10px !important",
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "30px !important",
    float: "left",
    width: "300px",
  },
  paddingStartDate: {
    paddingTop: "10px !important",
    paddingLeft: "30px !important",
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
    float: "left",
    width: "300px",
  },
}));
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f1592a",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});




export default function ConnectorEditJDBCConfiguration(props) {
  let defaultValues = props.defaultValues;
  const [show, setHide] = useState(true);
  const classes = useStyles();
  const [dataStoreType, setDataStoreType] = useState(
    defaultValues.dataStoreType
  );
  const [dataDirectionType, setDataDirectionType] = useState(
    defaultValues.historyOffsetDirectionType
  );
  // const [dataUnitType, setDataUnitType] = useState(
  //   defaultValues.historyDurationType
  // );



 
  const { connectorType, connectorFlowType, register, errors, control, multiline, rows, value } =
    props;

  const dataDirectionTypes = ["last", "next"];
  const dataUnitTypes = ["minutes","hours","days","weeks","months","years"];
  const [dataUnitType, setUnitType] = useState(
    defaultValues.historyDurationType
  );

  console.log('connectorFlowType',connectorFlowType);

  console.log('propsValues HIstorical Record',props);
  const [unlockUnitType,setUnlockUnitType] = useState(true);
  const [unlockOffestduration,setUnlockOffestduration] = useState(true);
  const [unlockOffsetUnitType,setUnlockOffsetUnitType] = useState(true);
  const [durationMilisecondCheck, setDurationMilisecondCheck] = useState();
  const [durationCheck, setDurationCheck] = useState();
  const [durationCheck2, setDurationCheck2] = useState();
  const [offSetDurationCheck, setOffSetDurationCheck] = useState(false);
  const [unitCheck, setUnitCheck] = useState();
  const [unitCheck2, setUnitCheck2] = useState();
  const [offSetUnitCheck, setOffsetUnitCheck] = useState(false);
  const [unitvalidationLast,setUnitvalidationLast] = useState();

  const durationValidationChk = (value) => {
    let timeToMilisecondsTwo = 0;
    if (unitCheck2 !== undefined || unitCheck2 !== null) {
      if (unitCheck2 === "days") {
        timeToMilisecondsTwo = parseInt(value) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (unitCheck2 === "minutes") {
        timeToMilisecondsTwo = parseInt(value) * 60000; // minuts to milliseconds
      }
      if (unitCheck2 === "hours") {
        timeToMilisecondsTwo = parseInt(value) * 60000 * 60; // horus to milliseconds
      }
      if (unitCheck2 === "weeks") {
        timeToMilisecondsTwo = parseInt(value) * 604800000; // days to milliseconds
      }
      if (unitCheck2 === "months") {
        timeToMilisecondsTwo = parseInt(value) * 2629800000; // days to milliseconds
      }
      if (unitCheck2 === "years") {
        timeToMilisecondsTwo = parseInt(value) * 31556952000; // days to milliseconds
      }
    }
    if (
      durationMilisecondCheck !== undefined ||
      durationMilisecondCheck !== null
    ) {
      if (durationMilisecondCheck < timeToMilisecondsTwo) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  };
  const durationValidationChkPart2 = (value) => {
    let timeToMiliseconds = 0;
    if (unitCheck !== undefined || unitCheck !== null) {
      if (unitCheck === "days") {
        timeToMiliseconds = parseInt(value) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (unitCheck === "minutes") {
        timeToMiliseconds = parseInt(value) * 60000; // minuts to milliseconds
      }
      if (unitCheck === "hours") {
        timeToMiliseconds = parseInt(value) * 60000 * 60; // horus to milliseconds
      }
      if (unitCheck === "weeks") {
        timeToMiliseconds = parseInt(value) * 604800000; // days to milliseconds
      }
      if (unitCheck === "months") {
        timeToMiliseconds = parseInt(value) * 2629800000; // days to milliseconds
      }
      if (unitCheck === "years") {
        timeToMiliseconds = parseInt(value) * 31556952000; // days to milliseconds
      }
    // setDurationMilisecondCheck(timeToMiliseconds);
    if (
      unitvalidationLast !== undefined ||
      unitvalidationLast !== null
    ) {
      if (timeToMiliseconds < unitvalidationLast) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  }
  };

  const unitValidationChk = (value) => {

    let millisecondsToAdd2 = 0;
    if (durationCheck2 !== undefined || durationCheck2 !== null) {
      if (value === "days") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (value === "minutes") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 60000; // minuts to milliseconds
      }
      if (value === "hours") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 60000 * 60; // horus to milliseconds
      }
      if (value === "weeks") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 604800000; // days to milliseconds
      }
      if (value === "months") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 2629800000; // days to milliseconds
      }
      if (value === "years") {
        millisecondsToAdd2 = parseInt(durationCheck2) * 31556952000; // days to milliseconds
      }
      setUnitvalidationLast(millisecondsToAdd2);
    }
    if (
      durationMilisecondCheck !== undefined ||
      durationMilisecondCheck !== null
    ) {
      if (durationMilisecondCheck < millisecondsToAdd2) {
        setOffsetUnitCheck(true);
      } else {
        setOffsetUnitCheck(false);
      }
    }
  };

  const unitValidationChk2 = (value) => {
    let millisecondsToAdd = 0;
    if (durationCheck !== undefined || durationCheck !== null) {
      if (value === "days") {
        millisecondsToAdd = parseInt(durationCheck) * 24 * 60 * 60 * 1000; // days to milliseconds
      }
      if (value === "minutes") {
        millisecondsToAdd = parseInt(durationCheck) * 60000; // minuts to milliseconds
      }
      if (value === "hours") {
        millisecondsToAdd = parseInt(durationCheck) * 60000 * 60; // horus to milliseconds
      }
      if (value === "weeks") {
        millisecondsToAdd = parseInt(durationCheck) * 604800000; // days to milliseconds
      }
      if (value === "months") {
        millisecondsToAdd = parseInt(durationCheck) * 2629800000; // days to milliseconds
      }
      if (value === "years") {
        millisecondsToAdd = parseInt(durationCheck) * 31556952000; // days to milliseconds
      }
    setDurationMilisecondCheck(millisecondsToAdd);
  }
  if (
    unitvalidationLast !== undefined ||
    unitvalidationLast !== null
  ) {
    if (unitvalidationLast > millisecondsToAdd) {
      setOffsetUnitCheck(true);
    } else {
      setOffsetUnitCheck(false);
    }
  }
  };



  const [checked, setChecked] = useState(true);
  const [checkedDate, setCheckedDate] = useState(false);

  const [selectedDate, setHandleDateChange] = useState(null);
  const [selectedEndDate, setHandleEndDate] = useState(null);
  const [initStartDate, setInitStartDate] = useState(
    props.defaultValues.fetchStartDateString
  );
  const [initEndDate, setInitEndDate] = useState(
    props.defaultValues.fetchEndDateString
  );
  const [show2, setHide2] = useState(props.defaultValues.frequency?true:false);
  const [initDefaultDate, setInitDefaultDate] = useState(null);
  const toggleHide = () => {
     setHide((oldState) => !oldState);
    setHide2(false);
    setCheckedDate(false);
    setChecked(false);
  };

 

  const toggleHide2 = () => {
    setHide2((oldState) => !oldState);
    setHide(false);
    setCheckedDate(false);
    setChecked(false);
  };

  useEffect(() => {
    setDataStoreType(props.defaultValues.dataStoreType);
   // setDataDirectionType(props.defaultValues.historyOffsetDirectionType);
  
    if (props.defaultValues.fetchOffsetType !== "customDate") {
      //console.log('initDefaultDate=====',initDefaultDate);
      setHide(false);
    } else {
      setInitDefaultDate(null);
    }
  }, []);

  const setDateValid = (date) => {

    
   var dateformt= selectedDate ||
    (initStartDate !== null
      ? new Date(initStartDate * 1000)
      : null || initDefaultDate);


    var milliseconds1 = dateformt.getTime();
    var milliseconds = date.getTime();
    if (milliseconds1 > milliseconds) {
      setCheckedDate(true);
    } else {
      setCheckedDate(false);
    }
    setHandleEndDate(date);
    
  };

  const setDateValid2 = (date) => {
   var dateformt2 = selectedEndDate ||
    (initEndDate !== null
      ? new Date(initEndDate * 1000)
      : null || initDefaultDate)
    if(dateformt2!==null){
    var milliseconds1 = dateformt2.getTime();
      var milliseconds = date.getTime();
      if (milliseconds1 < milliseconds) {
        setCheckedDate(true);
      } else {
        setCheckedDate(false);
      }
  }
  };
  let showStartDate;
  let showEndDate;
  showStartDate = props.defaultValues.fetchStartDateString;
  showEndDate = props.defaultValues.fetchEndDateString;
  const ShowSDate = new Date(showStartDate * 1000);
  const ShowEDate = new Date(showEndDate * 1000);
  const theme = useTheme();

  console.log(
    "converted date value",
    new Date(props.defaultValues.fetchStartDateString * 1000)
  );

  const dataStoreTypes = ["query", "table"];
  const showdownsm = useMediaQuery(theme.breakpoints.down("sm"))
    ? "100%"
    : null;
  const showUpsm = useMediaQuery(theme.breakpoints.up("sm")) ? "100%" : "100%";
  const showDownmd = useMediaQuery(theme.breakpoints.down("md")) ? "80%" : null;
  const showUpmd = useMediaQuery(theme.breakpoints.up("md")) ? "100%" : null;
  const showDownlg = useMediaQuery(theme.breakpoints.down("lg"))
    ? "100%"
    : null;
  const showUplg = useMediaQuery(theme.breakpoints.up("lg")) ? "100%" : null;
  const showdownxl = useMediaQuery(theme.breakpoints.only("xl"))
    ? "100%"
    : null;

  console.log(
    "inside ConnectorJDBCConfiguration",
    props.defaultValues.fetchStartDateString
  );
  console.log(props);

  console.log("initDefaultDate=======", initStartDate);

  const connectionHeader=connectorType+" Connection Details";

  let dataStoreTypeValue = null;
  if (dataStoreType !== undefined && dataStoreType === "query") {
    dataStoreTypeValue = (
      <RycomTextField
        id="query"
        label="Query"
        name="query"
        multiline={true}
        rows={2}
        rowsMax={4}
        style={{ width: showdownsm || showDownmd || showDownlg || showdownxl }}
        register={register}
        errors={errors}
        dataStoreType={dataStoreType}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 12);
        }}
        errorDescription={{
          required: {
            value: true,
            message: "Query Name is required",
          },
        }}
      />
    );
  }
  if (dataStoreType !== undefined && dataStoreType === "table") {
    dataStoreTypeValue = (
      <RycomTextField
        id="dataStoreName"
        label="Data Store Name"
        name="dataStoreName"
        register={register}
        errors={errors}
        dataStoreType={dataStoreType}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 12);
        }}
        errorDescription={{
          required: {
            value: true,
            message: "Data Store Name is required",
          },
        }}
      />
    );
  }

  const getMenuItems = () => {
    return dataStoreTypes.map((dataStoreType1, index) => {
      return dataStoreType1 == props.defaultValues.dataStoreType ? (
        <MenuItem key={++index} value={dataStoreType1}>
          {dataStoreType1}
        </MenuItem>
      ) : (
        <MenuItem key={++index} value={dataStoreType1}>
          {dataStoreType1}
        </MenuItem>
      );
    });
  };

  const getDirectionMenuItems = () => {
    return dataDirectionTypes.map((dataDirectionType1, index) => {
      return dataDirectionType1 == props.defaultValues.historyOffsetDirectionType ? (
        <MenuItem key={++index} value={dataDirectionType1}>
          {dataDirectionType1}
        </MenuItem>
      ) : (
        <MenuItem key={++index} value={dataDirectionType1}>
          {dataDirectionType1}
        </MenuItem>
      );
    });
  };

  const getUnitMenuItems = () => {
    return dataUnitTypes.map((dataUnitType1, index) => {
      return dataUnitType1 == props.defaultValues.historyDurationType ? (
        <MenuItem key={++index} value={dataUnitType1}>
          {dataUnitType1}
        </MenuItem>
      ) : (
        <MenuItem key={++index} value={dataUnitType1}>
          {dataUnitType1}
        </MenuItem>
      );
    });
  };
  const [publicKeyAuthenticaiton, setPublicKeyAuthenticaiton] = useState(
    props.publicKeyAuthenticaiton + ""
  );
  console.log(publicKeyAuthenticaiton + "");
  console.log("String StartDate", selectedDate, selectedEndDate);
  console.log(
    "True false print hoga smj nhi aayega",
    show ||
      ((initStartDate || selectedDate) && (initEndDate || selectedEndDate))
  );
  return (
    <div>
      <RycomSectionHeader title={connectionHeader} />
      {connectorType!=='ORIENT'?<span> <RycomGrid>	
                <RycomGridItem>
          <RycomTextField
            id="UserName"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 12);
            }}
            errorDescription={{
              required: {
                value: true,
                message: "User Name is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomGridItem>
          <TextField
            id="Password"
            label="Password*"
            variant="outlined"
            inputRef={register({
              required: {
                value: true,
                message: "Password is required",
              },
            })}
            name="password"
            type="password"
          />
          <span className={classes.invalidMessage}>
            {errors["password"] && <span> {errors["password"].message} </span>}
          </span>
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField
            id="host"
            label="Host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Host is required",
              },
            }}
          />
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField
            id="port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Port is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField
            id="databaseName"
            label="Database Name"
            name="databaseName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: "Database Name is required",
              },
            }}
          />
        </RycomGridItem>
        <RycomSelectField	
          id="sslEnabled"	
          label="SSL JDBC Connection"	
          name="sslEnabled"	
          onChangeHandler={(event) => {	
            return event[0].target.value;	
          }}	
          register={register}	
          control={control}	
          errors={errors}	
          errorDescription={{	
            required: {	
              value: true,	
              message: "SSL enabled is required",	
            },	
          }}	
        >	
            <MenuItem value="enabled" key="1">Enabled</MenuItem>	
            <MenuItem value="disabled" key="2">Disabled</MenuItem>	
        </RycomSelectField>	
        </RycomGrid>	
        <RycomGrid>	
        <RycomSelectField	
          id="DataBase Type"	
          label="DataBase Types"	
          name="databaseType"	
          onChangeHandler={(event) => {	
            return event[0].target.value;	
          }}	
          register={register}	
          control={control}	
          errors={errors}	
          errorDescription={{	
            required: {	
              value: true,	
              message: "Database Type is required",	
            },	
          }}	
        >	
            <MenuItem value="mariadb" key="1">MariaDB</MenuItem>	
            <MenuItem value="mysql_v8" key="2">MYSQL 8</MenuItem>	
            <MenuItem value="mysql_v5" key="3">MYSQL 5</MenuItem>	
            <MenuItem value="mssql_2005-2012" key="4">MSSQL 2000-2012</MenuItem>	
            <MenuItem value="mssql_latest" key="5">MSSQL 2012-2022</MenuItem>	
            <MenuItem value="oracle" key="6">ORACLE</MenuItem>	
            <MenuItem value="postgre" key="7">POSTGRE</MenuItem>	
        </RycomSelectField>	
      	
        </RycomGrid>	
        <RycomSectionHeader title="Table Details" />
        <RycomGrid>

        <RycomSelectField
          id="Data Store Type"
          label="Data Store Types"
          name="dataStoreType"
          list={dataStoreTypes}
          onChangeHandler={(event) => {
            setDataStoreType(event[0].target.value);
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Data Store Type is required",
            },
          }}
        >
          {dataStoreTypes.map((dataStoreType1, index) => (
            <MenuItem key={++index} value={dataStoreType1}>
              {dataStoreType1}
            </MenuItem>
          ))}
        </RycomSelectField>
        </RycomGrid>
      </span>: 
     <RycomGrid>
        <RycomGridItem>
        <RycomSelectField
          id="dataStoreType"
          label="Data Store Types"
          name="dataStoreType"
          list={dataStoreTypes}
          onChangeHandler={(event) => {
            setDataStoreType(event[0].target.value);
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          errorDescription={{
            required: {
              value: true,
              message: "Data Store Type is required",
            },
          }}
        >
          {dataStoreTypes.map((dataStoreType1, index) => (
            <MenuItem key={++index} value={dataStoreType1}>
              {dataStoreType1}
            </MenuItem>
          ))}
        </RycomSelectField>
        </RycomGridItem>
        </RycomGrid> }
      <Grid
        container
        spacing={12}
        style={{ padding: 4 }}
        className={classes.rycomGridItem}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={12}
          xl={12}
          style={{ marginRight: "3%" }}
          className={classes.rycomGridItem}
        >
          {dataStoreTypeValue}
        </Grid>
      </Grid>
        {connectorFlowType!=null && connectorFlowType=='inbound'?
        <HistoriaclOffsetDetails register={register} control={control} 
        errors={errors} connectorType={connectorType} 
        dateEndValue={props.dateEndValue}   
        dateStartValue={props.dateStartValue}
        dateFormat={props.dateFormat} 
        timeZone={props.timeZone} 
        maximumOffset={props.maximumOffset}
        maximumOffsetUnitType={props.maximumOffsetUnitType}
        offsetDuration={props.offsetDuration}
        offsetDurationUnitType={props.offsetDurationUnitType}
        fetchOffsetType={props.fetchOffsetType}
        historyDurationType={props.historyDurationType}
        frequency={props.frequency}
        fetchType={props.fetchType}
        fetchStartDateString={props.fetchStartDateString}
        fetchEndDateString={props.fetchEndDateString}
        />:''}





    </div>
  );
}