import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent'



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
  },

  title: {
    margin: 0,
    fontSize: 'large',
    fontWeight: 'bold',
    display: 'inline-block',
    paddingRight: '10px',
  },

  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
    borderBottom: '1px solid $ui-border-color-base'
  },

  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end'
  }
}));

export default function RycomTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <CardContent>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {props.columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {props.columns.map(column => {
                      let value;
                      if (column.id.indexOf('.') > 0) {
                        const properties = column.id.split(".");
                        value = row;
                        for (var i = 0; i < properties.length; i++) {
                          value = value[properties[i]]
                        }
                      } else {
                        value = row[column.id];
                      }
                      return (
                        <TableCell key={column.id} align={column.align} style={column.styles}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>

    </div>
  );
}
