import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomInfoPair from '../../../common/RycomInfoPair'
import * as RelationService from '../../../../services/RelationService';


import { useParams } from 'react-router-dom'


export default function RelationDetail() {

    let { id } = useParams();
    const [relationValue,setRelationValue]=useState({relation:[]})
 
    useEffect(() => {
        RelationService.getRelationById(id)
        .then((res) => {
            if (res && res.data && res.data) {
                setRelationValue(res.data);
            }
        })
        .catch((error) => {
            console.error('getRelationById =>', error)
        })
    },[]);

    return (<div>
        <RycomPageHeader title={relationValue ? `Relation Details about ${relationValue.rname}` : 'Relation Details'} links={[{ title: 'Back', url: '/topics/relation' }]}></RycomPageHeader>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Name" value={relationValue ? relationValue.rname : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Description" value={relationValue ? relationValue.rdescription : ''}></RycomInfoPair>
            </Grid>
      
        </Grid>
        <div>
            <RycomSectionHeader title="Vertex & Edge Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Base Vertex" value={relationValue ? relationValue.baseVertexName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Edge Name" value={relationValue  ? relationValue.edgeName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Ege Type" value={relationValue && relationValue.relation[0] ? relationValue.relation[0].edgerelationtype : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Ege Direction" value={relationValue && relationValue.relation[0] ? relationValue.relation[0].edgedirection : ''}></RycomInfoPair>
            </Grid>
                </Grid>
        </div>
    </div>
    );
}
