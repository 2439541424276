import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomSelectField from '../../../common/RycomSelectField'
import RycomTextField from '../../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../../common/RycomGrid'
import RycomSubmitModel from '../../../common/RycomSubmitModel'
import environment from '../../../../environment'

import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form'
import * as OrganisationService from '../../../../services/OrganisationService';
import * as VenueService from '../../../../services/VenueService';
import * as DeviceService from '../../../../services/DeviceService';
import * as VertexService from '../../../../services/VertexService';


import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },

    rycomButtonGroup: {
        paddingLeft: '40px',
        paddingTop: '15px'
    }
}));


export default function VertexEditTemplate(props) {

    const history = useHistory();
    const classes = useStyles();
    const defaultValues = props.defaultValues;
    console.log(defaultValues)
    const [vertexproperties, setVertexproperties] = useState(defaultValues.vertexproperties);
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });

    const canAddNewVertexProperties = () => {
        const length = vertexproperties.length - 1
        return vertexproperties[length].v_id
    };

    const newVertexProfile = () => {
        let id = 1;
        if (vertexproperties.length > 0) {
            let lastKey = canAddNewVertexProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newVertexProfile = [...vertexproperties, {
            'v_id': id,
            'key': null,
            'value': null,
            'valueType': null
        }]
        setVertexproperties(newVertexProfile);
    }

    const deleteVertexProfile = (index) => {
        const deleteVertexProfiles = [...vertexproperties]
        if (deleteVertexProfiles.length > 1) {
            deleteVertexProfiles.splice(index, 1);
            setVertexproperties(deleteVertexProfiles);
        } else {
            setVertexproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
                'valueType': null
            }])
        }
    };

    if (!vertexproperties.length) {
        newVertexProfile()
    }

    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    const changeKeyHandler = (vertexProperty) => (event) => {
        vertexProperty.key = event.target.value;
        console.log(vertexProperty.key)
        const updatedVertexProperties=[...vertexproperties];
        setVertexproperties(updatedVertexProperties);
    }

    const changeValueHandler = (vertexProperty) => (event) => {
        vertexProperty.value = event.target.value;
        console.log(vertexProperty.value)
        const updatedVertexProperties=[...vertexproperties];
        setVertexproperties(updatedVertexProperties);
    }

    const vertexList = vertexproperties.map((vertexProperty, index) => {
        vertexProperty.id = index;
        console.log(vertexProperty)
        return <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
            <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="Vertex Name"
                    style={{ width: 300 }}
                    variant="outlined"
                    inputRef={register({
                    })}
                    value={vertexProperty.key}
                    onChange={changeKeyHandler(vertexProperty)}
                />
                 <span>
                    </span>    
              </div>            
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
              <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Vertex Value"
                    style={{ width: 300 }}
                    variant="outlined"
                    inputRef={register({
                    
                    })}
                    value={vertexProperty.value}
                    onChange={changeValueHandler(vertexProperty)}
                />
                    </span>           
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomButtonGroup}>
                <Button color="secondary" variant="contained" size="small" disabled={vertexproperties.length==1} value="delete Vertex"
                    onClick={(e) => {
                        const deleteVertexProfiles = [...vertexproperties]

                        if (vertexproperties.length > 1) {
                            const deleteVertexProfiles1 = deleteVertexProfiles.filter(vertex => {
                                return vertex.v_id != vertexProperty.v_id
                            });
                            setVertexproperties(deleteVertexProfiles1);
                        } else {
                            setVertexproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null,
                                'valueType': null
                            }])
                        }
                    }}>Delete Vertex</Button>
            </Grid>
        </Grid>;
    });
    const onSubmit = payload => {
        console.log(vertexproperties)
        payload.vertexproperties=vertexproperties;
        //payload.parentcompanyid=parentcompanyid;
        payload.parentcompanyid=paramID;
        payload.vertexid=defaultValues.vertexid;
        console.log(payload);
       
        payload.type='CONFIG_TYPE';
        VertexService.updateVertex(payload).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('vertex updated successfully');
            history.push("/topics/vertex/detail/"+payload.vertexid)
          })
        .catch((error) => {
          alert("Connector error")                                      
           console.error('getConnector =>', error)
         })

    }
    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Vertex Details"
                    links={[{ title: 'Back', url: '/topics/vertex' }]}></RycomPageHeader>
                <RycomSectionHeader title="Vertex Overview Details"></RycomSectionHeader>
           
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="vertexName"
                            label="Vertex Name"
                            name="vertexname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Vertex Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="vertexdescription"
                            label="Vertex Description"
                            name="vertexdescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Vertex Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="Vertex Properties"></RycomSectionHeader>
                {vertexList}

                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add Vertex" onClick={newVertexProfile}>Add Vertex</Button>
                        </span>
                    </Grid>
                </Grid>;
            
             <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                setVertexproperties([])
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Update</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
