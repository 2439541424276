import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveRelation = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/relationship`,
          vertexPayload, addBearerToken()
    );
}

export const getAllRelation = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/parentcompanyrelationship/${companyId}`, addBearerToken());
}

export const getRelationById = (relationshipid) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/relationship/${relationshipid}`, addBearerToken());
}
