import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomTextField from '../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import RycomSubmitModel from '../common/RycomSubmitModel'
import * as LoginService from '../../services/LoginService';


import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import './main.css';
import './util.css';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '49ch',
        }
    }
}));


export default function LoginForm() {

    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);

    const classes = useStyles();
    const [companyId, setCompanyId] = useState(undefined);
    const { register, errors, handleSubmit, reset, watch, control } = useForm({});

    const onSubmit = data => {
        console.log(data);
        const payload = data

       LoginService.login(payload).then((res) => {
            console.log(res)
            if (res.status === 200)
            history.push("/topics/")
        })
            .catch((error) => {
                alert("Login error")
                console.error('Login =>', error)
            })
          }


    return (
        <div className={classes.root}>
            <div class="container-login100">
                <div class="wrap-login100">
                    <form class="login100-form validate-form p-l-55 p-r-55 p-t-178">
                        <span class="login100-form-title">
                            Rycom - Sign In
					</span>
                        <div class=" validate-input m-b-16" data-validate="Please enter username">
                            <TextField
                                id="outlined-multiline-flexible"
                                label=""
                                length="200"
                                variant="filled"
                                inputRef={register({})}
                                placeholder="User Name"
                                name="username" />
                            <span class="focus-input100"></span>
                        </div>
                        <div class="validate-input" data-validate="Please enter password">
                            <TextField
                                id="outlined-multiline-flexible"
                                label=""
                                length="200"
                                variant="filled"
                                inputRef={register({})}
                                placeholder="Password"
                                type="password"
                                name="password" />
                            <span class="focus-input100"></span>
                        </div>
                        <div class="text-right p-t-13 p-b-23">
                        <span class="txt1">
							Forgot
						</span>

						<a href="#" class="txt2">
							Username / Password?
						</a>

                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn"  onClick={handleSubmit(onSubmit)}>
                                Sign in
						</button>
                        </div>
                        <div class="flex-col-c p-t-170 p-b-40">
                            <span class="txt1 p-b-9">
                               Version 2: Don�t have an account?
						</span>

                            <a href="#" class="txt3">
                                Sign up now
						</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
}
