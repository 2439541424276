import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card'

import Button from '@material-ui/core/Button'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'large',
     fontWeight:'bold',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '15px',
      borderBottom: '1px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    }  



}));

export default function RycomCardTableHeader(props) {
  const classes = useStyles();
  

  return (
  <div>
    <Card className={classes.aocard}>
      <div className={classes.header}>
       <span className={classes.title} >
        {props.title}</span>
       </div>
      
      </Card>
    
    </div>
  );
}
