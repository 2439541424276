import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSubmitModel from '../common/RycomSubmitModel'

import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import Button from '@material-ui/core/Button'

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import * as TaskService from '../../services/TaskService';
import * as VertexService from '../../services/VertexService';
import * as DocumentClassService from '../../services/DocumentClassService';
import * as SchedulerService from '../../services/SchedulerService';
import * as RelationService from '../../services/RelationService';
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'xx-large',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '5px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },  

    buttonStyle: {
      paddingRight: '5px',
    }

}));


export default function TaskDetail() {

    const history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    let { vetexId } = useParams();
    const [taskValues, setTaskValues] = useState();
    
    const [vertexId, setTaskVertexId] = useState();
    const [vertexDetails, SetVertexDetails] = useState();
    const [jobStatus, setJobStatus] = useState('');
    const [timmingInfo, setTimmingInfo] = useState('');
    const [keycloak] = useKeycloak();
    const [relationDetails,setRelationName]=useState()

    useEffect(() => {
        console.log(id)
        TaskService.getTaskById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    setTaskValues(res.data);
                    if(res.data?.vertexTypeId!=='' && res.data?.vertexTypeId!==0)
                            {
                                DocumentClassService.getVertexById(res.data?.vertexTypeId)
                                        .then((res) => {
                                            if (res && res.data && res.data) {
                                                console.log("res.data")
                                                console.log(res.data)
                                                SetVertexDetails(res.data);
                                            }
                                        })
                                        .catch((error) => {
                                            console.error('getVertexById =>', error)
                                        });
                            }
                            if(res.data?.baserelationshipid!=='' && res.data?.baserelationshipid!==0)
                            {
                            RelationService.getRelationById(res.data?.baserelationshipid)
                                .then((res)=> {
                                    if (res && res.data && res.data) {
                                        console.log('RelationService',res.data);
                                        setRelationName(res.data)
                                    }
                                })
                                .catch((error) => {
                                    console.error('getRelationById =>', error)
                                });
                            }
                        
                    const task = res.data;
                    console.log(JSON.stringify(task));
                    console.log(task.schedulerStatus);
                    setJobStatus(task.schedulerStatus)
                    getTriggerDetails(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    }, []);

   
    const getTriggerDetails=(taskValues)=>{
        console.log('inside the trigger details')
        const payload={
            scheduledTaskId:taskValues.taskid,
            companyId:taskValues.companyid,
            dataMapperId:taskValues.datamapperid,
            groupId:taskValues.connectionid,
            schedulerId:taskValues.schedulerid
        }
        if(taskValues.schedulerStatus!=='Not Triggered' && taskValues.schedulerStatus!=='paused'){
        SchedulerService.getTriggerDetails(payload).then((res) => {
            if (res && res.data && res.data) {
                console.log(res.data);
                const timmingDetails=res.data
                setTimmingInfo(timmingDetails?
                    <div>
                        <RycomSectionHeader title="Job Status"></RycomSectionHeader>
                        <Grid container spacing={12} style={{ padding: 24 }}>
                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="Start Time" value={timmingDetails ? timmingDetails.startTime : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="Previous Fire Time" value={timmingDetails ? timmingDetails.previousFireTime : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <RycomInfoPair label="Next Fire Time " value={timmingDetails ? timmingDetails.nextFireTime : ''}></RycomInfoPair>
                       </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                        <RycomInfoPair label="End Time" value={timmingDetails ? timmingDetails.endTime : ''}></RycomInfoPair>
                    </Grid>
               
                    </Grid>
                    </div>:'');

            }
        })
            .catch((error) => {
                alert('some error in starting the Job')
                console.error('startTask =>', error)
            })
        }else{
            setTimmingInfo('')
        }
       
    }

    const createStartJobButton = () => {
        const startButton = <Button className={classes.buttonStyle} color="primary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                SchedulerService.startScheduler(taskValues).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        taskValues.schedulerStatus='running';
                        alert('Job Successfully Started')
                        setJobStatus('running')
                        getTriggerDetails(taskValues)
                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Start Scheduler</Button>
        return startButton;
    };

    const createStopJobButton = () => {
        const startButton = <Button color="Red" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                const payload={
                    scheduledTaskId:taskValues.taskid,
                    companyId:taskValues.companyid,
                    groupId:taskValues.connectionid
                }
                SchedulerService.stopScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Job Succesfully Stopped")
                        setJobStatus('Not Triggered')
                        taskValues.schedulerStatus='Not Triggered'
                        getTriggerDetails(taskValues)

                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Stop Scheduler</Button>
        return startButton;
    }

    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                if (window.confirm('Are you sure you wish to delete this item?')) 
                {
                    TaskService.deleteTask(taskValues).then((res) => {
                        if (res && res.data && res.data) {
                            console.log(res.data);
                            alert(res.data.message)
                            history.push("/topics/task");
                        }
                    })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
                } else {
                    console.log('Not Delted');
                } 
            }}>Delete</Button>
        return DeleteButton;
    }
  
    const createPauseJobButton = () => {
        const startButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
              const payload={
                    scheduledTaskId:taskValues.taskid,
		            companyId:taskValues.companyid,
                    groupId:taskValues.connectionid
                }
                SchedulerService.pauseScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('Job Successfully Paused')
                        setJobStatus('paused')
                        taskValues.schedulerStatus='paused'
                        getTriggerDetails(taskValues)
                        
                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Pause Scheduler</Button>
        return startButton;
    }

    const createResumeJobButton = () => {
        const payload={
            scheduledTaskId:taskValues.taskid,
            companyId:taskValues.companyid,
            groupId:taskValues.connectionid
        }
        const startButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                SchedulerService.resumeScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('successfully Resumed')
                        setJobStatus('running')
                        taskValues.schedulerStatus='running'
                        getTriggerDetails(taskValues)

                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Resume Scheduler</Button>
        return startButton;
    }
    const createManualButton = () => {
        const manualButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="small" value="Trigger Task Manually"
            onClick={(e) => {
                TaskService.executeTask(taskValues).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert('Task Status Message:'+res.data.message);
                    }
                })
                    .catch((error) => {
                        alert('Task Error Message:'+error.message)
                        console.error('executeTask =>', error)
                    })
            }}>Trigger Task Manually</Button>
        return manualButton;
    }
    let links ="";
    let taskvalueTypes ="";
        if (jobStatus == 'Not Triggered') {

        console.log('inside the '+jobStatus)
         links =   <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
         <Grid item xs={12} sm={12} lg={8} xl={4} className={classes.rycomGridItem}></Grid>
           <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.rycomGridItem}>
              <span className={classes.buttonStyle}>  {createStartJobButton()}</span>
              <span className={classes.buttonStyle}>     {createManualButton()}</span>
              </Grid>
      </Grid> 

    } else if (jobStatus == 'running') {
        links = 
        <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
           <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}></Grid>
             <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
             <span className={classes.buttonStyle}>  {createPauseJobButton()}</span>
                <span className={classes.buttonStyle}>  {createStopJobButton()}</span>
                <span className={classes.buttonStyle}>     {createManualButton()}</span>
                </Grid>
        </Grid>    
   }else if (jobStatus == 'paused') {
         links =  <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}></Grid>
             <Grid item xs={12} sm={12} lg={6} xl={6} className={classes.rycomGridItem}>
             <span className={classes.buttonStyle}>  {createResumeJobButton()}</span>
              <span className={classes.buttonStyle}>  {createStopJobButton()}</span>
              <span className={classes.buttonStyle}>     {createManualButton()}</span>
              </Grid>
      </Grid>    
    }  else if (jobStatus == 'stopped') {
         links = <RycomGrid>
            <RycomGridItem>
                {createManualButton()}
            </RycomGridItem>
        </RycomGrid>
        alert('Job for this Task has been stoped.')
    }

    console.log(jobStatus)
    console.log(links)
    console.log('Task Values will displyed here',taskValues)
    let vertexTypeIdGet = taskValues?.vertexTypeId;
    console.log('Original Vertex Id', vertexTypeIdGet);    
    // useEffect(() => {
    //     console.log('setVerTexData',vertexTypeIdGet);
    //     setTaskVertexId(vertexTypeIdGet);
    //     VertexService.getVertexById(taskValues?.vertexTypeId)
    //         .then((res) => {
    //             if (res && res.data && res.data) {
    //                 console.log('VertexService DATA',res.data)
    //                 SetVertexDetails(res.data);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('getVertexById =>', error)
    //         })
    // }, [taskValues]);


    console.log('VERTEX ID FOR VERTEX NAME',taskValues?.vertexTypeId);
    console.log('VERTEX Details',vertexDetails);
    console.log('VERTEX Details',relationDetails);
    if(taskValues?.taskStorageType==="GraphConnector")
    {
        taskvalueTypes = <RycomInfoPair label="Vertex Type" value={vertexDetails ? vertexDetails.className : ''}></RycomInfoPair>
    }
    // else
    // {
        // taskvalueTypes = <RycomInfoPair label="Vertex Type" value={relationDetails?relationDetails.rname : ''}></RycomInfoPair>   
    // }

    const taskStatus=jobStatus?'Task Status:'+jobStatus:'Task Status';

    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/task"><Button
    color="primary" variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:task")?<Link className={classes.buttonStyle} to={'/topics/task/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 
    {keycloak.hasRealmRole("delete:task")?<span className={classes.buttonStyle} to="/topics/task">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;
       
  
    return (<div>
        <Card className={classes.aocard}>
            <div className={classes.header}>
            <span className={classes.title} >
            {taskValues ? `Task Details: ${taskValues.taskname}` : 'Task Details'} </span>
            <span className={classes.toolbar}>
            {buttonLinks}
            </span>
            </div>
        </Card>
        <RycomSectionHeader title={taskStatus}></RycomSectionHeader>

        {links}
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task" value={taskValues ? taskValues.taskname : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task Description" value={taskValues ? taskValues.taskdescription : ''}></RycomInfoPair>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Organisation" value={taskValues ? taskValues.companyName : ''}></RycomInfoPair>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Venue Name" value={taskValues ? taskValues.venueName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Connection Name" value={taskValues ? taskValues.connectionName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Data Mapper" value={taskValues ? taskValues.dataMapperName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Scheduler Name" value={taskValues ? taskValues.schedulerName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task Type" value={taskValues ? taskValues.taskType : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                {taskvalueTypes}
            </Grid>

            
        </Grid>
        {timmingInfo}

    </div>
    );
}
