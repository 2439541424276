import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as VertexService from '../../../../services/VertexService';
import VertexEditTemplate from './VertexEditTemplate';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));

export default function VertexEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({});

    useEffect(() => {
        VertexService
            .getVertexById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log(res.data)
                     setDefaultValues(res.data);   
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])
 
    console.log(defaultValues);
  return (defaultValues && defaultValues.vertexid)?
        <VertexEditTemplate defaultValues={defaultValues}></VertexEditTemplate>:"";
  
}
