import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import { useParams } from 'react-router-dom'

import * as DocumentClassService from '../../services/DocumentClassService';

import * as SchedulerService from '../../services/SchedulerService';

import * as TaskService from '../../services/TaskService';
import RycomTable from '../common/RycomTable'
import RycomTableHeader from '../common/RycomTableHeader'





import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));
  

  export default function SchedulerDetail() {

    let { id } = useParams();
    const classes = useStyles();
    const [schedulerDetail, setSchedulerDetail] =useState();
    const history = useHistory();
    const [keycloak] = useKeycloak();

    const [taskDetails, setTaskDetails] = React.useState([])

    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
              
                SchedulerService.deleteSchedulerById(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/scheduler");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Delete</Button>
        return DeleteButton;
    } 

    useEffect(() => {
        console.log(id)
        SchedulerService.getSchedulerById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setSchedulerDetail(res.data);
                    TaskService.getAllTaskByScheduler(id).then((res) => {
                        if (res && res.data && res.data) {
                            setTaskDetails(res.data);
                          console.log(res.data);
                        }
                      })
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])





    const createTaskDeleteButton = (taskId, taskName) => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                if (window.confirm('Are you sure you wish to delete this'+ taskName +' Task Item?')) 
                {
                    TaskService.deleteTaskById(taskId).then((res) => {
                        if (res && res.data && res.data) {
                            console.log(res.data);
                            alert(res.data.message)
                            history.push("/topics/scheduler");
                        }
                    })
                    .catch((error) => {
                        alert('some error in deleting the Tasl')
                        console.error('Task Delete =>', error)
                    })
                } else {
                    console.log('Not Deleted');
                } 
            }}>Delete</Button>
        return DeleteButton;
    }

    const createStopJobButton = (taskValues) => {
        const startButton = <Button color="Red" variant="contained" size="small" value="Trigger Task"
            onClick={(e) => {
                const payload={
                    scheduledTaskId:taskValues.taskid,
                    companyId:taskValues.companyid,
                    groupId:taskValues.connectionid
                }
                SchedulerService.stopScheduler(payload).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Job Succesfully Stopped")
                        history.push("/topics/scheduler");
                    }
                })
                    .catch((error) => {
                        alert('some error in starting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Stop the Running Jobs</Button>
        return startButton;
    }


    const createAllTaskDeleteButton = (schedulerId, schedulerName) => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                if (window.confirm('Are you sure to delete all the tasks related to '+ schedulerName +' Connector Item?')) 
                {
                    TaskService.deleteTaskBySchedulerId(schedulerId).then((res) => {
                        if (res && res.data && res.data) {
                            console.log(res.data);
                            alert(res.data.message)
                            history.push("/topics/scheduler");
                        }
                    })
                    .catch((error) => {
                        alert('some error in deleting the Tasl')
                        console.error('Task Delete =>', error)
                    })
                } else {
                    console.log('Not Deleted');
                } 
            }}>Delete All Running Tasks Related to this Scheduler</Button>
        return DeleteButton;
    }

   


    const columns = [
        { id: 'taskname', label: 'Task Name', minWidth: 170 },
        { id: 'taskType', label: 'Task Type', minWidth: 170 },
        { id: 'schedulerStatus', label: 'Job Status', minWidth: 170 },
        { id: 'companyName', label: 'Company', minWidth: 100 },
        { id: 'connectorType', label: 'Connector Type', minWidth: 100 },
        { id: 'deleteTask', label: 'Delete Task', minWidth: 100 }
      ];
     
    
      const rows=[...taskDetails].map((task)=>{
        task.taskname=<Link to={`/topics/task/detail/${task.taskid}`} >{task.taskname}</Link>
        task.deleteTask= task.schedulerStatus=='Not Triggered'?createTaskDeleteButton(task.taskid, task.taskname):createStopJobButton(task);
        return task;
        });
    


    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/scheduler"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:graph")?<Link className={classes.buttonStyle} to={'/topics/scheduler/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>   
    </Link>:""} 
    {keycloak.hasRealmRole("delete:graph")?<span className={classes.buttonStyle} to="/topics/scheduler">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;
    return (<div>
        
        <Card className={classes.aocard}>
<div className={classes.header}>
<span className={classes.title} >
{schedulerDetail ? `Scheduler Details about ${schedulerDetail.schedulerName}` : 
        'Scheduler Details'} </span>
<span className={classes.toolbar}>
{buttonLinks}
</span>
</div>
</Card>



 

{taskDetails.length>0 ? 
       <span>
        <Card className={classes.aocard}>
    <div className={classes.header}>
    <span className={classes.title} >
    Task Details
    </span>
    <span className={classes.toolbar}>
    {createAllTaskDeleteButton(id, schedulerDetail.schedulerName)}
    </span>
    </div>
    </Card>
        <RycomTable rows={rows} columns={columns}></RycomTable></span>:
        <div></div>}



        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Scheduler Detail" value={schedulerDetail ? schedulerDetail.schedulerName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Scheduler Description" value={schedulerDetail ? schedulerDetail.schedulerDescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Cron Expression" value={schedulerDetail ? schedulerDetail.cronExpression : ''}></RycomInfoPair>
            </Grid>
                 </Grid>
    </div>
    );
}
