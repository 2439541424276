import React from "react";
import RycomInfoPair from "../common/RycomInfoPair";
import Grid from "@material-ui/core/Grid";
import RycomSectionHeader from "../common/RycomSectionHeader";
import { createMuiTheme ,useTheme, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
export default function ConnectorJDBCDetail({ connectorInfo }) {
  const theme = useTheme();
  const showdownsm = useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : null;
  const showUpsm = useMediaQuery(theme.breakpoints.up('sm')) ? '100%' : '100%';
  const showDownmd = useMediaQuery(theme.breakpoints.down('md')) ? '80%' : null;
  const showUpmd = useMediaQuery(theme.breakpoints.up('md'))? '100%' : null;
  const showDownlg = useMediaQuery(theme.breakpoints.down('lg'))? '100%' : null;
  const showUplg = useMediaQuery(theme.breakpoints.up('lg'))? '100%' : null;
  const showdownxl = useMediaQuery(theme.breakpoints.only('xl'))? '100%' : null;
  console.log("Here is connector info", connectorInfo);
  const headerContent = connectorInfo.connectionInfo.configuration.header;
  const [timeZoneLabel, setTimeZoneLabel]= useState(connectorInfo
    ? connectorInfo.connectionInfo.configuration.timeZone
    : "")
  console.log("header Contet here", headerContent);
  let headerContentArr = [];
  let headerContentValues = [];
  let InfofarValue = null;
  let offsetTypeVal = null;
  let rangeTextboxVal = null;
  let dateValue1 = null;
  let dateValue2 = null;
  let dateValue3 = null;
  let dateValue4 = null;
  let startDateShow = null;
  startDateShow = connectorInfo.connectionInfo.configuration.fetchStartDateString;
  // console.log('startDateShow=======',startDateShow);
  // const date1 = new Date(startDateShow);
  // console.log('DATE1 Display'date1);
  //console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date1));
  // var date = new Date(startDateShow * 1000); // multiply by 1000 for milliseconds
  // var date_string = date.toLocaleString('en-US');  // 24 hour format
  // console.log('DATE STRING VLUAE',date_string);
  // console.log('Details of Date__________',date1);
  // console.log('toLocaleString_____',date1.toLocaleString("en-US", { timeZone: 'UTC' , }));
  // const startDateDisply   = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour12:false, hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date1);

  let endDateShow = null;
  endDateShow = connectorInfo.connectionInfo.configuration.fetchEndDateString;
  // console.log('Indian Date----',new Date(endDateShow*1000));
  // const date2 = new Date(endDateShow*1000);
  // const endDateDisply   = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour12:false, hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date2);

  if (connectorInfo.connectionInfo.configuration.dataStoreType == "query") {
    InfofarValue = (
      <Grid item xs={12} sm={12} lg={12} xl={12}>
      <RycomInfoPair
        label="Query"
        value={
          connectorInfo ? connectorInfo.connectionInfo.configuration.query : ""
        }
      ></RycomInfoPair>
      </Grid>
    );
  } else {
    InfofarValue = (
      <Grid item xs={12} sm={2} lg={4} xl={2}>
      <RycomInfoPair
        label="Data Store Name"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.dataStoreName
            : ""
        }
      ></RycomInfoPair></Grid>
    );
  }
  if(connectorInfo.connectionInfo.configuration.fetchType == "range")
  {
    rangeTextboxVal = (<Grid container spacing={12} style={{ padding: 24 }}>
      <Grid item xs={12} sm={3} lg={2} xl={2}>
      <RycomInfoPair
        label="Start Parameter"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.startParamter
            : ""
        }
      ></RycomInfoPair></Grid>
      <Grid item xs={12} sm={3} lg={2} xl={2}>
        <RycomInfoPair
      label="End Parameter"
      value={
        connectorInfo
          ? connectorInfo.connectionInfo.configuration.endParamter
          : ""
      }
    ></RycomInfoPair></Grid>
    <Grid item xs={12} sm={3} lg={3} xl={2}><RycomInfoPair
    label="Date Format"
    value={
      connectorInfo
        ? connectorInfo.connectionInfo.configuration.dateFormat
        : ""
    }
  ></RycomInfoPair></Grid>
  <Grid item xs={12} sm={3} lg={3} xl={2}>
    <RycomInfoPair
  label="Time Zone"
  value={
    connectorInfo
      ? connectorInfo.connectionInfo.configuration.timeZone
      : ""
  }
></RycomInfoPair>
</Grid></Grid>);
  if (
    connectorInfo.connectionInfo.configuration.fetchOffsetType == "lastOneDay"
  ) {
    offsetTypeVal = (
      <RycomInfoPair
        label="Last One Day"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.fetchOffsetType
            : ""
        }
      ></RycomInfoPair>
    );
  } 
  else if (
    connectorInfo.connectionInfo.configuration.fetchOffsetType == "history"
  ) {
    offsetTypeVal = (
      <RycomInfoPair
        label="Historical Record"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.fetchOffsetType
            : ""
        }
      ></RycomInfoPair>
    );

 
    dateValue1 = (
      <RycomInfoPair
        label="Duration"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.frequency
            : ""
        }
      ></RycomInfoPair>
    );
    dateValue2 = (
      <RycomInfoPair
        label="Units"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.historyDurationType
            : ""
        }
      ></RycomInfoPair>
    );
    dateValue3 = (
      <RycomInfoPair
        label="Offset Duration"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.offsetDuration
            : ""
        }
      ></RycomInfoPair>
    );
    dateValue4 = (
      <RycomInfoPair
        label="Offset Units"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.offsetDurationUnitType
            : ""
        }
      ></RycomInfoPair>
    );
  } 
  
  
  else {
    offsetTypeVal = (
      <RycomInfoPair
        label="Custom Date"
        value={
          connectorInfo
            ? connectorInfo.connectionInfo.configuration.fetchOffsetType
            : ""
        }
      ></RycomInfoPair>
    );
    dateValue1 = (
      <RycomInfoPair
        label={"Start Date Time "+timeZoneLabel}
        value={connectorInfo?startDateShow
            : ""
        }
      ></RycomInfoPair>
    );
    dateValue2 = (
      <RycomInfoPair
        label={"End Date Time "+timeZoneLabel}
        value={connectorInfo?endDateShow
            : ""
        }
      ></RycomInfoPair>
    );
    
  }
}
else {
  offsetTypeVal = (
    <RycomInfoPair
      label="Complete"
      value={
        connectorInfo
          ? connectorInfo.connectionInfo.configuration.fetchOffsetType
          : ""
      }
    ></RycomInfoPair>
  );
}

  if (headerContent) {
    for (let key in headerContent) {
      headerContentArr.push({ headerKey: key, value: headerContent[key] });
    }

    console.log(headerContentArr);

    headerContentValues = headerContentArr.map((headerValues) => {
      return (
        <Grid container spacing={12} style={{ padding: 8 }}>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair
              label={headerValues ? headerValues.headerKey : ""}
              value={headerValues ? headerValues.value : ""}
            ></RycomInfoPair>
          </Grid>
        </Grid>
      );
    });
  }

  return (
    <div>
      <RycomSectionHeader title={`${connectorInfo.connectionInfo.type} Connection Details`}></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="User Name"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.userName
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Password"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.password
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Host"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.host
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Port"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.port
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Data Base Name"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.databaseName
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Data Store Type"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.dataStoreType
                : ""
            }
          ></RycomInfoPair>
        </Grid>
          {InfofarValue}
      </Grid>
      <RycomSectionHeader title="Fetching Records Offset Details"></RycomSectionHeader>
         {rangeTextboxVal}
      <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          {offsetTypeVal}
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          {dateValue1}
          </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          {dateValue2}
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          {dateValue3}
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          {dateValue4}
        </Grid>
      </Grid>
      <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Created Date Column"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.createdDateColumn
                : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Modified Date Column"
            value={
              connectorInfo
                ? connectorInfo.connectionInfo.configuration.modifiedDateColumn
                : ""
            }
          ></RycomInfoPair>
        </Grid>
      </Grid>
      {headerContentValues}
    </div>
  );
}
