import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    rycomGridItem: {
        paddingTop: "25px"
    }

}));

export default function RycomSubmitModel(props) {
    const classes = useStyles();
    return <Grid container spacing={12} className={classes.rycomGridItem}>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} >
            <span>
                {props.children}
            </span>
        </Grid>
    </Grid>;

}