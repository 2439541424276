import React, { Component } from 'react'
import RycomTable from '../../common/RycomTable'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomTableHeader from '../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import environment from '../../../environment'

import * as VenueService from '../../../services/VenueService'


class OrganisationVenueList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      venues: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //VenueService.getVenueByParentCompanyId(parentcompanyid)
    VenueService.getVenueByParentCompanyId(paramID)
      .then((res) => {
        if (res && res.data && res.data) {
          const venues = res.data;
          console.log(res);
          this.setState({ venues: venues });
        }
      })
      .catch((error) => {
        console.error('Venue List =>', error)
      })
  }
  render() {
  

    const columns = [
      { id: 'venuename', label: 'Venue Name', minWidth: 170 },
      { id: 'venuedescription', label: 'Venue Description', minWidth: 100 },
      { id: 'companyName', label: 'Company', minWidth: 100 }
    ];
    

      const rows=this.state.venues.map((venue)=>{
                   venue.venuename=<Link to={`/topics/company/venue/detail/${ venue.venueid}`} >{ venue.venuename}</Link>
                                                     return venue;
              });
      console.log(rows)
      return (<div>
        <RycomPageHeader title="" links={[{title:'Add Venue',url:'/topics/company/venue/form', role:"create:org"}]}></RycomPageHeader>
        <RycomTableHeader title="Venue" buttonTitle="Add Venue"></RycomTableHeader>
        <RycomTable rows={this.state.venues} columns={columns}></RycomTable>
      </div>);
    } 
}
export default OrganisationVenueList;
