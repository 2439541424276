import React, { Component } from 'react'
import RycomTable from '../common/RycomTable'
import RycomPageHeader from '../common/RycomPageHeader'

import RycomTableHeader from '../common/RycomTableHeader'
import * as ConnectorService from '../../services/ConnectorService';
import { Link } from 'react-router-dom'

class ConnectorList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectors: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount-Connectors');
    ConnectorService
      .getAllConnector()
      .then((res) => {
        if (res && res.data && res.data) {
          let connectorInfo = res.data[0].body;
          this.setState({ connectors: connectorInfo });
        }
      })
      .catch((error) => {
        alert('Connector Retrieval Error');
        console.error('getConnector =>', error)
      })
  }

  render() {
    console.log(this.state.connectors)
    let columns=[];
    if(this.state.connectors || this.state.connectors.connectionInfo.type == 'api'){
      columns = [
      { id: 'connectorName', label: 'Connector Name', minWidth: 170 },
      { id: 'connectionInfo.type', label: 'Connector Type', minWidth: 100 },
      {
        id: 'connectionInfo.dataflow',
        label: 'Connector Flow Type'
      }
    ];
    }else{
      columns = [
        { id: 'connectorName', label: 'Connector Name', minWidth: 170 },
        { id: 'connectionInfo.type', label: 'Connector Type', minWidth: 100 },
        {
          id: 'connectionInfo.configuration.host',
          label: 'Host'
        }
      ];
    }
     

    if (this.state.connectors !== undefined &&
      this.state.connectors.length > 0) {
      const rows=this.state.connectors.map((connector)=>{
      connector.connectorName=<Link to={`/topics/connector/detail/${connector.id}`} >{connector.connectorName}</Link>
           return connector;
      });
      console.log(rows)
      return (<div>
        <RycomPageHeader title="Connector List" links={[{title:'Add Connector',url:'/topics/connector/form', role:"create:connector"}]}></RycomPageHeader>
        <RycomTableHeader title="Connector" buttonTitle="Add Connector"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } else {
      console.log('inside the else')
      return (<div>
        <RycomPageHeader title="Connector List" links={[{title:'Add Connector',url:'/topics/connector/form', role:"create:connector"}]}></RycomPageHeader>
        <RycomTableHeader title="Connector" buttonTitle="Add Connector"></RycomTableHeader>
      </div>);
    }
  }
}
export default ConnectorList;
