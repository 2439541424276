import React from 'react';
import { Link } from 'react-router-dom'
import './Sidebar.scss'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Collapse } from '@material-ui/core';

import { withKeycloak } from '@react-keycloak/web'

import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";

import ViewListIcon from '@material-ui/icons/ViewList';
import PersonIcon from '@material-ui/icons/Person';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { useKeycloak } from '@react-keycloak/web';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import PeopleIcon from '@material-ui/icons/People';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import AlarmIcon from '@material-ui/icons/Alarm';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import StorageIcon from '@material-ui/icons/Storage';
import BuildIcon from '@material-ui/icons/Build';
import environment from './../../environment'

import logo from './../../rycom-header-logo.png';
import sideLogo from './../../rycom-log.jpeg';




import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import ConnectorList from '../connectors/ConnectorList';
import OrganisationList from  '../org/company/OrganisationList';

import * as UserService from  '../../services/UserService';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    color:'white',
    background:'#f1592a',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    background:'#f1591a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

  },
  navLinkText: {
    textDecoration: 'none',
    color:'#f1592a'    

  },
  menuitems: {
  background:'#f1592a'    
 },
 imgcircle: {
  borderRadius: '50%',
  width: '32px',
  margin: '0 12px'
}


}));
function RycomSidebar(props) {
  const [keycloak, initialized] = useKeycloak();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [prevState, setPrevState] = React.useState(false);
  const [prevOrgState, setPrevOrgState] = React.useState(false);
  const [prevTaskState, setPrevTaskState] = React.useState(false);

  const {focus, outOfFocus}=props;
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
    focus();
   };


  const handleDrawerClose = () => {
    setOpen(false);
    setPrevState(false)
    setPrevOrgState(false);
    setPrevTaskState(false);
    outOfFocus();
  };

  const handleChanges = () => {
    setPrevState(!prevState)
  }

  const handleOrgChanges = () => {
    setPrevOrgState(!prevOrgState)
  }

  const handleTaskChanges = () => {
    setPrevTaskState(!prevTaskState)
  }

  const getProfileInformation=()=>{
    UserService.getUserByKeycloakId(keycloak.subject)
    .then((res) => {
        if (res && res.data && res.data) {
            console.log(res.data)
            sessionStorage.setItem("userInfo",{parentcompanyid:res.data.parentcompanyid})
           history.push(`/topics/user/selfdetail/${res.data.userid}`)
        }
    })
    .catch((error) => {
        console.error('getUserById =>', error)
    })

  }

  const loginDetails=(keycloak && keycloak.authenticated)?
<span><Button color="secondary" variant="contained" size="small" 
                    onClick={(e) => keycloak.logout()}>logout</Button>
                    <Button color="secondary" variant="contained" size="small" 
                    onClick={(e) => getProfileInformation()}>Profile</Button></span>:
      <Button color="secondary" variant="contained" size="small" 
                    onClick={(e) => keycloak.login()}>login</Button>
  return (

    
  <div className={classes.root}>
     <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >



           </IconButton>
          

          <Typography variant="h4" noWrap>
       </Typography>

       {loginDetails}

        </Toolbar>
      </AppBar>

     {(keycloak && keycloak.authenticated) ?<Drawer
      onMouseOver={handleDrawerOpen}
    onMouseLeave={handleDrawerClose}
         variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
        </div>
        <Divider />
        <List >
        <List >
        
        {  (keycloak.hasRealmRole("view:org")|| keycloak.hasResourceRole("view:org"))?
        <ListItem button key="User">
        <ListItemIcon>
                  <PersonIcon style={{ color: '#f1592a' }}></PersonIcon>
           </ListItemIcon>
            <Link className={classes.navLinkText}
              to="/topics/user/" >
              <ListItemText  primary="User" />
            </Link>
          </ListItem>:""
      }
        
        {  (keycloak.hasRealmRole("view:org")|| keycloak.hasResourceRole("view:org"))?
          <ListItem
            button
            onClick={handleOrgChanges}>
               <ListItemIcon>
                  <PeopleIcon style={{ color: '#f1592a' }} ></PeopleIcon></ListItemIcon>
              <ListItemText className={classes.navLinkText} primary="Organisation" />
              {prevOrgState ?
              <ExpandLess /> :
              <ExpandMore />}
          </ListItem>:""
           }
     <Collapse
            in={prevOrgState}
            timeout="auto"
            unmountOnExit
          >
            <div>
           <ListItem 
                button
                className={classes.navLink}
              >
                <Link className={classes.navLinkText}
                    to="/topics/company" >
                  <ListItemText
                    inset
                    primary="Company"
                  />
                </Link>
              </ListItem>
               <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/company/venue" >
                  <ListItemText
                  className={classes.navLinkText}
                    inset
                    primary="Venue"
                  />
                </Link>
              </ListItem>
              </div>
          </Collapse>

      {  (keycloak.hasRealmRole("view:graph")|| keycloak.hasResourceRole("view:graph"))?
          <ListItem
            button
              onClick={handleChanges}>
        <ListItemIcon>
      <DataUsageIcon style={{ color: '#f1592a' }}></DataUsageIcon>
                </ListItemIcon>
        
            <ListItemText className={classes.navLinkText}

              primary="Data Services" />
            {prevState ?
              <ExpandLess /> :
              <ExpandMore />}
          </ListItem>:""
      }
          <Collapse
            in={prevState}
            timeout="auto"
            unmountOnExit
          >
            <div>
              <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/vertex/" >
                  <ListItemText
                    inset
                    primary="Vertex"
                  />
                </Link>
              </ListItem>
              <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/edge/" >
                  <ListItemText
                    inset
                    primary="Edge"
                  />
                </Link>

              </ListItem>
              <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/relation/" >
                  <ListItemText
                    inset
                    primary="Relationship"
                  />
                </Link>
              </ListItem>
            </div>
        
            <div>
              <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/documentvertex/" >
                  <ListItemText
                    inset
                    primary="Document Vertex"
                  />
                </Link>
              </ListItem>
              <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/documentedge/" >
                  <ListItemText
                    inset
                    primary="Document Edge"
                  />
                </Link>

              </ListItem>
         
            </div>
          </Collapse>
     
     
     
     
          {  (keycloak.hasRealmRole("view:graph")|| keycloak.hasResourceRole("view:graph"))?
          <ListItem
                button key="Data Mapper"
              >
                                <ListItemIcon>
                <ViewListIcon style={{ color: '#f1592a' }}>
                </ViewListIcon>
                </ListItemIcon>
              
                <Link className={classes.navLinkText}
                    to="/topics/datamapper/" >
               
                  <ListItemText
                    primary="Data Mapper"
                  />
                </Link>
              </ListItem>:""
      }

       {  (keycloak.hasRealmRole("view:connector")|| keycloak.hasResourceRole("view:connector"))?
                 <ListItem button key="connector">
                <ListItemIcon>
                <DeviceHubIcon style={{ color: '#f1592a' }}></DeviceHubIcon>
                </ListItemIcon>
                <Link className={classes.navLinkText}
                to="/topics/connector/" >
              <ListItemText primary="Connector" />

            </Link>
          </ListItem>:""
        }
       {  (keycloak.hasRealmRole("view:scheduler")|| keycloak.hasResourceRole("view:scheduler"))?
          <ListItem button key="scheduler">
                <ListItemIcon>
                  <AlarmIcon style={{ color: '#f1592a' }}></AlarmIcon>
                </ListItemIcon>
                <Link className={classes.navLinkText}
                to="/topics/scheduler/" >
              <ListItemText primary="Scheduler" />
            </Link>
          </ListItem>:""
        }  

{  (keycloak.hasRealmRole("view:task")|| keycloak.hasResourceRole("view:task"))?
          <ListItem
            button
            onClick={handleTaskChanges}>
               <ListItemIcon>
                  <PeopleIcon style={{ color: '#f1592a' }} ></PeopleIcon></ListItemIcon>
              <ListItemText className={classes.navLinkText} primary="Task" />
              {prevOrgState ?
              <ExpandLess /> :
              <ExpandMore />}
          </ListItem>:""
           }
     <Collapse
            in={prevTaskState}
            timeout="auto"
            unmountOnExit
          >
            <div>
           <ListItem 
                button
                className={classes.navLink}
              >
                <Link className={classes.navLinkText}
                    to="/topics/task/" >
                  <ListItemText
                    inset
                    primary="Vertex Task"
                  />
                </Link>
              </ListItem>
               <ListItem
                button
              >
                <Link className={classes.navLinkText}
                    to="/topics/edgetask/" >
                  <ListItemText
                  className={classes.navLinkText}
                    inset
                    primary="Edge Task"
                  />
                </Link>
              </ListItem>
              </div>
          </Collapse>

        </List>
        <Divider />
        <List>
        {  (keycloak.hasRealmRole("view:graph")|| keycloak.hasResourceRole("view:task"))?
          <ListItem button key="Org">
              <ListItemIcon><BubbleChartIcon style={{ color: '#f1592a' }} /></ListItemIcon>
              <Link className={classes.navLinkText} to="/task" target="_blank" onClick={(event) => {event.preventDefault(); window.open(`${environment.graphDbUrl}/studio/index.html`);}} >
                <ListItemText primary="Query - Data Analyser" />
           </Link>
          </ListItem>:""
        }
        </List>
        <List>
        {  (keycloak.hasRealmRole("view:graph")|| keycloak.hasResourceRole("view:task"))?
          <ListItem button key="Org">
              <ListItemIcon><BubbleChartIcon style={{ color: '#f1592a' }} /></ListItemIcon>
              <Link className={classes.navLinkText} to="/task" target="_blank" onClick={(event) => {event.preventDefault(); window.open(`https://rapidquerydev.rycom.com:4000/graphql`);}} >
                <ListItemText primary="GraphQL Analyser" />
           </Link>
          </ListItem>:""
        }
        </List>
        </List>
      </Drawer>:<div></div>
      }
   
    </div>
  );
}

export default withKeycloak(RycomSidebar)
