import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomInfoPair from '../../../common/RycomInfoPair'
import * as DocumentClassService from '../../../../services/DocumentClassService';
import { useParams } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';


import * as TaskService from '../../../../services/TaskService';
import * as SchedulerService from '../../../../services/SchedulerService';
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';


import RycomTable from '../../../common/RycomTable'
import RycomTableHeader from '../../../common/RycomTableHeader'




const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },

      mediumTitle: {
        margin: 0,
       fontSize: 'large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));
  

export default function VertexDetail() {

    let { id } = useParams();
    const classes = useStyles();
    const [vertexValues, setVertexValues] =useState();

    const history = useHistory();
    const [keycloak] = useKeycloak();
    const [taskDetails, setTaskDetails] = React.useState([])
    const [edgeDetails, setEdgeDetails] = React.useState([])



    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                DocumentClassService.deleteDocumentVertex(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/documentvertex");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Delete</Button>
        return DeleteButton;
    }




const createTaskDeleteButton = (taskId, taskName) => {
    const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
        onClick={(e) => {
            if (window.confirm('Are you sure you wish to delete this'+ taskName +' Task Item?')) 
            {
                TaskService.deleteTaskById(taskId).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/documentvertex/");
                    }
                })
                .catch((error) => {
                    alert('some error in deleting the Tasl')
                    console.error('Task Delete =>', error)
                })
            } else {
                console.log('Not Deleted');
            } 
        }}>Delete</Button>
    return DeleteButton;
}

const createStopJobButton = (taskValues) => {
    const startButton = <Button color="Red" variant="contained" size="small" value="Trigger Task"
        onClick={(e) => {
            const payload={
                scheduledTaskId:taskValues.taskid,
                companyId:taskValues.companyid,
                groupId:taskValues.connectionid
            }
            SchedulerService.stopScheduler(payload).then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data);
                    alert("Job Succesfully Stopped")
                    history.push("/topics/documentvertex");
                }
            })
                .catch((error) => {
                    alert('some error in starting the Job')
                    console.error('startTask =>', error)
                })
        }}>Stop the Running Jobs</Button>
    return startButton;
}





const createAllEdgeDeleteButton = (vertexName) => {
    const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
        onClick={(e) => {
            if (window.confirm('Are you sure to delete all the edges related to '+ vertexName +' Vertex Item?')) 
            {
              
                DocumentEdgeService.deleteEdgeByVertexType(vertexName).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Edges are deleted successfully")
                        history.push("/topics/documentvertex");
                    }
                })
                .catch((error) => {
                    alert('some error in deleting the Tasl')
                    console.error('Task Delete =>', error)
                })
            } else {
                console.log('Not Deleted');
            } 
        }}>Delete All Edge and Edge Tasks Related to this Vertex</Button>
    return DeleteButton;
}



const createEdgeDeleteButton = (edgeRelationId, edgeRelationName) => {
    const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
        onClick={(e) => {
            if (window.confirm('Are you sure to delete all the edges related to '+ edgeRelationName +' Vertex Item?')) 
            {
                DocumentEdgeService.deleteEdge(edgeRelationId).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Edge deleted successfully")
                        history.push("/topics/documentvertex");
                    }
                })
                .catch((error) => {
                    alert('some error in deleting the Tasl')
                    console.error('Task Delete =>', error)
                })
            } else {
                console.log('Not Deleted');
            } 
        }}>Delete Edge</Button>
    return DeleteButton;
}

const createAllTaskDeleteButton = (vertexId, vertexName) => {
    const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
        onClick={(e) => {
            if (window.confirm('Are you sure to delete all the tasks related to '+ vertexName +' Vertex Item?')) 
            {
                TaskService.deleteTaskByVertexId(vertexId).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Tasks are deleted successfully")
                        history.push("/topics/documentvertex");
                    }
                })
                .catch((error) => {
                    alert('some error in deleting the Tasl')
                    console.error('Task Delete =>', error)
                })
            } else {
                console.log('Not Deleted');
            } 
        }}>Delete All Running Tasks Related to this Vertex</Button>
    return DeleteButton;
}




    const createVertexDeleteContentButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                DocumentClassService.deleteDocumentVertexContent(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert("Records are deleted successfully in the Vertex")
                        history.push("/topics/documentvertex");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Delete Vertex Records</Button>
        return DeleteButton;
    }


    useEffect(() => {
        console.log(id)
        DocumentClassService.getVertexById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setVertexValues(res.data);
                    TaskService.getAllTaskByVertex(id).then((res) => {
                        if (res && res.data && res.data) {
                            setTaskDetails(res.data);
                          console.log(res.data);
                        }
                      })


                      DocumentEdgeService.getAllEdgeByVertexType(res.data.className).then((res) => {
                        if (res && res.data && res.data) {
                            setEdgeDetails(res.data);
                          console.log(res.data);
                        }
                      }) 


                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])

    let vertexproperties = ""


    let deleteButton="";        

    if(vertexValues && vertexValues.numberOfRecords>0){
        deleteButton= createVertexDeleteContentButton()  
    }else if(edgeDetails.length==0 && taskDetails.length==0){
        deleteButton=createDeleteButton()  
    }



    if(vertexValues && vertexValues.vertexproperties){
     vertexproperties = vertexValues.vertexproperties.map((vertexProperty => <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Key" value={vertexProperty ? vertexProperty.key : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Value" value={vertexProperty ? vertexProperty.value : ''}></RycomInfoPair>
        </Grid>
     </Grid>))
    }

    const columns = [
        { id: 'taskname', label: 'Task Name', minWidth: 170 },
        { id: 'taskType', label: 'Task Type', minWidth: 170 },
        { id: 'schedulerStatus', label: 'Job Status', minWidth: 170 },
        { id: 'companyName', label: 'Company', minWidth: 100 },
        { id: 'deleteTask', label: 'Delete Task', minWidth: 100 }
      ];
     

    const rows=[...taskDetails].map((task)=>{
        task.taskname=<Link to={`/topics/task/detail/${task.taskid}`} >{task.taskname}</Link>
        task.deleteTask= task.schedulerStatus=='Not Triggered'?createTaskDeleteButton(task.taskid, task.taskname):createStopJobButton(task);
        return task;
        });

        const edgeColumns = [
            { id: 'edgeName', label: 'Edge Name', minWidth: 120 },
            { id: 'fromClassName', label: 'From Vertex Name', minWidth: 120 },
            { id: 'fromPropertyName', label: 'From Property Name', minWidth: 120 },
            { id: 'toClassName', label: 'To Vertex Name', minWidth: 120 },
            { id: 'toPropertyName', label: 'To Property Name', minWidth: 120 },
            { id: 'deleteTask', label: 'Delete Task', minWidth: 120 }

          ];
      
      
           const edgeRows=[...edgeDetails].map((edge)=>{
            edge.edgeName=<Link  to={`/topics/documentedge/detail/${edge.edgeRelationshipId}`} >{edge.edgeName}</Link>
            edge.deleteTask=createEdgeDeleteButton(edge.edgeRelationshipId, edge.edgeName);
            return edge;
            });



    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/documentvertex"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:graph")?<Link className={classes.buttonStyle} to={'/topics/documentvertex/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 

    <span className={classes.buttonStyle} to="/topics/documentvertex">
        {deleteButton}
    </span>
  
  
  



 

  
    <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;
    return (<div>
        
        <Card className={classes.aocard}>
<div className={classes.header}>
<span className={classes.title} >
{vertexValues ? `Vertex Details about ${vertexValues.className}` : 
        'Vertes Details'} </span>
<span className={classes.toolbar}>
{buttonLinks}
</span>
</div>
</Card>



{taskDetails.length>0 ? 
       <span>
        <Card className={classes.aocard}>
    <div className={classes.header}>
    <span className={classes.mediumTitle} >
    Task Details
    </span>
    <span className={classes.toolbar}>
    {createAllTaskDeleteButton(id, vertexValues.className)}
    </span>
    </div>
    </Card>
        <RycomTable rows={rows} columns={columns}></RycomTable></span>:
        <div></div>}


{edgeDetails.length>0 ? 
       <span>
        <Card className={classes.aocard}>
    <div className={classes.header}>
    <span className={classes.mediumTitle} >
    Edge Details
    </span>
    <span className={classes.toolbar}>
    { createAllEdgeDeleteButton(vertexValues.className)}
    </span>
    </div>
    </Card>
        <RycomTable rows={edgeRows} columns={edgeColumns}></RycomTable></span>:
        <div></div>}



        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Name" value={vertexValues ? vertexValues.className : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Description" value={vertexValues ? vertexValues.classDescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Check for Duplicate Record" value={vertexValues ? (vertexValues.checkDuplicate?"TRUE":"FALSE") : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Maintain Historical Record - Changes" value={vertexValues ? (vertexValues.checkForHistoricalChanges?"TRUE":"FALSE")  : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Historical Vertex Name" value={vertexValues ? vertexValues.duplicateVertex : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Total Number of Records" value={vertexValues ? vertexValues.numberOfRecords : ''}></RycomInfoPair>
            </Grid>

                 </Grid>
    </div>
    );
}
