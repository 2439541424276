import React, { Component } from 'react'
import RycomTable from '../../../common/RycomTable'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomTableHeader from '../../../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import environment from '../../../../environment'
import * as DocumentEdgeService from '../../../../services/DocumentEdgeService';

class DocumentEdgeList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      edges: [],
    }
  }

  componentDidMount() {//5ec513ac32b4af33e72f4edb
    const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

    //DocumentEdgeService.getAllEdge(parentcompanyid)
    DocumentEdgeService.getAllEdge(paramID)
      .then((res) => {
        if (res && res.data && res.data) {
          let edges = res.data;
          this.setState({ edges: edges });
        }
      })
      .catch((error) => {
        console.error('getEdges =>', error)
        alert('Edge Retrieval Error');

      })
  }

  render() {
    const columns = [
      { id: 'edgeName', label: 'Edge Name', minWidth: 120 },
      { id: 'fromClassName', label: 'From Vertex Name', minWidth: 120 },
      { id: 'fromPropertyName', label: 'From Property Name', minWidth: 120 },
      { id: 'toClassName', label: 'To Vertex Name', minWidth: 120 },
      { id: 'toPropertyName', label: 'To Property Name', minWidth: 120 }
    ];


     const rows=this.state.edges.map((edge)=>{
      edge.edgeName=<Link  to={`/topics/documentedge/detail/${edge.edgeRelationshipId}`} >{edge.edgeName}</Link>
      console.log(edge.toPropertyName)
        //   if(edge.fromPropertyName && edge.fromPropertyName.length>0)
        //     edge.fromPropertyName=edge.fromPropertyName.join(",");

        //     if(edge.toPropertyName && edge.toPropertyName.length>0)
        //           edge.toPropertyName=edge.toPropertyName.join(",");
              return edge;
          });

     return (<div>
        <RycomPageHeader title="Document Edge List" links={[{title:'Add Document Edge',url:'/topics/documentedge/form', role:"create:graph"}]}></RycomPageHeader>
        <RycomTableHeader title="Document Edge" buttonTitle="Add Document Edge"></RycomTableHeader>
        <RycomTable rows={rows} columns={columns}></RycomTable>
      </div>);
    } 
}
export default DocumentEdgeList;
