import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator';


export const getAllConnector = () => {
    return axios.get(`${environment.connectorBaseUrl}/api/connect/getallconnectors`, addBearerToken()
    );
}

export const getConnectorById = (id) => {
    return axios.get(`${environment.connectorBaseUrl}/api/connect/getconnectorbyid/${id}`, addBearerToken()
    );
}

export const saveConnector = (connectorPayload) => {
    return axios.post(`${environment.connectorBaseUrl}/api/connect/create`,
         connectorPayload, addBearerToken()
         );
}

export const updateConnector = (connectorPayload) => {
    console.log(connectorPayload);
    return axios.put(`${environment.connectorBaseUrl}/api/connect/updateconnector`,
         connectorPayload, addBearerToken()
         );
}

export const deleteConnector = (id) => {
    return axios.patch(`${environment.connectorBaseUrl}/api/connect/deleteconnector/`,
    {
        "id": id
    }, addBearerToken()
    );
}

export const getConnectorFile = (type,configurationPayload) => {
    console.log("configurationPayload")
    
    console.log(JSON.stringify(configurationPayload))
     return axios.patch(`${environment.connectorBaseUrl}/api/connect/getFile/validate/${type}`,
        {
            ...configurationPayload,
        }, addBearerToken()
        );
}

export const getKeyFiles = () => {

    return axios.get(`${environment.connectorBaseUrl}/api/connect/sshKeyFileUpload`,addBearerToken());

}

export const uploadKeyFile = (connectorPayload) => {
    console.log("configurationPayload")
    
    console.log(JSON.stringify(connectorPayload))
    return axios.post(`${environment.connectorBaseUrl}/api/connect/sshKeyFileUpload`,
         connectorPayload, addBearerToken()
    );
}

export const updateKeyFile = (connectorPayload, fileName) => {
    console.log("configurationPayload")
    
    console.log(JSON.stringify(connectorPayload))
    return axios.put(`${environment.connectorBaseUrl}/api/connect/sshKeyFileUpload/${fileName}`,
         connectorPayload, addBearerToken()
    );
}

export const deleteKeyFile = (keyFileUpload) => {
    console.log("keyFileUpload")
    console.log(keyFileUpload)
    
    return axios.delete(`${environment.connectorBaseUrl}/api/connect/sshKeyFileUpload/${keyFileUpload}`,addBearerToken());
}

export const getEncFiles = () => {

    return axios.get(`${environment.connectorBaseUrl}/api/connect/pgpKeyFileUpload`,addBearerToken());

}

export const uploadEncryptionFile = (connectorPayload,params) => {
   
    return axios.post(`${environment.connectorBaseUrl}/api/connect/pgpKeyFileUpload?encryptionType=${params.encryptionType}&keyType=${params.keyType}`,
         connectorPayload,addBearerToken()
    );
}

export const updateEncryptionFile = (connectorPayload,params, encryptionFileName) => {
   
    return axios.post(`${environment.connectorBaseUrl}/api/connect/pgpKeyFileUpload/${encryptionFileName}?encryptionType=${params.encryptionType}&keyType=${params.keyType}`,
         connectorPayload,addBearerToken()
    );
}

export const deleteEncFile = (keyFileUpload) => {
    return axios.delete(`${environment.connectorBaseUrl}/api/connect/pgpKeyFileUpload/${keyFileUpload}`,addBearerToken());
}