import React from 'react';
import { useEffect, useState } from 'react'
import * as DataMapperService from '../../../services/DataMapperService';
import DataMapperEditTemplateForm from './DataMapperEditTemplateForm';
import { useParams } from 'react-router-dom'


export default function DataMapperEditForm() {

  let { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();


  useEffect(() => {
    DataMapperService
      .getDataMapperById(id)
      .then((res) => {
        if (res && res.data) {
          // console.log('getDataMapperById', res.data)
          let DataMapperData = {... res.data}

          setDefaultValues(DataMapperData);

        }
      })
      .catch((error) => {
        console.error('getConnector =>', error)
      })
  }, [])

  return (defaultValues) ?
    <DataMapperEditTemplateForm defaultValues={defaultValues}></DataMapperEditTemplateForm> : "";

}
