import React, { useEffect, useState } from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomTextField from '../common/RycomTextField'
import RycomSelectField from '../common/RycomSelectField'
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import RycomBrowseFile from '../common/RycomBrowseFile';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from '@material-ui/core';
import * as ConnectorService from "../../services/ConnectorService";
import FormControl from "@material-ui/core/FormControl";


const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    },
    radioLabel: {
      margin: "15px 10px",
      // display: "inline-flex",
      alignItems: "center",
      fontSize: "17px",
      fontWeight: "500"
    },
    rycomGridItem: {
      display:"flex"
    },
   
}));

const AuthenticationTypes = [
  { value:"keyFile", label:"Key File Authentication"},
  { value:"password", label:"Password"}
];

// const fileType = [
//   { value:"Existing", label:"Select Existing Key File"},
//   { value:"newImport", label:"Import New Key"}
// ];

const EncryptionTypes = [
  { value:"pgpEncryption", label:"PGP Encryption"}
];

export default function ConnectorSFTPConfiguration(props) {

  const classes = useStyles();
  const { connectorType, register, errors, control, connectorFlowType } = props;
  const [authenticationType, setAuthenticationType] = useState('');
  const [keyFileUpload, setkeyFileUpload] = useState('');
  
  const [selectExisting, setSelectExisting] = useState('');
  const [selectEncExisting, setSelectEncExisting] = useState('');
  const [encrptionEnabled, setEncrptionEnabled] = useState("false");
  const [decryptionsEnabled, setDecryptionsEnabled] = useState("false");

  const [enabledExtractFileName, setEnabledExtractFileName] = useState("true");
  const [existingFileStrategy, setExistingFileStrategy] = useState("replace");
  const [encryptionFileName, setEncryptionFileName] = useState("");
  const [enableBatchProcessing, setEnableBatchProcessing] = useState("true");
  
  const [chooseKeyFile, setChooseKeyFile] = useState("upload");
  const [chooseEncKeyFile, setChooseEncKeyFile] = useState("upload");

  const [fileType, setFileType] = useState([]);
  const [fileEncType, setFileEncType] = useState([]);
  const [selectKeyFile, setSelectKeyFile] = useState('newImport');
  
  const [encryptionType, setEncryptionType] = useState("");
  const [decryptionType, setDecryptionType] = useState("");

  console.log("inside ConnectorSFTPConfiguration")
  console.log(connectorType)
  const suffix = 'recursive'
  const pathLabel = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? 'Absoloute Directory Path' : 'Absolute File Path';
  
  const isRecursive = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? true : false;


  useEffect(() => {

    ConnectorService.getKeyFiles()
    .then((response) => {
      console.log(response)
      setFileType(response.data)
    })

    ConnectorService.getEncFiles()
    .then((response) => {
      console.log(response)
      setFileEncType(response.data)
    })

  },[keyFileUpload,encryptionFileName]);
    
  const keyFileUploadValidation = (event) => {

    let fileName = event[0].target.files[0].name;
    const data = fileName.split(".").pop();

    // if(data == "pem" || data == "ppk") {
      
      const uploadKeyFile = new FormData();

      // Update the formData object
      uploadKeyFile.append(
        "file",
        event[0].target.files[0],
        event[0].target.files[0].name
      );

      if(keyFileUpload == "") {

        ConnectorService.uploadKeyFile(uploadKeyFile)
        .then((response) => {
          if(response.data.statusCode == "OK") {
            setkeyFileUpload(fileName);
            alert(response.data.message);
          }
        })
        .catch((err) => {
          alert("Key file already exist");
        });

      } else {

        ConnectorService.updateKeyFile(uploadKeyFile,keyFileUpload)
        .then((response) => {
          if(response.data.statusCode == "OK") {
            setkeyFileUpload(fileName);
            alert(response.data.message);
          }
        })
        .catch((err) => {
          alert("Key file already exist");
        });

      }

      return fileName;
      
    // } else {

    //   setkeyFileUpload("");
    //   alert("Select a pem file or ppk file only");

    // }

  }

  const keyFileDelete = (event) => {


    ConnectorService.deleteKeyFile(keyFileUpload)
    .then((res) => {

      if(res.data.statusCode == "OK") {
        setkeyFileUpload("");
        alert(res.data.message);
      }

    })
  }
 
  const handleEncryptionFile = (event) => {

    let fileName = event[0].target.files[0].name;
    const data = fileName.split(".").pop();

    // if(data == "pgp") {

      const uploadEncryptionFile = new FormData();

      // Update the formData object
      uploadEncryptionFile.append(
        "file",
        event[0].target.files[0],
        event[0].target.files[0].name
      );

      if(decryptionType != "" || encryptionType != "") {
        
        const encType = connectorType == "inbound" ? "privateKey" : "publicKey";
  
        const params = {
          encryptionType: connectorType == "inbound" ? decryptionType : encryptionType,
          keyType:encType
        }

        if(encryptionFileName == "") {
  
          ConnectorService.uploadEncryptionFile(uploadEncryptionFile,params).then((response) => {
              
              if(response.data.statusCode == "OK") {
                setEncryptionFileName(fileName);
                alert(response.data.message);
              }
              
          })
          .catch((err) => {
            alert("Key file already exist");
          });

        } else {
            ConnectorService.updateEncryptionFile(uploadEncryptionFile,params, encryptionFileName).then((response) => {
                
                if(response.data.statusCode == "OK") {
                  setEncryptionFileName(fileName);
                  alert(response.data.message);
                }
                
            })
            .catch((err) => {
              alert("Key file already exist");
            });
        }
          
          return fileName;
      } else {
        alert("Select Encryption Type");
      }


    // } else {
    //   alert("Select a pgp file only");
    // }

  }

  const encryptionFileDelete = (event) => {

    ConnectorService.deleteEncFile(encryptionFileName)
    .then((res) => {

      if(res.data.statusCode == "OK") {
        setEncryptionFileName("");
        alert(res.data.message);
      }

    })
  }

  const handleDecryptionsEnabled = (event) => {
    setDecryptionsEnabled(event.target.value);
    setDecryptionType("");
    setEncryptionFileName("");
  };

  return (
    <div>
      {(connectorFlowType == "outbound" || connectorFlowType == "inbound") &&
      <>
      <RycomSectionHeader title="Server Information" />
      <RycomGrid>
       <RycomGridItem>
          <RycomTextField id="Host"
            label="host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Host is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="Port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Port is required'
              }
            }} />
        </RycomGridItem>
        {connectorFlowType == "outbound" && 
          <RycomGridItem>
            <RycomTextField id={pathLabel}
              label={pathLabel}
              name="filePath"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: pathLabel + ' is required'
                }
              }} />
          </RycomGridItem>
        }
      </RycomGrid>
      {connectorFlowType == "inbound" &&
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id={pathLabel}
            label={pathLabel}
            name="filePath"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: pathLabel + ' is required'
              }
            }} />
        </RycomGridItem>
        {!isRecursive && 
          <RycomGridItem>
            <RycomTextField id="fileName"
              label="File name"
              name="fileName"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: pathLabel + ' is required'
                }
              }} />
          </RycomGridItem>
        }
      </RycomGrid>
      }

      {connectorFlowType == "outbound" && 
        <>
          <Grid container spacing={12} style={{ padding: 2 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                <label className={classes.radioLabel}>Extract file name from Inbound Connector</label>
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
              <RadioGroup row aria-label="position" name="enabledExtractFileName" value={enabledExtractFileName} onChange={(e) => setEnabledExtractFileName(e.target.value)}>
                    <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                    />
                </RadioGroup>
              </Grid>
              {enabledExtractFileName == "false" && 
                <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                  <RycomTextField id="fileName1"
                    label="File Name"
                    name="fileName"
                    register={register}
                    errors={errors}
                    errorDescription={{
                      required: {
                        value: true,
                        message: 'File Name is required'
                      }
                    }} />
                </Grid>
              }
          </Grid> 


          <Grid container spacing={12} style={{ padding: 2 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                <label className={classes.radioLabel}>If the File Name already exist In the FTP server</label>
            </Grid>
            <Grid item xs={12} sm={8} lg={8} xl={8} className={classes.rycomGridItem}>

              <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="existingFileStrategy"
                    id="existingFileStrategy"
                    register={register}
                    defaultValue={existingFileStrategy}
                    as={
                      <RadioGroup row aria-label="position" name="existingFileStrategy" value={existingFileStrategy} >
                        <FormControlLabel
                            value="replace"
                            control={<Radio color="primary" />}
                            label="Replace the Existing File"
                            labelPlacement="end"
                            onChange={(e) => setExistingFileStrategy(e.target.value)}
                        />
                        <FormControlLabel
                            value="rename"
                            control={<Radio color="primary" />}
                            label="Rename the Existing File"
                            labelPlacement="end"
                            onChange={(e) => setExistingFileStrategy(e.target.value)}
                        />
                        <FormControlLabel
                            value="retain"
                            control={<Radio color="primary" />}
                            label="Retain the Existing File"
                            labelPlacement="end"
                            onChange={(e) => setExistingFileStrategy(e.target.value)}
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
              </Grid>
          </Grid> 
          <Grid container spacing={12} style={{ padding: 2 }} className={classes.rycomGridItem}>
            <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                <label className={classes.radioLabel}>If number of records/rows exceeds more than 10,000</label>
            </Grid>
            <Grid item xs={12} sm={8} lg={8} xl={8} className={classes.rycomGridItem}>
              <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="enableBatchProcessing"
                    id="enableBatchProcessing"
                    register={register}
                    defaultValue={enableBatchProcessing}
                    as={
                      <RadioGroup row aria-label="position" name="enableBatchProcessing" value={enableBatchProcessing} >
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Break into many Files with 10,000 records"
                            labelPlacement="end"
                            onChange={(e) => setEnableBatchProcessing(e.target.value)}
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="Store the records in single File"
                            labelPlacement="end"
                            onChange={(e) => setEnableBatchProcessing(e.target.value)}
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
              </Grid>
          </Grid> 
        </>
      }

      <RycomSectionHeader title="Authentication Details " />
      <RycomGrid>
        <RycomGridItem>
          <RycomSelectField 
              id="authenticationType"
              label="Authentication Type"
              name="authenticationType"
              onChangeHandler={(event) => {
                setAuthenticationType(event[0].target.value);
                return event[0].target.value;
              }}
              register={register}
              list={AuthenticationTypes}
              control={control}
              errors={errors}
              errorDescription={{
                  required: {
                      value: true,
                      message: 'Authentication Type is required'
                  }
              }}
          >
            {AuthenticationTypes.map((AuthType1, index) => (
              <MenuItem key={++index} value={AuthType1.value}>
                {AuthType1.label}
              </MenuItem>
            ))}
          </RycomSelectField>
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="User Name"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'User Name is required'
              }
            }} />
        </RycomGridItem>



        {authenticationType == "password" && 
          <RycomGridItem>
            <TextField
                  id="outlined-multiline-flexible"
                  label="Password*"
                  variant="outlined"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'password is required'
                    }})}
                  name="password"
                  type="password"/>
                <span className={classes.invalidMessage}>
                  {errors['password'] && <span>{errors['password'].message}</span>}
              </span>
          </RycomGridItem>
        }

    {authenticationType == "keyFile" && 
          <RycomGridItem>
            <TextField
                  id="outlined-multiline-flexible"
                  label="PassPhrase"
                  variant="outlined"
                  inputRef={register({
                  })}
                  name="passPhrase"
                  type="password"/>
                <span className={classes.invalidMessage}>
                  {errors['password'] && <span>{errors['password'].message}</span>}
              </span>
          </RycomGridItem>
        }
        
      </RycomGrid>
      <RycomGrid>
        {authenticationType == "keyFile" && 
          <>
            <RycomGridItem>
              <label className={classes.radioLabel}>Private Key File: </label>
            </RycomGridItem>
            <RycomGridItem>
              <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="sshKeyFileOption"
                    id="sshKeyFileOption"
                    register={register}
                    defaultValue={chooseKeyFile}
                    as={
                      <RadioGroup row aria-label="position" name="sshKeyFileOption" value={chooseKeyFile} >
                        <FormControlLabel
                            value="existing"
                            control={<Radio color="primary" />}
                            label="Select Existing File"
                            labelPlacement="end"
                            onChange={(e) => setChooseKeyFile(e.target.value)}
                        />
                        <FormControlLabel
                            value="upload"
                            control={<Radio color="primary" />}
                            label="Upload New File"
                            labelPlacement="end"
                            onChange={(e) => setChooseKeyFile(e.target.value)}
                        />
                      </RadioGroup>
                    }
                  />
              </FormControl>
            </RycomGridItem>
            {chooseKeyFile == "existing" && 
              <RycomGridItem>
                <RycomSelectField 
                  id="selectKey"
                  label="Select Existing Key File"
                  name="selectKey"
                  onChangeHandler={(event) => {
                    setSelectExisting(event[0].target.value);
                    return event[0].target.value; 
                  }}
                  value={selectExisting}
                  register={register}
                  // list={AuthenticationTypes}
                  control={control}
                  errors={errors}
                  // errorDescription={{
                  //     required: {
                  //         value: true,
                  //         message: 'Authentication Type is required'
                  //     }
                  // }}
                >
                  {fileType.map((type, index) => (
                    <MenuItem key={++index} value={type.fileName}>
                      {type.fileName}
                    </MenuItem>
                  ))}
                </RycomSelectField>
              </RycomGridItem>
            }
            {chooseKeyFile == "upload" && 
              <RycomGridItem>
                  <RycomBrowseFile 
                      id="sshPrivateKeyFileName"
                      label={keyFileUpload == "" ? "Add Key File" : "Update Key File"}
                      name="sshPrivateKeyFileName"
                      onChangeHandler={keyFileUploadValidation}
                      onDeleteHandler={() => {
                        if(window.confirm("Are you sure you want to delete Key File?")) {
                          keyFileDelete();
                        }
                      } }
                      register={register}
                      control={control}
                      errors={errors}
                      file={keyFileUpload}
                      // errorDescription={{
                      //     required: {
                      //         value: true,
                      //         message: 'Key file is required'
                      //     }
                      // }}
                  >
                  </RycomBrowseFile>
            </RycomGridItem>
            }
          </>
        }
      </RycomGrid>
      
      {connectorFlowType == "inbound" ? 
        <>
          <RycomSectionHeader title="Decryption Details " />
          <RycomGrid>
              <Grid item xs={12} sm={2} lg={2} xl={2} className={classes.rycomGridItem}>
                <label className={classes.radioLabel}>Decrypt the file</label>
              </Grid>
              <Grid item xs={12} sm={2} lg={2} xl={2} className={classes.rycomGridItem}>
                <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="decryptionEnabled"
                    id="decryptionEnabled"
                    register={register}
                    defaultValue={decryptionsEnabled}
                    as={
                      <RadioGroup row aria-label="position" name="decryptionEnabled" value={decryptionsEnabled} >
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                            labelPlacement="end"
                            onChange={handleDecryptionsEnabled}
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                            labelPlacement="end"
                            onChange={handleDecryptionsEnabled}
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
              </Grid>
              {decryptionsEnabled == "true" && 
              <>
                <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                  <RycomSelectField 
                    id="decryptionType"
                    label="Encryption Type"
                    name="decryptionType"
                    onChangeHandler={(e) => {
                      setDecryptionType(e[0].target.value);
                      return e[0].target.value;
                    }}
                    register={register}
                    // list={EncryptionTypes}
                    control={control}
                    errors={errors}
                    errorDescription={{
                        required: {
                            value: true,
                            message: 'Encryption Type is required'
                        }
                    }}
                  >
                    {EncryptionTypes.map((EncType1, index) => (
                      <MenuItem key={++index} value={EncType1.value}>
                        {EncType1.label}
                      </MenuItem>
                    ))}
                  </RycomSelectField>
                </Grid>
                {/* {decryptionType != "" &&
                <>
                  <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem}>
                    <RycomBrowseFile 
                        id="pgpKeyFileName"
                        label="Browse Key File"
                        name="pgpKeyFileName"
                        onChangeHandler={handleEncryptionFile}
                        register={register}
                        control={control}
                        errors={errors}
                        file={encryptionFileName}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Encryption Key file is required'
                            }
                        }}
                    >
                    </RycomBrowseFile>
                  </Grid>
                </> 
                } */}
              </>
              }

{decryptionType != "" && 
          <RycomGridItem>
            <TextField
                  id="outlined-multiline-flexible"
                  label="PassPhrase"
                  variant="outlined"
                  inputRef={register({
                  })}
                  name="encryptionPassphrase"
                  type="password"/>
          </RycomGridItem>
        }

          </RycomGrid>
          


          {decryptionType != "" && 
            <RycomGrid>
            <RycomGridItem>
              <label className={classes.radioLabel}>PGP Private Key File: </label>
            </RycomGridItem>
            <RycomGridItem>
              <FormControl component="fieldset">
                <Controller
                control={control}
                name="pgpKeyFileOption"
                id="pgpKeyFileOption"
                register={register}
                defaultValue={chooseEncKeyFile}
                as={
                  <RadioGroup row aria-label="position" name="pgpKeyFileOption" value={chooseEncKeyFile} >
                  <FormControlLabel
                    value="existing"
                    control={<Radio color="primary" />}
                    label="Select Existing File"
                    labelPlacement="end"
                    onChange={(e) => setChooseEncKeyFile(e.target.value)}
                  />
                  <FormControlLabel
                    value="upload"
                    control={<Radio color="primary" />}
                    label="Upload New File"
                    labelPlacement="end"
                    onChange={(e) => setChooseEncKeyFile(e.target.value)}
                  />
                  </RadioGroup>
                }
                />
              </FormControl>
            </RycomGridItem>

            {chooseEncKeyFile == "existing" && 
              <RycomGridItem>
                
                <RycomSelectField 
                    id="selectEncFile"
                    label="Select Existing Key File"
                    name="selectEncFile"
                    onChangeHandler={(event) => {
                      setSelectEncExisting(event[0].target.value);
                      return event[0].target.value; 
                    }}
                    register={register}
                    control={control}
                    errors={errors}
                  >
                    {fileEncType.map((type, index) => (
                      <MenuItem key={++index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </RycomSelectField>
              </RycomGridItem>
            }
            {chooseEncKeyFile == "upload" && 
              <RycomGridItem>
                  <RycomBrowseFile 
                      id="pgpKeyFileName"
                      label={encryptionFileName == "" ? "Add Key File" : "Update Key File"}
                      name="pgpKeyFileName"
                      onChangeHandler={handleEncryptionFile}
                      onDeleteHandler={() => {
                        if(window.confirm("Are you sure you want to delete File?")) {
                          encryptionFileDelete();
                        }
                      } }
                      register={register}
                      control={control}
                      errors={errors}
                      file={encryptionFileName}
                      // errorDescription={{
                      //     required: {
                      //         value: true,
                      //         message: 'Encryption Key file is required'
                      //     }
                      // }}
                  >
                  </RycomBrowseFile>
              </RycomGridItem>
            }
          </RycomGrid>
          }
        </>
      : 
      <>
        <RycomSectionHeader title="Encryption Details " />
        <RycomGrid>
            <Grid item xs={12} sm={2} lg={2} xl={2} className={classes.rycomGridItem}>
              <label className={classes.radioLabel}>Encrypt the file</label>
            </Grid>
            <Grid item xs={12} sm={2} lg={2} xl={2} className={classes.rycomGridItem}>
              <FormControl component="fieldset">
                  <Controller
                    control={control}
                    name="encryptionEnabled"
                    id="encryptionEnabled"
                    register={register}
                    defaultValue={encrptionEnabled}
                    as={
                      <RadioGroup row aria-label="position" name="decryptionEnabled" value={encrptionEnabled} >
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                            labelPlacement="end"
                            onChange={(e) => {
                              setEncrptionEnabled(e.target.value);
                              setEncryptionType("");
                              setEncryptionFileName("");
                            }}
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                            labelPlacement="end"
                            onChange={(e) => {
                              setEncrptionEnabled(e.target.value);
                              setEncryptionType("");
                              setEncryptionFileName("");
                            }}
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
            </Grid>
            {encrptionEnabled == "true" && 
            <>
              <Grid item xs={12} sm={4} lg={4} xl={4} className={classes.rycomGridItem}>
                <RycomSelectField 
                  id="EncryptionType"
                  label="Encryption Type"
                  name="EncryptionType"
                  register={register}
                  list={EncryptionTypes}
                  onChangeHandler={(e) => {
                    setEncryptionType(e[0].target.value);
                    return e[0].target.value;
                  }}
                  control={control}
                  errors={errors}
                  errorDescription={{
                    required: {
                        value: true,
                        message: 'Encryption Type is required'
                    }
                  }}
                >
                  {EncryptionTypes.map((EncType1, index) => (
                    <MenuItem key={++index} value={EncType1.value}>
                      {EncType1.label}
                    </MenuItem>
                  ))}
                </RycomSelectField>
              </Grid>
              {/* {encryptionType != "" && 
                <>
                  <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem}>
                    <RycomBrowseFile 
                        id="pgpKeyFileName1"
                        label="Browse Key File"
                        name="pgpKeyFileName"
                        onChangeHandler={handleEncryptionFile}
                        register={register}
                        control={control}
                        errors={errors}
                        file={encryptionFileName}
                        errorDescription={{
                            required: {
                                value: true,
                                message: 'Encryption Key file is required'
                            }
                        }}
                    >
                    </RycomBrowseFile>
                  </Grid>
                </>
              } */}
            </>
            }
            

        </RycomGrid>
        {encryptionType != "" &&
          <RycomGrid>
          <RycomGridItem>
            <label className={classes.radioLabel}>Private Key File: </label>
          </RycomGridItem>
          <RycomGridItem>
            <FormControl component="fieldset">
              <Controller
              control={control}
              name="pgpKeyFileOption"
              id="pgpKeyFileOption1"
              register={register}
              defaultValue={chooseEncKeyFile}
              as={
                <RadioGroup row aria-label="position" name="pgpKeyFileOption" value={chooseEncKeyFile} >
                <FormControlLabel
                  value="existing"
                  control={<Radio color="primary" />}
                  label="Select Existing File"
                  labelPlacement="end"
                  onChange={(e) => setChooseEncKeyFile(e.target.value)}
                />
                <FormControlLabel
                  value="upload"
                  control={<Radio color="primary" />}
                  label="Upload New File"
                  labelPlacement="end"
                  onChange={(e) => setChooseEncKeyFile(e.target.value)}
                />
                </RadioGroup>
              }
              />
            </FormControl>
          </RycomGridItem>
          {chooseEncKeyFile == "existing" && 
            <RycomGridItem>
              
              <RycomSelectField 
                  id="selectEncFile1"
                  label="Select Existing Key File"
                  name="selectEncFile"
                  onChangeHandler={(event) => {
                    setSelectEncExisting(event[0].target.value);
                    return event[0].target.value; 
                  }}
                  register={register}
                  control={control}
                  errors={errors}
                >
                  {fileEncType.map((type, index) => (
                    <MenuItem key={++index} value={type.name}>
                      {type.name}
                    </MenuItem>
                  ))}
                </RycomSelectField>
            </RycomGridItem>
          }
          {chooseEncKeyFile == "upload" && 
            <RycomGridItem>
                <RycomBrowseFile 
                    id="pgpKeyFileName"
                    label={encryptionFileName == "" ? "Add Key File" : "Update Key File"}
                    name="pgpKeyFileName"
                    onChangeHandler={handleEncryptionFile}
                    onDeleteHandler={() => {
                      if(window.confirm("Are you sure you want to delete File?")) {
                        encryptionFileDelete();
                      }
                    } }
                    register={register}
                    control={control}
                    errors={errors}
                    file={encryptionFileName}
                    // errorDescription={{
                    //     required: {
                    //         value: true,
                    //         message: 'Encryption Key file is required'
                    //     }
                    // }}
                >
                </RycomBrowseFile>
            </RycomGridItem>
          }
        </RycomGrid>    
        }
      </>
      } 
      </>
    }
      
    </div>
  );
}
