import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import * as OrganisationService from '../../../services/OrganisationService';


import { useParams } from 'react-router-dom'


export default function OrganisationDetail() {

    let { id } = useParams();
    const [orgValues, setOrgValues] =useState();

    useEffect(() => {
        console.log(id)
        OrganisationService.getCompanyById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setOrgValues(res.data);
                }
            })
            .catch((error) => {
                console.error('getCompanyById =>', error)
            })
    }, [])

    const orgContacts=orgValues?
    orgValues.contacts.map(contact=><Grid container spacing={12} style={{ padding: 24 }}>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="First Name" value={contact ? contact.firstname : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Last Name" value={contact ? contact.lastname : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Email Address" value={contact ? contact.emailaddress : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Phone" value={contact ? contact.phone : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Contact Type" value={contact ? contact.contacttype : ''}></RycomInfoPair>
        </Grid>
    </Grid>
    ):<div>No Contact for this Org</div>

    const metaDataProperties=orgValues?
    orgValues.metadata.map(metadata=><Grid container spacing={12} style={{ padding: 24 }}>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Key" value={metadata ? metadata.key : ''}></RycomInfoPair>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={2}>
        <RycomInfoPair label="Last Name" value={metadata ? metadata.value : ''}></RycomInfoPair>
    </Grid>
    </Grid>
    ):<div>No Metadata Properties for this Org</div>


    return (<div>
        <RycomPageHeader title={orgValues ? `Company Details about ${orgValues.companyname}` : 'Organisation Details'} links={[{ title: 'Back', url: '/topics/company' }, { title: 'Edit', url: '/topics/connector' }]}></RycomPageHeader>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="organisation" value={orgValues ? orgValues.companyname : ''}></RycomInfoPair>
            </Grid>
           
        </Grid>
        <div>
            <RycomSectionHeader title="Venue Address Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address1" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.address1 : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address2" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.address2 : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Address3" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.address3 : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="City" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.city : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="State" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.state : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Country" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.country : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="zip" value={orgValues && orgValues.companyAddress ? orgValues.companyAddress.zip : ''}></RycomInfoPair>
                </Grid>
            </Grid>
            <RycomSectionHeader title="Venue Contact Details"></RycomSectionHeader>
                {orgContacts}
            <RycomSectionHeader title="MetaData Properties"></RycomSectionHeader>
                 {metaDataProperties}

        </div>
    </div>
    );
}
