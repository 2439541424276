import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import * as DeviceService from '../../../services/DeviceService';

import { useParams } from 'react-router-dom'


export default function DeviceDetail() {

    let { id } = useParams();
    const [deviceValues, setDeviceValues] =useState();

    useEffect(() => {
        console.log(id)
        DeviceService.getDeviceById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setDeviceValues(res.data);
                }
            })
            .catch((error) => {
                console.error('getCompanyById =>', error)
            })
    }, [])

    
    return (<div>
        <RycomPageHeader title={deviceValues ? `Device Details: ${deviceValues.devicename}` : 'Task Details'} links={[{ title: 'Back', url: '/topics/company/device' }, { title: 'Edit', url: '/topics/company/device' }]}></RycomPageHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Device Name" value={deviceValues ? deviceValues.devicename : ''}></RycomInfoPair>
                </Grid>
          
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Device Description" value={deviceValues ? deviceValues.devicedescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Organisation" value={deviceValues ? deviceValues.companyName : ''}></RycomInfoPair>
            </Grid>
       
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Venue Name" value={deviceValues ? deviceValues.venueName : ''}></RycomInfoPair>
                </Grid>
        </Grid>        
                <RycomSectionHeader title="Active Zone"></RycomSectionHeader>
                  <Grid container spacing={12} style={{ padding: 24 }}>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Zone Name" value={deviceValues && deviceValues.zoneName ? deviceValues.zoneName[0] : ''}></RycomInfoPair>
                </Grid>
            </Grid>
            </div>
      );
    }
